import React from "react";

const AdsCommercial = () => {
  return(
    <>
    <div className="px-5">
      <div className="md:grid-cols-2 xl:gap-[67px] gap-[30px] xl:max-w-[1186px] 2xl:mt-[-28px] 2xl:max-w-[1440px] mx-auto 4xl:max-w-[1930px]">
        <div className="p-14">
          <p className="4xl:text-[35px] xl:text-[27px] text-[20px] text-black font-medium block text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of
            type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of
            Lorem Ipsum.
          </p>
          <div className="flex justify-center w-full mt-[60px] h-[400px]">
            <img src={require("../assets/images/advertising.jpg")} />
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default AdsCommercial;
