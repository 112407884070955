import React, { useEffect, useState } from "react";
import { ChatRoomDto, useUnreadCountList } from "../../API/api";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import CircularProgressBar from "../CircularProgressBar";
import { useTranslation } from "react-i18next";
import { smartStorage } from "../../utils/SmartStorage";
import { useResetChatList } from "../services/Mutation";
import moment from "moment";
import { ClockIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { useServiceCategoryQuery } from "../../API/api";
import { MessageSqureIcon } from "../../assets/images/AllSvg";

interface ChatDto {
  roomId: number;
}

interface Skill {
  id: number;
  name: string;
}

const ChatList = ({
  menu,
  setMenu,
  chatList,
  onChatRoomSelected,
  selectedChatRoomId,
  isLoading,
  setChatRoomInfo,
}: {
  isLoading: boolean;
  menu: boolean;
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
  chatList: ChatRoomDto[] | undefined;
  onChatRoomSelected: (chatRoom: ChatRoomDto) => void;
  selectedChatRoomId: number;
  setChatRoomInfo: React.Dispatch<React.SetStateAction<ChatRoomDto | null>>;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: chatCountData } = useUnreadCountList();
  const resetChatMutation = useResetChatList();
  const { data: skillData, isLoading: isSkillLoading } =
    useServiceCategoryQuery();
  const { t } = useTranslation();
  useEffect(() => {
    const storedChatRoomId = smartStorage.getItem("openChatRoomId");
    if (storedChatRoomId) {
      setChatRoomInfo(JSON.parse(storedChatRoomId));
    }
  }, []);

  const handleChatItemClick = async (
    item: ChatRoomDto,
    unreadCount: number
  ) => {
    smartStorage.setItem("openChatRoomId", JSON.stringify(item));
    onChatRoomSelected(item);
    if (unreadCount > 0) {
      try {
        await resetChatMutation.mutateAsync({ roomId: item.chatRoomId });
      } catch (error) {
        toast.error("Error resetting chat list:");
        console.error("Error resetting chat list:", error);
      }
    }
  };

  const filteredChatList = chatList
    ?.filter((chatRoom) =>
      chatRoom.userName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (!a.timeStamp && !b.timeStamp) return 0; // HANDLE TIMESTAMP IS UNDEFINED
      if (!a.timeStamp) return 1;
      if (!b.timeStamp) return -1;
      const timeA = new Date(a.timeStamp).getTime();
      const timeB = new Date(b.timeStamp).getTime();
      return timeB - timeA; //MOST RECENT FIRST
    });

  return (
    <div className="relative">
      <div className="px-[16px] py-[8px] border-b-[1px] border-[#E9E8E3]">
        <div className="relative">
          <input
            type="text"
            placeholder={String(t("Search contacts"))}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full border-[#D1D0CA] border-[1px] focus:outline-none rounded-[4px] h-[37px] indent-[30px]"
          />
          <div className="absolute top-2 left-2 ">
            <IoSearchSharp />
          </div>
        </div>
      </div>
      <div className="overflow-y-auto scroll-remove h-[calc(100vh-215px)]">
        {isLoading ? (
          <div className="flex items-center justify-center mt-8">
            <CircularProgressBar />
          </div>
        ) : filteredChatList && filteredChatList.length > 0 ? (
          filteredChatList.map((val: ChatRoomDto, i: number) => {
            return ChatRoomRow(
              val,
              handleChatItemClick,
              selectedChatRoomId,
              chatCountData,
              skillData
            );
          })
        ) : (
          <div className="flex items-center justify-center mt-8 text-gray-500">
            {t("No results found")}
          </div>
        )}
      </div>

      <div
        className={`absolute top-[8px] right-[-40px] p-[3px] border-[1px] border-[#E9E8E3] rounded-[4px] ${
          menu ? "block" : "hidden"
        }`}
        onClick={() => setMenu(false)}
      >
        <IoClose className="text-[24px]" />
      </div>
    </div>
  );
};

export default ChatList;

const ChatRoomRow = (
  chatRoom: ChatRoomDto,
  handleChatItemClick: (item: ChatRoomDto, unreadCount: number) => void,
  selectedChatRoomId: number,
  chatCountData: any,
  skillData: any
) => {
  const correspondingUser = chatCountData?.find(
    (chat: any) => chat?.roomId === chatRoom?.chatRoomId
  );
  const unreadCount = correspondingUser?.unreadCount ?? 0;
  const truncateMessage = (message: string, maxLength = 20) => {
    if (message.length <= maxLength) return message;
    return `${message.slice(0, maxLength - 3)}...`;
  };

  const formatMessageTime = (timestamp: string) => {
    if (!timestamp) return "";

    const messageDate = moment(timestamp);
    const now = moment();

    if (now.isSame(messageDate, "day")) {
      return messageDate.format("h:mm A");
    } else if (now.subtract(1, "day").isSame(messageDate, "day")) {
      return "Yesterday";
    } else if (now.diff(messageDate, "days") < 7) {
      return messageDate.format("dddd");
    } else if (now.diff(messageDate, "years") < 1) {
      return messageDate.format("MMM D");
    } else {
      return messageDate.format("MM/DD/YYYY");
    }
  };
  if (!chatRoom.timeStamp) {
    return null;
  }

  const skillName =
    chatRoom?.skillMasterIds && chatRoom?.skillMasterIds?.length > 0
      ? skillData.find(
          (skill: Skill) => skill?.id === chatRoom?.skillMasterIds[0]
        )?.name || ""
      : "";
  return (
    <div
      className="flex items-center p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-50"
      onClick={() => handleChatItemClick(chatRoom, unreadCount)}
    >
      <img
        src={require(`../../assets/images/user_default.png`)}
        alt="Profile"
        className="w-10 h-10 mr-4 rounded-full"
      />
      <div className="flex flex-col flex-grow">
        <div className="flex items-center justify-between mb-1">
          <div className="w-full">
            <div>
              <h3 key={chatRoom.chatRoomId} className="text-base font-semibold">
                {skillName}

                {unreadCount > 0 && (
                  <div className="ml-1 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full dark:border-gray-900">
                    {unreadCount}
                  </div>
                )}
              </h3>
            </div>

            <div className=" flex justify-between pt-2 font-semibold text-blue-800  w-full ">
              <div className="text-base">{chatRoom?.userName}</div>
              <div className="text-xs text-gray-500 flex items-center">
                <ClockIcon className="w-3 h-3 mr-1 text-gray-300" />
                {formatMessageTime(chatRoom?.timeStamp)}
              </div>
            </div>
          </div>
        </div>
        <p className="text-sm flex items-center text-gray-600 w-full gap-1">
          <MessageSqureIcon />
          {truncateMessage(chatRoom?.lastMessage || "")}
        </p>{" "}
      </div>
    </div>
  );
};
