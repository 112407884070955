import React from "react";
import { Quotes } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";

const ReplyQoutes = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#FFFCF6] py-[128px] px-5">
      <div className="max-w-[903px] flex flex-col items-center mx-auto ">
        <Quotes />
        <span className="text-[#F0A61F] text-[18px] font-bold">
          {t("OurTestimonial")}
        </span>
        <h2 className="text-[#162634] font-bold mb-[25px] lg:text-[48px] md:text-[28px] text-[24px] text-center">
          {t("Someofourrespectedhappyclientssays")}
        </h2>
        <p className="4xl:text-[1vw] lg:text-[20px] text-[16px] text-center text-[#666666] italic font-medium">
          {/* {t("LoremIpsum")} */}
          {t("contactPara3")}
          {t("contactPara4")}

          {/* {t("LoremIpsum")} */}
        </p>
        <div className="flex items-center gap-[27px] mt-[24px]">
          <div>
            <img
              src={require("../../assets/images/Maskgroup.png")}
              className="4xl:w-[100px] 4xl:h-[100px]  w-[69px] h-[69px] rounded-full"
            />
          </div>
          <div>
            <h4 className="text-[#162634] 4xl:text-[30px] text-[21px] font-bold">
              {t("JohnDeo")}
            </h4>
            <span className="text-[#666666] 4xl:text-[27px] text-[18px] font-medium">
              {t("ManagerHowarts")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyQoutes;
