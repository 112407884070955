import { useTranslation } from "react-i18next";

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="px-5">
      <div className="md:grid-cols-2 xl:gap-[67px] gap-[30px] xl:max-w-[1186px] 2xl:mt-[-28px] 2xl:max-w-[1440px] mx-auto 4xl:max-w-[1930px]">
        <h1 className="ml-2 text-4xl font-bold text-center mb-7 lg:text-left mt-14">
          {t("About Us")}
        </h1>
        <div className="min-h-screen pt-10 pl-8 pr-8 bg-gray-100 rounded-xl">
          <div className="flex flex-col items-center justify-center lg:flex-row lg:items-start ">
            <div className="lg:w-1/2">
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("AboutPagePara1 Eaidex is a leading provider")}
              </p>
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("AboutPagePara2 Our platform offers")}
              </p>
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("AboutPagePara3 Our extensive network")}
              </p>

              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("AboutPagePara4 As a Private Limited")}
              </p>
            </div>
            <div className="order-first w-full lg:w-1/2 md:order-last lg:order-last ">
              <img
                src={require("../assets/images/about-us-modified.png")}
                className="w-full h-auto"
                alt="Report Abuse Image"
              />
            </div>
          </div>

          <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
            {t(
              "Whether you need a plumber to fix a leaky faucet, a carpenter to repair your furniture, a gardener to take care of your lawn, or a taxi to take you to your destination, Eaidex has got you covered. With our platform, you can enjoy the convenience of on-demand services and the peace of mind that comes with knowing that you are in good hands."
            )}
          </p>
          <p className=" 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
            {t("AboutPagePara5 At Eaidex, we are dedicated")}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
