import React from "react";
import {
  ProfileViewIcon,
  FavouriteIcon,
  SettingsIcon,
  StarIcon,
  WalletIcon,
} from "../../assets/images/AllSvg";
import { UserRole } from "../../API/types";
import { hasRole } from "../../utils/utils";
import { useLabourerDashboardLegend } from "../../API/api";

interface InfoBoxData {
  id: number;
  bgGradient: string;
  icon: JSX.Element;
  title: string;
  value: number | string;
}

const InfoBox: React.FC<any> = ({ bgGradient, icon, title, value }) => (
  <div className="boxMainDiv w-[221px] h-[98px] 3xl:w-[425px] 3xl:h-[140px] snap-start flex-shrink-0">
    <div
      className={`bg-gradient-to-b ${bgGradient} rounded-lg p-4 border border-[#F0F0F0] h-full`}
    >
      <div className="flex items-center gap-2">
        <div>
          <div className="bg-white rounded-full p-2 w-10 h-10">{icon}</div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-roboto font-medium text-[15px] leading-[24px] tracking-tight text-[#F0A61F]">
            {title}
          </span>
          <span className="font-sans text-4xl  ml-3 font-semibold leading-none tracking-tight text-left">
            {value}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export const ProfileSummary: React.FC<any> = ({ userRole }) => {
  const labourerId = userRole?.labourerDetails?.id;

  const { data: legendData } = useLabourerDashboardLegend(labourerId);
  const isEmployer = hasRole(userRole?.user, UserRole.EMPLOYER);
  const isLabourer = hasRole(userRole?.user, UserRole.LABOURER);

  const LabourerData: InfoBoxData[] = [
    {
      id: 1,
      bgGradient: "from-white to-[#DEF1FF]",
      icon: <SettingsIcon />,
      title: "Total Jobs",
      value: legendData?.jobsObtainedCount || 0,
    },
    {
      id: 2,
      bgGradient: "from-white to-[#FEF7DA]",
      icon: <ProfileViewIcon />,
      title: "Total Profile Views",
      value: legendData?.profileViewsCount || 0,
    },
    {
      id: 3,
      bgGradient: "from-white to-[#FFE7DB]",
      icon: <FavouriteIcon />,
      title: "Favourites",
      value: legendData?.favouritesCount || 0,
    },
    {
      id: 4,
      bgGradient: "from-white to-[#F0DCFF]",
      icon: <WalletIcon />,
      title: "Wallet",
      value: legendData?.referralEarnings || 0,
    },
    {
      id: 5,
      bgGradient: "from-white to-[#E9FFF8]",
      icon: <StarIcon />,
      title: "Rating",
      value: legendData?.rating || 0,
    },
  ];

  let data: InfoBoxData[] = [];

  if (isEmployer && isLabourer) {
    data = [...LabourerData];
  } else if (isEmployer) {
    data = [
      ...LabourerData.filter(
        (item) => item.title !== "Referral" && item.title !== "Total Jobs"
      ),
    ];
  } else if (isLabourer) {
    data = LabourerData.filter((item) => item.title !== "Searches");
  }
  if (isEmployer && !isLabourer) {
    return null;
  }
  return (
    <div className="firstSubDiv flex gap-4 overflow-x-auto scroll-smooth snap-x hide-scrollbar overflow-scroll no-scrollbar">
      {data.map((item) => (
        <InfoBox
          key={item.id}
          bgGradient={item.bgGradient}
          icon={item.icon}
          title={item.title}
          value={item.value}
        />
      ))}
    </div>
  );
};

export default ProfileSummary;
