import { useRef } from "react";
import Text from "../component/Text/Text";

function RefundCancellation() {
  const scrolldivRef: any = useRef();
  const subRef = useRef(null);

  const sections = [{ label: "Subscription and Refund Policy", ref: subRef }];

  const fixedHeaderHeight = 140;

  const handleScrollToScope = (ref: any) => {
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;

      if (scrolldivRef) {
        scrolldivRef?.current.scroll({
          top: elementTop,
          behavior: "smooth"
        });
      }
    }
  };

  return (
    <>
      <header className="bg-gray-50   border-gray-500 border-b-2   top-0">
        <div className="  text-black text-2xl font-bold ml-[20%] font-Montserrat leading-10">
          Refund and Cancellation
        </div>
      </header>
      <div className="bg-white-600 flex   ">
        <div className="section1-main border-gray-500 border-r-2 h-screen   flex-shrink-0 w-[20%] pt-1 pr-1  ">
          <div className="section-sub bg-gray-100 h-screen">
            <ul className="list-disc pl-4 md:pl-14 pt-4 md:pt-16">
              {sections?.map((section, index) => (
                <li
                  key={index}
                  className=" cursor-pointer text-black hover:text-black text-xs font-normal font-robotoserif leading-none mb-3 "
                  onClick={() => handleScrollToScope(section.ref)}
                >
                  {section.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="section2-Main w-[80%]  pl-1 pt-1 mt-0">
          <div
            className="section2-sub bg-gray-100 p-3  overflow-scroll no-scrollbar scroll-smooth  h-screen "
            ref={scrolldivRef}
          >
            <div className="innerArea bg-white p-10 w-10/12 flex-col items-center justify-center">
              <div className="flex justify-center w-full h-[400px]  mb-[20px]">
                <img
                  src={require("../assets/images/refundandcancellation.jpg")}
                />
              </div>

              <div className="font-robotoserif text-black font-normal text-xs ml-4 ">
                <div ref={subRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase font-bold font-roboto mb-4 underline"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    SUBSCRIPTION AND REFUND POLICY:
                  </Text>
                  <Text
                    className="leading-[20px]   my-0.5 text-black text-justify text-xs tracking-[0.12px] font-normal font-roboto mb-3"
                    size="txtRobotoSerifRegular12"
                  >
                    Subscription Agreement: By subscribing to Eaidex, you agree
                    to pay the annual subscription fee of Indian Rupees 300/- in
                    order to register and have access the account profile of
                    Eaidex’s fully serviced search engine/classifieds on both
                    the website and application platforms.
                  </Text>
                  <ul className="ml-8 list-disc tracking-[.12px] ">
                    <li className="text-black font-roboto  ">
                      Subscriptions can be automatically renewed on an annual
                      basis. In such cases Users will be notified in advance of
                      renewal, and continuation of the subscription implies
                      acceptance of the renewal charge.
                    </li>
                    <li className="text-black font-roboto  mt-3">
                      Annual Subscription: Subscribers will be charged an annual
                      fee for access to Eaidex, our paid search engine. The
                      subscription fee excludes GST, and it is non-refundable.
                    </li>
                    <li className="text-black font-roboto  mt-3">
                      Cancellation: Subscribers can cancel their subscription at
                      any time, but no refunds will be provided for the
                      remaining subscription period. The GST component is also
                      non-refundable.
                    </li>
                    <li className="text-black font-roboto   mt-3">
                      Incentive System: Monthly Incentives: Eaidex operates an
                      incentive system for users. Incentives will be paid out
                      once a month to eligible participants. The exact details
                      of the incentive program will be outlined separately.
                    </li>

                    <li className="text-black font-roboto   mt-3">
                      Non-Refundable Fee: The subscription charge is
                      non-refundable under any circumstances. Once payment is
                      made, there will be no reimbursement, whether the
                      subscription is terminated voluntarily or involuntarily.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RefundCancellation;
