import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { True } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCompleteImageUrl } from "../../utils/GeneralUtils";
interface ServiceData {
  name: string;
  imageUrl: string;
}

interface OurServicesProps {
  benefitsRef: React.RefObject<HTMLDivElement>;
  serviceData: Array<ServiceData>;
  targetId: string;
}

const OurServices: React.FC<OurServicesProps> = ({
  benefitsRef,
  serviceData,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();

  return (
    <div className="py-16 md:py-24 bg-[#272D29]">
      <div className="container mx-auto px-4">
        <h2 className="text-white text-center font-bold text-3xl md:text-4xl lg:text-5xl xl:text-6xl mb-4">
          {t("OurServices")}
        </h2>
        <p className="text-lg md:text-xl lg:text-2xl max-w-4xl mx-auto leading-relaxed text-center text-white mb-12">
          {t(
            "Discover reliable and skilled professionals for all your home service needs with Eaidex's easy-to-use platform."
          )}
        </p>
        <div className="mb-16" onClick={() => navigate("/home")}>
          <Slider {...settings} className="our-services-slider">
            {serviceData?.map((data, index) => (
              <div key={index} className="px-2">
                <div
                  className={`bg-white rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl h-full ${
                    index === 0 ? "bg-yellow-400" : ""
                  }`}
                >
                  <div className="pt-3 pl-3 pr-3 pb-1 flex flex-col items-center justify-center h-full">
                    <div className="w-full h-40 mb-4 overflow-hidden rounded-lg">
                      <img
                        src={getCompleteImageUrl(data?.imageUrl)}
                        alt="img"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <span className="text-lg font-medium text-gray-800 text-center">
                      {t(data?.name)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div
          ref={benefitsRef}
          className="grid lg:grid-cols-2 4xl:max-w-full xl:max-w-[1186px] mt-[116px] gap-[46px] 2xl:max-w-[1440px] mx-auto px-[20px]"
          id="benefitsRef"
        >
          <div className="lg:mb-0 mb-6">
            <img
              src={require("../../assets/images/ourbenefits.png")}
              alt="img"
              className="mx-auto"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="4xl:text-[2vw] 2xl:text-[53px] lg:text-[42px] text-[30px] leading-[78px] font-bold text-white 2xl:mb-10 mb-6 ">
              {t("OurBenefits")}
            </h2>
            <div>
              <ul>
                <li className="flex items-center 2xl:gap-[33px] gap-[20px] mb-[36px]">
                  <div className="min-w-[30px]">
                    <True />
                  </div>
                  <span className="xl:text-[23px] 4xl:text-[1.5vw] sm:text-[18px] text-[16px] text-white font-medium 4xl:leading-normal leading-[28px]">
                    {t("Eaidexprovidesreliable")}
                  </span>
                </li>
                <li className="flex items-center 2xl:gap-[33px] gap-[20px] mb-[36px]">
                  <div className="min-w-[30px]">
                    <True />
                  </div>
                  <span className="xl:text-[23px] 4xl:text-[1.5vw] sm:text-[18px] text-[16px] text-white font-medium 4xl:leading-normal leading-[28px]">
                    {t("Affordableandtrustedservicesacrossmanymorecategories")}
                  </span>
                </li>
                <li className="flex items-center 2xl:gap-[33px] gap-[20px] mb-[36px]">
                  <div className="min-w-[30px]">
                    <True />
                  </div>
                  <span className="xl:text-[23px] 4xl:text-[1.5vw] sm:text-[18px] text-[16px] text-white font-medium 4xl:leading-normal leading-[28px]">
                    {t("Customerscaneasilysearchforandbookservices")}
                  </span>
                </li>
                <li className="flex items-center 2xl:gap-[33px] gap-[20px] mb-[36px]">
                  <div className="min-w-[30px]">
                    <True />
                  </div>
                  <span className="xl:text-[23px] 4xl:text-[1.5vw] sm:text-[18px] text-[16px] text-white font-medium 4xl:leading-normal leading-[28px]">
                    {t("Businesstoconsumerlongtail")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
