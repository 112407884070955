import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-plugin-datalabels";
import {
  useReferralIncentiveLegends,
  GetMonthlyReferralIncentives,
  useUserMeQuery,
  IncentiveDataDto,
} from "../../API/api";
import CircularProgressBar from "../CircularProgressBar";
import { EmptyData } from "../chart/EmptyData";
import { StackedBarChartIcon } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReferralIncentivesChart: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useUserMeQuery();
  const labourerId = userData?.labourerDetails?.userId;
  const { data: IncentiveLegendsData } =
    useReferralIncentiveLegends(labourerId);
  const {
    data: incentivesData,
    isLoading,
    isError,
  } = GetMonthlyReferralIncentives(labourerId);

  const earningsData = incentivesData?.map(
    (item: IncentiveDataDto) => item?.earnings
  );
  const payoutsData = incentivesData?.map(
    (item: IncentiveDataDto) => item?.payouts
  );

  const data = {
    labels: incentivesData?.map((item: IncentiveDataDto) =>
      item?.monthName.substring(0, 3)
    ),

    datasets: [
      {
        label: "Earnings",
        backgroundColor: "#1892C3",
        data: earningsData || [],
      },
      {
        label: "Payouts",
        backgroundColor: "#50E3C1",
        data: payoutsData || [],
      },
    ],
  };

  const options = {
    plugins: {
      legend: { display: false },
      datalabels: {
        color: "#ffffff",
        font: {
          weight: "bold" as const,
          size: 10,
        },
        formatter: (value: number) => {
          // If the value is 0, don't display the label
          return value !== 0 ? value.toString() : "";
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: true,

        grid: {
          display: false,
        },
      },
      y: {
        display: false,

        stacked: true,
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
  };

  const noIncentivesData: boolean =
    incentivesData?.every(
      (item: IncentiveDataDto) => item?.earnings === 0 && item?.payouts === 0
    ) ?? true;
  return (
    <>
      <div className="bg-white rounded-lg p-2 shadow-md pb-6">
        <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto pb-4">
          {t("Referral Incentives")}
        </span>
        <div className="grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-2 2xl:gap-4  pb-5 font-medium  ">
          <div className="flex gap-1 items-center">
            <div className="text-xs md:text-sm text-black font-roboto font-semibold">
              Total Referrals:
            </div>
            <div className="text-[#0F8E71] font-roboto font-semibold text-xs md:text-sm">
              {IncentiveLegendsData?.referralRegistrations}
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <div className="text-xs md:text-sm text-black font-roboto font-semibold">
              Success Counts:
            </div>
            <div className="text-[#0F8E71] font-roboto font-semibold text-xs md:text-sm">
              {IncentiveLegendsData?.successCount}
            </div>
          </div>

          <div className="flex gap-1 items-center">
            <div className="text-xs md:text-sm text-black font-roboto font-semibold">
              Earnings:
            </div>
            <div className="text-[#0F8E71] font-roboto font-semibold text-xs md:text-sm">
              {IncentiveLegendsData?.totalEarnings}
            </div>
          </div>

          <div className="flex gap-1 items-center">
            <div className="text-xs md:text-sm text-black font-roboto font-semibold">
              Payout:
            </div>
            <div className="text-[#0F8E71] font-roboto font-semibold text-xs md:text-sm">
              {IncentiveLegendsData?.totalPayouts}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              <CircularProgressBar />
            </div>
          </div>
        ) : isError ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              Error loading data
            </div>
          </div>
        ) : noIncentivesData ? (
          <EmptyData
            Icon={StackedBarChartIcon}
            header={"referral incentives"}
          />
        ) : (
          <Bar data={data} options={options} />
        )}
      </div>
    </>
  );
};
export default ReferralIncentivesChart;
