import { useNavigate } from "react-router-dom";

const useNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string, state?: any) => {
    navigate(path, { state });
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return { navigateTo, navigateBack };
};

export default useNavigation;
