import React, { useEffect, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import Slider from "react-slick";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { sendFeedbackMutation, useFeedbackMaster } from "../API/api";
import { isAuthenticated } from "../utils/jwtUtils";
import { UserType } from "../API/types";

interface ContactUsProps {
  onRegisterClicked: (UserType?: UserType) => void;
}
const Feedback: React.FC<ContactUsProps> = ({ onRegisterClicked }) => {
  const { t } = useTranslation();
  const placeholderName = t("JohnDeo") || "";
  const placeholderEmail = t("email") || "";
  const placeholderMessage = t("message") || "";

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { data } = useFeedbackMaster();

  const [selectedId, setSelectedId] = useState<number>(1);
  const [feedbackData, setFeedbackData] = useState({
    name: "",
    feedbackId: 1,
    message: "",
    emailId: "",
    status: "NEW",
  });

  const handleSignUpOnChange = (e: any) => {
    const { name, value } = e.target;

    setFeedbackData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      feedbackId: Number(selectedId),
    }));
  };

  const handleSelectChange = (event: any) => {
    const { value } = event.target;
    setSelectedId(value);
  };

  const FeedBackMutation = useMutation(sendFeedbackMutation, {
    onSuccess: (data) => {
      if (data?.id) {
        toast.success("Success");
      } else if (data?.error) {
        toast.error(data?.error?.message);
      }
    },
  });

  const sendFeedback = () => {
    FeedBackMutation.mutate(feedbackData);
  };

  return (
    <>
      <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] mt-[45px] px-5 2xl:max-w-[1440px] mx-auto ">
        <div className="w-full bg-[#FFFCF6] rounded-[20px] shadow-box lg:h-[368px] h-full lg:pl-[55px] pl-4 pt-[62px] flex items-center lg:justify-between mt-[116px] lg:flex-nowrap flex-wrap relative">
          <div>
            <h2 className="lg:text-5xl text-[10vw] text-black font-bold lg:text-left text-center">
              {t("Feedback")}{" "}
            </h2>
            <p className="lg:text-[27px] text-[5vw] text-black max-w-[610px] font-medium mt-3 lg:text-left text-center">
              {t("loreamShortPara")}
            </p>

            {!isAuthenticated() && (
              <button
                onClick={() => {
                  onRegisterClicked();
                }}
                className="flex justify-center text-[17px] leading-[13px] font-medium gap-3 py-[13px] px-[16px] rounded-full bg-[#F0A61F] min-w-[161px] mt-9 h-[50px] items-center lg:mx-0 mx-auto"
              >
                {t("Signup")} <AiFillCaretRight className="text-[12px]" />
              </button>
            )}
          </div>
          <div className="lg:w-auto w-full">
            <img
              src={require("../assets/images/feedback.png")}
              alt="feedback"
              className="2xl:mb-[126px] lg:mb-[66px] lg:ml-0 ml-auto lg:mt-0 mt-3"
            />
          </div>
        </div>
        <div className="my-[119px]">
          <h1 className="lg:text-[53px] text-[6vw] font-semibold">
            {t("ClientTestimonials")}
          </h1>
          <div className="lg:mt-16">
            <Slider {...settings}>
              <div className="lg:p-10 p-2 shadow-testimonial max-w-[364px] h-[372px] rounded-xl my-10">
                <p className="text-black text-[22px]">{t("HasBeen")}</p>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:p-10 p-2 shadow-testimonial max-w-[364px] h-[372px] rounded-xl my-10">
                <p className="text-black text-[22px]">{t("HasBeen")}</p>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:p-10 p-2 shadow-testimonial max-w-[364px] h-[372px] rounded-xl my-10">
                <p className="text-black text-[22px]">{t("HasBeen")}</p>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:p-10 p-2 shadow-testimonial max-w-[364px] h-[372px] rounded-xl my-10">
                <p className="text-black text-[22px]">{t("HasBeen")}</p>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="my-[119px]">
          <h1 className="lg:text-[53px] text-[6vw] font-semibold">
            {t("CompanyCallCentrers")}
          </h1>
          <div className="mt-10 grid md:grid-cols-2 grid-cols-1 gap-14">
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add1")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd1")} </p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call1")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add2")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd2")}</p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call2")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add1")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd1")} </p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call1")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add2")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd2")}</p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call2")}</p>
            </div>
          </div>
        </div>
        <div className="mt-[119px] mb-[85px]">
          <p className="text-[#272D29] text-[30px] font-semibold">
            {t("QueriesText")}
          </p>
          <p className="mt-5 text-xl font-medium">{t("HelpText")}</p>
          <p className="mt-5 text-lg font-medium border-b-2 border-[#F0A61F] w-fit">
            {t("Feedback")}
          </p>
          <div className="mt-6 flex flex-col gap-5">
            <div>
              <input
                name="name"
                onChange={handleSignUpOnChange}
                type="text"
                placeholder={placeholderName}
                className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
              />
            </div>
            <div>
              <input
                type="email"
                name="emailId"
                onChange={handleSignUpOnChange}
                placeholder={placeholderEmail}
                className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
              />
            </div>
            <div>
              <select
                name="feedbackId"
                className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                onChange={handleSelectChange}
              >
                {data?.map((item: any) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.type}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <textarea
                name="message"
                onChange={handleSignUpOnChange}
                placeholder={placeholderMessage}
                className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[148px] p-3  focus:outline-none"
              />
            </div>
            <button
              onClick={sendFeedback}
              className="min-w-[127px] min-h-[24px] py-[10px] px-[65px] rounded mt-[68px] mx-auto flex justify-center items-center text-white text-lg font-medium bg-[#272D29]"
            >
              {t("SendFeedback")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
