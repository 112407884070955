import moment from "moment";
import { SkillDto, UserDto, UserRole } from "../API/types";

enum DateFormatter {
  FORMAT1 = "YYYY-MM-DDTHH:mm:ss.SSSZ",
}

export const timeAgo = (timestampString: string | undefined): string => {
  if (timestampString === undefined || timestampString.length === 0) return "";
  const currentDate: Date = moment().utc().toDate();

  const previousDate = moment(timestampString, DateFormatter.FORMAT1).toDate();

  const timeDifference: number = currentDate.getTime() - previousDate.getTime();
  const seconds: number = Math.floor(timeDifference / 1000);
  const minutes: number = Math.floor(seconds / 60);
  const hours: number = Math.floor(minutes / 60);
  const days: number = Math.floor(hours / 24);
  const weeks: number = Math.floor(days / 7);
  const months: number = Math.floor(days / 30);
  const years: number = Math.floor(days / 365);

  if (seconds < 60) {
    return `${seconds} s`;
  } else if (minutes < 60) {
    return `${minutes} m`;
  } else if (hours < 24) {
    return `${hours} h`;
  } else if (days < 7) {
    return `${days} d`;
  } else if (months < 1) {
    return `${weeks} w`;
  } else if (months === 1) {
    return `${months} m`;
  } else if (years < 1) {
    return `${months} m`;
  } else if (years === 1) {
    return `${years} Y`;
  } else {
    return `${years} y`;
  }
};

export function classNames(...classes: (string | undefined | null)[]): string {
  return classes.filter(Boolean).join(" ");
}

export const hasRole = (user: UserDto | undefined, role: UserRole) => {
  return user !== undefined && user!.roles.includes(role);
};

export const isEmployer = (user: UserDto | undefined) => {
  return (
    user !== undefined &&
    user!.roles.includes(UserRole.EMPLOYER) &&
    user!.roles.length === 1
  );
};

export const isLabourer = (user: UserDto | undefined) => {
  return (
    user !== undefined &&
    user!.roles.includes(UserRole.LABOURER) &&
    user!.roles.length === 1
  );
};

export const isHybrid = (user: UserDto) => {
  return (
    user.roles.includes(UserRole.LABOURER) &&
    user.roles.includes(UserRole.EMPLOYER)
  );
};

/**
 * Converts a data URL string to a Blob object.
 * @param dataURL The data URL string to convert.
 * @returns A Blob object representing the data in the data URL.
 * @throws {Error} If the MIME type cannot be determined from the data URL.
 */
export const dataURLtoBlob = (dataURL: any) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const getFileTypeFromUrl = (url: string): string => {
  if (url.includes("data:image/png")) {
    return "image/png";
  } else if (url.includes("data:image/gif")) {
    return "image/gif";
  } else {
    return "image/jpeg";
  }
};

export const mapSkillIds = (skills: SkillDto[]) => {
  const skillIds: number[] = [];
  for (const value of skills) {
    if (value.skillId !== undefined) {
      skillIds.push(value.skillId);
    }
  }
  return skillIds;
};

export const getSkillName = (skillId: number, serviceData: any) => {
  const skill = serviceData?.find((skill: any) => skill.id === skillId);
  return skill ? skill.name : "";
};
