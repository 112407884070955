import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoginSchema } from "../../schema/loginSchema";
import { useMutation } from "@tanstack/react-query";
import { ApiError, VerifyMobileResponse } from "../../API/types";
import { verifyMobile } from "../../API/api";
import { toast } from "react-toastify";
import LoadingIndicator from "../LoadingIndicator";

interface VerifyMobileProps {
  lang: string;
  onVerificationSucess: (verificationId: string, phone: string) => void;
}

const VerifyMobileComponent = (props: VerifyMobileProps) => {
  const { t } = useTranslation();
  const { lang, onVerificationSucess } = props;

  const handleLogin = (formData: any) => {
    //console.log("loginFormGetValues", loginFormGetValues("phone"));
    LoginMutation.mutate(formData);
  };

  const LoginMutation = useMutation<VerifyMobileResponse, ApiError, any, any>(
    verifyMobile,
    {
      onSuccess: (data) => {
        if (data) {
          //TODO - Remove on production build
          toast.success(data.otp);
          onVerificationSucess(
            data.verificationId,
            loginFormGetValues("phone") || ""
          );
          //setVerifyId();
          //setVerify(true);
          //setLogin(false);
          //setRegister(false);
        }
      },
      onError(error, variables, context) {
        toast.error("please enter the proper phone number");
        // toast.error(error.message);
      },
    }
  );

  const {
    register: loginForm,
    handleSubmit: loginFormSubmission,
    formState: {
      errors: loginFormErrors,
      isValid: loginFormIsValid,
      isDirty: customerFormIsDirty,
    },
    control: loginFormControl,
    getValues: loginFormGetValues,
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: "onBlur",
  });

  return (
    <div className="h-[80vh] flex flex-col justify-center">
      <div className="items-center justify-between sm:flex">
        <img
          src={require("../../assets/images/login-vector.png")}
          className="sm:hidden"
        />
        <div className="mt-6 sm:mt-0">
          {/* <h1
            className={`${
              lang === "en" || lang === "hi"
                ? "2xl:whitespace-nowrap whitespace-normal"
                : ""
            } w-[100px] text-[#272D29] md:text-[32px] font-bold`}
          >
            {t("Verify Mobile Number")}
          </h1> */}

          <h1 className=" text-[#272D29]  font-bold md:text-[20px] lg:text-[20px] 2xl:text-[28px] sm:text-[28px] text-[24px]">
            {t("Verify Mobile Number")}
          </h1>
          <p className="mt-[10px] text-[#696969] text-[15px]">
            {t("Enter your registered mobile number")}
          </p>
        </div>
        <img
          src={require("../../assets/images/login-vector.png")}
          className="sm:mt-[-50px] sm:block hidden"
        />
      </div>
      <form onSubmit={loginFormSubmission(handleLogin)}>
        <input
          type="number"
          {...loginForm("phone")}
          placeholder={t("MobileNumber") ?? ""}
          className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
          // onKeyPress={(event) => {
          //   if (event.charCode < 49) {
          //     event.preventDefault();
          //   }
          // }}
        />
        <p className="text-[16px] text-red-600">
          {loginFormErrors.phone?.message}
        </p>
        <button
          className="relative bg-[#F0A61F] rounded text-white mt-[30px] w-full h-[44px]"
          disabled={LoginMutation.isLoading}
          type="submit"
        >
          {t("Continue")}
          {LoginMutation.isLoading && (
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
              <LoadingIndicator color="black" />
            </div>
          )}
        </button>
      </form>
    </div>
  );
};

export default VerifyMobileComponent;
