import React, { useState, useEffect, useMemo } from "react";
import { Controller, Control } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { useServiceCategoryQuery, useSkillAttributes } from "../../API/api";
import { ServiceProviderType } from "../LabourerRegisterForm/LabourerRegisterForm";
import { SkillAttributesDataDto } from "../../API/types";

export interface Skill {
  id: number;
  name: string;
  experience: number;
}
interface SkillAttributesResult {
  data: SkillAttributesDataDto | undefined;
  isLoading: boolean;
}

interface MyAutocompleteProps {
  control: Control<any>;

  handleSkillUpdate: (skills: { id: number; experience: number }[]) => void;
  error?: string;
  providerType: ServiceProviderType;
  selectedSkills: any;
  setSelectedSkills: any;
  setShowBusinessModal: any;
}
const MyAutocomplete: React.FC<MyAutocompleteProps> = ({
  control,
  handleSkillUpdate,
  error,
  providerType,
  selectedSkills,
  setSelectedSkills,
  setShowBusinessModal,
}) => {
  const [dynamicAttributes, setDynamicAttributes] = useState<{
    [key: number]: { [attrId: number]: string };
  }>({});
  const [validationErrors, setValidationErrors] = useState<{
    [key: number]: string;
  }>({});
  const [attributeErrors, setAttributeErrors] = useState<{
    [key: number]: { [attrId: number]: string };
  }>({});
  const [showExperience, setShowExperience] = useState<boolean>(false);

  const servicesQuery = useServiceCategoryQuery();
  const data = servicesQuery.data || [];
  const handleSkillChange = (event: any, newValue: Skill[], field: any) => {
    let updatedSkills: Skill[] = newValue;
    if (providerType === ServiceProviderType.Business) {
      updatedSkills = newValue.slice(-1); // Select only the last skill
    }
    setSelectedSkills(updatedSkills);
    field.onChange(
      updatedSkills.map((skill) => ({
        id: skill.id,
        name: skill.name,
        experience: skill.experience || 0,
      }))
    );
    // Update the modal and experience visibility
    if (providerType === ServiceProviderType.Individual) {
      setShowExperience(updatedSkills.length > 0);
      setShowBusinessModal(false);
    } else if (providerType === ServiceProviderType.Business) {
      setShowBusinessModal(updatedSkills.length > 0); // Show modal only if a skill is selected
      setShowExperience(false); // Hide experience input for businesses
    }
  };

  const handleAttributeChange = (
    skillId: number,
    attrId: number,
    value: string
  ) => {
    setDynamicAttributes((prevState) => ({
      ...prevState,
      [skillId]: {
        ...prevState[skillId],
        [attrId]: value,
      },
    }));
  };

  const validateExperience = () => {
    const errors: { [key: number]: string } = {};

    selectedSkills.forEach((skill: any) => {
      if (!skill.experience || skill.experience <= 0) {
        errors[skill.id] = "Experience must be greater than 0";
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateAttributes = () => {
    const errors: { [key: number]: { [attrId: number]: string } } = {};

    selectedSkills.forEach((skill: any) => {
      skillAttributes
        .filter((attr: any) => attr.skillId === skill.id && attr.isVisible)
        .forEach((attr: any) => {
          const attribute = masterAttributes.find(
            (masterAttr: any) => masterAttr.id === attr.skillMasterAttributeId
          );
          const value =
            dynamicAttributes[skill.id]?.[attr.skillMasterAttributeId] || "";
          if (value.trim() === "" && attribute?.attributeLabel) {
            if (!errors[skill.id]) errors[skill.id] = {};
            errors[skill.id][
              attr.skillMasterAttributeId
            ] = `${attribute.attributeLabel} is required`;
          }
        });
    });
    setAttributeErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    const isExperienceValid = validateExperience();
    const isAttributesValid = validateAttributes();
    if (isExperienceValid && isAttributesValid) {
      setShowExperience(false);
    } else {
      setShowExperience(true);
    }
  };

  const skillIds = useMemo(() => {
    return selectedSkills?.map((skill: any) => skill?.id) ?? [];
  }, [selectedSkills]);

  const {
    data: skillAttributesData,
    isLoading: skillIsLoading,
  }: SkillAttributesResult = useSkillAttributes(skillIds);
  const skillAttributes = skillAttributesData?.skillAttributes ?? [];
  const masterAttributes = skillAttributesData?.masterAttributes ?? [];

  return (
    <Controller
      name="skills"
      control={control}
      render={({ field }) => (
        <div className="relative">
          <Autocomplete
            multiple
            options={data as any[]}
            value={selectedSkills}
            // onChange={handleSkillChange}
            onChange={(event, newValue) =>
              handleSkillChange(event, newValue, field)
            }
            getOptionLabel={(option: Skill) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox
                  sx={{
                    color: "#D4D4D4",
                    "&.Mui-checked": { color: "#F0A61F" },
                    "& .MuiSvgIcon-root": { fontSize: 25 },
                  }}
                  size="small"
                  checked={selected}
                />
                {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Skills" error={!!error} />
            )}
          />
          {error && <p className="text-red-700 text-md mt-2">{error}</p>}
          {selectedSkills.length > 0 &&
            showExperience &&
            providerType === ServiceProviderType.Individual && (
              <div className="absolute top-full mt-2 w-full bg-white p-4 rounded-lg shadow-md border z-10">
                <div className="max-h-[280px] overflow-y-auto no-scrollbar">
                  {selectedSkills.map((skill: any) => (
                    <React.Fragment key={`skill-${skill.id}`}>
                      <div className="mb-3 p-4 border rounded-lg">
                        <div className="">
                          <Checkbox
                            sx={{
                              color: "#D4D4D4",
                              "&.Mui-checked": { color: "#F0A61F" },
                              "& .MuiSvgIcon-root": { fontSize: 25 },
                            }}
                            size="small"
                            checked={true}
                          />
                          <span className="font-semibold text-lg">
                            {skill?.name}
                          </span>
                        </div>

                        {/* Experience input */}
                        <div className="mb-2">
                          <div className="flex items-center gap-2 ">
                            <label className="text-base text-gray-600">
                              Experience (Years):
                            </label>
                            <TextField
                              sx={{
                                padding: "0",
                                ":focus": { outline: "none", border: "none" },
                                height: "8px",
                                width: "40px",
                                boxSizing: "border-box",
                                textAlign: "center",
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                    borderRadius: "4px",
                                  },
                              }}
                              value={skill.experience || ""}
                              onChange={(e) => {
                                const updatedSkills = selectedSkills.map(
                                  (s: any) =>
                                    s.id === skill.id
                                      ? {
                                          ...s,
                                          experience: Number(e.target.value),
                                        }
                                      : s
                                );
                                setSelectedSkills(updatedSkills);
                                field.onChange(updatedSkills);
                              }}
                              type="number"
                              error={!!validationErrors[skill.id]}
                            />
                          </div>

                          {validationErrors[skill.id] && (
                            <div className="text-red-600 text-xs mb-5 ">
                              {validationErrors[skill.id]}
                            </div>
                          )}
                        </div>

                        {skillIsLoading ? (
                          <div className="flex justify-center items-center h-full">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#F0A61F]"></div>
                          </div>
                        ) : (
                          skillAttributes
                            .filter(
                              (attr: any) =>
                                attr.skillId === skill.id && attr.isVisible
                            )
                            .map((attr: any) => {
                              const attribute = masterAttributes.find(
                                (masterAttr: any) =>
                                  masterAttr.id === attr.skillMasterAttributeId
                              );
                              return (
                                attribute && (
                                  <div
                                    key={`attr-${attr.id}-${attribute.id}`}
                                    className="mb-2"
                                  >
                                    <input
                                      type="text"
                                      placeholder={attribute.attributeLabel}
                                      className="shadow-md bg-white rounded-lg px-4 py-2 w-full"
                                      value={
                                        dynamicAttributes[skill.id]?.[
                                          attr.skillMasterAttributeId
                                        ] || ""
                                      }
                                      onChange={(e) =>
                                        handleAttributeChange(
                                          skill.id,
                                          attr.skillMasterAttributeId,
                                          e.target.value
                                        )
                                      }
                                    />
                                    {attributeErrors[skill.id]?.[
                                      attr.skillMasterAttributeId
                                    ] && (
                                      <div className="text-red-600 text-sm mt-1 mb-5 ml-2">
                                        {
                                          attributeErrors[skill.id][
                                            attr.skillMasterAttributeId
                                          ]
                                        }
                                      </div>
                                    )}
                                  </div>
                                )
                              );
                            })
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>

                <button
                  className="bg-[#272D29] text-white font-bold rounded px-4 py-2 mt-4 w-full"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            )}
        </div>
      )}
    />
  );
};
export default MyAutocomplete;
