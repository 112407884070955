import { useRef, useState } from "react";
import Text from "../component/Text/Text";
import Menubar from "../component/Menubar";

function PrivacyPolicy() {
  const scrolldivRef: any = useRef();
  const scopeRef = useRef(null);
  const introductionRef = useRef(null);
  const personalRef = useRef(null);
  const collectRef = useRef(null);
  const relatedRef = useRef(null);
  const contactRef = useRef(null);
  const responseRef = useRef(null);
  const collectionRef = useRef(null);
  const sections = [
    { label: "Introduction", ref: introductionRef },
    { label: "Scope", ref: scopeRef },
    { label: "What we collect", ref: collectRef },
    { label: "Use of Personal Info", ref: personalRef },
    { label: "Third-party related terms", ref: relatedRef },
    { label: "Cookies & Other Automatic Data", ref: collectionRef },
    { label: "Other rights & responsibilities", ref: responseRef },
    { label: "Contact us", ref: contactRef }
  ];

  const fixedHeaderHeight = 140;

  const handleScrollToScope = (ref: any) => {
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;

      if (scrolldivRef) {
        scrolldivRef?.current.scroll({
          top: elementTop,
          behavior: "smooth"
        });
      }
    }
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggleSidebar = (ref: React.RefObject<any>) => {
    setSidebarVisible(!sidebarVisible);
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;
      scrolldivRef?.current.scroll({
        top: elementTop,
        behavior: "smooth"
      });
    }
  };
  const handleClose = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <header className="bg-[#f8f8f8]  border-gray-200 border-b-2   top-0">
        <div className="  text-black text-base md:text-2xl font-bold ml-[20%] font-Montserrat leading-10">
          Eaidex Privacy Policy{" "}
        </div>
      </header>
      <div className="bg-white-600 flex   ">
        <div className="section1-main border-gray-200 border-r-2 h-screen flex-shrink-0 md:w-[20%] hidden sm:block pt-1 pr-1">
          <div className="section-sub bg-gray-100 h-screen">
            <ul className="list-disc pl-4 md:pl-14 pt-4 md:pt-16">
              {sections?.map((section, index) => (
                <li
                  key={index}
                  className=" cursor-pointer text-black hover:text-blue-gray-900 text-xs font-normal font-robotoserif leading-none mb-5 "
                  onClick={() => handleScrollToScope(section.ref)}
                >
                  {section.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="section2-Main md:w-[80%] w-[100%]  pl-1 pt-1 mt-0">
          <div
            className="section2-sub bg-gray-100 md:p-3 p-4  overflow-scroll no-scrollbar scroll-smooth  h-screen "
            ref={scrolldivRef}
          >
            <div className="  innerArea bg-white p-4 md:p-10 md:w-10/12 w-full flex-col items-center justify-center">
              <button
                className=" text-white absolute left-0 top-24 md:hidden lg:hidden 2xl:hidden xl:hidden sm:block"
                onClick={() => handleToggleSidebar(scrolldivRef)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 17h18v-2H3v2zM3 11h18V9H3v2zm0-6v2h18V5H3z" />
                </svg>
              </button>

              <div className="flex justify-center w-full md:h-[400px] h-auto  mb-[20px]">
                <img src={require("../assets/images/privacypolicy.jpg")} />
              </div>

              {/* <div className="flex justify-center w-full mb-[20px]">
                <img
                  src={require("../assets/images/privacypolicy.jpg")}
                  className="max-w-full h-auto"
                  alt="Privacy Policy"
                />
              </div> */}
              <div
                className="font-robotoserif text-black font-normal text-xs  "
                ref={introductionRef}
              >
                <div className="mb-6">
                  <Text
                    className="text-[13px]  mb-3 text-black underline uppercase"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Introduction
                  </Text>

                  <Text
                    className="leading-[25.00px] text-black text-justify text-xs tracking-[0.12px]"
                    size="txtRobotoSerifRegular12"
                  >
                    <span className="text-black font-robotoserif font-bold">
                      EAIDEX Services Pvt Ltd
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      {" "}
                      d/b/a eaidex (“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      eaidex
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”/“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      we
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”/“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      us
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”/“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      our
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      ”) owns and operates the website located at
                      https://eaidex.com/ (the “
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      Website
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      ”), and any subdomains used in connection with or related
                      to the same, and the Eaidex mobile application (the “
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      App
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      ”). Eaidex is committed to protecting your privacy. This
                      Privacy Policy describes how we collect, store, use and
                      distribute information about identifiable individuals and
                      information which can be used to identify an individual (“
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      Personal Information
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      ”) through the Website, App and Service (as defined in our
                      Terms of Service). We are engaged in the business of
                      providing web-based solutions to facilitate network
                      between customers who seeks specific listed services and
                      service professionals that offers the required listed
                      services. In this policy, the service offered to a service
                      seeker by service professionals through Eaidex platform
                      are referred as (
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      Service Professionals
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      ), Our Service is provided to customers who have
                      registered for an account with us (“
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      Account Owners
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      ”) and any information collected via the Website, App and
                      Service from or about Account Owners’, their own customers
                      (“
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      end users
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      <>
                        ”) and service professional providers will be made
                        available to the applicable Account Owner and will be
                        used by such Account Owner according to their own
                        privacy policy(ies).
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      A Note About Children.
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      <>
                        {" "}
                        You must be at least 18 years old to use the Website,
                        App, and/or Service. We do not intentionally gather
                        Personal Information (defined in Collection of
                        Information below) from visitors who are under the age
                        of 18. If a child under 18 submits Personal Information
                        to Eaidex and we learn that the Personal Information is
                        the information of a child under 14, we will attempt to
                        delete the information as soon as possible. If you
                        believe that we might have any Personal Information from
                        a child under 18, please contact us at
                        privacy@eaidex.com (you may want to consider the
                        acceptance of the registration through confirming the
                        age of the use which above 18 years of age.)
                        <br />
                        <br />
                      </>
                    </span>

                    <span className="text-black-900 font-robotoserif font-bold">
                      A Note to Users Outside of India.
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      <>
                        {" "}
                        If you are a non-Indian user of the Website, App, and/or
                        Service, by visiting or using the Website, App, and/or
                        Service and providing us with data, you acknowledge and
                        agree that your Personal Information may be processed
                        for the purposes identified in this Privacy Policy. In
                        addition, your Personal Information may be processed in
                        the country in which it was collected and by providing
                        your data, you consent to such transfer.
                        <br />
                        <br />
                      </>
                    </span>

                    <span className="text-black-900 font-robotoserif font-bold">
                      Lawful processing:
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      {" "}
                      We process your personal information only to the extent
                      necessary for the performance of our obligations to you,
                      which is in providing the Advisory Services to you under
                      our Terms of Service at www.eaidex.com/terms-of-service.
                      We set out below the type of Personal Information that we
                      collect and how we use it. Except as set forth in this
                      Privacy Policy, your Personal Information will not be used
                      for any other purpose without your consent. We will not
                      actively collect Personal Information for the purpose of
                      sale or third-party marketing in a way that specifically
                      identifies the individual. In other words, we don’t sell
                      customer lists. You may withdraw your consent to our
                      processing of your Personal Information at any time.
                      However, withdrawing consent may result in your inability
                      to continue using the Website, the App, and/or the
                      Service.
                    </span>
                  </Text>
                </div>

                <div ref={scopeRef}>
                  <Text
                    className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    SCOPE
                  </Text>
                  <Text
                    className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] w-full"
                    size="txtRobotoSerifRegular12"
                  >
                    This Privacy Policy covers the activities of Eaidex but does
                    not apply to the practices of companies and other third
                    parties that we do not own or control, including our
                    customers and third parties that offer products or services
                    to customers linked or made available through our Service
                    (e.g., via the Eaidex Job Marketplace). You are responsible
                    for ensuring that you have obtained the necessary
                    authorizations and consents for any Personal Information you
                    make available to us for use in accordance with this Privacy
                    Policy, in particular, our customers represent and warrant
                    to Eaidex that they have the necessary rights under
                    applicable law or have obtained the necessary consents from
                    each end user whose Personal Information is provided by that
                    customer to Eaidex in order to allow Eaidex to use,
                    disclose, and otherwise process such Personal Information
                    for the purposes described in this Privacy Policy in the
                    manner described in this Privacy Policy.
                  </Text>
                </div>

                {/* What we Collect */}

                <div ref={collectRef}>
                  <Text
                    className="mt-[9px] text-[13px] text-black font-bold font-robotoserif underline uppercase"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    What we Collect
                  </Text>
                  <div className="leading-[25.00px] my-2 text-black text-justify text-xs tracking-[0.12px] w-full font-normal font-robotoserif">
                    <span className="text-black-900 font-robotoserif font-normal mb-2">
                      <>
                        Set out below are the ways in which we may collect
                        Personal Information:
                      </>
                    </span>

                    <ul className="ml-8 list-disc">
                      <li className="text-black font-robotoserif font-bold ">
                        Personal Information.
                      </li>

                      <span className="text-black font-robotoserif font-normal">
                        <>
                          we Collect from our Customers. We may collect
                          registration data such as your full name and email
                          address as well as business contact information of
                          individuals who work for our customers (i.e., field
                          service companies that use the Service to manage
                          aspects of their business and transactions) to
                          communicate with those customers about their business
                          relationship with Eaidex. We may also collect payment
                          credentials or related information from our customers
                          to allow those customers to pay Eaidex for services
                          procured by such customers or to remit to such
                          customers their share of transaction fees collected
                          from end users. You can take/upload photos, notes and
                          other content to the Website, App, and/or Service such
                          as a photo and description of a job that you have
                          completed. The content that you make available will be
                          accessible to the Account Owner.
                        </>
                      </span>
                      <li className="text-black font-robotoserif font-bold mt-2">
                        Logs.
                      </li>
                      <span className="text-black font-robotoserif font-normal">
                        <>
                          Our servers automatically record information created
                          by your use of our job marketplace solution to help us
                          diagnose and fix technical issues, and to improve the
                          overall quality and user experience of our services.
                          Logs may include information such as your IP address,
                          browser type, operating system, details of how you
                          used the job marketplace (such as the functions you
                          asked our Service to perform), diagnostic information
                          related to the Services (such as crash activity
                          reports), the referring web page, pages visited,
                          location, your mobile carrier, device and application
                          IDs, search history, search terms, and cookie
                          information. Please see further below for more
                          information on this.
                        </>
                      </span>
                      <li className="text-black-900 font-robotoserif font-bold mt-2">
                        Cookies and Similar Tracking Technologies.
                      </li>
                      <span className="text-black font-robotoserif font-normal">
                        <>
                          {" "}
                          Eaidex use technologies like cookies, pixel tags and
                          similar tracking technologies to gather information
                          about how you are interacting with the job marketplace
                          solutions, which may include identifying your IP
                          address, browser type, and referring page. Please see
                          further below for more information on this.
                        </>
                      </span>
                      <li className="text-black font-robotoserif font-bold mt-2">
                        Service Seeker Candidate and Service Provider Candidate
                        Information.
                      </li>
                      <span className="text-black font-robotoserif font-normal">
                        <>
                          {" "}
                          When Eaidex publishes the candidates who seeks
                          potential jobs or contracting engagements with Eaidex,
                          we collect information that those candidates choose to
                          provide to us when applying, which may include contact
                          information, education and employment history,
                          credentials, place of residence, and other information
                          the candidate believes to be relevant. For people who
                          register with us, we will typically retain the
                          information provided by those candidates and service
                          seekers in the application process along with
                          additional information to manage their profile with
                          us, including, without limitation, information related
                          to income tax reporting. (in each case, to the extent
                          applicable for the relevant relationship).
                        </>
                      </span>
                      <li className="text-black font-robotoserif font-bold mt-2">
                        Marketing Information.
                      </li>
                      <span className="text-black font-robotoserif font-normal">
                        <>
                          {" "}
                          From time to time, we may conduct surveys, offers,
                          feedbacks, comments, chat boxes, polls or hold
                          contests or other events and in this connection we may
                          use your connected Eaidex account information to
                          identify you and we may collect information you elect
                          to provide about yourself, such as your name, email
                          address, telephone number, organization name and
                          address, and any general information about your work.
                          Further we also collect your call records when you
                          communicate the Eaidex Job market application. In
                          addition, we may use third-party service providers to
                          collect business-related information about your
                          customer such as its name, size, and revenue in
                          connection with potentially offering the Services to
                          your employer or for analytics purposes which may
                          include evaluating current or future service
                          offerings.
                        </>
                      </span>
                      <li className="text-black font-robotoserif font-bold mt-2">
                        Suppliers and Partners.
                      </li>
                      <span className="text-black font-robotoserif font-normal">
                        <>
                          {" "}
                          Eaidex collects business contact information of
                          individuals who is service seeker and service provider
                          to communicate with each other’s about their business
                          relationship with Eaidex.
                        </>
                      </span>
                    </ul>
                  </div>
                </div>

                <div ref={personalRef}>
                  <Text
                    className="mt-[9px] text-[13px] text-black text-xs underline uppercase font-bold font-robotoserif"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Use of Personal Information
                  </Text>

                  <Text
                    className="leading-[25.00px] text-black text-xs tracking-[0.12px] font-robotoserif mt-2"
                    size="txtRobotoSerifRegular12"
                  >
                    Eaidex uses the Personal Information described above to:
                  </Text>
                  <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                    <li className="text-black  font-xs mt-2 ">
                      Provide, operate, maintain, and improve the Website, App,
                      and/or web-based Services to Eaidex account holders;
                    </li>
                    <li>
                      Account administration, billing, verification, and
                      authentication purposes and to contact you in relation to
                      the same;
                    </li>
                    <li>
                      Send technical notices, updates, security alerts, and
                      support and administrative messages;
                    </li>
                    <li>
                      Facilitate transactions and send related information to
                      the relevant transaction participants, including
                      confirmations and invoices;
                    </li>
                    respond to comments, questions, and requests, and provide
                    customer service and support;
                    <li>
                      communicate with you and provide news or information about
                      us;
                    </li>
                    investigate and prevent fraudulent transactions,
                    unauthorized access to the Website, App, and/or Service, and
                    other illegal activities;
                    <li>
                      monitor and analyze trends, usage, and activities in
                      connection with the Website, App, and/or Service,
                      including generating aggregated and anonymized statistics
                      and disclosing industry trends to third parties which are
                      derived from use of the Service but do not include any
                      Personal Information;
                    </li>
                    conduct business and contractual relationships that we have
                    with various persons and companies (such as customers,
                    suppliers, partners, and employees); and
                    <li>
                      for other purposes which we will notify you about and seek
                      your consent.
                    </li>
                  </ul>
                </div>

                <div ref={relatedRef}>
                  <Text
                    className="mt-[9px] text-[13px] text-black underline uppercase font-bold font-robotoserif"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    THIRD PARTY RELATED-TERMS
                  </Text>

                  {/* <ul className="text-black ml-8 list-disc font-roboto-serif text-xs font-normal  leading-6 tracking-tight "> */}

                  <ul className="leading-[25.00px] ml-8 text-black list-disc text-justify text-xs font-robotoserif tracking-[0.12px]">
                    <li className="text-black  font-bold">
                      Connecting Through Third-Party Sites.
                      <span className="text-black  font-normal">
                        Users may login or register to use the Service through
                        certain third-party owned or operated social networking
                        services (“SNSs”). If you are already logged into the
                        App and/or Service and a SNS or a Third-Party Account,
                        when you connect to the SNS or Third-Party Account, you
                        may be prompted to merge your profiles. By proceeding,
                        you are allowing the App and/or Service to access your
                        information and you are agreeing to the SNSs’ or
                        Third-Party Account’s terms of use in your use of the
                        App or Service. Conversely, if you are not currently
                        registered as a user of the App or Service, and you
                        click on “Sign in” through the SNS or Third-Party
                        Account that we support, you will first be asked to
                        enter your SNS or Third-Party Account credentials and
                        then be given the option to register for the App or
                        Service. In this case, we may receive information from
                        the SNS or Third-Party Account to make it easier for you
                        to create an account on the Website and show our
                        relevant content from your SNS friends. Once you
                        register on the Website and connect with the SNS or
                        Third-Party Account you will be able to automatically
                        post recent activity back to the SNS or Third-Party
                        Account. Any information that we collect from your SNS
                        account or Third-Party Account may depend on the privacy
                        settings you have with that SNS or Third-Party Account,
                        so please consult the SNSs’ or Third-Party Account’s
                        privacy and data practices. If you decide at any time
                        that you no longer wish to have your Third-Party Account
                        linked to your account, please contact us at
                        privacy@eaidex.com
                      </span>
                    </li>
                    <li className="text-black font-robotoserif font-bold">
                      Third-Party Links.
                      <span className="text-black font-robotoserif font-normal">
                        The Website, App, and/or Eaidex Job marketplace
                        application may contain links to other third-party
                        websites that are not owned or controlled by us. Such
                        third-party websites are governed by the terms and
                        conditions and privacy policies of such third-party
                        providers, and we are not involved in any interaction or
                        transaction between you and such third parties. We
                        encourage you to read the privacy policies of every
                        website you visit. The links to third-party websites or
                        locations are for your convenience and do not signify
                        our endorsement of such third parties or their products,
                        content, or websites.
                      </span>
                    </li>
                    <li className="text-black font-robotoserif font-bold">
                      Third-Party Partner Integrations.
                      <span className="text-black font-robotoserif font-normal">
                        Optional third-party add-ons may be offered by our
                        integration partners from time to time. Data shared with
                        third-party integration partners is subject to the
                        privacy policies and terms of service of such third
                        parties.
                      </span>
                    </li>
                    <li className="text-black font-robotoserif font-bold">
                      Eaidex Payments.
                    </li>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        If you register for, or otherwise use, the Eaidex
                        Payments window, we may collect additional information
                        from you, including certain account information. We may
                        also collect certain payment and other personal
                        information from the Account Owner, subject to the terms
                        of your own privacy policy and our customer agreement.
                        <br />
                        <br />
                        Because our payment service is provided by a third-party
                        service provider, AIRPAY,{" "}
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      by signing up for and/or using Eaidex Payments, you are
                      also agreeing to the terms of the Airpay Privacy Policy
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        (see www.airpay.com/privacy) Any personal information
                        that you provide to AIRPAY about you, or your end users
                        will be treated in accordance with the terms of the
                        AIRPAY privacy policy.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Eaidex Job Marketplace.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      {" "}
                      The Website, App, or Service may include access to a
                      marketplace enabling subscribing Eaidex users to browse
                      and select third-party software applications, tools, and
                      add-ons (collectively “
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Third-Party Apps
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”) which are offered by third-party developers (“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      App Providers
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”). By downloading any Third-Party App you acknowledge and
                      agree that the App Provider will have access to certain
                      information from you when contracting with you to provide
                      you with access to one or more Third-Party Apps. Use of
                      any personal information provided directly or indirectly
                      to or derived by an App Provider will be subject to the
                      privacy policy of the applicable App Provider. Eaidex has
                      no control over the privacy policies of App Providers
                      although we contractually require App Providers to agree
                      to: (i) comply with all applicable laws and regulations in
                      their collection and processing of any personal data in
                      accordance with their stated privacy policies; (ii) inform
                      users of a Third-Party App (“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Users
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”) about any information about a User or data generated or
                      derived from a User’s use of a Third-Party App (“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      User Data
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        ”) that the Third Party App collects and how such User
                        Data is used, stored, secured, and disclosed, and their
                        data retention policies; and (iii) describe the controls
                        that Users have over the use and sharing of their User
                        Data and how they may access their User Data.
                        <br />
                        <br />
                        If we plan to use your Personal Information in future
                        for any other purposes not identified above, we will
                        only do so after informing you by updating this Privacy
                        Policy. See further the section of this Privacy Policy
                        entitled ‘Amendment of this Policy’.
                        <br />
                        <br />
                      </>
                    </span>
                  </ul>
                </div>
                {/* COOKIES & OTHER AUTOMATIC DATA COLLECTION */}
                <div ref={collectionRef}>
                  <Text
                    className="mt-2 text-[13px] text-black underline uppercase font-bold font-Roboto-Serif"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    COOKIES & OTHER AUTOMATIC DATA COLLECTION AND TRACKING
                    METHODS
                  </Text>
                  <Text
                    className="leading-[25.00px] mt-1 text-black text-justify text-xs tracking-[0.12px]"
                    size="txtRobotoSerifRegular12"
                  >
                    <span className="text-black font-robotoserif font-bold">
                      Location Data Generally.{" "}
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        If you download the App, the App will request access to
                        your device location. If you grant the App access to
                        your device location, we will be able to track the
                        physical location of your device using GPS and WiFi
                        signals. We use this information to provide the Advisory
                        Service. You can remove the App’s access to your device
                        location at any time by changing your privacy settings
                        on your device. Not enabling access to your device
                        location may limit the features and functionality of the
                        Service that you can use. Location data will be
                        available to the Account Owner, account administrators,
                        and to Eaidex.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Location Data: Security Tracking.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        Location data is automatically tracked for fraud
                        prevention purposes (e.g., to potentially identify
                        unauthorized access to your account) and unusual
                        patterns or location data that is flagged by the
                        Security Services may be notified to the Account
                        Owner/applicable administrators. In the event of
                        high-risk activity flagged by the Security Services,
                        your account may be temporarily disabled to protect
                        against fraudulent activity through your account. We
                        will notify you if we take this action and will provide
                        information on how to verify your account and unblock
                        access to the same.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Time Tracking.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        You may use the time tracking functionality in the App
                        to track how long it takes you to complete a work task.
                        Time tracking functions may also use location data, if
                        enabled. Such information will be made available to the
                        Account Owner, account administrators, and to Eaidex in
                        our logs.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Metadata.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        We collect and use various metadata derived from your
                        use of the Eaidex Job Marketplace. For example, if you
                        take a photo using the App, we also collect the time
                        that such photo was taken. Such metadata will also be
                        made available to the Account Owner.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Third-Party Services.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      {" "}
                      In addition to the tracking technologies we place, other
                      companies may set their own cookies or similar tools when
                      you visit our Website or use the App and/or Service. This
                      includes third-party analytics services, including but not
                      limited to Google Analytics (“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Analytics Services
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”), that we engage to help analyze how users use the
                      Website, as well as third parties that deliver content or
                      offers and third-party fraud detection and prevention
                      services (“
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Security Services
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      ”) that we engage to help predict and prevent fraudulent
                      activities on the Website and Services. We may receive
                      reports based on these parties’ use of these tools on an
                      individual or aggregate basis. We use the information we
                      get from Analytics Services only to improve our Website
                      and Services and the use of information from Security
                      Services will be used in accordance with the terms of the
                      (ACCOUNT SECURITY AND FRAUD TERMS) The information
                      generated by the cookies or other technologies about use,
                      access to and the location of such use and access by you
                      and others with access to your Eaidex account and to our
                      Website and Job marketplace application (the “
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Analytics Information
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        ”) is transmitted to the Analytics Services and Security
                        Services. The Analytics Services use Analytics
                        Information to compile reports on user activity and the
                        Security Services use Analytics Information within
                        digital products and tools including an AI-driven real
                        time machine learning software platform which analyses
                        and aggregates data to identify risk patterns and
                        predict fraudulent activities. The Analytics Services
                        may also transfer information to third parties where
                        required to do so by law, or where such third parties
                        process Analytics Information on their behalf. Each
                        Analytics Services’ ability to use and share Analytics
                        Information is restricted by such Analytics Services’
                        terms of use and privacy policy. By using our Website,
                        App, and/or Job marketplace, you consent to the
                        processing of data about you by Analytics Services in
                        the manner and for the purposes set out above.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      System Logs, Cookies and Similar Tracking Technologies.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        Cookies and similar tracking technologies are used by us
                        to track content usage and traffic on the Website and
                        App. A cookie is a feature of your web browser that
                        consists of a text file that is placed on your hard disk
                        by a web server. Cookies and similar tracking technology
                        help us compile aggregate statistics about usage of the
                        Website and App, such as how many users visit the
                        Website, how long users spend viewing the Website, and
                        what pages are viewed most often. This information is
                        used to improve the content of the Website and App. You
                        can set your browser to notify you when you are sent a
                        cookie. This gives you the chance to decide to accept it
                        or not. If you disable cookies, you may not be able to
                        take advantage of all the features of the Website, App,
                        and/or Services. Your IP address is reported by your web
                        browser whenever you visit a page on the Website. This
                        information is recorded together with your registration
                        information on our databases.
                        <br />
                        <br />
                        How We Respond to Do Not Track Signals. We do not
                        currently respond to “do not track” signals or other
                        mechanisms that might enable consumers to opt out of
                        tracking on our website.
                        <br />
                        <br />
                      </>
                    </span>
                  </Text>
                </div>

                {/* DISCLOSURE OF PERSONAL INFORMATION WITH THIRD PARTIES */}

                <div>
                  <Text
                    className="mt-[9px] text-[13px] text-black underline uppercase font-bold font-robotoserif"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    DISCLOSURE OF PERSONAL INFORMATION WITH THIRD PARTIES
                  </Text>
                  <Text
                    className="leading-[25.00px] text-black text-justify text-xs tracking-[0.12px]"
                    size="txtRobotoSerifRegular12"
                  >
                    <span className="text-black font-robotoserif font-bold">
                      Service Providers and Business Partners.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        We may from time to time employ third parties to perform
                        tasks for us and we may need to share Personal
                        Information (including account information) with them to
                        perform those tasks. Unless we tell you differently,
                        such third parties do not have any right to use the
                        Personal Information we share with them beyond what is
                        necessary for them to perform the relevant tasks for us.
                        The third parties we currently engage include
                        third-party companies and individuals employed or
                        contracted by us to provide certain capabilities within
                        the Advisory Services and for certain general business
                        functions, including the provision of database
                        management, payment processing, and customer
                        relationship management tools, including the
                        Sub-Processors.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Business Transfers.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        If our business (or substantially all our assets) are
                        acquired by a third party, or if we go out of business,
                        enter bankruptcy, or go through some other change of
                        control, Personal Information may be made available or
                        otherwise transferred to the new controlling entity,
                        where permitted under applicable law. Your Personal
                        Information may also be transferred in connection with
                        due diligence for any such transactions. In all cases,
                        if any such transactions occur, your Personal
                        Information will remain subject to the restrictions and
                        protections set forth in this Privacy Policy.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      SNSs.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        Our Website, App, and/or Service may enable you to post
                        content to SNSs or other Third-Party Accounts. If you
                        choose to do this, we will provide information to such
                        SNSs or Third-Party Accounts in accordance with your
                        elections. You acknowledge and agree that you are solely
                        responsible for your use of those websites and that it
                        is your responsibility to review the terms of use and
                        privacy policy of the third-party provider of such SNSs
                        or Third-Party Accounts. We will not be responsible or
                        liable for: (i) the availability or accuracy of such
                        SNSs or Third-Party Accounts; (ii) the content,
                        products, or services on or availability of such SNSs;
                        or (iii) your use of any such SNSs or Third-Party
                        Accounts
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      App Providers.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        By accessing Third-Party Apps or Eaidex-Built Apps
                        through the Eaidex Job Marketplace, or through other
                        means as applicable, certain Personal Information may be
                        shared by us with App Providers in the case of
                        Third-Party Apps, or applicable third-party software
                        providers in the case of Eaidex-Built Apps.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Payment Processors.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        When you provide credit card, Debit card or other
                        payment information to pay for Eaidex subscriptions,
                        your credit card, Debit card and financial information
                        is processed by a third-party subscription management
                        provider. Eaidex does not store and does not have access
                        to detailed credit card or Debit card information. If
                        you use Eaidex Payments, your payment information and
                        payments will be processed by AIRPAY and such
                        information and processing will be subject to AIRPAY’S
                        own legal agreements which you should read carefully.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      As Required by Law.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        We may disclose your Personal Information to third
                        parties without your consent if we have reason to
                        believe that disclosing this information is necessary to
                        identify, contact, or bring legal action against someone
                        who may be causing injury to or interference with
                        (either intentionally or unintentionally) our rights or
                        property, other users, or anyone else (including the
                        rights or property of anyone else) that could be harmed
                        by such activities. Further, we may disclose Personal
                        Information when we believe in good faith that such
                        disclosure is required by and in accordance with the
                        law. We also reserve the right to access, read,
                        preserve, and disclose any information as we reasonably
                        believe is necessary to:
                        <br />
                        satisfy any applicable law, regulation, legal process,
                        or governmental request (including in pursuant to civil
                        or criminal investigative demands, or similar
                        processes);
                        <br />
                        enforce our contracts or user agreements, including
                        investigation of potential violations hereof; and
                        <br />
                        detect, prevent, or otherwise address fraud, security,
                        or technical issues.
                        <br />
                        <br />
                        The above may include exchanging information with other
                        companies and organizations for fraud protection,
                        spam/malware prevention, and know-your-customer
                        purposes. Notwithstanding the general terms of this
                        policy, the collection, use, and disclosure of Personal
                        Information may be made outside of the terms of this
                        Privacy Policy to the extent provided for in any
                        applicable privacy or other legislation in effect from
                        time to time, or pursuant to court orders (including in
                        respect to depositions, interrogatories, civil or
                        Criminal investigative demands, and other court or
                        regulatory-mandated discovery processes).
                        <br />
                      </>
                    </span>
                  </Text>
                </div>
                {/* OTHER RIGHTS AND RESPONSIBILITIES */}

                <div ref={responseRef}>
                  <Text
                    className="mt-[9px] text-[13px] text-black underline uppercase font-robotoserif font-bold"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    OTHER RIGHTS AND RESPONSIBILITIES
                  </Text>
                  <Text
                    className="leading-[25.00px] text-black text-justify text-xs tracking-[0.12px]"
                    size="txtRobotoSerifRegular12"
                  >
                    <span className="text-black font-robotoserif font-bold">
                      Retention.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        Eaidex reserves the right to keep your Personal
                        Information for as long as it remains necessary for the
                        identified purpose or as required by law, which may
                        extend beyond the termination of our relationship with
                        you. We may retain certain data as necessary to prevent
                        fraud or future abuse, or for legitimate business
                        purposes, such as analysis of aggregated, non-personally
                        identifiable data, account recovery, or if required by
                        law. All retained personal information will remain
                        subject to the terms of this Privacy Policy. If you
                        request that your Personal Information, be removed from
                        our databases, it may not be possible to completely
                        delete all your Personal Information due to
                        technological and legal constraints.
                        <br />
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      Access & Accuracy.
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        You have the right to access the Personal Information we
                        hold about you to verify the Personal Information we
                        have collected in respect to you and to have a general
                        account of our uses of that information. Upon receipt of
                        your written request, we will provide you with a copy of
                        your Personal Information although, in certain limited
                        circumstances, we may not be able to make all relevant
                        information available to you such as where that
                        information also pertains to another user. In such
                        circumstances we will provide reasons for the denial to
                        you upon request. We will endeavor to deal with all
                        requests for access and modifications in a timely
                        manner. We will make every reasonable effort to keep
                        your Personal Information accurate and up-to-date, and
                        we will provide you with mechanisms to update, correct,
                        delete, or add to your Personal Information as
                        appropriate. As appropriate, this amended Personal
                        Information will be transmitted to those parties to
                        which we are permitted to disclose your information.
                        Having accurate Personal Information about you enables
                        us to give you the best possible service.
                        <br />
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      <>
                        <br />
                        Amendment of this Policy.
                      </>
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      <>
                        {" "}
                        Eaidex reserve the right to change this Privacy Policy
                        at any time. If we decide to make any material changes
                        to this Privacy Policy in the future, including how we
                        use your Personal Information, we will send you an email
                        or in-App notification. Any non-material change (such as
                        clarifications) to this Privacy Policy will become
                        effective on the date the change is posted and any
                        material changes will become effective 30 days from
                        their posting on the Website or App, unless expressly
                        acknowledged by you at an earlier date. Unless stated
                        otherwise, our current Privacy Policy applies to all
                        Personal Information that we have about you. The date on
                        which the latest update was made is indicated at the top
                        of this document. Your continued use of the Website,
                        App, and/or Service signifies your acceptance of any
                        changes. You should consult this Privacy Policy
                        regularly for any changes.
                        <br />
                      </>
                    </span>
                  </Text>
                </div>

                <div ref={contactRef}>
                  <Text
                    className="mt-[9px] text-[13px] text-black  uppercase font-robotoserif font-bold"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Contact Us
                  </Text>
                  <Text
                    className="leading-[25.00px] text-black text-xs tracking-[0.12px] font-robotoserif"
                    size="txtRobotoSerifRegular12"
                  >
                    <>
                      If you would like to access your information or would
                      otherwise like to exercise any of your rights in respect
                      of your Personal Information (whether as set out above or
                      otherwise), if you have any questions, comments, or
                      suggestions, or if you find any errors in our information
                      about you, please contact us at: privacy@eaidex.com
                      <br />
                      <br />
                      Attn: Privacy Officer <br />
                      Joseph Abraham <br />
                      Eaidex Services Pvt. Ltd.
                      <br />
                      B&B Complex, CMC 13, A.C. Road, Cherthala, <br />
                      Alappuzha, Kerala, India. PIN. 688 524
                    </>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menubar
        sections={sections}
        visible={sidebarVisible}
        onClose={handleToggleSidebar}
        close={handleClose}
      />
    </>
  );
}

export default PrivacyPolicy;
