import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSkillAttributes } from "../API/api";
import SkillAttributesDisplay from "../component/SkillAttributesDisplay/SkillAttributesDisplay";
import LabourRegisterForm from "../component/LabourerRegisterForm/LabourerRegisterForm";
import SubscriptionPlanList from "../component/SubscriptionPlanList/SubscriptionPlanList";
import { SkillAttributesDataDto } from "../API/types";

enum RegistrationStep {
  BASIC,
  SKILL_INPUTS,
  PAYMENT,
}
interface Skill {
  id: number;
  experience: number;
}
interface SelectedSkillsList {
  skills: Skill[];
}

interface SkillAttributesResult {
  data: SkillAttributesDataDto | undefined;
  isLoading: boolean;
}

const Register = () => {
  const [skillList, setSkillList] = useState<SelectedSkillsList>();
  // const skillIds = registerData?.skills?.map((skill) => skill?.id) ?? [];

  
  const { t } = useTranslation();
  const [step, setStep] = useState<RegistrationStep>(RegistrationStep.BASIC);

  const handleSkillUpdate = (updatedSkills: any) => {
    setSkillList((prevFormData) => ({
      ...prevFormData!,
      skills: updatedSkills,
    }));
  };

  const nextStep = () => {
    let next;

    switch (step) {
      case RegistrationStep.BASIC:
        next = RegistrationStep.SKILL_INPUTS;
        break;
      case RegistrationStep.SKILL_INPUTS:
        next = RegistrationStep.PAYMENT;
        break;
      default:
        next = step;
        break;
    }

    setStep(next);
  };

  return (
    <>
      <div className="px-[20px] py-[64px] sm:mt-[54px]">
        <div className="md:flex block justify-between bg-[#FFFCF6] shadow-box rounded-[20px] xl:max-w-[1146px] 2xl:mt-[72px] gap-[46px] 2xl:max-w-[1440px] mx-auto ">
          <div className="md:p-[72px_0_90px_55px] p-[40px]">
            <h2 className="2xl:text-[48px] lg:text-[40px] text-[34px] leading-[55px]  mb-2.5 font-bold">
              {t("needaJob")}
            </h2>
            <p className="2xl:text-[27px] lg:text-[20px] text-[16px] font-medium 2xl:max-w-auto xl:max-w-[600px] max-w-[400px]">
              {t(
                "Looking for job opportunities? Join Eaidex's network and start earning today!"
              )}
            </p>
            <p className="text-[#FF0000] font-bold mt-[39px] md:leading-3 text-[30px]">
              {t("Register here as a Labourer")}
            </p>
          </div>
          <div className="relative">
            <div className="md:absolute bottom-0 2xl:w-max md:w-[300px] w-full right-0 ">
              <img
                src={require("../assets/images/needjob.png")}
                className="ml-auto"
                alt="job"
              />
            </div>
          </div>
        </div>
        <div className="max-w-[857px] w-full  sm:px-5 px-3 mx-auto">
          {step === RegistrationStep.BASIC && (
            <LabourRegisterForm
              skillList={skillList}
              setSkillList={setSkillList}
              handleSkillUpdate={handleSkillUpdate}
              nextStep={nextStep}
            />
          )}
          {/* {step === RegistrationStep.SKILL_INPUTS && (
            <SkillAttributesDisplay
              skillAttributes={skillAttributes}
              masterAttributes={masterAttributes}
              skillIsLoading={skillIsLoading}
              nextStep={nextStep}
            />
          )} */}
          {step === RegistrationStep.PAYMENT && (
            <>
              <SubscriptionPlanList />
            </>
          )}
        </div> 
      </div>
    </>
  );
};

export default Register;
