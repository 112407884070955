import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMdRefresh } from "react-icons/io";
import { DeleteIcon } from "../../assets/images/AllSvg";
import {
  usePreviousSearchDetails,
  useUserMeQuery,
  useServiceCategoryQuery,
} from "../../API/api";
import moment from "moment";
import { useDeleteSearchHistory } from "../services/Mutation";
import CircularProgressBar from "../CircularProgressBar";
import ConfirmationModal from "../DeleteModal";
import { toast } from "react-toastify";
import Pagination from "../Pagination";

interface SearchData {
  id: number;
  location: string;
  createdAt: string;
  resultCount: number;
  skills: string;
}

interface SkillItems {
  id: number;
  name: string;
}

interface PreviousSearchProps {
  searchInput: string;
}

const PreviousSearch: React.FC<PreviousSearchProps> = ({ searchInput }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteItem, setDeleteItem] = useState<number | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { data: userData } = useUserMeQuery();
  const userId = userData?.user.id;
  const { data: skillData, isLoading: isSkillLoading } =
    useServiceCategoryQuery();
  const {
    data: searchHistoryData,
    isLoading: isSearchItemsLoading,
    refetch: refetchSearchHistory,
  } = usePreviousSearchDetails(userId);
  const useDeleteSearchHistoryMutation = useDeleteSearchHistory();

  const filteredData = useMemo(() => {
    if (!searchHistoryData) return [];
    const sortedData = searchHistoryData.sort(
      (a: any, b: any) =>
        moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
    );
    if (searchInput && skillData) {
      return sortedData.filter((data: SearchData) => {
        const skillIds = JSON.parse(data.skills)[0];
        const matchingSkill = skillData.find(
          (skill: SkillItems) => skill.id === skillIds
        );
        return (
          matchingSkill &&
          matchingSkill.name.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    return sortedData;
  }, [searchInput, searchHistoryData, skillData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData?.slice(indexOfFirstRow, indexOfLastRow);
  const totalRows = filteredData?.length || 0;
  const startRow = totalRows === 0 ? 0 : (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, totalRows);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteClick = (item: SearchData) => {
    setDeleteItem(item.id);
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteItem !== null) {
      try {
        await useDeleteSearchHistoryMutation.mutateAsync(deleteItem);
        setShowConfirmDialog(false);
        refetchSearchHistory();
      } catch (error) {
        toast.error("Failed to delete search history. Please try again later.");
        console.error("Failed to delete search history:", error);
        setShowConfirmDialog(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmDialog(false);
  };

  if (isSearchItemsLoading || isSkillLoading) {
    return (
      <div className="mt-8 flex justify-center items-center">
        <CircularProgressBar />
      </div>
    );
  }

  return (
    <div className="mt-16">
      <h2 className="mb-5 text-lg font-semibold xl:text-2xl lg:text-2xl">
        {t("Previous Searches")}
      </h2>
      <div className="w-full overflow-x-scroll">
        <table className="min-w-[968px] w-full divide-y divide-graylighter border border-[#C9C9C9] mb-5">
          <thead className="bg-[#EDEDED]">
            <tr>
              <th className="px-3 py-[19px] text-left text-sm font-semibold text-black w-[240px]">
                {t("Services Name")}
              </th>
              <th className="px-3 py-[19px] text-center text-sm font-semibold text-black">
                {t("Location")}
              </th>
              <th className="px-3 py-[19px] text-center text-sm font-semibold text-black">
                {t("Searched on")}
              </th>
              <th className="px-3 py-[19px] text-center text-sm font-semibold text-black">
                {t("Result Count")}
              </th>
              <th className="px-3 py-[19px] text-center text-sm font-semibold text-black"></th>
            </tr>
          </thead>
          <tbody>
            {currentRows?.length === 0 ? (
              <tr>
                <td colSpan={5}>
                  <div className="text-center items-center text-primary text-lg mt-3">
                    {t("No results found")}
                  </div>
                </td>
              </tr>
            ) : (
              currentRows?.map((data: SearchData) => {
                const skillIds = JSON.parse(data.skills)[0];
                const matchingSkill = skillData?.find(
                  (skill: SkillItems) => skill.id === skillIds
                );
                return (
                  <tr className="border-b-2" key={data.id}>
                    <td className="py-[26px] pl-[10px] flex items-center justify-start gap-2 w-[240px]">
                      {matchingSkill && (
                        <span className="bg-[#F0A61F] text-[13px] leading-[16px] py-[8px] px-[13px] rounded-[24px] text-secondary block w-fit">
                          {t(matchingSkill.name)}
                        </span>
                      )}
                    </td>
                    <td className="py-[26px] px-[23px] w-[190px] text-center">
                      {t(data.location) || "Kochi"}
                    </td>
                    <td className="py-[26px] px-[23px] w-[190px] text-center">
                      {moment(data.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-center w-[190px] whitespace-nowrap">
                      <span>{data.resultCount}</span>
                    </td>
                    <td className="w-[190px] px-4">
                      <div className="flex relative justify-center gap-[16px]">
                        <button
                          onClick={() => refetchSearchHistory()}
                          className="px-3 py-2 rounded shadow-md border border-black w-[46px] flex items-center justify-center"
                        >
                          <IoMdRefresh className="w-[22px] focus:outline-none focus:ring-0 flex items-center justify-center" />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(data)}
                          className="px-3 py-2 rounded shadow-md border border-black w-[46px] flex items-center justify-center"
                        >
                          <DeleteIcon className="w-[40px] focus:outline-none focus:ring-0 h-[40px] flex items-center justify-center" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {totalRows > 0 && (
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          startRow={startRow}
          endRow={endRow}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          indexOfLastRow={indexOfLastRow}
          totalNumber={totalRows}
        />
      )}
      <ConfirmationModal
        isOpen={showConfirmDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isLoading={useDeleteSearchHistoryMutation.isLoading}
        itemName={t("search data")}
      />
    </div>
  );
};

export default PreviousSearch;
