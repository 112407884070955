import React, { createContext, useState } from "react";

interface ItemDataContextProps {
  children: React.ReactNode;
}

export const ItemDataContext = createContext<any>(null);

export const ItemDataProvider: React.FC<ItemDataContextProps> = ({ children }: ItemDataContextProps) => {
  const [itemData, setItemData] = useState<any>(null);

  return (
    <ItemDataContext.Provider value={{ itemData, setItemData }}>
      {children}
    </ItemDataContext.Provider>
  );
};
