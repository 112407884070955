import { useQuery } from "@tanstack/react-query";
import { NoContentResponse, REACT_APP_API_URL, apiEndpoint } from "./api";
import { AddRatingReview, RatingReviewResponse } from "./types";
import * as QueryKey from "../constants/QueryKeys";

export const useEmployerReviews = (rateActive: boolean) => {
  const enabled = rateActive === true ? true : false;
  return useQuery({
    queryKey: [QueryKey.GET_EMPLOYER_REVIEW],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/employer-reviews`,
        {},
        "GET"
      ),
    enabled: enabled,
  });
};

export const addReview = async (reviewData: AddRatingReview) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/employer-reviews`,
    reviewData,
    "POST"
  );
};

export const updateEmployerReview = async (request: {
  reviewId: number;
  reviewData: AddRatingReview;
}) => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/employer-reviews/${request.reviewId}`,
    request.reviewData,
    "PATCH"
  );
};

export const useEmployerReviewObtained = (labourerId: number) => {
  return useQuery({
    queryKey: ["reviewsData"],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/employer-reviews/labourer/${labourerId}`,
        {},
        "GET"
      ),
  });
};

export const useReviewDetails = (reviewId: number | null) => {
  return useQuery({
    queryKey: ["reviewsDetail", { reviewId }],
    queryFn: () =>
      apiEndpoint<RatingReviewResponse>(
        `${REACT_APP_API_URL}/employer-reviews/${reviewId}`,
        {},
        "GET"
      ),
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
  });
};
