import React from "react";
import { useTranslation } from "react-i18next";

const AboutLanding = () => {
  const { t } = useTranslation();
  return (
    <div className="px-[14px]">
      <h2 className="font-bold xl:text-[53px] md:text-[42px] text-[26px] mb-2.5 xl:leading-[78px] leading-tight text-center text-black">
        {t("AboutUs")}
      </h2>
      <p className="xl:text-[31px] md:text-[24px] sm:text-[20px] text-[17px] mx-auto xl:leading-[46px] leading-normal bold text-justify text-black">
        {t("AboutUs Text")}
      </p>
    </div>
  );
};

export default AboutLanding;
