import { AiFillCaretRight } from "react-icons/ai";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { FeedbackSchema } from "../schema/feedbackSchema";
import { useState } from "react";
import Slider from "react-slick";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  sendFeedbackMutation,
  useGetFaqs,
  FaqsDto,
  FeedbackDetailsDto,
} from "../API/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgressBar from "../component/CircularProgressBar";
import { isAuthenticated } from "../utils/jwtUtils";
import { UserType } from "../API/types";
import { useSendFeedback } from "../component/services/Mutation";

interface ContactUsProps {
  onRegisterClicked: (UserType?: UserType) => void;
}
interface FeedbackFormDto {
  name: string;
  email: string;
  phone: string;
  message: string;
}
const Faqs: React.FC<ContactUsProps> = ({ onRegisterClicked }) => {
  const { t } = useTranslation();
  const placeholderName = t("Name") || "";
  const placeholderEmail = t("Email Id") || "";
  const placeholderPhone = t("Phone Number") || "";
  const placeholderMessage = t("Message") || "";

  const { data: faqsData, isLoading } = useGetFaqs();

  const sendFeedbackMutation = useSendFeedback();

  const {
    register: feedbackForm,
    handleSubmit: feedbackFormSubmission,
    formState: { errors: feedbackFormErrors },
    reset,
  } = useForm({
    resolver: yupResolver(FeedbackSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: FeedbackFormDto) => {
    try {
      const feedbackDetails: FeedbackDetailsDto = {
        name: data.name,
        message: data.message,
        emailId: data.email,
        status: "NEW",
        phoneNumber: data.phone,
      };
      await sendFeedbackMutation.mutateAsync(feedbackDetails);
      reset();
    } catch (error) {
      console.error("An error occurred during mutation:", error);
    }
  };

  const sortedFaqsData = faqsData?.sort((a: FaqsDto, b: FaqsDto) => {
    return (
      new Date(b.updatedDateTime).getTime() -
      new Date(a.updatedDateTime).getTime()
    );
  });
  const filteredFaqsData = sortedFaqsData?.filter((faq: FaqsDto) => faq.status);
  const latestFiveFaqs = filteredFaqsData?.slice(0, 5);

  return (
    <div className="xl:max-w-[1186px] lg:mt-[45px] px-5 2xl:max-w-[1440px] mx-auto ">
      <div className="w-full rounded-[20px] lg:h-[368px] h-full  lg:pl-4 pt-[62px] lg:flex items-center lg:justify-between lg:mt-[116px] relative ">
        <div>
          <h2 className="2xl:text-[48px] lg:text-[40px] text-[34px] text-black font-bold lg:text-left text-center">
            {t("faq")}{" "}
          </h2>
          <div className="flex flex-col	gap-2">
            <p className="2xl:text-[27px] lg:text-[20px] text-[16px] text-black max-w-[610px] lg:mx-0 mx-auto font-medium mt-3 lg:text-left text-center">
              {t("Do you have questions?")}
            </p>
            <p className="2xl:text-[20px] lg:text-[10px] text-[12px] text-black max-w-[750px] lg:mx-0 mx-auto font-sm  lg:text-left text-center">
              {t(
                "We have answers, and we appreciate your efforts to understand our business"
              )}
            </p>
          </div>
          {!isAuthenticated() && (
            <button
              onClick={() => {
                onRegisterClicked();
              }}
              className="flex justify-center text-[17px] leading-[13px] font-medium gap-3 py-[13px] px-[16px] rounded-full bg-[#F0A61F] min-w-[161px] mt-9 h-[50px] items-center lg:mx-0 mx-auto"
            >
              {t("Signup")} <AiFillCaretRight className="text-[12px]" />
            </button>
          )}
        </div>
        <div className="lg:w-auto w-full lg:mt-0 mt-10">
          <img
            src={require("../assets/images/faqs.png")}
            alt="feedback"
            className="mx-auto"
          />
        </div>
      </div>
      <div className="mx-auto md:px-6 pt-[98px] lg:px-8">
        <div className="mx-auto">
          {isLoading ? (
            <div className="mt-8 flex justify-center items-center">
              <CircularProgressBar />
            </div>
          ) : (
            <dl>
              {latestFiveFaqs?.map((faq: FaqsDto) => (
                <Disclosure
                  as="div"
                  key={faq?.question}
                  className="py-[12px] border-b-[#5F5F5F] border-b-[1px]"
                >
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-black">
                          <span className="sm:text-base text-[14px] font-normal sm:ml-10 leading-7">
                            {t(faq?.question)}
                          </span>
                          <span className="ml-6 sm:mr-10 flex h-7 items-center text-black">
                            {open ? (
                              <MinusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 ml-10 text-gray-600">
                          {t(faq?.answer)}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          )}
        </div>

        <div className=" mt-12">
          <p className="text-xl">{t("Still stuck? Help is a mail away")}</p>
        </div>
        <div className=" mt-4 border border-gray-200 shadow-lg mb-4">
          <form onSubmit={feedbackFormSubmission(onSubmit)}>
            <div className=" flex flex-col px-3 sm:px-3 md:px-6 lg:px-6 xl:px-6 2xl:px-6 mt-5 mb-5 gap-2">
              <div>
                <input
                  {...feedbackForm("name")}
                  name="name"
                  type="text"
                  placeholder={placeholderName}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[20px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {feedbackFormErrors.name?.message}
                </p>
              </div>
              <div>
                <input
                  {...feedbackForm("email")}
                  type="email"
                  name="email"
                  placeholder={placeholderEmail}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[20px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {feedbackFormErrors.email?.message}
                </p>
              </div>
              <div>
                <input
                  {...feedbackForm("phone")}
                  type="number"
                  name="phone"
                  placeholder={placeholderPhone}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[20px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {feedbackFormErrors.phone?.message}
                </p>
              </div>
              <div>
                <textarea
                  {...feedbackForm("message")}
                  name="message"
                  placeholder={placeholderMessage}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[20px] w-full h-[148px] p-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {feedbackFormErrors.message?.message}
                </p>
              </div>
              <div className=" flex gap-1 items-center">
                <input className="m-0 h-4 w-[20px] mr-1" type="checkbox" />
                <div className=" text-xl tracking-[0.16px] leading-[24px] font-medium font-montserrat  text-left inline-block  shrink-0 mq450:text-base mq450:leading-[19px]">
                  {t("I’m not a robot")}
                </div>{" "}
                <img
                  className="w-[50px] h-[50px] relative z-[1] ml-3"
                  loading="eager"
                  alt=""
                  src={require("../assets/images/captchaIcon.png")}
                />
              </div>
              <button
                disabled={sendFeedbackMutation?.isLoading}
                className="min-w-[127px] min-h-[24px] py-[10px] px-[65px] rounded mt-[25px] mx-auto text-white sm:text-lg text-sm font-medium  bg-[#3a423d]  hover:bg-[#272D29]"
              >
                {sendFeedbackMutation?.isLoading ? (
                  <>
                    <span className="animate-spin mr-2">&#8987;</span>
                    Submitting...
                  </>
                ) : (
                  t("Send a message")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
