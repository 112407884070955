import { useEffect, useRef, useState } from "react";
import { Filter, Megnify } from "../assets/images/AllSvg";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSearchLabourer } from "../API/api";
import MapComponent from "../component/GoogleMapComponent";
import { Service } from "../API/types";
import useGeolocation, {
  AddressDetails,
  LocationData,
} from "../utils/useGeolocation";
import { useCompleteImageUrl } from "../utils/GeneralUtils";
import LocationSelector from "./Location/LocationSelector";

const Home = ({ serviceData }: { serviceData: Array<Service> }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [openLocation, setOpenLocation] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [isOutside, setIsOutside] = useState(false);

  const [selectedLocation, setSelectedLocation] =
    useState<null | AddressDetails>({
      city: "Ahmedabad",
      state: "Gujarat",
      country: "India",
    });

  const [locationData, setLocationData] = useState<LocationData | null>(null);

  /* const [selectName, setselectedName] = useState({
    city: "Ahmedabad",
    state: "Gujarat",
    country: "India",
  }); */

  /* const [locationData, setLocationData] = useState({
    lat: 0,
    lng: 0,
    address: "",
  }); */

  const [searchId, setSearchId] = useState<any>({
    id: null,
    name: "",
  });

  const {
    location: geoLocation,
    selectedName,
    error,
    detectLocation,
  } = useGeolocation();

  useEffect(() => {
    setLocationData(geoLocation);
    setSelectedLocation(selectedName);
    if (error) {
      console.log(error);
    }
  }, [geoLocation, selectedName, error]);

  const handleTogglePopover = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowPopover(!showPopover);
  };

  const searchLabour = (searchId: Service) => {
    setSearchId({ id: searchId.id, name: searchId.name });
    navigate("/result", {
      state: {
        searchInput: searchId,
        location: locationData,
      },
    });
  };

  const placeholderText = t("Searchforservices") || "";

  const handlePlaceSelect = (place: any) => {
    setLocationData({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    });

    setSelectedLocation({
      city: JSON.parse(JSON.stringify(place?.address_components[0]?.long_name)),
      state: JSON.parse(
        JSON.stringify(
          place?.address_components[2]?.long_name
            ? place?.address_components[1].long_name
            : place?.address_components[2]?.long_name
        )
      ),
      country: JSON.parse(
        JSON.stringify(
          place?.address_components[3]?.long_name +
            " , " +
            place?.address_components[4]?.long_name
        )
      ),
    });

    // setShowPopover(false);
  };
  const handleDetectLocation = () => {
    detectLocation();
  };

  const popoverRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        popoverRef.current &&
        !(popoverRef?.current as any)?.contains(event.target)
      ) {
        setShowPopover(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [showPopover]);

  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();
  return (
    <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] mt-[80px] px-5 2xl:max-w-[1440px] mx-auto">
      <p className="4xl:text-[1.2vw] text-[#5E6D76] text-[23px] font-medium">
        {t("Get100safeservicebyverifiedpartners")}
      </p>
      <h1 className="text-[#162634] 4xl:text-[2.5vw] lg:text-[70px] text-[7vw] font-bold">
        {t("whatServiceDoYouNeed")}
      </h1>
      <div className="flex gap-[14px] md:flex-nowrap flex-wrap">
        <div className="relative w-full h-full mt-4 lg:mt-0">
          <div className="absolute flex items-center justify-center top-0 bottom-0  w-[60px] h-full">
            <Megnify className={"#272D29"} />
          </div>
          <input
            className="border border-solid outline-none focus:outline-none pl-[60px] py-[18px] rounded-[14px] w-full placeholder:text-[#999485] text-black  border-[#B9B9B9] text-[21px] leading-[24px] font-medium placeholder:4xl:text-[1vw]"
            type="text"
            placeholder={placeholderText}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     searchJobs(searchInput);
            //   }
            // }}
          />
        </div>
        <div className="flex md:gap-[14px] justify-between w-full md:w-auto ">
          <LocationSelector />
        </div>
      </div>

      <div className="mt-[46px]">
        <div className="flex items-center justify-between">
          <h1 className="lg:text-[53px] text-[6vw] font-semibold">
            {t("Categories")}
          </h1>
          <div className="flex gap-[15px]">
            <button className="text-[#0A2640] w-[40px] h-[40px] text-[24px] flex justify-center items-center rounded-full bg-[#D9D9D9]">
              <BiLeftArrowAlt />
            </button>
            <button className="text-[#0A2640] w-[40px] h-[40px] text-[24px] flex justify-center items-center rounded-full bg-[#F4B337]">
              <BiRightArrowAlt />
            </button>
          </div>
        </div>
        <div className="grid 4xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-[60px] gap-11 mb-[103px]">
          {serviceData
            .filter((item: Service) =>
              item.name.toLowerCase().includes(searchInput.toLowerCase())
            )
            .map((item: Service, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => searchLabour(item)}
                  className="flex flex-col items-center cursor-pointer"
                >
                  <img
                    src={
                      item?.imageUrl
                        ? getCompleteImageUrl(item.imageUrl)
                        : require("../assets/images/no_preview_image.png")
                    }
                    alt="Preview"
                    className="w-64 border border-gray-300 rounded-lg h-60"
                  />
                  <p className="justify-self-center	 text-[#272D29] 4xl:text-[1.2vw] text-[22px] font-medium mt-[15px] text-center">
                    {t(item.name)}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Home;
