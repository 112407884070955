import { useLocation } from "react-router-dom";

export enum PaymentGatewayTxStatus {
  SUCCESS_SANDBOX = "100", //Transaction is success in sandbox
  SUCCESS = "200", //Transaction is success
  TRANSACTION_IN_PROGRESS = "211", //Transaction in processing
  FAILED = "400", // Transaction in failed
  DROPPED = "401", // The transaction will not register properly
  CANCEL_1 = "402", // payment that has not yet been processed
  CANCEL_2 = "502", // payment that has not yet been processed
  INCOMPLETE = "403", // Not recieved any call back from bank
  BOUNCED = "405", // The transaction has bounced
  NO_RECORD = "503", // There is no records found
}

export const PaymentStatus: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const status = queryParams.get("status");
  const txnId = queryParams.get("txnId");

  const error = (
    <div className="flex flex-col items-center justify-center h-screen">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-16 h-16 mb-4 text-red-600"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm1.25 14.75a.75.75 0 1 0-1.5 0v.5a.75.75 0 0 0 1.5 0v-.5zm-.75-8.5a1 1 0 0 0-1 1v4.5a1 1 0 0 0 2 0v-4.5a1 1 0 0 0-1-1zm0-3.75a.75.75 0 0 0-.75.75v.5a.75.75 0 1 0 1.5 0v-.5a.75.75 0 0 0-.75-.75z"
          clipRule="evenodd"
        />
      </svg>
      <h1 className="mb-4 text-3xl font-semibold text-red-600">
        Payment Failed!
      </h1>
      <p className="mb-2 text-lg text-gray-700">
        We're sorry, your payment could not be processed.
      </p>
      <p className="mb-2 text-lg text-gray-700">
        Please try again or contact support.
      </p>
    </div>
  );

  const success = (
    <div className="flex flex-col items-center h-screen mt-10">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-16 h-16 mb-4 text-green-600"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zM8.354 13.854a.5.5 0 0 0 .708 0l5-5a.5.5 0 0 0-.708-.708l-4.146 4.146-1.646-1.647a.5.5 0 0 0-.708.708l2 2z"
          clipRule="evenodd"
        />
      </svg>
      <h1 className="mb-4 text-3xl font-semibold text-green-600">
        Payment Successful!
      </h1>
      <p className="mb-2 text-lg text-gray-700">Thank you for your purchase.</p>
      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <p className="mb-2 text-lg text-gray-800">Amount Paid: INR {50}</p>
        <p className="mb-2 text-lg text-gray-800">Transaction ID: {200}</p>
      </div>
    </div>
  );

  const isSuccess =
    status == PaymentGatewayTxStatus.SUCCESS ||
    status == PaymentGatewayTxStatus.SUCCESS_SANDBOX;

  return isSuccess ? success : error;
};
