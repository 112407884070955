import * as yup from "yup";

import {
  BANK_NAME_REQUIRED,
  IFSC_REQUIRED,
  ACCOUNT_NUMBER_REQUIRED,
  ACCOUNT_NAME_REQUIRED,
  ACTIVE_REQUIRED,
  BANK_NAME_INVALID,
  IFSC_INVALID,
  ACCOUNT_NUMBER_INVALID,
  ACCOUNT_NAME_INVALID,
  BANK_NAME_MAX_LENGTH,
  IFSC_LENGTH,
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NAME_MAX_LENGTH,
} from "../constants/ValidationMessage";

const BANK_NAME_REGEX = /^(?!\s*$)[A-Za-z\s]+$/;
const IFSC_REGEX = /^[A-Za-z]{4}[0-9]{7}$/;
const ACCOUNT_NUMBER_REGEX = /^\d+$/;
const ACCOUNT_NAME_REGEX = /^(?!\s*$)[A-Za-z\s]+$/;

export const BankDetailsSchema = yup
  .object()
  .shape({
    bankName: yup
      .string()
      .required(BANK_NAME_REQUIRED)
      .matches(BANK_NAME_REGEX, BANK_NAME_INVALID)
      .max(30, BANK_NAME_MAX_LENGTH),

    ifsc: yup
      .string()
      .required(IFSC_REQUIRED)
      .matches(IFSC_REGEX, IFSC_INVALID)
      .length(11, IFSC_LENGTH),

    accountNumber: yup
      .string()
      .required(ACCOUNT_NUMBER_REQUIRED)
      .matches(ACCOUNT_NUMBER_REGEX, ACCOUNT_NUMBER_INVALID)
      .max(20, ACCOUNT_NUMBER_MAX_LENGTH),

    accountName: yup
      .string()
      .required(ACCOUNT_NAME_REQUIRED)
      .matches(ACCOUNT_NAME_REGEX, ACCOUNT_NAME_INVALID)
      .max(30, ACCOUNT_NAME_MAX_LENGTH),

    active: yup.boolean().required(ACTIVE_REQUIRED),
  })
  .required();
