import jwt_decode from "jwt-decode";
import { smartStorage } from "./SmartStorage";

export const TOKEN = "Token";

var _verifiedToken: string | null = null;

interface DecodedToken {
  exp: number;
}

// Function to check if JWT token is expired
const isTokenExpired = (token: string | null): boolean => {
  try {
    if (!token) return true;
    // Decode the JWT token
    const decodedToken: DecodedToken = jwt_decode(token);

    // Get the current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token expiration time is in the future
    return decodedToken.exp < currentTime;
  } catch (error) {
    // If decoding fails, token is considered expired
    return true;
  }
};

export const isAuthenticated = () => {
  let token = smartStorage.getItem(TOKEN) ?? null;
  return !isTokenExpired(token);
};

export const _token = () => {
  return smartStorage.getItem(TOKEN) ?? null;
};

export const setToken = (token: string) => {
  smartStorage.setItem(TOKEN, token);
};

export const clearToken = () => {
  smartStorage.removeItem(TOKEN);
};

export const verifiedToken = () => {
  return _verifiedToken;
};

export const isPhoneVerified = () => {
  return !isTokenExpired(_verifiedToken);
};

export const setVerifiedToken = (token: string) => {
  _verifiedToken = token;
};

export const clearVerifiedToken = () => {
  _verifiedToken = null;
};
