import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DashboardIcon,
  Heart,
  Job,
  Megnify,
  Message,
  Messagequotes,
  Paymenthistory,
  ReferralIcon,
  Settings,
} from "../assets/images/AllSvg";
import { RiMenu3Fill } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useTotalUnreadCount, useUserMeQuery } from "../API/api";
import { isHybrid, isLabourer } from "../utils/utils";

const SideBar = () => {
  const { data: userRoleData } = useUserMeQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/")[1];
  const [open, setOpen] = useState(false);

  const handleChangePath = useCallback(
    (e: any) => {
      navigate(`${e}`);
    },
    [path]
  );

  const { data: countData } = useTotalUnreadCount();
  const showPaymentHistory = useMemo(() => {
    if (!userRoleData?.user) return false;
    return isLabourer(userRoleData?.user) || isHybrid(userRoleData?.user);
  }, [userRoleData]);

  const showMyReferrals = useMemo(() => {
    if (!userRoleData?.user) return false;
    return isLabourer(userRoleData?.user) || isHybrid(userRoleData?.user);
  }, [userRoleData]);

  const Navigation = [
    {
      path: "dashboard",
      name: "Dashboard",
      Icon: <DashboardIcon className={"#000"} />,
      Activeicon: <DashboardIcon className={"#F0A61F"} />,
    },
    {
      path: "searchservices",
      name: "Search for Services",
      Icon: <Megnify className={"#000"} />,
      Activeicon: <Megnify className={"#F0A61F"} />,
    },
    {
      path: "jobstatistics",
      name: "Job Statistics",
      Icon: <Job className={"#000"} />,
      Activeicon: <Job className={"#F0A61F"} />,
    },
    {
      path: "favourites",
      name: "Favourites",
      Icon: <Heart className={"#000"} />,
      Activeicon: <Heart className={"#F0A61F"} />,
    },
    {
      path: "rates&review",
      name: "Rates & Reviews",
      Icon: <Messagequotes className={"#000"} />,
      Activeicon: <Messagequotes className={"#F0A61F"} />,
    },
    {
      path: "paymenthistory",
      name: "Payment History",
      Icon: <Paymenthistory className={"#000"} />,
      Activeicon: <Paymenthistory className={"#F0A61F"} />,
      show: showPaymentHistory,
    },
    {
      path: "messageCenter",
      name: "Message Centre",
      Icon: <Message className={"#000"} />,
      Activeicon: <Message className={"#F0A61F"} />,
    },
    {
      path: "referrals",
      name: "My Referrals",
      Icon: <ReferralIcon className={"#000"} />,
      Activeicon: <ReferralIcon className={"#F0A61F"} />,
      show: showMyReferrals,
    },
    {
      path: "settings",
      name: "Settings",
      Icon: <Settings className={"#000"} />,
      Activeicon: <Settings className={"#F0A61F"} />,
    },
  ];
  return (
    <>
      <div
        className={`custom-scroll overflow-y-auto min-w-[280px] bg-[#FFFCF6] py-4 ${
          open ? "fixed top-0 h-full w-[90%] z-50" : "md:block hidden"
        }`}
      >
        <div className="relative">
          {/* {Navigation.map((router, index) => { */}
          {Navigation.filter((item) => item.show !== false).map(
            (router, index) => {
              return (
                <div key={index}>
                  <button
                    onClick={() => handleChangePath(router?.path)}
                    className={`${
                      path === router.path
                        ? "bg-[#FEF4E1] after:content-[' '] after:absolute after:rounded-tl-[4px] after:rounded-bl-[4px] after:top-0 after:right-0 after:w-[4px] after:h-[64px] after:bg-[#F0A61F]"
                        : ""
                    } w-full flex items-center justify-center gap-6 text-[#2B2F42] font-medium text-sm h-[64px] relative`}
                  >
                    {path === router.path ? router.Activeicon : router.Icon}
                    <p className="  w-[140px] text-left">
                      {t(router.name)}
                      {router.name === "Message Centre" &&
                        countData &&
                        countData.count > 0 && (
                          <div className="inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full dark:border-gray-900">
                            {countData?.count}
                          </div>
                        )}
                    </p>
                  </button>
                </div>
              );
            }
          )}
          <AiOutlineCloseCircle
            onClick={() => setOpen(!open)}
            className="text-[30px] absolute top-0 right-0 md:hidden block"
          />
        </div>
      </div>
      <div className="block md:hidden cursor-pointer absolute z-[49]">
        <RiMenu3Fill className="text-[30px]" onClick={() => setOpen(true)} />
      </div>
    </>
  );
};

export default SideBar;
