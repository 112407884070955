import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import {
  GetOverallRatings,
  GetLabourerSkillRatings,
  useUserMeQuery,
  SkillRateDto,
} from "../../API/api";
import CircularProgressBar from "../CircularProgressBar";
import { EmptyData } from "../chart/EmptyData";
import { BarChartIcon } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";

Chart.register(ChartDataLabels);

const createGradient = (ctx: any, chartArea: any) => {
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.top,
    0,
    chartArea.bottom
  );
  gradient.addColorStop(0, "#50E3C1"); // Start color
  gradient.addColorStop(1, "#0E8D70"); // End color
  return gradient;
};

const RatingChart: React.FC<any> = () => {
  const { t } = useTranslation();
  const { data: userData } = useUserMeQuery();
  const labourerId = userData?.labourerDetails?.id;
  const { data: overallRatingData } = GetOverallRatings(labourerId);
  const {
    data: skillRatingData,
    isLoading,
    isError,
  } = GetLabourerSkillRatings(labourerId);

  const skillRatingValues = skillRatingData?.map(
    (dataPoint: SkillRateDto) => dataPoint?.avgRating
  );
  const skillNameValues = skillRatingData?.map(
    (dataPoint: SkillRateDto) => dataPoint?.skillName
  );

  const data: ChartData<"bar"> = {
    labels: skillNameValues,
    datasets: [
      {
        label: "Ratings & Reviews",
        data: skillRatingValues as (number | [number, number] | null)[],
        // backgroundColor: ["#F0A61F"],
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return createGradient(ctx, chartArea);
        },
        borderWidth: 1,
      },
    ],
  };

  // CHART DATA CONFIG
  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        display: true,
        max: 5,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      datalabels: {
        color: "#000",
        font: {
          weight: "bold",
          size: 14,
        },
        align: "end",
        anchor: "start",
        formatter: function (value: any, context: any) {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}      ${value} ★ `; // Combine label and value
        },
      },
      legend: { display: false },
    },
    layout: {
      padding: {
        right: 40,
      },
    },
    responsive: true,
  };

  const overallRating = overallRatingData?.length
    ? overallRatingData[0]?.overallRating ?? 0
    : 0;

  return (
    <>
      <div className="bg-white jobsMaindiv flex flex-col items-center  pl-4 rounded-md pb-2 shadow-md">
        <div className="mb-4 flex justify-between p-2 items-center w-full">
          <div>
            <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto">
              {t("Ratings & Reviews")}
            </span>
          </div>
          <div className="text-right mt-2">
            <span className=" font-extrabold text-black text-2xl"> ★</span>{" "}
            <span className="text-xl font-semibold">
              {overallRating?.toFixed(1)}
            </span>
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              <CircularProgressBar />
            </div>
          </div>
        ) : isError ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              {t("Error loading data")}
            </div>
          </div>
        ) : skillRatingData.length === 0 ? (
          <EmptyData Icon={BarChartIcon} header={"ratings & reviews"} />
        ) : (
          <div className="flex flex-col items-center w-full p-1 ">
            <Bar data={data} options={options} />
          </div>
        )}
      </div>
    </>
  );
};

export default RatingChart;
