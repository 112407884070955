import React, { useState } from "react";
import {
  usePaymentCardData,
  useUserMeQuery,
  GetPaymentCardDto,
} from "../../API/api";
import { toast } from "react-toastify";
import { TickIcon } from "../../assets/images/AllSvg";
import { useNavigate } from "react-router-dom";
import {
  PaymentInitResponse,
  initPayment,
  proceedPaymentUsingWallet,
} from "../../API/subscriptionApi";
import { useTranslation } from "react-i18next";
import GenericDialog from "../GenericDialog";
import CircularProgressBar from "../CircularProgressBar";
import { FaSpinner } from "react-icons/fa";

const benefits = [
  {
    name: "Unlimited Projects",
  },
  {
    name: "24/7 Support",
  },
  {
    name: "Cloud Storage",
  },
];

export interface SubscriptionDto {
  subscriptionPlanId: number;
  userId: number;
  userUuid: string;
  subscriptionId: number;
  paymentUUID: string;
}
const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center  py-8">
    <CircularProgressBar />
  </div>
);

const SubscriptionPlanList: React.FC = () => {
  const { t } = useTranslation();
  const { data: paymentCardData, isLoading: paymentDataLoading } =
    usePaymentCardData();
  const { data: userData } = useUserMeQuery();
  const navigate = useNavigate();
  const [showUseWalletConfirmation, setShowUseWalletConfirmation] =
    useState(false);
  const [loading, setLoading] = useState<number | null>(null); // New state to manage loading

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>,
    subscriptionPlanId: number
  ) => {
    event.preventDefault();
    setLoading(subscriptionPlanId);
    try {
      const response = await initPayment(subscriptionPlanId);
      handleResponse(response, subscriptionPlanId);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(null);
    }
  };

  const handleSkip = () => {
    navigate("/dashboard");
  };

  const handleError = (error: any) => {
    toast.error(t("subscriptionPaymentInitiationFailed"));
  };

  const handleResponse = (
    response: PaymentInitResponse,
    subscriptionPlanId: number
  ) => {
    if (response.amountToBePaid == 0) {
      // TODO sufficient balance
      proceedPaymentUsingWallet({
        paymentUuid: response.paymentUUID,
        subscriptionId: response.subscriptionId,
      })
        .then((response) => {
          setShowUseWalletConfirmation(true);
        })
        .catch((ex) => {
          console.error(ex);
        });
    } else {
      const userId = userData?.user?.id ?? 0;
      const userUuid = userData?.user?.userId ?? "";
      const subscriptionDto: SubscriptionDto = {
        subscriptionPlanId: subscriptionPlanId,
        userId: userId,
        userUuid: userUuid,
        subscriptionId: response.subscriptionId,
        paymentUUID: response.paymentUUID,
      };
      try {
        navigate("/checkout", { state: subscriptionDto });
      } catch (error) {
        handleError(error);
        console.error("Error initiating subscription payment:", error);
      }
    }
  };

  if (paymentDataLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {paymentCardData?.map((plan: GetPaymentCardDto) => (
        <div className="max-w-[857px] w-full mt-[45px] md:p-7 p-4  mx-auto  border shadow-lg rounded ">
          <>
            <div className="flex flex-col shadow-2xl sm:flex-row">
              {/* Section 1 */}
              <div className="justify-center flex-grow section1 sm:w-3/4">
                <div className="relative items-center h-full p-8 overflow-hidden text-center text-white bg-gradient-to-tr from-purple-500 via-pink-500 to-red-500">
                  <div className="mb-4 text-base font-bold md:text-2xl ">
                    {plan?.name}
                  </div>
                  <p className="mb-4 text-base text-center">
                    {plan?.description}
                  </p>
                  <div className="mt-6">
                    <span className="text-3xl font-extrabold md:text-5xl">
                      &#8377;{plan?.price}
                    </span>
                    <span className="text-sm md:text-lg">
                      / {plan?.validityDays} {t("Days Validity")}
                    </span>
                  </div>
                </div>
              </div>
              {/* Section 2 */}
              <div className="section2 sm:w-2/5">
                <div className="items-center p-6 overflow-hidden text-center bg-white shadow-lg ">
                  <div className="mb-2 text-xl font-bold text-center">
                    {plan?.name}
                  </div>
                  <p className="text-base text-center text-gray-700">
                    {plan?.description}
                  </p>
                  <div className="mt-4">
                    <span className="text-3xl font-bold">
                      {" "}
                      &#8377;{plan?.price}
                    </span>
                    <span className="text-sm text-gray-600">
                      / {plan?.validityDays} {t("Days Validity")}
                    </span>
                  </div>
                  <ul className="mt-4">
                    {benefits?.map((benefit, index) => (
                      <li key={index} className="flex items-center mt-3">
                        <TickIcon />
                        {t(benefit?.name)}
                      </li>
                    ))}
                  </ul>
                  <div className="mt-6 text-center">
                    <button
                      onClick={(event) => handleSubmit(event, plan?.id)}
                      className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out transform bg-blue-500 rounded hover:bg-blue-700 hover:-translate-y-1"
                      disabled={loading === plan?.id}
                    >
                      {loading === plan?.id ? (
                        <span>
                          <FaSpinner className="inline-block animate-spin mr-3" />
                          {t("Processing")}
                        </span>
                      ) : (
                        t("Subscribe")
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <button
                onClick={handleSkip}
                className=" text-gray-600  hover:text-gray-900 font-semibold  text-lg text-left leading-[24px]    focus:outline-none"
              >
                {t("Skip")}
              </button>
            </div>
          </>
        </div>
      ))}

      <GenericDialog
        open={showUseWalletConfirmation}
        setOpen={setShowUseWalletConfirmation}
        title={t("subscriptionPayment.success.title")}
        message={t("subscriptionPayment.success.message")}
      />
    </>
  );
};

export default SubscriptionPlanList;
