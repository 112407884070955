import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "../component/Context/AppContext";
import { createJob, jobAwardActionPost, jobChatRoom } from "../API/api";
import { SearchResult } from "../API/types";
import { toast } from "react-toastify";
import { useState } from "react";
import { mapSkillIds } from "../utils/utils";
import useNavigation from "./useNavigation";

interface JobHook {
  performAction: (
    actionType: "chat" | "award",
    item: SearchResult
  ) => Promise<void>;
  chatRoomLoadingState: boolean;
  awardJobLoadingState: boolean;
  createJobError: string | null;
  openAwardMenu: boolean;
  setOpenAwardMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useJob = (): JobHook => {
  const { appState, setAppState } = useAppContext();
  // const navigate = useNavigate();
  const { navigateTo } = useNavigation();

  const updateJobId = (jobId: number | null) => {
    setAppState({
      ...appState,
      jobId: jobId,
    });
  };

  const [chatRoomLoadingState, setChatRoomLoadingState] = useState(false);
  const [awardJobLoadingState, setAwardJobLoadingState] = useState(false);
  const [createJobError, setCreateJobError] = useState<string | null>(null);
  const [openAwardMenu, setOpenAwardMenu] = useState(false);

  const createJobMutation = useMutation(createJob, {
    onSuccess: (data) => {
      updateJobId(data.id);
    },
    onError: (error: any) => {
      setCreateJobError(error.message);
    },
  });

  const JobRoomMutation = useMutation(jobChatRoom, {
    onSuccess: (data: any) => {
      if (data?.error) {
        toast.error(data?.error?.message);
      } else {
        setChatRoomLoadingState(false);
        // navigate("/messageCenter", { state: { chatRoomId: data.chatRoomId } });
        navigateTo("/messageCenter", { chatRoomId: data.chatRoomId });
      }
    },
    onError: (error: { message: string }) => {
      setChatRoomLoadingState(false);
      toast.error(error.message);
    },
  });

  const jobAwardMutation = useMutation(jobAwardActionPost, {
    onSuccess: () => {
      setAwardJobLoadingState(false);
      setOpenAwardMenu(true);

      updateJobId(null);
    },
    onError: () => {
      setAwardJobLoadingState(false);
    },
  });

  const performAction = async (
    actionType: "chat" | "award",
    item: SearchResult
  ): Promise<void> => {
    const savedJobId = appState.jobId;
    if (actionType === "chat") {
      setChatRoomLoadingState(true);
      if (savedJobId) {
        JobRoomMutation.mutate({
          jobId: savedJobId,
          labourerId: item.userId,
        });
      } else {
        try {
          const createJobRequest = {
            location: item.location,
            latitude: item.lat,
            longitude: item.lng,
            skillIds: mapSkillIds(item.skills),
            labourerId: item.userId,
          };
          const job = await createJobMutation.mutateAsync(createJobRequest);
          const jobId = job.id as number;
          updateJobId(jobId);
          JobRoomMutation.mutate({
            jobId: jobId,
            labourerId: item.userId,
          });
        } catch (error: any) {
          setChatRoomLoadingState(false);
          setCreateJobError(error.message);
        }
      }
    } else if (actionType === "award") {
      setAwardJobLoadingState(true);
      if (savedJobId) {
        jobAwardMutation.mutate({
          jobId: savedJobId,
          labourerId: item.userId,
        });
      } else {
        try {
          const createJobRequest = {
            location: item.location,
            latitude: item.lat,
            longitude: item.lng,
            skillIds: mapSkillIds(item.skills),
            labourerId: item.userId,
          };
          const job = await createJobMutation.mutateAsync(createJobRequest);
          const jobId = job.id as number;
          updateJobId(jobId);
          jobAwardMutation.mutate({
            jobId: jobId,
            labourerId: item.userId,
          });
        } catch (error: any) {
          setAwardJobLoadingState(false);
          setCreateJobError(error.message);
        }
      }
    }
  };

  return {
    performAction,
    chatRoomLoadingState,
    awardJobLoadingState,
    createJobError,
    openAwardMenu,
    setOpenAwardMenu,
  };
};
