import React, { useEffect, useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import { Downarrow } from "../../assets/images/AllSvg";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  useLanguagesQuery,
  useUpdateProfileImageMutation,
} from "../../API/api";
import MyAutocomplete from "../../component/AutoCompleteSkills/AutoComplete";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { UploadProfilePic } from "../../component/Models/UploadProfilePic";
import { LabourerRegisterSchema } from "../../schema/labourerSignInSchema";
import {
  useSignUpLabourerMutation,
  useSignUpEmployerMutation,
} from "../services/Mutation";
import { dataURLtoBlob, getFileTypeFromUrl } from "../../utils/utils";
import { Skill, UserRole } from "../../API/types";
import LocationSelector from "../../pages/Location/LocationSelector";
import { useLocationContext } from "../Context/LocationContext";
import PlanDisplay from "./PlanDetails";
import BusinessDetailsModal from "./BusinessDetailsModal";
import {
  CalendarDaysIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";

interface LabourerRegisterFormState {
  register?: RegisterData;
  name?: string;
  referralCode?: string;
  labourer?: boolean;
}
interface RegisterData {
  phone: any;
  verificationId: string;
}
interface Language {
  id: number;
  name: string;
}

export enum ServiceProviderType {
  Individual = 1,
  Business = 2,
}

interface LabourRegisterFormProps {
  skillList: any;
  setSkillList: React.Dispatch<React.SetStateAction<any>>;
  handleSkillUpdate: (updatedSkills: any) => void;
  nextStep: () => void;
}
function LabourRegisterForm({
  skillList,
  setSkillList,
  handleSkillUpdate,
  nextStep,
}: LabourRegisterFormProps) {
  const location = useLocation();
  const {
    register: regData,
    name: regName,
    referralCode: regRefCode,
    labourer: regLabourer,
  } = location?.state as LabourerRegisterFormState;
  const [providerType, setProviderType] = useState<ServiceProviderType>(
    ServiceProviderType.Individual
  );
  const { state } = useLocationContext();
  const { selectedLocationData } = state;
  const employeraslabourer = regLabourer === false ? true : regLabourer;
  const { t } = useTranslation();
  const languagesQuery = useLanguagesQuery();
  const languagesData = languagesQuery.data || [];
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const [showUploadPicModal, setShowUploadPicModal] = useState<boolean>(false);
  const [imageErrorStatus, setImageErrorStatus] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [url, setUrl] = useState("");

  const {
    register: registerForm,
    handleSubmit: registerFormSubmission,
    formState: { errors: registerFormErrors },
    reset: registerFormReset,
    control: registerFormControl,
  } = useForm({
    resolver: yupResolver(LabourerRegisterSchema),
    defaultValues: {
      skills: [],
    },
    mode: "onBlur",
  });
  const handleSelectedLanguagesChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: { id: number; name: string }[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    const selectedLanguageIds = value.map((language) => language.id);
    setSelectedLanguages(selectedLanguageIds);
  };
  const handleFullNameChange =
    (onChange: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fullName = event.target.value;
      onChange(fullName);
      const nameParts = fullName.trim().split(/\s+/);
      setFirstName(nameParts[0] || "");
      setLastName(nameParts.slice(1).join(" ") || "");
    };

  // mutation
  const SignUpLabourerMutation = useSignUpLabourerMutation();
  const SignUpEmployerMutation = useSignUpEmployerMutation();
  const ImageMutation = useUpdateProfileImageMutation();

  const capture = async (imageSrc: string) => {
    if (imageSrc) {
      setUrl(imageSrc);
      setShowUploadPicModal(false);
    }
  };

  const handleRegister = async (formData: any) => {
    try {
      if (!selectedLocationData?.lat || !selectedLocationData?.lng) {
        toast.error("Please select a valid location.");
        return;
      }
      if (!url) {
        toast.error("Please upload your profile image.");
        return;
      }
      const { adharnumber, referralCode, ...formDataWithoutAadhaar } = formData;
      const updatedFormData = {
        ...formDataWithoutAadhaar,
        languagesKnown: selectedLanguages,
        userType: UserRole.LABOURER,
        lat: selectedLocationData.lat,
        lng: selectedLocationData.lng,
        location: selectedLocationData.city,
        referralCode: referralCode,
        skills: skillList?.skills,
      };
      const mutation = employeraslabourer
        ? SignUpEmployerMutation
        : SignUpLabourerMutation;
      try {
        if (!imageErrorStatus) {
          await mutation.mutateAsync(updatedFormData);
        }
        await doImageUpload();
      } catch (error) {
        console.error("Error occurred during mutation:", error);
        toast.error("An error occurred during registration. Please try again.");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error(
        "An error occurred during form submission. Please try again."
      );
    }
  };

  const doImageUpload = async () => {
    try {
      if (!url) {
        toast.error("No image URL provided.");
      }
      const blob = dataURLtoBlob(url);
      let fileType = getFileTypeFromUrl(url);
      const file = new File([blob], "image." + fileType.split("/")[1], {
        type: fileType,
        lastModified: Date.now(),
      });
      const imageResponse = await ImageMutation.mutateAsync(file);
      if (imageResponse) {
        // toast.success("Successfully registered as a labourer");
        // nextStep();
        setShowUploadPicModal(false);
        registerFormReset();
      }
    } catch (error) {
      console.error("Error occurred during image upload:", error);
      toast.error("Failed to upload profile image.");
      setImageErrorStatus(true);
    }
  };

  const handleProfilePicModalOpen = () => {
    setShowUploadPicModal((value) => !value);
  };

  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [showBusinessModal, setShowBusinessModal] = useState<boolean>(false);
  const [businessDetails, setBusinessDetails] = useState<any | null>(null);
  const [showBusinessDetails, setShowBusinessDetails] =
    useState<boolean>(false);

  const handleProviderTypeChange = (newType: ServiceProviderType) => {
    setProviderType(newType);
    setSelectedSkills([]);
    setShowBusinessDetails(false);
    setBusinessDetails(null);
  };

  const handleBusinessDetailsConfirm = (details: any) => {
    setBusinessDetails(details);
    setShowBusinessModal(false);
    if (selectedSkills.length === 0) {
      setShowBusinessDetails(false);
    } else {
      setShowBusinessDetails(true);
    }
  };

  const handleBusinessDetailsCancel = () => {
    setShowBusinessModal(false);
    setSelectedSkills([]);
  };
  useEffect(() => {
    if (selectedSkills.length === 0) {
      setShowBusinessDetails(false);
    }
  }, [selectedSkills]);

  return (
    <>
      <form onSubmit={registerFormSubmission(handleRegister)}>
        <div className="max-w-[857px] w-full mt-[45px] sm:px-5 px-3 mx-auto p-10   border border-gray-300 shadow-lg rounded ">
          <div className="flex gap-[39px] w-full md:flex-row flex-col">
            <div>
              <div className="bg-[#FBE9C7] min-w-[147px] h-[147px] w-max mx-auto flex justify-center items-center rounded-full">
                <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
                  <img
                    src={url ? url : require("../../assets/images/profile.png")}
                    alt=""
                    className="object-cover h-full"
                  />
                </div>
              </div>
              <button
                type="button"
                className="bg-[#FBE9C7]  py-1.5 px-10 mx-auto mt-3 flex justify-center items-center"
                onClick={handleProfilePicModalOpen}
              >
                <AiOutlineCamera className="text-[#F0A61F] text-[26px]" />
              </button>
              {showUploadPicModal && (
                <UploadProfilePic
                  open={showUploadPicModal}
                  handleClose={handleProfilePicModalOpen}
                  handleImageSave={capture}
                  isApiCalling={ImageMutation.isLoading}
                  isError={ImageMutation.isError}
                  error={
                    ImageMutation.error as {
                      statusCode: number;
                      name: string;
                      message: string;
                    }
                  }
                />
              )}
            </div>

            <div className="w-full flex flex-col gap-[23px]">
              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Name")}
                </label>
                <div className="w-full">
                  <Controller
                    name="fullName"
                    control={registerFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        onChange={handleFullNameChange(field.onChange)}
                        className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                        placeholder="Enter your full name"
                      />
                    )}
                  />{" "}
                  <p className="text-[16px] text-red-600">
                    {registerFormErrors.fullName?.message}
                  </p>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] sm:mt-3 font-medium min-w-[167px]">
                  {t("MobileNumber")}
                </label>
                <div className="w-full">
                  <input
                    type="number"
                    value={regData?.phone ? regData?.phone : regData}
                    className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                    placeholder="9021997888"
                    {...registerForm("phone")}
                  />
                  <p className="text-[16px] text-red-600">
                    {registerFormErrors.phone?.message}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                  <label className="text-[17px] font-medium min-w-[167px]">
                    {t("Languages Spoken")}
                  </label>

                  <div className="relative w-full ">
                    {languagesData && (
                      <Controller
                        name="selectedLanguages"
                        control={registerFormControl}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            id="checkboxes-tags-demo"
                            options={languagesData as readonly Language[]}
                            disableCloseOnSelect
                            getOptionLabel={(option: Language) => option.name}
                            onChange={(event, value, reason, details) => {
                              field.onChange(value);
                              handleSelectedLanguagesChange(
                                event,
                                value,
                                reason,
                                details
                              );
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  sx={{
                                    color: "#D4D4D4",
                                    "&.Mui-checked": {
                                      color: "#F0A61F",
                                    },
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                  size="small"
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search Languages"
                                error={!!registerFormErrors.selectedLanguages}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                    <div className="absolute top-[50%] translate-y-[-50%] right-[20px]">
                      <Downarrow />
                    </div>
                    <div className="text-[16px] text-red-600 mt-2">
                      {registerFormErrors.selectedLanguages &&
                        registerFormErrors.selectedLanguages.message}
                    </div>
                  </div>
                </div>
              </div>

              {/* type */}

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Services Provider Type")}
                </label>

                <div className="flex w-full">
                  <label className="inline-flex items-center mr-4">
                    <input
                      type="radio"
                      name="registrationType"
                      value={ServiceProviderType.Individual}
                      checked={providerType === ServiceProviderType.Individual}
                      onChange={() =>
                        handleProviderTypeChange(ServiceProviderType.Individual)
                      }
                      className="form-radio"
                    />
                    <span className="ml-2">Individual</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="registrationType"
                      value={ServiceProviderType.Business}
                      checked={providerType === ServiceProviderType.Business}
                      onChange={() =>
                        handleProviderTypeChange(ServiceProviderType.Business)
                      }
                      className="form-radio"
                    />
                    <span className="ml-2">Business</span>
                  </label>
                </div>
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Skills/Services")}
                </label>

                <div className="relative w-full">
                  <MyAutocomplete
                    control={registerFormControl}
                    handleSkillUpdate={handleSkillUpdate}
                    error={registerFormErrors.skills?.message}
                    providerType={providerType}
                    selectedSkills={selectedSkills}
                    setSelectedSkills={setSelectedSkills}
                    setShowBusinessModal={setShowBusinessModal}
                  />
                </div>
              </div>

              {showBusinessModal && (
                <BusinessDetailsModal
                  isOpen={showBusinessModal}
                  onConfirm={handleBusinessDetailsConfirm}
                  onCancel={handleBusinessDetailsCancel}
                  selectedSkills={selectedSkills}
                />
              )}

              {selectedSkills.length > 0 &&
                showBusinessDetails &&
                providerType === ServiceProviderType.Business && (
                  <div className="bg-white p-2  rounded-md  overflow-hidden">
                    <div className=" space-y-3 ">
                      <div className="flex items-center ">
                        <h2 className="text-xs font-bold text-gray-400">
                          {businessDetails.businessName}
                        </h2>
                      </div>
                      <div className="flex items-center space-x-3">
                        <MapPinIcon className="text-gray-400 h-3 w-4" />
                        <p className="text-gray-400 text-xs">
                          GSTIN: {businessDetails.gstin}
                        </p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <CalendarDaysIcon className="text-gray-400 h-3 w-4" />
                        <p className="text-gray-400 text-xs">
                          Working Days: {businessDetails.workingDays.join(", ")}
                        </p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <ClockIcon className="text-gray-400 h-3 w-4" />
                        <p className="text-gray-400 text-xs">
                          Opening Time: {businessDetails.openingTime} - Closing
                          Time: {businessDetails.closingTime}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Location")}
                </label>
                <LocationSelector />
              </div>
              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Referral Code")}
                </label>

                <div className="w-full">
                  <input
                    type="text"
                    value={regRefCode}
                    className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                    {...registerForm("referralCode")}
                  />
                </div>
              </div>

              <div>
                <h2 className="block mb-2 mt-2 text-lg font-bold text-gray-800">
                  For Billing
                </h2>{" "}
                <div className="addresDiv  grid grid-cols-6  md:gap-2 lg:gap-2  p-4 border border-gary-200  rounded-md ">
                  <div className="col-span-6 mb-4  md:col-span-6  xl:col-span-3 lg:col-span-6">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    />
                    <p className="text-[16px] text-red-600">
                      {/* {CheckoutFormErrors?.firstName?.message} */}
                    </p>
                  </div>

                  <div className="col-span-6 mb-4 md:col-span-6 lg:col-span-6 xl:col-span-3">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    />
                    <p className="text-[16px] text-red-600">
                      {/* {CheckoutFormErrors?.lastName?.message} */}
                    </p>
                  </div>
                  <div className="col-span-6 mb-4 md:col-span-6">
                    <input
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      // {...checkoutForm("Email")}
                      id="Email"
                      type="Email"
                      placeholder="Email"
                      name="Email Address"
                    />
                    <p className="text-[16px] text-red-600">
                      {/* {CheckoutFormErrors?.Email?.message} */}
                    </p>
                  </div>
                  <div className="col-span-6 mb-4 md:col-span-6 ">
                    <input
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="Phone"
                      type="number"
                      defaultValue={regData?.phone ? regData?.phone : regData}
                      name="Phone Number"
                      placeholder="Phone"
                    />
                    <p className="text-[16px] text-red-600">
                      {registerFormErrors.phone?.message}
                    </p>
                  </div>
                </div>
              </div>

              {/* end */}
              {/* subscriptionStart */}
              <>
                <div className=" ">
                  <h2 className="block mb-2 mt-2 text-lg font-bold text-gray-800">
                    Plan Details
                  </h2>
                  <PlanDisplay />
                </div>
              </>
              <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-4 space-y-4 sm:space-y-0 mt-11">
                {/* Subscribe Button */}
                <button
                  type="submit"
                  disabled={
                    SignUpLabourerMutation.isLoading ||
                    SignUpEmployerMutation.isLoading ||
                    ImageMutation.isLoading
                  }
                  className={`text-white md:w-[258px] md:h-[44px] w-[150px] h-[44px] rounded font-roboto font-medium text-lg leading-6 ${
                    SignUpLabourerMutation.isLoading ||
                    SignUpEmployerMutation.isLoading ||
                    ImageMutation.isLoading
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-700"
                  }`}
                >
                  {SignUpLabourerMutation.isLoading ||
                  SignUpEmployerMutation.isLoading ||
                  ImageMutation.isLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <span>{t("Subscribe")}</span>
                  )}
                </button>

                {/* Skip Button */}
                <button
                  // onClick={handleSkip}
                  className="text-gray-600 hover:text-gray-900 font-semibold text-lg leading-[24px] focus:outline-none"
                >
                  {t("Skip now")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default LabourRegisterForm;
