import { StarIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { DeleteIcon, EditIcon, Review1 } from "../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { useUserMeQuery } from "../API/api";
import ReviewMade from "../component/rates&Reviews/ReviewMade";
import ReviewObtained from "../component/rates&Reviews/ReviewObtained";

interface tabData {
  name: string;
  current: boolean;
}

const tabs: tabData[] = [
  { name: "Rate & Reviews Obtained", current: true },
  { name: "Rate & Reviews Made", current: false },
];

const RatesReview = ({ serviceData }: { serviceData: any }) => {
  const { t } = useTranslation();

  const { data: userData } = useUserMeQuery();
  const [activeTab, setActiveTab] = useState(
    userData && userData?.user && userData?.user?.roles?.[0]
  );

  const handleTabClick = (role: any) => {
    setActiveTab(role);
  };
  useEffect(() => {
    if (userData?.user?.roles?.[0]) {
      setActiveTab(userData.user.roles[0]);
    }
  }, [userData]);

  return (
    <div>
      <div className="text-2xl text-[#272D29] font-semibold my-8">
        {t("Rate & Reviews")}
      </div>
      <div className="">
        <nav className="flex flex-wrap md:space-x-8" aria-label="Tabs">
          {/* {tabs.map((tab) => (
            <a
              key={tab.name}
              className={classNames(
                active.name === tab.name
                  ? "border-[#F0A61F] text-black"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-[22px] py-4 pb-[11px] font-medium text-[19px] md:mx-0 mx-auto cursor-pointer"
              )}
              onClick={() => setActive({ name: tab.name, status: true })}
            >
              {t(tab.name)}
            </a>
          ))} */}
          {userData?.user?.roles?.map((role: any) => (
            <a
              key={role}
              className={`text-black hover:text-gray-700 whitespace-nowrap border-b-2 px-10 text-[19px] font-medium cursor-pointer ${
                activeTab === role ? "border-b-2 border-[#F0A61F]" : ""
              }`}
              onClick={() => handleTabClick(role)}
            >
              {role === "labourer" && <span>{t("Reviews Obtained")}</span>}
              {role === "employer" && <span>{t("Reviews Made")}</span>}
            </a>
          ))}
        </nav>
      </div>
      {activeTab === "labourer" && <ReviewObtained serviceData={serviceData} />}
      {activeTab === "employer" && <ReviewMade />}
    </div>
  );
};

export default RatesReview;
