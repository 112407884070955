import React, { useRef, useState } from "react";
import {
  Language,
  Map,
  Mobile,
  Skill,
  Feedback,
  Wallet,
} from "../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { useUserMeQuery, useUpdateProfileImageMutation } from "../API/api";
import { AiFillCamera } from "react-icons/ai";
import Webcam from "react-webcam";
import { UploadProfilePic } from "../component/Models/UploadProfilePic";
import { FaUserEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TagIcon } from "@heroicons/react/20/solid";
import { useCompleteImageUrl } from "../utils/GeneralUtils";
const videoConstraints = {
  width: 720,
  height: 360,
  facingMode: "user",
};
const Profile = () => {
  const [showWebcam, setShowWebcam] = useState(false);
  const [showUploadPicModal, setShowUploadPicModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userData, refetch } = useUserMeQuery();
  const profileUrl = userData?.user?.profileUrl;
  const webcamRef = useRef<Webcam>(null);
  const [url, setUrl] = useState("");
  const mutation = useUpdateProfileImageMutation();
  //console.log(">>mutation", mutation);
  const capture = async (imageSrc: string) => {
    if (imageSrc) {
      const blob = dataURLtoBlob(imageSrc);
      setUrl(imageSrc);
      let fileType = "image/jpeg";
      if (imageSrc.includes("data:image/png")) {
        fileType = "image/png";
      } else if (imageSrc.includes("data:image/gif")) {
        fileType = "image/gif";
      }

      const file = new File([blob], "image." + fileType.split("/")[1], {
        type: fileType,
        lastModified: Date.now(),
      });

      const response = await mutation.mutateAsync(file);
      if (response) {
        refetch();
        setShowUploadPicModal(false);
      }
    }
  };

  const dataURLtoBlob = (dataURL: any) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleProfilePicModalOpen = () => {
    setShowUploadPicModal((value) => !value);
  };
  const languagesdisplay = userData?.labourerDetails?.languagesKnown?.map(
    (item: any) => item?.name
  );

  const getUserRole = (roles: any) => {
    if (!roles || roles.length === 0) {
      return "No Role";
    }
    if (roles.includes("employer") && roles.includes("labourer")) {
      return "Employer, Labourer (Hybrid)";
    }

    if (roles.includes("employer")) {
      return "Employer";
    }

    if (roles.includes("labourer")) {
      return "Labourer";
    }

    return roles.join(", ");
  };

  const isHybridOrLabourer =
    userData?.user?.roles.includes("labourer") ||
    (userData?.user?.roles.includes("employer") &&
      userData?.user?.roles.includes("labourer"));
  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();
  return (
    <>
      <div>
        <h1 className="text-[24px] text-[#222] font-semibold my-[33px]">
          {t("Profile")}
        </h1>
        <div className="flex items-center gap-6 border-b border-[#F0A61F] pb-[24px] flex-wrap md:justify-start justify-center">
          <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
            {showWebcam ? (
              <>
                <Webcam
                  audio={false}
                  width={150}
                  height={150}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  className="object-fill h-full"
                />
              </>
            ) : userData?.user?.profileUrl !== null ? (
              <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
                <img
                  src={
                    profileUrl
                      ? getCompleteImageUrl(profileUrl)
                      : "../assets/images/profile.png"
                  }
                  alt=""
                  className="object-cover h-full"
                />
              </div>
            ) : (
              <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
                <img
                  src={url ? url : require("../assets/images/profile.png")}
                  alt=""
                  className="object-cover h-full"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[19px] md:items-start items-center">
            <h3 className="text-[22px] text-[#272D29] font-semibold">
              {userData?.user?.name}
            </h3>
            <h5 className="text-base font-roboto text-[#beb6a0]">
              {/* {userData?.user?.roles} */}
              {getUserRole(userData?.user?.roles)}
            </h5>
            <button
              className="flex items-center w-[67px] h-[24px] bg-[#F0A61F] rounded justify-center gap-1"
              onClick={handleProfilePicModalOpen}
            >
              <FaUserEdit /> {t("Edit")}
            </button>
            <div className="flex items-center gap-10">
              <p className="font-roboto text-lg font-normal text-[#272D29]">
                {t("Notifications")}
              </p>
              <label className="relative inline-flex items-center mr-5 cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="w-[28px] h-[14px] bg-gray-200 rounded-full peer-checked:after:translate-x-[160%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[9px] after:w-[9px] after:transition-all peer-checked:bg-[#00C54F]"></div>
              </label>
            </div>
          </div>
        </div>
        <div className="py-[25px] border-b  border-[#D4D4D4]">
          <h3 className="text-[#272D29] text-[22px] font-medium mb-4">
            {t("Account Information")}
          </h3>
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-start">
              <div className="flex items-center sm:w-64 mb-2 sm:mb-0">
                <div className="w-10 flex-shrink-0">
                  <Mobile className="w-6 h-6" />
                </div>
                <h2 className="text-lg sm:text-xl font-normal">
                  {t("MobileNumber")}
                </h2>
              </div>
              <div className="text-[#F0A61F] text-lg sm:text-xl pl-10 sm:pl-0">
                {userData?.user?.phoneNumber}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-start">
              <div className="flex items-center sm:w-64 mb-2 sm:mb-0">
                <div className="w-10 flex-shrink-0">
                  <Map className="w-6 h-6" />
                </div>
                <h2 className="text-lg sm:text-xl font-normal">
                  {t("Location")}
                </h2>
              </div>
              <div className="text-[#F0A61F] text-lg sm:text-xl pl-10 sm:pl-0">
                {userData?.labourerDetails?.location
                  ? userData?.labourerDetails?.location
                  : t("Location not provided")}
              </div>
            </div>
          </div>
        </div>

        <div className="py-[25px] border-b border-[#D4D4D4]">
          <h3 className="text-[#272D29] text-[22px] font-medium mb-6">
            {t("General Information")}
          </h3>

          <div className="space-y-6">
            {/* App Language */}
            <div className="flex flex-col sm:flex-row sm:items-start">
              <div className="flex items-center sm:w-64 mb-2 sm:mb-0">
                <div className="w-10 flex-shrink-0">
                  <Language className="w-6 h-6" />
                </div>
                <h2 className="text-[20px] font-normal">{t("App Language")}</h2>
              </div>
              <div className="text-[#F0A61F] text-[20px] pl-10 sm:pl-0">
                {languagesdisplay?.join(", ")}
              </div>
            </div>
            {isHybridOrLabourer && (
              <>
                {/* Skills */}
                <div className="flex flex-col sm:flex-row sm:items-start">
                  <div className="flex items-center sm:w-64 mb-2 sm:mb-0">
                    <div className="w-10 flex-shrink-0">
                      <Skill className="w-6 h-6" />
                    </div>
                    <h2 className="text-[20px] font-normal">{t("Skills")}</h2>
                  </div>
                  <div className="flex flex-wrap gap-2 pl-10 sm:pl-0">
                    {userData?.labourerDetails?.skills?.map((skill, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-white px-3 py-1 rounded-full  hover:shadow-sm transition-all shadow-sm hover:bg-blue-50"
                      >
                        <TagIcon className="w-4 h-4 mr-1 text-[#F0A61F]" />
                        <span className="text-sm font-medium text-gray-700 ">
                          {t(
                            `${skill?.name} (${skill?.experience} ${t(
                              "years"
                            )})`
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row sm:items-start">
                  <div className="flex items-center sm:w-64 mb-2 sm:mb-0">
                    <div className="w-10 flex-shrink-0">
                      <Language className="w-6 h-6" />
                    </div>
                    <h2 className="text-[20px] font-normal">
                      {t("Languages Spoken")}
                    </h2>
                  </div>
                  <div className="text-[#F0A61F] text-[20px] pl-10 sm:pl-0">
                    {languagesdisplay?.join(", ")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="py-[25px] border-b border-[#D4D4D4] mb-[71px]">
          <h3 className="text-[#272D29] text-[22px] font-medium">
            {t("Options")}
          </h3>
          <div
            className="max-w-[511px] flex justify-between items-center"
            onClick={() => {
              navigate("/rates&review");
            }}
          >
            <div className="flex items-center mt-6 gap-7 cursor-pointer">
              <Feedback />
              <h2 className="text-[20px] font-normal">{t("Feedback")}</h2>
            </div>
          </div>
          <>
            {" "}
            {isHybridOrLabourer && (
              <div
                className="max-w-[511px] flex justify-between items-center "
                onClick={() => {
                  navigate("/paymenthistory");
                }}
              >
                <div className="flex items-center mt-6 gap-7">
                  <Wallet />
                  <h2 className="text-[20px] font-normal cursor-pointer">
                    {t("Payment History")}
                  </h2>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      {showUploadPicModal && (
        <UploadProfilePic
          open={showUploadPicModal}
          handleClose={handleProfilePicModalOpen}
          handleImageSave={capture}
          isApiCalling={mutation.isLoading}
          isError={mutation.isError}
          error={
            mutation.error as {
              statusCode: number;
              name: string;
              message: string;
            }
          }
        />
      )}
    </>
  );
};

export default Profile;
