import React, { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useUserMeQuery, useUpdateProfileImageMutation } from "../API/api";
import { AiFillCamera } from "react-icons/ai";
import Webcam from "react-webcam";
import { UploadProfilePic } from "../component/Models/UploadProfilePic";
import { FaUserEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import SubscriptionPlanList from "../component/SubscriptionPlanList/SubscriptionPlanList";

const Subscribe = () => {
  const { t } = useTranslation();

  
  return (
    <>
      <div>
        <h1 className="text-[24px] text-[#222] font-semibold my-[33px]">
          {t("Subscribe")}
        </h1>
        <SubscriptionPlanList />

      </div>
    </>
  );
};

export default Subscribe;
