import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import Navbar from "../component/Navbar";
import Landing from "../pages/Landing";
import Footer from "../component/Footer";
import Home from "../pages/Home";
import Result from "../pages/Result";
import Details from "../pages/Details";
import Feedback from "../pages/Feedback";
import Faqs from "../pages/Faqs";
import Register from "../pages/Register";
import Dashboard from "../pages/Dashboard";
import Layout from "../component/Layout";
import JobStatistics from "../pages/JobStatistics";
import Favourites from "../pages/Favourites";
import Settings from "../pages/Settings";
import MessageCenter from "../pages/MessageCenter";
import Payment from "../pages/PaymentHistory";
import RatesReview from "../pages/Rates&Review";
import Profile from "../pages/Profile";
import { useEffect, useRef, useState } from "react";
import { useServiceCategoryQuery, useUserMeQuery } from "../API/api";
import Support from "../pages/Support";
import Solutions from "../pages/Solutions";
import TermsConditions from "../pages/TermsConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RefundCancellation from "../pages/RefundCancellation";
import CopyrightPolicy from "../pages/CopyrightPolicy";
import AdsCommercial from "../pages/AdsCommercial";
import AdvertiseOnEaidex from "../pages/AdvertiseOnEaidex";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Careers from "../pages/Careers";
import Blogs from "../pages/Blogs";
import Help from "../pages/Help";
import ReportAbuse from "../pages/ReportAbuse";
import DisclaimerPolicy from "../pages/DisclaimerPolicy";
import AcceptablePolicy from "../pages/AcceptablePolicy";
import { _token, isAuthenticated, isPhoneVerified } from "../utils/jwtUtils";
import CheckoutPage from "../pages/CheckoutPage";
import { UserDto, UserRole, UserType } from "../API/types";
import { DisplayMode, SearchQuery } from "../pages/ServiceProviderResult";
import ServiceProviderResult from "../pages/ServiceProviderResult";
import { hasRole } from "../utils/utils";
import MyReferrals from "../pages/MyReferrals";
import { SendToPaymentGateway } from "../pages/SendToPaymentGateway";
import { PaymentStatus } from "../pages/PaymentStatus";
import Subscribe from "../pages/Subscribe";
const Routers = () => {
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [method, setMethod] = useState("");
  const [preSelectedUserType, setPreSelectedUserType] = useState<
    UserType | undefined
  >(undefined);

  const [searchQuery, setSearchQuery] = useState<SearchQuery | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<string>("");

  const scrollToDiv = (ref: any) => {
    const element = ref.current;
    const rect = element?.getBoundingClientRect();
    const scrollTop =
      window.pageYOffset || document?.documentElement?.scrollTop;
    window.scrollTo({
      top: rect?.top + scrollTop,
      behavior: "smooth",
    });
  };

  // Search query saving temporary
  const handleSearchQuerySave = (searchQuery: SearchQuery) => {
    setSearchQuery(searchQuery);
  };

  const compRef = useRef(null);
  const solutionRef = useRef(null);
  const benefitsRef = useRef(null);
  const servicesRef = useRef(null);

  const isScroll = () => {
    scrollToDiv(compRef);
  };
  const isScrollSolution = () => {
    scrollToDiv(solutionRef);
  };
  const isScrollBenefits = () => {
    scrollToDiv(benefitsRef);
  };
  const isScrollServices = () => {
    scrollToDiv(servicesRef);
  };

  function PrivateRoute() {
    return isAuthenticated() ? (
      <Layout>
        <div className="overflow-auto sm:px-[24px] px-3 custom-scroll w-full">
          <Outlet />
        </div>
      </Layout>
    ) : (
      <Navigate to="/" replace />
    );
  }

  const [user, setUser] = useState<UserDto>();

  const { refetch: refetchUser } = useUserMeQuery(false);

  useEffect(() => {
    // This approach is used because of a navigation issue in the 'become a laboure' flow.
    refetchUser()
      .then((response) => {
        setUser(response?.data?.user);
      })
      .catch((e) => {
        setUser(undefined);
      });
  }, [isAuthenticated()]);

  const RegisterRoute = () => {
    if (isAuthenticated()) {
      if (hasRole(user, UserRole.LABOURER)) {
        return <Navigate to="/dashboard" />;
      } else if (hasRole(user, UserRole.EMPLOYER)) {
        return <Register />;
      } else {
        return <Navigate to="/" replace />;
      }
    } else if (isPhoneVerified()) {
      return <Register />;
    } else {
      onRegisterClicked(UserType.LABOURER);
      return <Navigate to="/" replace />;
    }
  };

  const { data: serviceData } = useServiceCategoryQuery();

  const _resetPreselectedUserType = () => {
    setPreSelectedUserType(undefined);
  };

  const onRegisterClicked = (UserType: UserType | undefined) => {
    setPreSelectedUserType(UserType);
    setOpen(true);
  };

  return (
    <div>
      <Navbar
        preSelectedUserType={preSelectedUserType}
        resetPreselectedUserType={_resetPreselectedUserType}
        open={open}
        setOpen={setOpen}
        isScroll={isScroll}
        isScrollSolution={isScrollSolution}
        isScrollBenefits={isScrollBenefits}
        isScrollServices={isScrollServices}
      />

      <Routes>
        {}
        <Route
          path="/"
          element={
            <Landing
              compRef={compRef}
              solutionRef={solutionRef}
              benefitsRef={benefitsRef}
              servicesRef={servicesRef}
              serviceData={serviceData}
              onRegisterClicked={onRegisterClicked}
              preSelectedUserType={preSelectedUserType}
              resetPreselectedUserType={_resetPreselectedUserType}
              open={open}
              setOpen={setOpen}
            />
          }
        />
        <Route
          path="/home"
          element={<Home serviceData={serviceData ?? []} />}
        />
        <Route
          path="/result"
          element={
            <Result
              setOpen={setOpen}
              setLogin={setLogin}
              setMethod={setMethod}
              _serviceData={serviceData}
              selectedStatus={selectedStatus}
            />
          }
        />
        <Route path="/details" element={<Details setOpen={setOpen} />} />
        <Route path="/send-to-paygate" element={<SendToPaymentGateway />} />
        <Route path="/user/:userId" element={<Details setOpen={setOpen} />} />
        <Route path="/disclaimer-policy" element={<DisclaimerPolicy />} />
        <Route path="/acceptable-policy" element={<AcceptablePolicy />} />
        <Route
          path="/feedback"
          element={<Feedback onRegisterClicked={onRegisterClicked} />}
        />
        <Route
          path="/refund-and-cancellation"
          element={<RefundCancellation />}
        />
        <Route path="/help" element={<Help />} />
        <Route path="/report-abuse" element={<ReportAbuse />} />
        <Route path="/copyrightpolicy" element={<CopyrightPolicy />} />
        <Route path="/adscommercial" element={<AdsCommercial />} />
        <Route path="/advertiseoneaidex" element={<AdvertiseOnEaidex />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route
          path="/contact-us"
          element={<ContactUs onRegisterClicked={onRegisterClicked} />}
        />
        <Route path="/careers" element={<Careers />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route
          path="/faqs"
          element={<Faqs onRegisterClicked={onRegisterClicked} />}
        />
        <Route path="/register" element={<RegisterRoute />} />
        <Route path="/chat" element={<MessageCenter />} />
        <Route path="/support" element={<Support />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/favourites"
            element={<Favourites serviceData={serviceData} />}
          />
          <Route path="/paymenthistory" element={<Payment />} />
          <Route path="/jobstatistics" element={<JobStatistics />} />
          <Route
            path="/rates&review"
            element={<RatesReview serviceData={serviceData} />}
          />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/payment/confirmation" element={<PaymentStatus />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/referrals" element={<MyReferrals />} />
          <Route path="/messageCenter" element={<MessageCenter />} />
          <Route
            path="/searchservices"
            element={
              <ServiceProviderResult
                _displayMode={DisplayMode.Default}
                serviceData={serviceData}
                setOpen={setOpen}
                setLogin={setLogin}
                setMethod={setMethod}
                selectedStatus={selectedStatus}
                handleSearchQuerySave={handleSearchQuerySave}
                savedSearchQuery={searchQuery}
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default Routers;
