import { StarOutline } from "@mui/icons-material";
import { RemoveIcon, EditDataIcon } from "../../assets/images/AllSvg";

const EmployerReviewsComponent = ({ reviewsData, userData, t }: any) => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl ">
      <div className="bg-white shadow- rounded-lg ">
        <div className="bg-orange-100 border-b border-orange-200 px-6 py-4">
          <h2 className="text-2xl font-semibold text-orange-600">
            {t("Reviews")}
          </h2>
        </div>
        <div className="overflow-y-auto  max-h-[700px] no-scrollbar">
          {reviewsData?.employerReviews?.map((item: any, index: any) => {
            const isAuthorizedUser = item?.job?.userId === userData?.user?.id;
            const matchedLabourer = reviewsData?.users.find(
              (user: any) => user?.id === item?.job.userId
            );
            return (
              <div
                key={item.id}
                className={`p-6 ${
                  index !== reviewsData.employerReviews.length - 1
                    ? "border-b border-gray-200"
                    : ""
                }`}
              >
                <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
                  <div className="flex flex-col items-center">
                    <img
                      className="h-12 w-12 sm:h-16 sm:w-16 rounded-full object-cover border-2 border-indigo-500"
                      src={
                        matchedLabourer?.profileUrl ||
                        require("../../assets/images/user_default.png")
                      }
                      alt=""
                    />
                    <h3 className="mt-2 text-lg font-semibold text-gray-800">
                      {t(matchedLabourer?.name)}
                    </h3>
                  </div>
                  <div className="flex-1 space-y-4">
                    <p className="text-gray-600 text-center md:text-left">
                      {item?.review}
                    </p>
                    <div className="flex justify-center md:justify-start">
                      {[0, 1, 2, 3, 4].map((rating) =>
                        item?.employerRatings[0]?.rating > rating ? (
                          <StarOutline
                            key={rating}
                            className="text-yellow-400"
                          />
                        ) : (
                          <StarOutline key={rating} className="text-gray-300" />
                        )
                      )}
                    </div>
                  </div>
                  {isAuthorizedUser && (
                    <div className="flex gap-4 mt-4 md:mt-0">
                      <button className="text-blue-600 hover:text-blue-800">
                        {/* <EditDataIcon /> */}
                      </button>
                      <button className="text-red-600 hover:text-red-800">
                        {/* <RemoveIcon /> */}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EmployerReviewsComponent;
