export const PHONE_REQUIRED = "Enter the proper phone number";
export const NAME_REQUIRED = "Enter the proper name";
export const AADHAR_NUMBER_REQUIRED = "Enter the proper aadhar number";
export const EMAIL_REQUIRED = "Enter the email id";
export const MESSAGE_REQUIRED = "Enter the message";
export const FIRST_NAME_REQUIRED = "Enter the first name";
export const LAST_NAME_REQUIRED = "Enter the last name";
export const ADDRESS_REQUIRED = "Enter the address";
export const PINCODE_REQUIRED = "Enter the pincode";
export const CITY_REQUIRED = "Enter the city name";
export const STATE_REQUIRED = "State is required";
export const COUNTRY_REQUIRED = "Country/Region is required";
export const BANK_NAME_REQUIRED = "Bank name is required";
export const IFSC_REQUIRED = "IFSC code is required";
export const ACCOUNT_NUMBER_REQUIRED = "Account number is required";
export const ACCOUNT_NAME_REQUIRED = "Account name is required";
export const ACTIVE_REQUIRED = "Active account status is required";
export const BANK_NAME_INVALID = "Bank name should contain alphabets and not be only spaces";
export const IFSC_INVALID = "IFSC code should be in the format: 4 letters followed by 7 numbers";
export const ACCOUNT_NUMBER_INVALID = "Account number should only contain numbers";
export const ACCOUNT_NAME_INVALID = "Account name should contain alphabets and not be only spaces";
export const BANK_NAME_MAX_LENGTH = "Bank name should not exceed 30 characters";
export const IFSC_LENGTH = "IFSC code must be exactly 11 characters";
export const ACCOUNT_NUMBER_MAX_LENGTH = "Account number should not exceed 20 digits";
export const ACCOUNT_NAME_MAX_LENGTH = "Account name should not exceed 30 characters";
export const MOBILE_INVALID = "Mobile number must be exactly 10 digits";
export const EMAIL_INVALID = "Invalid email address";
export const NAME_MAX_LENGTH = "Name should not exceed 30 characters ";
export const MESSAGE_MAX_LENGTH  = "Message should not exceed 150 characters ";
export const NAME_INVALID = "Name should only contain alphabets";
export const MESSAGE_INVALID = "Message should only contain alphabets";
export const INVALID_AADHAR_FORMAT = "Aadhar number must be in the number format '7892 9898 8989'";
export const INVALID_PHONE_FORMAT = "Mobile number must be in the number format '9898898988'";
export const INVALID_NAME_FORMAT = "Name must only contain alphabetic characters and spaces ";
export const LANGUAGE_REQUIRED = "Please select at least one language";
export const LANGUAGE_NAME_REQUIRED = "Language name is required";
export const LANGUAGE_ID_REQUIRED = "Language id is required";
export const SKILL_ID_REQUIRED = "Skill id is required";
export const SKILL_NAME_REQUIRED = "Skill name is required";
export const SKILL_REQUIRED = "Please select at least one skil";
export const BUSINESS_NAME_REQUIRED = "Business name is required";
export const GSTIN_REQUIRED = "GSTIN is required";
export const GSTIN_LENGTH_REQUIRED = "GSTIN must be exactly 15 characters";

export const WORKINGDAY_REQUIRED = "At least one working day must be selected";
export const WORKING_DAY_REQUIRED = "Working days are required";
export const OPENING_TIME_REQUIRED = "Opening Time is required";
export const CLOSING_TIME_REQUIRED = "Closing Time is required";

