import { useState, useEffect } from "react";

export interface LocationData {
  lat: number;
  lng: number;
}

export interface AddressDetails {
  city: string;
  state: string;
  country: string;
}

interface GeolocationHook {
  location: LocationData | null;
  selectedName: AddressDetails | null;
  error: string | null;
  detectLocation: () => void;
}

const useGeolocation = (): GeolocationHook => {
  const [location, setLocation] = useState<LocationData | null>(null);
  const [selectedName, setSelectedName] = useState<AddressDetails | null>(null);
  const [error, setError] = useState<string | null>(null);

  const detectLocation = (): void => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by this browser.");
      return;
    }
    // console.log('navigator.geolocation', navigator.geolocation)
    navigator.geolocation.getCurrentPosition(
      onLocationSuccess,
      onLocationError
    );
  };

  const onLocationSuccess = (position: GeolocationPosition): void => {
    const { latitude, longitude } = position.coords;
    setLocation({ lat: latitude, lng: longitude });

    let _window = window as any;

    if (!_window.google || !_window.google.maps) {
      setError("Google Maps API not loaded");
      return;
    }
    // console.log('_window.google', _window.google)
    const geocoder = new _window.google.maps.Geocoder();
    const latLng = new _window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, onGeocodeSuccess);
  };

  const onLocationError = (error: GeolocationPositionError): void => {
    setError("Error detecting location: " + error.message);
  };

  const onGeocodeSuccess = (results: any[], status: any): void => {
    let _window = window as any;
    if (status !== _window.google.maps.GeocoderStatus.OK) {
      setError("Geocoder failed due to: " + status);
      return;
    }

    if (!results[0]) {
      setError("No results found");
      return;
    }

    const locationName = results[0].formatted_address;
    const addressComponents = results[0].address_components;
    const { city, state, country } = getAddressDetails(addressComponents);

    setSelectedName({ city, state, country });
  };

  const getAddressDetails = (addressComponents: any[]): AddressDetails => {
    let city = "";
    let state = "";
    let country = "";

    for (let i = 0; i < addressComponents.length; i++) {
      const types = addressComponents[i].types;
      if (types.includes("locality")) {
        city = addressComponents[i].long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        state = addressComponents[i].long_name;
      }
      if (types.includes("country")) {
        country = addressComponents[i].long_name;
      }
    }

    return { city, state, country };
  };

  return { location, selectedName, error, detectLocation };
};

export default useGeolocation;
