import React, { Fragment, useState } from "react";
import { Transition, Listbox } from "@headlessui/react";
import {
  UnreadNotificationDto,
  useNotificationCounts,
  useUnreadNotifications,
} from "../../API/api";
import { useMarkAllAsRead } from "../services/Mutation";
import moment from "moment";
import CircularProgressBar from "../CircularProgressBar";
import { NotificationIcon } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";

interface TabsProps {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  counts: { [key: string]: number };
}
interface UnreadNotificationPayloadDto {
  roomId: string;
  senderName: string;
  senderId: string;
  type: string;
}
const NotificationBox = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>("Chat");
  const [pageNum, setPageNum] = useState<number>(1);
  const pageSize = 10;
  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
  const { data: notificationData } = useNotificationCounts();
  const {
    data: unreadNotificationData,
    isLoading: notificationsIsLoading,
    isError: notificationsIsError,
  } = useUnreadNotifications(pageNum, pageSize);

  const { mutate, isLoading } = useMarkAllAsRead();
  const handleUpdate = () => {
    const ids = unreadNotificationData?.map((data) => data?.id) ?? [];
    mutate(ids);
  };
  const loadPreviousNotifications = () => {
    if (pageNum > 1) {
      setPageNum((prevPage) => prevPage - 1);
    }
  };
  const loadMoreNotifications = () => {
    setPageNum((prevPage) => prevPage + 1);
  };

  const toggleNotificationBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  const filteredNotifications = unreadNotificationData ?? [];
  const totalNotificationCount = notificationData?.[0]?.total ?? 0;
  const chatCount = notificationData?.[0]?.chatNotifCount ?? 0;

  const generalCount =
    (notificationData?.[0]?.expiryNotifCount ?? 0) +
    (notificationData?.[0]?.paymentNotifCount ?? 0);

  const counts = {
    Chat: chatCount,
    General: generalCount,
  };

  // SET ACTIVE TAB FUNCTION PART

  const Tabs = ({ tabs, activeTab, setActiveTab, counts }: TabsProps) => (
    <div className="flex gap-7 border-b border-gray-200 mt-2">
      {tabs?.map((tab) => (
        <button
          key={tab}
          className={`flex gap-2 py-2 text-start ${
            activeTab === tab
              ? "border-b-2 border-blue-600 font-semibold text-sm  text-blue-600 "
              : "text-black font-medium"
          }`}
          onClick={() => setActiveTab(tab)}
        >
          {t(tab)}
          <span className="text-sm bg-[#e5eeff] text-gray-800 font-medium rounded-full px-2 py-1 flex items-center justify-center w-5 h-5">
            {counts[tab]}
          </span>
        </button>
      ))}
    </div>
  );

  //DP COLOUR
  const getColorByIndex = (index: number) => {
    const colors = ["#DEF1FF", "#FFE7DB", "#F0DCFF", "#E9FFF8", "#FEF7DA"];
    return colors[index % colors.length];
  };

  //RENDER NOTIFICATION FUNCTION PART
  const renderNotification = (
    notification: UnreadNotificationDto,
    index: number
  ) => {
    const { senderName } = JSON.parse(notification?.payload);

    const formattedDate = moment(notification?.createdAt).format("DD/MM/YYYY");
    const formattedTime = moment(notification?.createdAt).format("h:mm A");
    const backgroundColor = getColorByIndex(index);

    return (
      <Listbox.Option
        key={notification?.id}
        className={({ active }) =>
          `relative cursor-default select-none py-2 px-1 ${
            active ? "bg-gray-100" : "text-gray-900"
          }`
        }
        value={notification}
      >
        <div key={notification?.id} className=" border-b border-gray-200 ">
          <div className="flex flex-col">
            <div className="flex items-center space-x-4 justify-center p-2">
              <div
                className="md:w-14 md:h-10  w-14 h-8  flex items-center justify-center rounded-full "
                style={{ backgroundColor }}
              >
                <span className="text-sm font-serif font-semibold text-gray-800">
                  {senderName?.slice(0, 2).toUpperCase()}
                </span>
              </div>
              <div className="flex flex-wrap space-x-1 text-[12px] md:text-sm text-start">
                <p> {t("You have an unread message from")}</p>{" "}
                <p className="font-semibold">{senderName}</p> <p>{t("on")}</p>
                <p>{`${formattedDate}`}</p>
              </div>
            </div>
            <div className="md:text-sm text-[13px] text-gray-400">
              {" "}
              {`${formattedTime}`}
            </div>
          </div>
        </div>
      </Listbox.Option>
    );
  };

  return (
    <>
      <div className="relative flex items-center justify-center md:gap-4 sm:gap-0">
        <Listbox as="div" className="relative" value={null}>
          {({ open }) => (
            <>
              <Listbox.Button
                className="relative w-full cursor-pointer flex items-center"
                onClick={toggleNotificationBox}
              >
                <NotificationIcon />
                <div
                  className={`absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white rounded-full ${
                    totalNotificationCount > 0 ? "bg-red-500" : "bg-transparent"
                  }`}
                >
                  {totalNotificationCount > 0 && totalNotificationCount}
                </div>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute max-h-[476px] right-0 mt-2 w-72 sm:w-96 py-1 overflow-auto text-base bg-white rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm translate-x-1/2 sm:right-1/2 lg:translate-x-0 lg:right-0">
                  <div className="p-4">
                    <div className="flex justify-between items-center">
                      <h2 className="text-lg font-semibold sm:text-sm">
                        {t("Notifications")}
                      </h2>

                      {filteredNotifications?.length > 0 && (
                        <button
                          className="text-sm font-semibold text-blue-600"
                          onClick={() => handleUpdate()}
                          disabled={isLoading}
                        >
                          {isLoading ? t("Marking...") : t("Mark all as read")}
                        </button>
                      )}
                    </div>
                    <Tabs
                      tabs={["Chat", "General"]}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      counts={counts}
                    />
                  </div>
                  <div className=" text-center">
                    {pageNum > 1 && (
                      <button
                        className="text-sm text-blue-500 hover:underline mr-4"
                        onClick={loadPreviousNotifications}
                      >
                        {notificationsIsLoading
                          ? ("")
                          : t("Previous notification")}
                      </button>
                    )}
                  </div>

                  {notificationsIsLoading ? (
                    <div className="flex items-center justify-center py-4">
                      <CircularProgressBar />
                    </div>
                  ) : notificationsIsError ? (
                    <div className="flex items-center justify-center h-full md:pb-20 pb-10">
                      <div className="text-center text-[#F0A61F] text-lg font-semibold">
                        {t("Error loading data")}
                      </div>
                    </div>
                  ) : filteredNotifications?.length === 0 ? (
                    <div className="flex items-center justify-center h-full md:pb-20 pb-10">
                      <div className="text-center text-[#F0A61F] text-lg font-semibold">
                        {t("No notifications")}
                      </div>
                    </div>
                  ) : (
                    filteredNotifications?.map(renderNotification)
                  )}
                  <div className="px-4 py-2 text-center">
                    <button
                      className="text-sm text-blue-500 hover:underline"
                      onClick={loadMoreNotifications}
                      disabled={notificationsIsLoading}
                    >
                      {notificationsIsLoading
                        ? t("Loading...")
                        : t("See more notifications")}
                    </button>
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
    </>
  );
};

export default NotificationBox;
