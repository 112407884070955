import React from "react";
import { useTranslation } from "react-i18next";

const DowloadApp = () => {
  const {t} = useTranslation()
  return (
    <div className="px-5 pt-[132px] pb-[148px]">
      <div className="lg:grid block grid-cols-2 items-center gap-[67px] p-[20px] 4xl:max-w-[1930px] xl:max-w-[1186px] 2xl:max-w-[1440px] mx-auto">
        <div className="items-center">
          <div className="relative">
            <div className="absolute top-[-280px] left-[-100px]">
              <img
                src={require("../../assets/images/Main.png")}
                className="w-full"
              />
            </div>
            <div className="z-[3] relative">
              <h2 className="lg:text-[48px] text-[32px] font-bold mb-2.5">
                {t("DownloadEaidexApp")}
              </h2>
              <p className="lg:text-[31px] text-[24px] font-medium">
              {t("Get quick and easy access to reliable home services in your area - download the Eaidex app now!")}
              </p>
              <div className="flex items-center mt-[40px] gap-[14px]">
                {/* <button className="outline-none w-[178px] h-[60px] overflow-hidden rounded-lg">
                  <img
                    src={require("../../assets/images/appstore.png")}
                    className="w-full"
                  />
                </button> */}
                <button className="outline-none w-[178px] h-[60px] overflow-hidden rounded-lg">
                  <img
                    src={require("../../assets/images/playstore.png")}
                    className="w-full"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="relative lg:mt-0 mt-[100px]">
          <div className="flex lg:justify-start justify-center">
            <img
              src={require("../../assets/images/Home 1.png")}
              className=" z-[1]"
            />
          </div>
          <div>
            <img
              src={require("../../assets/images/Categories 1.png")}
              className="absolute -z-30 lg:right-[160px] lg:left-auto sm:left-[100px] left-[30px] top-[40px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DowloadApp;
