import { Dialog } from "@material-tailwind/react";
import React, { Fragment } from "react";

const GenericDialog = ({
  open,
  setOpen,
  title,
  message,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      handler={handleClose}
      className="p-4 min-w-[100%] sm:min-w-[545px] lg:min-w-[750px] lg:max-h-[750px] w-full"
    >
      <Fragment>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-xl font-semibold leading-6 text-gray-900">
              {title}
            </h3>
            <div className="mt-2">
              <p className="text-lg font-semibold text-gray-700">{message}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex justify-center w-full px-6 py-2 mt-3 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm sm:mt-0 sm:w-auto hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={handleClose}
          >
            Okay
          </button>
        </div>
      </Fragment>
    </Dialog>
  );
};

export default GenericDialog;
