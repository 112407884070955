import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import firebase from "./config/firebase";
import { ItemDataProvider } from "./component/Context/contextData";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        //(firebase as any).messaging().useServiceWorker(registration);
      })
      .catch((error) => {
        console.error("Error registering service worker:", error);
      });
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ItemDataProvider>
      <App />
    </ItemDataProvider>
  </React.StrictMode>
);
