import { toast } from "react-toastify";
export const handleFavorites = async (
  labourerId: number,
  favouritesData: any | undefined,
  setFavoriteLoadingStates: Function,
  removeFavouriteMutation: any,
  favouritesMutation: any,
  setOpen: Function,
  isAuthenticated: Function
) => {
  if (isAuthenticated()) {
    const isFavourite = favouritesData?.find(
      (favourite: any) => favourite?.labourerId === labourerId
    );
    const favouriteId = isFavourite?.id;
    try {
      setFavoriteLoadingStates((prev: any) => ({
        ...prev,
        [labourerId]: {
          add: !isFavourite,
          remove: !!isFavourite,
        },
      }));
      if (isFavourite && favouriteId !== undefined) {
        await removeFavouriteMutation.mutateAsync(favouriteId);
      } else if (!isFavourite) {
        await favouritesMutation.mutateAsync(labourerId);
      } else {
        toast.error("Favourite found but ID is undefined");
      }
    } catch (error) {
      toast.error("Error");
    } finally {
      setFavoriteLoadingStates((prev: any) => ({
        ...prev,
        [labourerId]: {
          add: false,
          remove: false,
        },
      }));
    }
  } else {
    setOpen(true);
  }
};

export const handleFavoriteClick = (
  isFavorite: boolean | undefined,
  handleFavorites: (id: number) => void,
  itemId: number,
  setIsModalOpen: Function
) => {
  if (isFavorite) {
    setIsModalOpen(true);
  } else {
    handleFavorites(itemId);
  }
};

export const confirmRemoveFavorite = (
  handleFavorites: (id: number) => void,
  itemId: number,
  setIsModalOpen: Function
) => {
  handleFavorites(itemId);
  setIsModalOpen(false);
};
