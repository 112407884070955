import React, { useState, ChangeEvent, FormEvent } from "react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  webUrl: string;
  serviceSector: string;
  typeOfAbuse: string;
  comments: string;
  attachment: File | null;
  // recaptcha: string;
}

interface FormErrors {
  [key: string]: string;
}

const ReportAbuse: React.FC = () => {
  const { t } = useTranslation();
  const placeholderFirstName = t("firstName") || "";
  const placeholderLastName = t("lastName") || "";
  const placeholderWeb = t("Web URL with Abuse") || "";
  const placeholderComment = t("commentLimit") || "";

  const placeholderService = t("Service Sector Or Menu Item") || "";

  const placeholderEmail = t("Email Id") || "";
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
  };
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    webUrl: "",
    serviceSector: "",
    typeOfAbuse: "",
    comments: "",
    attachment: null
    // recaptcha: ""
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      attachment: file
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: FormErrors = {};
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    console.log("Form submitted:", formData);
  };

  return (
    <div className="px-5">
      <div className="md:grid-cols-2 xl:gap-[67px] gap-[30px] xl:max-w-[1186px] 2xl:mt-[-28px] 2xl:max-w-[1440px] mx-auto 4xl:max-w-[1930px]">
        <h1 className="mx-8 font-bold text-center lg:text-4xl mb-7 lg:text-left mt-14">
          {t("Reporting Abuse")}
        </h1>
        <div className="flex flex-col">
          <div className="flex flex-col justify-center bg-gray-100 min-h-fit lg:flex-row">
            <div className="p-8 lg:w-1/2">
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("Reporting Abuse Para1")}
              </p>
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("Reporting Abuse Para2")}
              </p>
              <p className="2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("Reporting Abuse Para3")}
              </p>
            </div>
            <div className="flex items-center justify-center order-first w-full lg:w-1/2 md:order-last lg:order-last">
              <img
                src={require("../assets/images/reportabuse-modified.png")}
                className="w-3/4 h-auto "
                alt="Report Abuse Image"
              />
            </div>
          </div>
          <div className="flex gap-1 mx-8 mt-12 mb-5">
            <h1 className="font-semibold lg:text-2xl ">
              {t("Submit An Abuse Report")}
            </h1>
            <button
              onClick={toggleFormVisibility}
              className="text-blue-500 cursor-pointer focus:outline-none"
            >
              <span className="flex items-center ml-5 text-blue-500 sm:mr-10 h-7">
                {isFormVisible ? (
                  <MinusSmallIcon
                    className="w-8 h-8 text-blue-700 "
                    aria-hidden="true"
                  />
                ) : (
                  <PlusSmallIcon
                    className="w-8 h-8 text-blue-700 "
                    aria-hidden="true"
                  />
                )}
              </span>
            </button>
          </div>
          {/* Form */}
          {isFormVisible && (
            <div className="p-8 mt-4 mb-4 border border-gray-200 rounded-xl">
              <form onSubmit={handleSubmit}>
                {/* First Name */}
                <div className="mb-6">
                  <input
                    type="text"
                    placeholder={placeholderFirstName}
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {formErrors.firstName && (
                    <p className="text-red-500">{formErrors.firstName}</p>
                  )}
                </div>
                {/* Last Name */}
                <div className="mb-6">
                  <input
                    placeholder={placeholderLastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {formErrors.lastName && (
                    <p className="text-red-500">{formErrors.lastName}</p>
                  )}
                </div>
                {/* Email */}
                <div className="mb-6">
                  <input
                    placeholder={placeholderEmail}
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {formErrors.email && (
                    <p className="text-red-500">{formErrors.email}</p>
                  )}
                </div>
                {/* Web URL with Abuse */}
                <div className="mb-6">
                  <input
                    placeholder={placeholderWeb}
                    type="text"
                    id="webUrl"
                    name="webUrl"
                    value={formData.webUrl}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {formErrors.webUrl && (
                    <p className="text-red-500">{formErrors.webUrl}</p>
                  )}
                </div>
                {/* Service Sector Or Menu Item */}
                <div className="mb-6">
                  <input
                    placeholder={placeholderService}
                    type="text"
                    id="serviceSector"
                    name="serviceSector"
                    value={formData.serviceSector}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {formErrors.serviceSector && (
                    <p className="text-red-500">{formErrors.serviceSector}</p>
                  )}
                </div>
                {/* Type of Abuse */}
                <div className="mb-6">
                  <select
                    id="typeOfAbuse"
                    name="typeOfAbuse"
                    value={formData.typeOfAbuse}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="">{t("Select Type of Abuse")}</option>
                    <option value="Copyright Infringement">
                      {t("Copyright Infringement")}
                    </option>
                    <option value="Adult Content or CSAM">
                      {t("Adult Content or CSAM")}
                    </option>
                    <option value="Domain Protected by Privacy">
                      {t("Domain Protected by Privacy")}
                    </option>
                    <option value="Fraudulent Business Scheme">
                      {t("Fraudulent Business Scheme")}
                    </option>
                    <option value="Violent or Offensive Content">
                      {t("Inappropriate, Violent or Offensive Content")}
                    </option>
                    <option value="Inaccurate WHOIS information">
                      {t("Invalid, Inaccurate WHOIS information")}
                    </option>
                    <option value="Malware"> {t("Malware")}</option>
                    <option value="Fishing">{t("Fishing")} </option>
                    <option value="Spam"> {t("Spam")}</option>
                    <option value="Trademark Infringement">
                      {t("Trademark Infringement")}
                    </option>
                    {t(
                      " Invalid, Inaccurate WHOIS information, Malware, Fishing, Spam, Trademark Infringement "
                    )}
                  </select>
                  {formErrors.typeOfAbuse && (
                    <p className="text-red-500">{formErrors.typeOfAbuse}</p>
                  )}
                </div>
                {/* Comments */}
                <div className="mb-6">
                  <textarea
                    id="comments"
                    name="comments"
                    placeholder={placeholderComment}
                    value={formData.comments}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    rows={4}
                  />
                  {formErrors.comments && (
                    <p className="text-red-500">{formErrors.comments}</p>
                  )}
                </div>
                {/* Attach File */}
                <div className="mb-8">
                  <input
                    type="file"
                    id="attachment"
                    name="attachment"
                    onChange={handleFileChange}
                    accept=".jpg, .pdf, .doc"
                    className="w-full border border-gray-300 rounded"
                  />
                  {formErrors.attachment && (
                    <p className="text-red-500">{formErrors.attachment}</p>
                  )}
                  <p className="mt-1 text-sm text-gray-500">
                    {t("AttachedFileMaxSize")}
                  </p>
                </div>
                <div className="flex items-center gap-1 mt-10 ">
                  <input className="m-0 h-4 w-[20px] mr-1" type="checkbox" />
                  <div className=" text-xl tracking-[0.16px] leading-[24px] font-medium font-montserrat  text-left inline-block  shrink-0 mq450:text-base mq450:leading-[19px]">
                    {t("I’m not a robot")}
                  </div>{" "}
                  <img
                    className="w-[50px] h-[50px] relative z-[1] ml-3"
                    loading="eager"
                    alt=""
                    src={require("../assets/images/captchaIcon.png")}
                  />
                </div>
                <div className="flex items-center justify-center mb-6 text-center">
                  <button
                    type="submit"
                    className="bg-[#1F2937]  hover:bg-[#2f3e53]  text-white py-2 px-4 rounded mt-10"
                  >
                    {t("REPORT ABUSE")}
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className="h-5"></div>
        </div>
      </div>
    </div>
  );
};

export default ReportAbuse;
