import React, { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoCloseOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { registerFcmToken } from "../../API/api";
import { ItemDataContext } from "../Context/contextData";
import { UserType } from "../../API/types";
import firebase from "../../config/firebase";
import { smartStorage } from "../../utils/SmartStorage";
import VerifyMobileComponent from "../Authentication/VerifyMobileComponent";
import OtpInputComponent from "../Authentication/OtpInputComponent";
import ChooseUserType from "../Authentication/ChooseUserType";
import EmployerRegistration from "../Authentication/EmployerRegistration";

export enum AuthStep {
  VERIFY_NUMBER,
  VALIDATE,
  CHOOSE_USER_TYPE,
  REGISTER_EMPLOYER
}

const Login = ({
  open,
  setOpen,
  lang,
  preSelectedUserType
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lang: string;
  preSelectedUserType: UserType | undefined;
}) => {
  const { itemData } = useContext(ItemDataContext);
  const navigate = useNavigate();
  const [step, setStep] = useState<AuthStep>(AuthStep.VERIFY_NUMBER);
  const [otp, setOtp] = useState({
    phone: "",
    verificationId: ""
  });

  const location = useLocation();

  const url = new URL(window.location.href);
  const referralCode = url?.searchParams?.get("referralCode");

  const handleOnMobileVerification = (
    verificationId: string,
    phone: string
  ) => {
    setOtp((oldOtp) => ({
      ...oldOtp,
      phone: phone,
      verificationId: verificationId
    }));
    setStep(AuthStep.VALIDATE);
  };

  const registerFcmTokenMutation = useMutation(registerFcmToken, {
    onSuccess: () => {
      // toast.success("FCM Token added");
    },
    onError: (error: { message: string }) => {
      toast.error(error.message);
    }
  });

  const handleFcmToken = async () => {
    if ("serviceWorker" in navigator) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await firebase.messaging().getToken();
        smartStorage.handleTokenRefresh(token); // Update or delete
        const deviceId = smartStorage.getItem("deviceId");
        if (!deviceId) {
          const newDeviceId = uuidv4();
          smartStorage.setItem("deviceId", newDeviceId);
        }
        registerFcmTokenMutation.mutate({
          deviceId: smartStorage.getItem("deviceId") as string,
          platform: "web",
          provider: 1,
          pushToken: token
        });
      }
    }
  };

  const onVerifyOtpSuccess = (isNewUser: boolean) => {
    if (isNewUser) {
      if (preSelectedUserType != undefined) {
        onUserSelected(preSelectedUserType);
      } else if (referralCode) {
        setOpen(false);
        navigate("/register", {
          state: { register: otp, referralCode: referralCode }
        });
      } else {
        setStep(AuthStep.CHOOSE_USER_TYPE);
      }
    } else {
      handleFcmToken();
      setOpen(false);
      if (location?.pathname === "/result") {
        navigate("/result", { state: { searchData: itemData } });
      } else {
        navigate("/dashboard");
      }
    }
  };

  const onUserSelected = (userType: UserType) => {
    if (userType === UserType.EMPLOYER) {
      setStep(AuthStep.REGISTER_EMPLOYER);
    } else {
      setOpen(false);
      navigate("/register", {
        state: { register: otp, referralCode: referralCode }
      });
    }
  };

  const onLoginLinkClicked = () => {
    setStep(AuthStep.VERIFY_NUMBER);
  };

  const onSignupComplete = () => {
    setOpen(false);
    if (location?.pathname === "/result") {
      navigate("/result", { state: { searchData: itemData } });
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden bg-[#00000080]">
              <div className="pointer-events-none fixed inset-y-0 right-0 lg:w-[680px] flex pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white lg:w-[680px] md:p-[56px_54px] p-[40px_20px] shadow-xl">
                      <div>
                        <div className="text-start">
                          <button onClick={() => setOpen(false)}>
                            <IoCloseOutline className="text-[40px]" />
                          </button>
                          <div className="overflow-hidden">
                            {step === AuthStep.VERIFY_NUMBER && (
                              <VerifyMobileComponent
                                lang={lang}
                                onVerificationSucess={
                                  handleOnMobileVerification
                                }
                              />
                            )}
                            {step === AuthStep.VALIDATE && (
                              <OtpInputComponent
                                onOtpVerificationSucess={onVerifyOtpSuccess}
                                initialValues={otp}
                              />
                            )}
                          </div>
                          {step === AuthStep.REGISTER_EMPLOYER && (
                            <EmployerRegistration
                              onSignupComplete={onSignupComplete}
                              onLoginLinkClicked={onLoginLinkClicked}
                              phoneNumber={otp.phone}
                            />
                          )}
                          {step === AuthStep.CHOOSE_USER_TYPE && (
                            <ChooseUserType
                              defaultUserType={UserType.LABOURER}
                              onUserSelected={onUserSelected}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Login;
