import { BankDetailsSchema } from "../../schema/bankDetailsSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiX } from "react-icons/fi";
interface BankDetailsFormProps {
  userId: number | undefined;
  addBankDetailsMutation: any;
  updateBankDetailsMutation: any;
  editData: any;
  onCancel: () => void;
}

function BankDetailsForm({
  userId,
  addBankDetailsMutation,
  updateBankDetailsMutation,
  editData,
  onCancel,
}: BankDetailsFormProps) {
  const {
    register: bankDetailsForm,
    handleSubmit: BankDetailsSubmission,
    formState: { errors: BankDetailsFormErrors },
  } = useForm({
    resolver: yupResolver(BankDetailsSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: any) => {
    try {
      data = data as FormData;
      const bankDetails: any = {
        bankName: data.bankName,
        accountNumber: data.accountNumber,
        accountHolderName: data.accountName,
        ifsc: data.ifsc,
        isDefault: data.active,
        isDeleted: false,
        userId: userId,
      };
      // if (editData) {
      //   bankDetails.id = editData?.id;
      //   await updateBankDetailsMutation.mutate(bankDetails);
      // } else {
      //   await addBankDetailsMutation.mutate(bankDetails);
      // }
      // onCancel();
      if (editData) {
        bankDetails.id = editData?.id;
        await updateBankDetailsMutation.mutateAsync(bankDetails, {
          onSuccess: () => {
            onCancel();
          },
        });
      } else {
        await addBankDetailsMutation.mutateAsync(bankDetails, {
          onSuccess: () => {
            onCancel();
          },
        });
      }
    } catch (error) {
      console.error("An error occurred during mutation:", error);
    }
  };
  return (
    <div className="middleAddressDiv bg-white p-5 relative rounded-md shadow-md">
      <button
        className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700"
        onClick={onCancel}
      >
        <FiX size={24} />
      </button>

      <form
        className="order-2 mb-4 md:col-span-3 "
        onSubmit={BankDetailsSubmission(onSubmit)}
      >
        <div className="addresDiv max-w-[550px] grid grid-cols-6 gap:1 md:gap-2 ">
          <div className="col-span-6 mb-4 md:col-span-6">
            <div className="bg-[#feedcf]  p-4 mt-4 mb-4 text-center">
              {editData ? (
                <p className="text-center">Modify your bank details</p>
              ) : (
                <p>
                  Enter bank details. You can add details of max 3 banks, which
                  will be displayed on your website.
                </p>
              )}
            </div>
            <input
              {...bankDetailsForm("bankName")}
              className="w-full px-3 py-3 leading-tight text-gray-600 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline "
              id="bankName"
              name="bankName"
              type="text"
              defaultValue={editData?.bankName}
              placeholder="Bank Name.
            "
            />
            <p className="text-[16px] text-red-600">
              {BankDetailsFormErrors?.bankName?.message}
            </p>
          </div>

          <div className="col-span-6 mb-4 md:col-span-3">
            <input
              {...bankDetailsForm("ifsc")}
              type="text"
              id="ifsc"
              name="ifsc"
              defaultValue={editData?.ifsc}
              placeholder="IFSC Code"
              className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            />
            <p className="text-[16px] text-red-600">
              {BankDetailsFormErrors?.ifsc?.message}
            </p>
          </div>

          <div className="col-span-6 mb-4 md:col-span-3">
            <input
              {...bankDetailsForm("accountNumber")}
              className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="accountNumber"
              type="text"
              name="accountNumber"
              defaultValue={editData?.accountNumber}
              placeholder="Account Number"
            />
            <p className="text-[16px] text-red-600">
              {BankDetailsFormErrors?.accountNumber?.message}
            </p>
          </div>
          <div className="col-span-6 mb-4 md:col-span-6">
            <input
              {...bankDetailsForm("accountName")}
              className="w-full px-3 py-3 leading-tight text-gray-600 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="accountName"
              name="accountName"
              defaultValue={editData?.accountHolderName}
              type="text"
              placeholder="Account Name"
            />
            <p className="text-[16px] text-red-600">
              {BankDetailsFormErrors?.accountName?.message}
            </p>
          </div>
          <div className="col-span-6  md:col-span-6">
            <div className="col-span-6 mb-4 md:col-span-6">
              <div className="flex pt-4 pb-1 items-center">
                <input
                  {...bankDetailsForm("active")}
                  type="checkbox"
                  id="active"
                  name="active"
                  defaultChecked={editData?.isDefault}
                  className="mr-2 leading-tight text-gray-600 border-gray-300"
                />
                <p className="text-[16px] text-red-600">
                  {BankDetailsFormErrors?.active?.message}
                </p>
                <label htmlFor="terms" className="inline-block">
                  Set as primary account
                </label>
              </div>
            </div>

            <div className=" w-full flex justify-end">
              {editData ? (
                <button className="px-4 py-2 text-sm text-gray-700 bg-[#feedcf] rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300">
                  {updateBankDetailsMutation?.isLoading
                    ? "Saving Changes..."
                    : "Save Changes"}
                </button>
              ) : (
                <button className="px-4 py-2 text-sm text-gray-700 bg-[#feedcf] rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300">
                  {addBankDetailsMutation?.isLoading
                    ? "Adding New Account..."
                    : "Add New Account"}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BankDetailsForm;
