import { useTranslation } from "react-i18next";
import { usePayOutHistory } from "../../API/api";
import moment from "moment";
import { Spinner } from "@material-tailwind/react";

const PayOutHistory = () => {
  const {
    data: paymentHistory,
    refetch: paymentHistoryFetch,
    isLoading } = usePayOutHistory();

  const { t } = useTranslation()

  return (
    <div className="mt-8 bg-[#F5F5F5] w-full">
      <div className="w-full mb-20">
        {isLoading ? <Spinner /> :
          (<table className="min-w-[750px] w-full overflow-x-scroll divide-y divide-graylighter border border-[#C9C9C9]">
            <thead className="bg-[#EDEDED]">
              <tr>
                <th className=" px-[23px] py-[19px]  text-left text-sm font-semibold text-black w-[190px]">
                  {t("Payment Id")}
                </th>
                <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                  {t("Transaction Date")}
                </th>
                <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                  {t("Reference Id")}
                </th>
                <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                  {t("Amount")}
                </th>
                <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                  {t("Account Number")}
                </th>
                <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                  {t("Bank Name")}
                </th>
                <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                  {t("IFSC")}
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory?.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    <div className=" text-center items-center text-pirmary text-lg mt-3">
                      {t("No Results Found")}
                    </div>
                  </td>
                </tr>
              )}
              {paymentHistory?.map((item) => {
                return (
                  <tr className="border-b-2">
                    <td className="py-[26px] px-[23px] w-[190px]">
                      <span className="font-medium block text-[16px] text-left break-normal leading-[20px] text-black">
                        {item.paymentid}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                        {moment(item?.transactionDate).format("DD-MM-YYYY hh:mm A")}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                        {item.referenceId}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                        {item?.amount}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                        {item.accountNumber}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                        {t(item.bankName)}
                      </span>
                    </td>
                    <td>
                      <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                        {item.ifsc}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          )}
      </div>
    </div>
  );
};

export default PayOutHistory;
