import React from "react";
import { useTranslation } from "react-i18next";
import { acknowledgeCookieAcceptance } from "../../API/api";

const CookieConsentBanner = ({
  onCookieAcknowledged
}: { onCookieAcknowledged: () => void; }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ position: 'fixed', bottom: '0px', right: '0px', left: '0px', border: '2px solid #F0A61F', backgroundColor: '#272D29' }}
      className="lg:grid z-[1000]	block grid-cols-1 items-center p-[20px] 4xl:max-w-[1930px] xl:max-w-[1186px] 2xl:max-w-[1440px] mx-auto"
    >
      <div>
        <img
          src={require(`../../assets/images/cookie.png`)}
          className={'h-[50px] w-[50px]'}
        />
        <h4 className="4xl:text-[1.3vw] text-[#F0A61F] mb-3 text-[23px] font-semibold">
          {t("Your privacy")}
        </h4>
      </div>
      <p className="text-[#FFFFFF]">
        {t("Accept Cookies Description")}
      </p>
      <div
        className="lg:grid block grid-cols-2 gap-5 items-center p-[5px] 4xl:max-w-[1930px] xl:max-w-[1186px] 2xl:max-w-[1440px] mx-auto"
      >
        <button
          className="gap-1.5 mt-[23px] 4xl:text-[1vw] 4xl:py-5 text-[17px]  leading-[13px] font-medium py-[14px] px-[24px] rounded-full bg-[#F0A61F]"
          onClick={() => {
            acknowledgeCookieAcceptance(true)
            onCookieAcknowledged()
          }
          }
        >
          {t("Accept all cookies")}
        </button>
        <button
          className="gap-1.5 mt-[23px] 4xl:text-[1vw] 4xl:py-5 text-[17px]  leading-[13px] font-medium py-[14px] px-[24px] rounded-full bg-[#F0A61F]"
          onClick={() => {
            acknowledgeCookieAcceptance(false)
            onCookieAcknowledged()
          }
          }
        >
          {t("Necessary cookies only")}
        </button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
