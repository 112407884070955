import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Routers/Routers";
import "./App.css";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { useEffect } from "react";
import firebase from "./config/firebase";
import { queryClient } from "./API/api";
import FcmMessageHandler from "./utils/FcmMessageHandler";
import { AppContextProvider } from "./component/Context/AppContext";
import { LocationProvider } from "./component/Context/LocationContext";

const NotificationToast = ({
  title,
  body,
}: {
  title: string;
  body: string;
}) => (
  <div className="custom-toast">
    <h2 className="toast-title">{title}</h2>
    <div className="toast-body">{body}</div>
  </div>
);

function App() {
  const { t } = useTranslation();
  useEffect(() => {
    const getToken = async () => {
      try {
        await Notification.requestPermission();
        const token = await firebase.messaging().getToken();
        console.log("Token:", token);
        // smartStorage.handleTokenRefresh(token);
      } catch (error: any) {
        if (error.code === "messaging/permission-blocked") {
          console.log(
            "Notification permission blocked. Please enable notifications in your browser settings."
          );
        } else {
          console.log("Error getting token:", error);
        }
      }
    };

    if ("serviceWorker" in navigator) {
      firebase.messaging().onMessage((payload: any) => {
        //console.log("Received FCM foreground message:", payload);
        const { title, body } = FcmMessageHandler.handlePayload(payload, t);
        console.log(title, body);
        notify(title, body);
      });

      getToken();
    }
  }, []);

  const notify = (title: string, body: string) =>
    toast(<NotificationToast title={title} body={body} />);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <AppContextProvider>
          <LocationProvider>
            <Router>
              <Routers />
            </Router>
          </LocationProvider>
        </AppContextProvider>
        <ToastContainer />
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;
