import { useRef, useState } from "react";
import { TermsAndCondition } from "../assets/images/AllSvg";
import Text from "../component/Text/Text";
import Menubar from "../component/Menubar";

function TermsConditions() {
  const scrolldivRef: any = useRef();
  const informationRef = useRef(null);
  const agreeRef = useRef<HTMLDivElement>(null);
  const assentRef = useRef<HTMLDivElement>(null);
  const NoWARRANTIERef = useRef<HTMLDivElement>(null);
  const definitionsRef = useRef<HTMLDivElement>(null);
  const eligibleRef = useRef<HTMLDivElement>(null);
  const advertisRef = useRef<HTMLDivElement>(null);
  const privacyRef = useRef<HTMLDivElement>(null);
  const limitedUseRef = useRef<HTMLDivElement>(null);
  const regRef = useRef<HTMLDivElement>(null);
  const subRef = useRef<HTMLDivElement>(null);
  const userLicenseRef = useRef<HTMLDivElement>(null);
  const generalConditionRef = useRef<HTMLDivElement>(null);
  const exclusionRef = useRef<HTMLDivElement>(null);
  const userContentRef = useRef<HTMLDivElement>(null);
  const ownershipRef = useRef<HTMLDivElement>(null);
  const advertisingRef = useRef<HTMLDivElement>(null);
  const featuredAdsRef = useRef<HTMLDivElement>(null);
  const endUserLicenseRef = useRef<HTMLDivElement>(null);
  const reservationOfRightsRef = useRef<HTMLDivElement>(null);
  const copyrightInfringementRef = useRef<HTMLDivElement>(null);
  const liabilityLimitationsRef = useRef<HTMLDivElement>(null);
  const intellectualPropertyRef = useRef<HTMLDivElement>(null);
  const mobileServicesRef = useRef<HTMLDivElement>(null);
  const indemnificationRef = useRef<HTMLDivElement>(null);
  const modificationVariationRef = useRef<HTMLDivElement>(null);
  const limitationOnLiabilityRef = useRef<HTMLDivElement>(null);
  const disputeResolutionRef = useRef<HTMLDivElement>(null);
  const terminationOfServicesRef = useRef<HTMLDivElement>(null);
  const governingLawRef = useRef<HTMLDivElement>(null);
  const severabilityRef = useRef<HTMLDivElement>(null);
  const contactingUsRef = useRef<HTMLDivElement>(null);

  const sections = [
    { label: "The Agreement ", ref: agreeRef },
    { label: "Definitions", ref: definitionsRef },
    { label: "Assent & Acceptance", ref: assentRef },
    { label: "Accuracy of Information", ref: informationRef },
    { label: "Eligibility", ref: eligibleRef },
    { label: "Advertisments ", ref: advertisRef },
    { label: "Privacy ", ref: privacyRef },
    { label: "Limited Use of the Platform", ref: limitedUseRef },
    { label: "Registration", ref: regRef },
    { label: "Subscription and Refund Policy", ref: subRef },
    { label: "User License", ref: userLicenseRef },
    { label: "General Condition", ref: generalConditionRef },
    { label: "User Content", ref: userContentRef },
    { label: "Ownership", ref: ownershipRef },
    { label: "Advertising", ref: advertisingRef },
    { label: "Featured Ads", ref: featuredAdsRef },
    { label: "End User License", ref: endUserLicenseRef },
    { label: "Mobile Services", ref: mobileServicesRef },
    { label: "Reservation of Rights", ref: reservationOfRightsRef },
    {
      label: "Notification of Copyright Infringement",
      ref: copyrightInfringementRef
    },
    { label: "No Warranties and Guarantees", ref: NoWARRANTIERef },
    { label: "Liability Limitations", ref: liabilityLimitationsRef },
    { label: "Exclusion of Liability", ref: exclusionRef },
    { label: "Intellectual Property", ref: intellectualPropertyRef },
    { label: "Indemnification", ref: indemnificationRef },
    { label: "Modification & Variation", ref: modificationVariationRef },
    { label: "Limitation on Liability", ref: limitationOnLiabilityRef },
    { label: "Dispute Resolution", ref: disputeResolutionRef },
    { label: "Governing Law and Jurisdiction", ref: governingLawRef },
    { label: "Severability", ref: severabilityRef },
    { label: "Termination of Services", ref: terminationOfServicesRef },
    { label: "Contacting Us", ref: contactingUsRef }
  ];

  const fixedHeaderHeight = 140;

  const handleScrollToScope = (ref: any) => {
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;

      if (scrolldivRef) {
        scrolldivRef?.current.scroll({
          top: elementTop,
          behavior: "smooth"
        });
      }
    }
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggleSidebar = (ref: React.RefObject<any>) => {
    setSidebarVisible(!sidebarVisible);
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;
      scrolldivRef?.current.scroll({
        top: elementTop,
        behavior: "smooth"
      });
    }
  };
  const handleClose = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <header className="bg-gray-50   border-gray-200 border-b-2   top-0">
        <div className="  text-black md:text-2xl sm:text-base font-bold ml-[15%] mb-2 font-Montserrat leading-10">
          Eaidex - Terms and Conditions
        </div>
      </header>
      <div className="bg-white-600 flex   ">
        <div className="section1-main border-gray-200 border-r-2 h-auto flex-shrink-0 md:w-[20%] hidden sm:block pt-1 pr-1 ">
          <div className="section-sub bg-gray-100 ">
            <ul className="list-disc pl-4 md:pl-14 pt-4 md:pt-10  md:pb-4 space-y-5 ">
              {sections?.map((section, index) => (
                <li
                  key={index}
                  className=" cursor-pointer text-black hover:text-blue-gray-900 text-xs font-normal font-robotoserif leading-none  "
                  onClick={() => handleScrollToScope(section.ref)}
                >
                  {section.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="section2-Main md:w-[80%] w-[100%] h-screen pl-1 pt-1 mt-0">
          <div
            className="section2-sub bg-gray-100 md:p-3 p-4  overflow-scroll no-scrollbar scroll-smooth h-screen md:h-[1060px]  "
            ref={scrolldivRef}
          >
            <div className="innerArea bg-white p-4 md:p-10 md:w-10/12 w-full flex-col items-center justify-center">
              <button
                className="  text-white absolute left-0 top-24 md:hidden lg:hidden 2xl:hidden xl:hidden sm:block"
                onClick={() => handleToggleSidebar(scrolldivRef)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 17h18v-2H3v2zM3 11h18V9H3v2zm0-6v2h18V5H3z" />
                </svg>
              </button>

              <div className="font-robotoserif text-black font-normal text-xs  ">
                <div className="flex justify-center w-full  md:mt-[50px] md:mb-5">
                  <TermsAndCondition />
                </div>
                <div className="font-robotoserif text-black font-normal text-xs ">
                  <p>
                    THIS DOCUMENT CONSTITUTES AN ELECTRONIC RECORD UNDER THE
                    INFORMATION TECHNOLOGY ACT, 2000, AND THE APPLICABLE RULES,
                    INCLUDING PROVISIONS RELATED TO ELECTRONIC RECORDS IN
                    VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY
                    ACT, 2000. IT IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT
                    REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                  </p>

                  <p className="mt-3 mb-4">
                    THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH RULE 3(1) OF
                    THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES)
                    RULES, 2011. THIS RULE MANDATES THE PUBLICATION OF THE RULES
                    AND REGULATIONS, PRIVACY POLICY, AND TERMS OF USE FOR
                    ACCESSING OR UTILIZING THE PLATFORM.
                  </p>
                </div>
                <div ref={agreeRef}>
                  <Text
                    className=" text-[13px] text-black underline uppercase   font-bold mb-2"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    THE AGREEMENT
                  </Text>
                  <Text
                    className=" mb-4  text-black   font-normal  "
                    size="txtRobotoSerifRegular12"
                  >
                    The use of this platform and services on this
                    website/application provided by Eaidex Services Pvt Ltd. (
                    Eaidex referred to as "Platform") are subject to the
                    following Terms & Conditions, all parts and sub-parts of
                    which are specifically incorporated by reference here. This
                    Agreement shall govern the use of all pages on this
                    website/application (hereinafter collectively referred to as
                    "Platform").
                  </Text>
                </div>

                {/* Personal Information */}
                <div ref={definitionsRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase font-bold "
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    DEFINITIONS
                  </Text>
                  <Text
                    className="leading-[25.00px] my-0.5 text-black text-justify text-xs tracking-[0.12px]  font-normal "
                    size="txtRobotoSerifRegular12"
                  >
                    “Agreement” denotes these Terms and Conditions and the
                    Privacy Policy and other documents provided to you by the
                    website/application.
                  </Text>
                  <Text
                    className="leading-[25.00px] text-black text-justify text-xs tracking-[0.12px] mt-2"
                    size="txtRobotoSerifRegular12"
                  >
                    <span className="text-black font-robotoserif font-bold">
                      “We,” “us,”
                    </span>
                    <span className="text-black font-robotoserif font-normal">
                      and{" "}
                    </span>
                    <span className="text-black font-robotoserif font-bold">
                      “our”
                    </span>

                    <span className="text-black-900 font-robotoserif font-normal ml-1 ">
                      are references to
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold ml-1">
                      Eaidex Services Pvt Ltd.
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      “User,” “You,” and “your”
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      denote the person who is accessing the platform to publish
                      their skilled profile who would be the
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      Service Providers{" "}
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal">
                      and those who search for those published profiles would be
                    </span>
                    <span className="text-black-900 font-robotoserif font-bold">
                      Service Seekers.
                    </span>
                    <span className="text-black-900 font-robotoserif font-normal mb-2">
                      The User shall be shall include the Individuals,
                      companies, partnership firms, sole trader, person, body
                      corporate, or association taking services of this
                      platform.
                    </span>
                    <div className="mt-2 mb-2">
                      <span className="text-black font-robotoserif font-bold mb-1">
                        Service providers{" "}
                      </span>
                      <span className="text-black-900 font-robotoserif font-normal">
                        are referred as Individuals, companies or organisation
                        who provides certain specific skills for the completing
                        of a task.
                      </span>
                    </div>
                    <div className="mb-2">
                      <span className="text-black-900 font-robotoserif font-bold">
                        Service seekers{" "}
                      </span>
                      <span className="text-black-900 font-robotoserif font-normal">
                        are referred as Individuals, companies or organisation
                        who seeks certain specific skills for the complexation
                        of a task
                      </span>
                    </div>

                    <div>
                      <span className="text-black-900 font-robotoserif font-bold mr-1">
                        Services
                      </span>
                      <span className="text-black-900 font-robotoserif font-normal">
                        refer to specific skillsets offered by skilled
                        individuals referred as
                      </span>
                      <span className="text-black-900 font-robotoserif font-bold ml-1 mr-1">
                        Service Providers;
                      </span>
                      <span className="text-black-900 font-robotoserif font-normal">
                        who could provide and perform such skilled task to other
                        individuals or persons referred as
                      </span>
                      <span className="text-black-900 font-robotoserif font-bold ml-1 mb-2">
                        Service Seekers.
                      </span>
                    </div>

                    <div>
                      <span className="text-black-900 font-robotoserif font-bold mr-1">
                        “Website/Application”
                      </span>
                      <span className="text-black-900 font-robotoserif font-normal">
                        shall mean and include
                      </span>
                      <span className="text-black-900 font-robotoserif font-bold ml-1">
                        Eaidex{" "}
                      </span>
                      <span className="text-black-900 font-robotoserif font-normal">
                        and any successor platform of the Company or any of its
                        affiliates.{" "}
                      </span>
                    </div>

                    <div className="mt-2 ">
                      <span className="text-black-900 font-robotoserif font-bold mr-1">
                        Parties:
                      </span>

                      <span className="text-black-900 font-robotoserif font-normal">
                        Collectively, the parties to this Agreement (We and You)
                        will be referred to as Parties.
                      </span>
                    </div>
                  </Text>
                </div>

                {/* What we Collect */}

                <div ref={assentRef}>
                  <Text
                    className="mt-4 text-[13px] text-black font-bold  underline uppercase"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    ASSENT & ACCEPTANCE
                  </Text>
                  <Text
                    className="mb-1 mt-2 text-black  leading-5 tracking-tight   "
                    size="txtRobotoSerifRegular12"
                  >
                    PLEASE READ THESE TERMS OF USE, OUR PRIVACY POLICY, AND ALL
                    APPLICABLE SUPPLEMENTAL TERMS (COLLECTIVELY, THE "TERMS")
                    CAREFULLY, AS THEY CONTAIN TERMS AND CONDITIONS THAT IMPACT
                    YOUR RIGHTS, OBLIGATIONS, AND REMEDIES IN CONNECTION WITH
                    YOUR USE OF THE SERVICES AND CONTENT. FOR EXAMPLE, THE TERMS
                    INCLUDE:
                  </Text>
                  <div className="">
                    <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                      <li className="  ">
                        YOUR OBLIGATION TO COMPLY WITH ALL APPLICABLE LAWS AND
                        REGULATIONS.{" "}
                      </li>
                      <li className="">
                        LIMITATIONS OF OUR LIABILITY TO YOU; AND
                      </li>
                      <li className="">
                        A REQUIREMENT THAT YOU PURSUE CLAIMS OR SEEK RELIEF
                        AGAINST US (INCLUDING MONETARY, INJUNCTIVE, AND
                        DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS RATHER THAN
                        AS A PARTICIPANT IN ANY CLASS OR REPRESENTATIVE ACTION
                        OR PROCEEDING.{" "}
                      </li>

                      <Text
                        className="leading-5 mb-1 text-black font-robotoserif  tracking-[0.12px] "
                        size="txtRobotoSerifRegular12"
                      >
                        YOUR ACCESS TO AND USE OF THE SERVICES ARE CONDITIONED
                        ON YOUR ACCEPTANCE OF AND COMPLIANCE WITH ALL APPLICABLE
                        TERMS. If you do not agree to these Terms or our Privacy
                        Policy, then please cease using the Services
                        immediately. We reserve the right to change these Terms
                        at any time (see “Changes to these Terms” below.) By
                        accessing, browsing, and/or using the Services after
                        updates to these Terms have been posted, you agree to be
                        bound by the updated Terms. THESE TERMS AND OUR PRIVACY
                        POLICY CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND
                        EAIDEX.
                      </Text>
                      <span className="text-black  font-normal">
                        <>Consequences of Non-Compliance</>
                      </span>
                      <span className="text-black  font-normal">
                        <>
                          Your failure to comply with the Terms may result in
                          the suspension or termination of your subscription
                          account and/or access to the Services and may subject
                          you to civil and criminal penalties.
                        </>
                      </span>
                    </ul>
                  </div>

                  <div ref={informationRef}>
                    <Text
                      className=" text-[13px] text-black uppercase mt-4 "
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      ACCURACY OF INFORMATION
                    </Text>
                    <Text
                      className="leading-5  mt-2 text-black text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Occasionally, there may be information on the
                      website/application that contains typographical errors,
                      inaccuracies, or omissions that may relate to user
                      descriptions, pricing, availability, promotions, and
                      offers. We reserve the right, in our sole discretion, to
                      make changes or modifications to these Terms and
                      Conditions at any time and for any reason. We undertake no
                      obligation to update, amend or clarify information on the
                      website/application, including content posted by Eaidex or
                      by other user. No specified update or refresh date applied
                      on the website/application should be taken to indicate
                      that all information on the website/application has been
                      modified or updated. Please ensure that you check the
                      applicable Terms and Conditions every time you use our
                      website/application so that you understand which Terms and
                      Condition apply. You will be subject to and will be deemed
                      to have been made aware of and to have accepted the
                      changes in any revised Terms and Conditions by your
                      continued use of the website/application after the date
                      such revised Terms and conditions are posted.
                    </Text>
                  </div>
                  <div ref={eligibleRef}>
                    <Text
                      className="mt-3 mb-2 text-[13px] text-black uppercase "
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      ELIGIBILITY
                    </Text>
                    <Text
                      className="leading-5 my-0.5 text-black text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      By accessing and using the Service, you affirm and assure
                      Eaidex that you are eighteen (18) years old or older and
                      possess the legal capacity to enter into, perform, and
                      abide by these Terms and Conditions. Prior to uploading
                      any content, making comments, or engaging in any other use
                      of the website/application, you commit to registering and
                      providing accurate details, including, but not limited to,
                      your full name, age, email address, residential address,
                      and contact number.
                    </Text>
                  </div>
                  <div ref={advertisRef}>
                    <Text
                      className="mt-3 mb-2 text-[13px] text-black uppercase "
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      ADVERTISEMENTS
                    </Text>
                    <Text
                      className="leading-5  text-black text-justify text-xs tracking-[0.12px]  font-normal "
                      size="txtRobotoSerifRegular12"
                    >
                      During your use of Eaidex’s website/application/platform,
                      you may enter correspondence with or participate in
                      promotions of advertisers or sponsors showing their goods
                      or services through the website/application/platform. Any
                      such activity and any terms, conditions, warranties, or
                      representations associated with such activity are solely
                      between you and the applicable third party. We shall have
                      no liability, obligation, or responsibility for any such
                      correspondence or promotion between you and any such third
                      party.
                    </Text>
                  </div>
                  <div ref={privacyRef}>
                    <Text
                      className="mt-3 mb-2 text-[13px] text-black uppercase font-bold "
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      PRIVACY
                    </Text>
                    <Text
                      className="leading-5  text-black text-xs tracking-[0.12px]  font-normal  mt-3 mb-2"
                      size="txtRobotoSerifRegular12"
                    >
                      We do not sell or rent your personal information to third
                      parties for their marketing purposes without your explicit
                      consent. We use your information only as described in the
                      Company Privacy Policy. We view the protection of users’
                      privacy as a very important community principle. We store
                      and process your information on computers located in India
                      that are protected by physical as well as technological
                      security devices. You can access and modify the
                      information you provide us and choose not to receive
                      certain communications by signing into your account. We
                      use third parties to verify and certify our privacy
                      principles. For a complete description of how we use and
                      protect your personal information, see our Company Privacy
                      Policy- privacy@eaidex.com. If you object to your
                      information being transferred or used in this way, please
                      do not use our services.
                    </Text>
                  </div>
                  <div ref={limitedUseRef}>
                    <Text
                      className="mt-3 text-[13px] text-black uppercase font-bold "
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      LIMITED USE OF THE PLATFORM
                    </Text>
                    <Text
                      className="leading-5 mt-3 mb-2 text-black text-xs tracking-[0.12px]  font-normal "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex provides an online service search engine platform
                      via the website/application that enables users (Service
                      Providers and Service Seekers) to connect with each other
                      and enter into various service arrangements, business and
                      commercial / non-commercial arrangement between
                      themselves. Eaidex provides an online platform which
                      serves as a neutral Job marketplace to facilitate the
                      above mentioned transactions by allowing the Service
                      Seekers to find suitable Service Providers based on listed
                      skills in the website/application/platform. However,
                      Eaidex does not offer, engage in or provide goods or
                      services for purchase, rent or sale through the
                      website/application/platform. Eaidex acts solely as a
                      facilitator / connector between the users (Service
                      Provider and Service Seeker) and does not act as an agent
                      for either of them. The transaction between the users
                      (Service Provider and Service Seeker) is on principal to
                      principal basis, and any arrangements, understandings,
                      agreements and transactions made are exclusively between
                      the Users and Eaidex will not have any responsibility or
                      liability to any such transactions. Eaidex is not a
                      contracting party in any arrangement, business or
                      agreement made between the Service Provider and Service
                      Seeker and is not liable for either of the users conduct
                      or service provided. Users acknowledge that Eaidex only
                      provides a platform to introduce users (Service Providers
                      and Service Seekers) and facilitate to publish the
                      information /skills provided by the Service Provider in
                      the platform; wherein the Service Seeker who requires
                      specific services will have choice of viewing/ identifying
                      and contacting those Service Providers directly.
                    </Text>
                  </div>

                  <div ref={regRef}>
                    <Text
                      className="mt-3  mb-2 text-[13px] text-black uppercase font-bold "
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      REGISTRATION
                    </Text>
                    <ul className="text-black ml-8 list-disc font-robotoserif   leading-6 tracking-tight ">
                      <li className="text-black   ">
                        To use the platform, as a Service Seeker or a Service
                        Provider you will need open an profile account by
                        registering themself as a respective “User” with
                        Eaidex’s website/application/platform. A User can list a
                        specific skill in the platform by registering as Service
                        Provider similarly a User in order to avail the service
                        of the Service Provider can register as Service Seeker
                        in the website/application/platform. By registering as a
                        Service Seeker or a Service Provider, you are giving
                        Eaidex (i) the permission to disclose the information
                        you provide on Eaidex’s website/application/platform to
                        other Users, (ii) to make some or all the information
                        you provide available on the
                        website/application/platform, (iii) disclose some or all
                        the information you provide to third parties for
                        verification purposes, to obtain and use additional
                        information about you in a similar fashion. Eaidex
                        reserves the right to accept or reject users at our sole
                        discretion for any reason.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        If you register, you will: (a) provide accurate, current
                        and complete information about you as may be prompted by
                        any signup, login and/or registration forms made
                        available via the service (the “ Registration Data “);
                        (b) maintain and promptly update the Registration Data,
                        and any other information you provide to Eaidex.com, in
                        order to keep it accurate, current and complete; and (c)
                        accept all risk of unauthorised access to the
                        Registration Data and any other information you provide
                        to Eaidex. Eaidex reserves the right to request proof of
                        identification, age, address, qualifications and skills
                        (for example, proof of your ability to obtain licenses
                        to operate certain product/services).{" "}
                      </li>
                      <li>
                        The Company is under no obligation to retain a record of
                        your account or any Registration Data or other
                        information that you may have stored by means of your
                        account or your use of the service. You are only
                        authorised to create and use one account for the purpose
                        of providing service or seeking service and are
                        prohibited from using alter egos or other disguised
                        identities when using the Eaidex’s
                        website/application/platform. You are under no
                        obligation to use or continue to use the platform and
                        may stop using the website/application/platform without
                        notice to Eaidex.com.
                      </li>
                      <li>
                        We do not endorse, investigate, or verify any User,
                        Registration Data or User Content at any time. All
                        Registration Data and details and other information
                        relating to the account profiles of the Service
                        Providers or Service Seekers available through the
                        platform are directly that of USERS/ACCOUNT OWNERS
                        (Service Seekers and Service Providers). Eaidex does not
                        assume any liability for the accuracy, completeness, and
                        timeliness of this information. Eaidex gives no
                        guarantee to the User that other Users using the
                        platform will not use the platform with the intent to
                        defraud. Eaidex does not accept any liability arising
                        because of a User registering under a false identity or
                        providing false information. Eaidex recommends that all
                        Users verify the identity of their contracting partners
                        (Service Seeker or Service Providers) and other
                        information relating to their respective profile, any
                        equipment or goods to be used or the services prior to
                        engaging in any transactions, arrangements, business or
                        agreements (Oral or Written). Any assistance provided by
                        Eaidex shall not constitute an endorsement,
                        certification or guarantee by Eaidex about any User
                        (Service Provider or Service Seeker), including their
                        identity and whether the User is trustworthy, safe, or
                        suitable.
                      </li>

                      <li>
                        Service Seekers and Service Providers are solely
                        responsible for the activity that they choose or that
                        occurs on your account, and you must keep your account
                        password secure. You must notify us immediately of any
                        breach of security or unauthorised use of your account.
                        We will not be liable for any losses caused by any
                        unauthorised use of your account.
                      </li>
                    </ul>
                  </div>

                  <div ref={subRef}>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      SUBSCRIPTION AND REFUND POLICY:
                    </Text>
                    <Text
                      className="leading-[25.00px] my-0.5 text-black text-justify text-xs tracking-[0.12px] font-normal font-robotoserif"
                      size="txtRobotoSerifRegular12"
                    >
                      Subscription Agreement: By subscribing to Eaidex, you
                      agree to pay the annual subscription fee of Indian Rupees
                      300/- in order to register and have access the account
                      profile of Eaidex’s fully serviced search
                      engine/classifieds on both the website and application
                      platforms.
                    </Text>
                    <ul className="ml-8 list-disc">
                      <li className="text-black font-robotoserif  ">
                        Subscriptions can be automatically renewed on an annual
                        basis. In such cases Users will be notified in advance
                        of renewal, and continuation of the subscription implies
                        acceptance of the renewal charge.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Annual Subscription: Subscribers will be charged an
                        annual fee for access to Eaidex, our paid search engine.
                        The subscription fee excludes GST, and it is
                        non-refundable.
                      </li>
                      <li>
                        Cancellation: Subscribers can cancel their subscription
                        at any time, but no refunds will be provided for the
                        remaining subscription period. The GST component is also
                        non-refundable.
                      </li>
                      <li>
                        Incentive System: Monthly Incentives: Eaidex operates an
                        incentive system for users. Incentives will be paid out
                        once a month to eligible participants. The exact details
                        of the incentive program will be outlined separately.
                      </li>

                      <li>
                        Non-Refundable Fee: The subscription charge is
                        non-refundable under any circumstances. Once payment is
                        made, there will be no reimbursement, whether the
                        subscription is terminated voluntarily or involuntarily.
                      </li>
                    </ul>
                  </div>

                  <div ref={userLicenseRef}>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      USER LICENSE{" "}
                    </Text>

                    <ul className="ml-8 list-disc">
                      <li className="text-black font-robotoserif  ">
                        Subject to the terms, conditions and limitations set
                        forth in these Terms and Conditions, Eaidex grants you a
                        non-exclusive, non- transferable, and revocable license
                        to use the website/application on any computer or mobile
                        device that you own or control. You may use Eaidex
                        website/application/platform (i) form any part of the
                        world, (ii) have reached the age of majority where you
                        live, and (iii) can form legally binding contracts under
                        applicable law. You may not use the website/application
                        if you live in a jurisdiction where access to or use of
                        the website/application or any part of it may be illegal
                        or prohibited. It is solely your responsibility to
                        determine whether your use of this website/application
                        is lawful, and you must comply with all applicable laws.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Each User represents and warrants to Eaidex that: (a)
                        you have reached the age of majority where you live and
                        can form legally binding contracts under applicable law;
                        (b) if you are acting on behalf of a corporation, entity
                        or organisation, you: (i) are authorised to bind that
                        corporation, entity or organisation to these Terms and
                        Conditions; and (ii) the corporation, entity or
                        organisation on behalf of which you are entering into
                        these Terms and Conditions is a duly formed entity
                        existing in good standing in each of the jurisdictions
                        in which the entity transacts business.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Your use of the user license and service and your
                        contact, interaction, or dealings with other Users and
                        any third parties arising out of your use of the service
                        is entirely at your own risk. Users are independent of
                        Eaidex and are not agents, representatives, or employees
                        of Eaidex. Eaidex is not responsible for, and will not
                        be held liable for, the acts, errors, omissions,
                        representations, warranties, breaches, or negligence of
                        any User or any of its employees, independent
                        contractors, representatives, agents, assigns, carriers,
                        operators, drivers, delivery personnel or other
                        suppliers for any personal injuries, death, property
                        damage, loss, theft or other damages or expenses
                        resulting therefrom.
                      </li>
                    </ul>
                  </div>

                  <div ref={generalConditionRef}>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      GENERAL CONDITION{" "}
                    </Text>

                    <ul className="ml-8 list-disc">
                      <li className="text-black font-robotoserif  ">
                        Eaidex exercise no editorial control over much of the
                        content on the website/application, and in many cases,
                        other persons provide the content on the
                        website/application or operate parts of the
                        website/application or other websites you may view or
                        access through Eaidex’s website/application.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Eaidex may revise these terms and conditions from time
                        to time by updating this posting on this
                        website/application. The revised terms will take effect
                        when they are posted
                      </li>

                      <li className="text-black font-robotoserif  mt-2">
                        Eaidex do not guarantee the accuracy, completeness,
                        validity, or timeliness of information listed by the
                        users.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Eaidex make material changes to these terms and
                        conditions from time to time; we may notify you either
                        by prominently posting a notice of such changes or via
                        email communication.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Eaidex’s website/application/platform is licensed to you
                        on a limited, non-exclusive, non-transferable,
                        non-sublicensable basis, solely to be used in connection
                        with the service for your private, personal,
                        non-commercial use, subject to all the terms and
                        conditions of this Agreement as they apply to the
                        service.
                      </li>
                      <li className="text-black font-robotoserif  mt-2">
                        Eaidex users remain responsible for complying with all
                        applicable laws, regulations, or restrictions on items,
                        services, or manner of sale, payment, or exchange that
                        may apply to transactions in which they participate. We
                        encourage you to research the applicable laws and
                        regulations that may apply to your transaction.
                      </li>
                    </ul>
                  </div>
                  <div ref={userContentRef}>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      USER CONTENT{" "}
                    </Text>
                    <div>
                      <Text
                        className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                        size="txtRobotoSerifNormalRomanBold13"
                      >
                        CONTENT RESPONSIBILITY
                      </Text>
                      <div>
                        <ul className="ml-8 list-decimal">
                          <li className="text-black font-robotoserif ">
                            Responsibility for Your Content. Users are alone
                            responsible for Your Content and once posted to
                            Eaidex’s platform, it cannot always be withdrawn.
                            You assume all risks associated with Your Content,
                            including anyone's reliance on its quality,
                            accuracy, or reliability, and any risks associated
                            with the personal information you disclose. You
                            represent that you own or have the necessary
                            permissions to use and authorise the use of Your
                            Content as described herein. You may not imply that
                            Your Content is in any way sponsored or endorsed by
                            Eaidex.
                          </li>
                          <li className="text-black font-robotoserif  mt-2">
                            You may expose yourself to liability if, for
                            example, Your Content contains material that is
                            false, intentionally misleading, or defamatory;
                            violates any third-party right, including any
                            copyright, trademark, service mark, patent, trade
                            secret, moral right, privacy right, right of
                            publicity, or any other intellectual property or
                            proprietary right; contains material that is
                            unlawful, including illegal hate speech or
                            pornography; exploits or otherwise harms minors;
                            violates or advocates the violation of any law or
                            regulation, or violates these Terms and Conditions.
                            Eaidex platform permits you to place, add, share
                            content, post comments, feedback, etc., but you are
                            solely responsible for the content posted by you.
                            You represent that you have required permission to
                            use the content.
                          </li>

                          <li className="text-black font-robotoserif  mt-2">
                            Eaidex platform permits you to share content, post,
                            feedback, etc., but you are solely responsible for
                            the content posted by you. You represent that you
                            have required permission to use the content.
                          </li>
                          <li className="text-black font-robotoserif  mt-2">
                            When posting content to the website/application,
                            please do not post content that:
                          </li>
                          <ul className="ml-4 list-disc">
                            <li className="text-black font-robotoserif  mt-2">
                              contains ill-mannered, profane, abusive, racist,
                              or hateful language or expressions, text,
                              photographs, or illustrations that are
                              pornographic or in poor taste, inflammatory
                              attacks of a personal, racial, or religious
                              nature.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              It is defamatory, threatening, disparaging,
                              grossly inflammatory, false, misleading,
                              fraudulent, inaccurate, unfair, and contains
                              exaggeration or unsubstantiated claims.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              Violating the privacy rights of any third party is
                              unreasonably harmful or offensive to any
                              individual or community.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              Discriminates on the grounds of race, religion,
                              national origin, gender, age, marital status,
                              sexual orientation, or disability, or refers to
                              such matters in any manner prohibited by law.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              Violates or inappropriately encourages the
                              violation of any municipal, state, domestic or
                              international law, rule, regulation, or ordinance.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              Uses or attempts to use another's account,
                              password, service, or system except as expressly
                              permitted by the Terms of Use uploads or transmits
                              viruses or other harmful, disruptive, or
                              destructive files.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              Sends repeated messages related to another user
                              and/or makes derogatory or offensive comments
                              about another individual or repeats prior posting
                              of the same message under multiple emails or
                              subjects.
                            </li>
                            <li className="text-black font-robotoserif  mt-2">
                              Any submitted content that includes, but is not
                              limited to, the following, will be refused. If
                              repeated violations occur, we reserve the right to
                              cancel user access to the
                              website/application/platform without advanced
                              notice.
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      HYPERLINKING TO OUR CONTENT{" "}
                    </Text>

                    <div>
                      <ul className="ml-4 list-decimal">
                        <li className="text-black font-robotoserif  ">
                          Without prior written approval, the following
                          organisations may link to our site:
                        </li>
                        <ul className="ml-4 list-disc">
                          <li className="text-black font-robotoserif  ">
                            Search engines.{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            News organisations.{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            Government agencies.
                          </li>
                          <li className="text-black font-robotoserif  ">
                            Online directory distributors, when they list us in
                            the directory, may link to our website/application
                            in the same manner as they hyperlink to the
                            websites/applications of other listed businesses and{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            Systemwide Accredited Businesses except soliciting
                            non-profit organisations.
                          </li>
                        </ul>
                        <li className="text-black font-robotoserif  ">
                          We allow these links if the link (a) is not
                          misleading; (b) does not falsely imply sponsorship,
                          endorsement, or approval of the linking party or its
                          products or services; and (c) is appropriate to the
                          linking party's site.
                        </li>
                        <li className="text-black font-robotoserif  ">
                          We may consider and approve, in our sole discretion,
                          other link requests from the following types of
                          organisations:
                        </li>
                        <ul className="ml-4 list-disc">
                          <li className="text-black font-robotoserif  ">
                            Commonly-known consumer and business information
                            sources such as Chambers of Commerce and Consumers
                            Union.{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            dot.com community sites.{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            Associations or other groups representing charities,
                            including charity-giving sites{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            online directory distributors.{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            Internet portals.{" "}
                          </li>
                          <li className="text-black font-robotoserif  ">
                            Accounting, law, and consulting firms whose primary
                            clients are businesses; and educational institutions
                            and trade associations.
                          </li>
                        </ul>
                      </ul>
                    </div>
                    <Text
                      className="leading-[25.00px] my-0.5 text-black text-justify text-xs tracking-[0.12px] w-full font-normal font-robotoserif"
                      size="txtRobotoSerifRegular12"
                    >
                      If you are one of the organisations listed in paragraph 2
                      and would like to link to our website/application, please
                      contact us at info@eaidex.com.
                    </Text>
                  </div>
                  <div ref={ownershipRef}>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      OWNERSHIP
                    </Text>
                    <Text
                      className="leading-[25.00px] my-0.5 text-black text-justify text-xs tracking-[0.12px] font-normal font-robotoserif"
                      size="txtRobotoSerifRegular12"
                    >
                      As between you and Eaidex, you own Your Content. We own
                      the Eaidex Content, including but not limited to visual
                      interfaces, interactive features, graphics, design,
                      compilation (including, but not limited to, our selection,
                      coordination, aggregation, and arrangement of User Content
                      and other Service Content), computer code, products,
                      software, aggregate star ratings, and all other elements
                      and components of the service excluding Your Content, User
                      Content and Third-Party Content. We also own the
                      copyrights, trademarks, service marks, trade names, trade
                      secrets, and other intellectual and proprietary rights
                      throughout the world associated with the Eaidex Content
                      and the Service, which are protected by copyright, patent,
                      trademark, and trade secret laws and all other applicable
                      intellectual and proprietary rights and laws. As such, you
                      may not sell, license, copy, publish, modify, reproduce,
                      distribute, create derivative works or adaptations of,
                      publicly display or in any way use or exploit any of the
                      Eaidex Content in whole or in part except as expressly
                      authorised by us. Except as expressly and unambiguously
                      provided herein, we do not grant you any express or
                      implied rights, and all the content rights of Eaidex’s
                      website/applications/platforms are retained by Eaidex
                      Services Pvt Ltd.
                    </Text>
                  </div>

                  <div ref={advertisingRef}>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      ADVERTISING
                    </Text>
                    <Text
                      className="leading-[25.00px] my-0.5 text-black text-justify text-xs tracking-[0.12px]  font-normal font-robotoserif"
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex and its licensees may publicly display
                      advertisements, paid content, and other information nearby
                      or in association with Your Content. You are not entitled
                      to any compensation for such advertisements. The manner,
                      mode, and extent of such advertising are subject to change
                      without specific notice to you.
                    </Text>
                    <span>
                      List of services for sale and services the advertisement
                      of which is not List of services for sale and services the
                      advertisement of which is not permitted on Eaidex:
                    </span>
                    <div>
                      <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                        <li className=" ">
                          Related to or obscene material or child pornography.
                        </li>
                        <li className=" ">
                          Related to or offer or solicitation of illegal
                          prostitution and/or sex trafficking.{" "}
                        </li>
                        <li className=" ">
                          Related to or weapons and related items, including but
                          not limited to firearms, disguised, undetectable, or
                          switchblade knives, martial arts weapons, scopes,
                          silencers, ammunition, ammunition magazines, BB guns,
                          tear gas, or stun guns.{" "}
                        </li>
                        <li className=" ">
                          Related to or obscene material or child pornography.
                        </li>
                        <li className=" ">
                          Related to or fireworks, including but not limited to
                          "safe and sane" fireworks or any destructive devices
                          or explosives.{" "}
                        </li>
                        <li className=" ">
                          Related to or controlled substances or illegal drugs,
                          substances and items used to manufacture controlled
                          substances and drug paraphernalia.{" "}
                        </li>
                        <li className=" ">
                          Related to or Alcohol or tobacco products.{" "}
                        </li>
                        <li className=" ">
                          Related to or prescription drugs and medical devices,
                          including but not limited to prescription or contact
                          lenses, defibrillators, hypodermic needles, or hearing
                          aids.{" "}
                        </li>
                        <li className=" ">
                          Related to or blood, bodily fluids, or body parts.{" "}
                        </li>
                        <li className="">
                          Related to or pet animal parts, blood, or fluids.
                        </li>
                        <li className="">Issue of a warning to you.</li>
                        <li className=" ">
                          Related to or restricted or regulated plants and
                          insects, including but not limited to noxious weeds,
                          endangered plant species, or live insects or pests.
                        </li>
                        <li className="  ">
                          Related to or pesticides or hazardous substances, or
                          items containing hazardous substances, including but
                          not limited to contaminated toys or art or craft
                          material containing toxic substances without a warning
                          label.
                        </li>
                        <li className=" ">
                          Related to or Illegal telecommunications equipment,
                          including but not limited to access cards, password
                          sniffers, access card programmers and or cable
                          descramblers
                        </li>
                        <li className=" ">
                          Related to or stolen property or property with serial
                          number removed or altered.
                        </li>
                        <li className=" ">
                          Related to or burglary tools, including but not
                          limited to lock-picks or motor vehicle master keys.
                        </li>
                        <li className=" ">
                          Related to or false identification cards, items with
                          police insignia, citizenship documents, or birth
                          certificates.
                        </li>
                        <li className=" ">
                          Related to or counterfeit currency, coins and stamps,
                          tickets, as well as equipment designed to make them.
                        </li>{" "}
                        <li className=" ">
                          Related to or counterfeit, replica, or knock-off brand
                          name goods.
                        </li>{" "}
                        <li className=" ">
                          Related to or material that infringes copyright,
                          including but not limited to software or other digital
                          goods you are not authorized to sell, warez, bootlegs
                          (without consent of the band).
                        </li>{" "}
                        <li className=" ">
                          Related to or airline tickets that restrict transfer,
                          and tickets of any kind that you are not authorized to
                          sell.
                        </li>{" "}
                        <li className=" ">
                          Related to or coupons or gift cards that restrict
                          transfer and coupons or gift cards that you are not
                          authorised to sell.
                        </li>{" "}
                        <li className=" ">
                          Related to or gambling items, including but not
                          limited to lottery tickets, sports trading card 'grab
                          bags,' raffle tickets, sweepstakes entries, or slot
                          machines.
                        </li>
                        <li className=" ">
                          Related to or used or rebuilt batteries or batteries
                          containing mercury.
                        </li>
                        <li className=" ">
                          Related to or used bedding and clothing unless
                          sanitised in accordance with the law.
                        </li>
                        <li className=" ">
                          Related to or cribs with drop-down sides.
                        </li>
                        <li className=" ">
                          Related to or non-packaged food items or adulterated
                          food.{" "}
                        </li>
                        <li className=" ">
                          Related to or bulk email or mailing lists that contain
                          names, addresses, phone numbers, or other
                          personally-identifying information.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div ref={featuredAdsRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      FEATURED ADS
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex may offer a service known as "Featured Ads," where
                      users may pay a non-refundable fee to have their ads
                      posted in selected locations on the website/application,
                      thus potentially increasing an ad's visibility. To
                      purchase a Featured Ad, you may be required to transmit
                      certain information through a third-party service
                      provider, which may be governed by its own terms of use
                      and other policies. Eaidex makes no representation or
                      guarantee as to the safety or security of the information
                      transmitted to any Third-Party service provider, and
                      you're linking to any Third-Party service is completely at
                      your own risk, and Eaidex disclaims all liability related
                      thereto.
                    </Text>
                  </div>
                  <div>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      LINKS AND ADVERTISEMENTS
                    </Text>

                    <div>
                      <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                        <li className=" ">
                          Eaidex does not review all the sites linked to this
                          site and are not responsible for the content or
                          accuracy of any off-site pages or any other sites
                          linked to this site (including, without limitation,
                          sites linked through advertisements or through any
                          search engines).{" "}
                        </li>
                        <li className=" ">
                          Some links which appear on the site are automatically
                          generated and may be offensive or inappropriate to
                          some people. The inclusion of any link does not imply
                          that we endorse the linked site and you use the links
                          at your own risk.
                        </li>
                        <li className=" ">
                          Users correspondence or dealings with, or
                          participation in promotions of, advertisers on the
                          site are solely between you and such advertisers.
                        </li>
                        <li className=" ">
                          Eaidex will not be liable or responsible for any loss
                          or damage of any sort incurred as the result of any
                          such dealings or as the result of the presence of
                          advertisements on the site.
                        </li>
                        <li className=" ">
                          Users acknowledge that Eaidex shall promote,
                          distribute, and advertise this site to a wide variety
                          of users in the public domain, including directly
                          related and unrelated business networks, corporates,
                          and other groups and that such advertising,
                          distribution, or publicity shall include any
                          information provided by you on this site, and you
                          agree that Eaidex shall not be liable or responsible
                          for any loss or damage of any sort incurred as the
                          result of such promotion.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <Text
                      className="mt-[5px] text-[13px] text-black uppercase font-bold font-robotoserif"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      THE USE OF THE WEBSITE/APPLICATION IS AT YOUR RISK{" "}
                    </Text>

                    <div>
                      <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                        <li className=" ">
                          You use the site at your risk. You must evaluate and
                          bear all risks associated with the use of any content,
                          including reliance on the accuracy, completeness, or
                          usefulness of any content. You shall seek your own
                          independent advice with respect to any content.
                        </li>
                        <li className=" ">
                          Eaidex endeavour is to provide a convenient and
                          functional website/application, but we do not
                          guarantee that the content will be error-free or that
                          the site or the server that operates it is free of
                          viruses or other harmful components.
                        </li>
                        <li className=" ">
                          Although Eaidex takes reasonable endeavours to
                          maintain the content, we do not undertake to provide
                          support or maintenance services for the content. We
                          urge you to keep backup copies of the content you post
                          to, maintain, or use with the website/application.
                        </li>
                        <li className=" ">
                          If your use of the site results in the need for
                          servicing or replacing property, material, equipment,
                          or data, we will not be responsible for such costs.
                        </li>
                        <li className=" ">
                          Eaidex strive to ensure 24-hour access to the
                          website/application, however Eaidex does not hold any
                          responsibility for kind of losses incurred if the
                          website/application is unavailable or not functioning.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div ref={endUserLicenseRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      END USER LICENSE{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex grants the User a limited, non-exclusive,
                      non-transferable, and revocable license to use the Eaidex
                      software and applications, subject to compliance with the
                      Terms and Conditions outlined in this agreement. The
                      license is solely for personal, non-commercial use.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      App Stores{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      The User acknowledges that the distribution platforms (App
                      Stores) from which they obtain the Eaidex software and
                      applications (e.g., Apple App Store, Google Play Store)
                      have their terms and conditions. The User agrees to comply
                      with the applicable App Store terms, and this agreement
                      does not modify any terms between the User and the App
                      Store provider.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Apple-Enabled Software{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      If the User obtains Eaidex application through the Apple
                      App Store, the following additional terms apply: The User
                      acknowledges that Apple is not responsible for providing
                      support, and Apple has no warranty obligations regarding
                      the Eaidex software. However, Eaidex's End User License
                      Agreement is intended to benefit the User and is
                      enforceable by Eaidex.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Google-Sourced Software{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      If the User obtains Eaidex application through the Google
                      Play Store, the following additional terms apply: The User
                      acknowledges and agrees to comply with Google's terms and
                      conditions. Google is not a party to this agreement and
                      has no obligations or liabilities to the User under this
                      agreement.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Special Notice for International Use; Export Controls{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      The User acknowledges that the Eaidex software may be
                      subject to export controls and agrees to comply with all
                      applicable export laws and regulations. The User is solely
                      responsible for ensuring compliance with these laws when
                      using Eaidex outside the country of purchasing the
                      subscription.
                    </Text>
                  </div>

                  <div ref={mobileServicesRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      MOBILE SERVICES{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex provides mobile services, including access to the
                      Eaidex platform via mobile applications. The User
                      acknowledges and agrees that standard carrier charges may
                      apply for mobile services, and Eaidex is not responsible
                      for any such charges.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Telephonic Communications Services{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      In using telephonic communication services provided by
                      Eaidex, the User agrees to comply with applicable laws and
                      regulations. The User acknowledges that Eaidex may monitor
                      and record telephonic communications for quality
                      assurance, training, or other purposes permitted by law.
                    </Text>
                  </div>

                  <div ref={reservationOfRightsRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      RESERVATION OF RIGHTS{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex reserve all of our rights, including but not
                      limited to any and all copyrights, trademarks, patents,
                      trade secrets, and any other proprietary rights that we
                      may have in our website/application, its content, and the
                      goods and services that may be provided. The use of our
                      rights and property requires our prior written consent. We
                      are not providing you with any implied or express licenses
                      or rights by making services available to you, and you
                      will have no right to make any commercial use of our
                      website/application without our prior written consent.
                    </Text>
                  </div>

                  <div ref={copyrightInfringementRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      NOTIFICATION OF COPYRIGHT INFRINGEMENT{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      If you believe that your property has been used in any way
                      that would be considered copyright infringement or a
                      violation of your intellectual property rights, our
                      copyright agent may be contacted at the following address:
                      privacy@eaidex.com
                    </Text>
                  </div>

                  <div ref={NoWARRANTIERef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      NO WARRANTIES AND GURANTEES NoWARRANTIERef
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      You agree that your use of Eaidex’s
                      website/application/platform is at your sole and exclusive
                      risk and that the access provided to the platform by us
                      are on an "As Is" basis. We hereby expressly disclaim any
                      express or implied warranties of any kind, including but
                      not limited to the implied warranty of fitness for a
                      particular purpose and the implied warranty of
                      merchantability. You agree that Eaidex does not guarantee
                      any enquires or leads to be converted into benefits for
                      either Service Providers or Service Seekers. We make no
                      warranties that Eaidex’s website/application/platform or
                      its affiliates shall meet your needs or that the
                      website/application will be uninterrupted, error-free, or
                      secure. We also make no warranties as to the reliability
                      or accuracy of any information on the Eaidex’s
                      website/application. You agree that any damage that may
                      occur to you through your computer system or because of
                      the loss of your data from your use of Eaidex’s
                      website/application/platform is your sole responsibility
                      and that Eaidex is not liable for any such damage or loss.
                    </Text>
                  </div>

                  <div ref={liabilityLimitationsRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      LIABILITY LIMITATIONS{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      The user (Service Provide and Service Seeker) agrees not
                      to hold Eaidex responsible for things other users post or
                      do. As most of the content on Eaidex.com comes from other
                      users, Eaidex does not guarantee the accuracy of postings,
                      user communications, or the quality, safety, or legality
                      of what's offered. Continuous or secure access to Eaidex's
                      platform cannot be guaranteed. To the extent legally
                      permitted, Eaidex expressly disclaims all warranties,
                      representations, and conditions, express or implied,
                      including those of quality, merchantability, merchantable
                      quality, durability, fitness for a particular purpose, and
                      those arising by statute. Eaidex is not liable for any
                      loss, whether of money (including profit), goodwill, or
                      reputation, or any special, indirect, or consequential
                      damages arising from the usage of the Eaidex.com website
                      or its application, even if Eaidex has been advised or
                      could have reasonably foreseen the possibility of any such
                      damage occurring. Some jurisdictions do not allow the
                      disclaimer of warranties or exclusion of damages, so such
                      disclaimers and exclusions may not apply to the user.
                    </Text>
                  </div>

                  <div ref={exclusionRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      EXCLUSION OF LIABILITY{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      You understand and agree that Eaidex,
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      (A) do not guarantee the accuracy, completeness, validity,
                      or timeliness of information listed by us or any third
                      parties, and (B) shall not be responsible for any
                      materials posted by us or any third party. You shall use
                      your judgment, caution, and common sense in evaluating any
                      prospective methods or offers and any information provided
                      by us or any third party. Further, Eaidex shall not be
                      liable for direct, indirect consequential, or any other
                      form of loss or damage that may be suffered by a user
                      using the Eaidex’s website/application/platform, including
                      loss of data or information or any kind of financial or
                      physical loss or damage. In no event shall Eaidex, nor its
                      owner, directors, employees, partners, agents, suppliers,
                      or affiliates, be accountable for any indirect,
                      incidental, special, eventful, or exemplary costs,
                      including without limitation, loss of proceeds, figures,
                      usage, goodwill, or other intangible losses, consequential
                      from (i) your use or access of or failure to access or use
                      the service; (ii) any conduct or content of any third
                      party on the service; (iii) any content attained from the
                      service; and (iv) unlawful access, use or alteration of
                      your transmissions or content, whether or not based on
                      guarantee, agreement, domestic wrong (including
                      carelessness) or any other lawful concept, whether or not
                      we've been aware of the possibility of such damage, and
                      even if a cure set forth herein is originated to have
                      futile of its important purpose.{" "}
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      MISCELLANEOUS INFORMATION{" "}
                    </Text>
                    <div>
                      <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                        <li className="text-black  font-xs mt-2 ">
                          In the event that this Term and Condition conflicts
                          with any law under which any provision may be held
                          invalid by a court with jurisdiction over the parties,
                          such provision will be interpreted to reflect the
                          original intentions of the parties in accordance with
                          applicable law, and the remainder of this Terms and
                          Conditions will remain valid and intact;
                        </li>
                        <li>
                          You agree that without regard to any statue or
                          contrary law that any claim or cause arising out of
                          this website/application must be filed within 15 days
                          after such claim or cause arose or the claim shall be
                          forever barred;
                        </li>
                        <li>
                          Eaidex may assign our rights and obligations under
                          this Terms and Conditions, and we shall be relieved of
                          any further obligation.
                        </li>
                        <li>
                          This website/application is an interactive online
                          classifieds service that enables access to many users
                          and should not be seen as the publisher of any
                          information provided by another information content
                          provider.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div ref={intellectualPropertyRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      INTELLECTUAL PROPERTY{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      You agree that the website/application/platform and its
                      affiliates are the property of Eaidex Services Pvt Ltd,
                      including all copyrights, trademarks, trade secrets,
                      patents, and other intellectual property ("Our IP"). You
                      agree that we own all rights, title, and interest in and
                      to our IP and that you will not use our IP for any
                      unlawful or infringing purpose. You agree not to reproduce
                      or distribute our IP in any way, including electronically
                      or via registration of any new trademarks, trade names,
                      service marks, or Uniform Resource Locators (URLs),
                      without express written permission from us.
                    </Text>
                  </div>

                  <div ref={indemnificationRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      INDEMNIFICATION{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px]"
                      size="txtRobotoSerifRegular12"
                    >
                      To the maximum extent permitted by law, you agree to
                      indemnify, defend and hold harmless - Eaidex Services Pvt
                      Ltd and its affiliates and/or related entities, whether
                      direct or indirect, current, former or future, and their
                      respective current, former, or future officers, directors,
                      employees, agents, successors and related third parties
                      (each an “Indemnified Party”), for any claims, causes of
                      action, debts, damages, losses, costs, liabilities and
                      expenses (including reasonable attorneys’ fees) relating
                      to or arising out of any third-party claim that (a) your
                      use of or inability to use the Services, (b) any user
                      postings made by you, (c) your violation of any terms of
                      this Agreement or your violation of any rights of a
                      third-party, or (d) your violation of any applicable laws,
                      rules or regulations, except to the extent caused by any
                      unlawful or negligent act or omission by Eaidex Services
                      Pvt Ltd. Eaidex Services Pvt Ltd reserves the right, at
                      its own cost, to assume the exclusive defence and control
                      of any matter otherwise subject to indemnification by you,
                      in which event you will fully cooperate with Eaidex
                      Services Pvt Ltd in asserting any available defences. An
                      Indemnified Party may participate in the defence by a
                      lawyer of its own choosing, at its own cost and expense.
                      You shall not settle any claim that adversely affects an
                      Indemnified Party or imposes any obligation or liability
                      on an Indemnified Party without the Indemnified Party’s
                      prior written consent.
                    </Text>
                  </div>

                  <div ref={modificationVariationRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      MODIFICATION & VARIATION{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      We may, from time to time and at any time without notice
                      to you, modify this Terms and Condition. You agree that we
                      have the right to modify this Terms and Condition or
                      revise anything contained herein. You further agree that
                      all modifications to this Terms and Conditions are in full
                      force and effect immediately upon posting on the
                      website/application and that modifications or variations
                      will replace any prior version of this Terms and Condition
                      unless prior versions are specifically referred to or
                      incorporated into the latest modification or variation of
                      this Terms and Condition.
                    </Text>
                  </div>
                  <div ref={limitationOnLiabilityRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      LIMITATION ON LIABILITY{" "}
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex is not liable for any damages that may occur to you
                      because of your use of its website/application/platform to
                      the fullest extent permitted by law. This section applies
                      to any claims by you, including, but not limited to, lost
                      profits or revenues, consequential or punitive damages,
                      negligence, strict liability, fraud, or torts of any kind.
                    </Text>
                  </div>

                  <div ref={disputeResolutionRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      DISPUTE RESOLUTION{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      This Arbitration clause in this Terms and Condition
                      governs the resolution of disputes between the USER’s
                      (Service Provider and Service Seeker) and Eaidex Services
                      Pvt Ltd, a company located in the State of Kerala, India.
                      Both parties agree to submit to the terms and conditions
                      outlined in this agreement for the fair and efficient
                      resolution of any disagreements.{" "}
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Pre-Arbitration Dispute Resolution{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Before initiating arbitration proceedings, the parties
                      commit to engaging in good-faith negotiations or
                      alternative dispute resolution mechanisms. This
                      pre-arbitration stage encourages open communication and
                      collaborative efforts to reach a mutually satisfactory
                      resolution.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Arbitration Rules and Forum{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Arbitration proceedings will adhere to the Indian
                      Arbitration and Conciliation (Amendment) Act of 2021. The
                      designated forum for arbitration shall be determined
                      through mutual agreement, ensuring a fair and neutral
                      venue for the resolution of disputes.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Authority of Arbitrator{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      In the event that any disputes, differences, claims and
                      questions whatsoever arising out of or in connection with
                      or incidental to or touching this Terms and Condition or
                      the construction or application thereof or any clauses or
                      thing herein contained or in respect of any account and
                      the duties, responsibilities and obligations of either
                      party hereunder or as to any act or omission of any party
                      or as to any other matter relating to the rights, duties
                      and liabilities of either party under these presents shall
                      be resolved and settled by Arbitration, and administered
                      electronically, in accordance with the Arbitration and
                      Conciliation (Amendment) Act, 2021 or any statutory
                      modification or re-enactment thereof for the time being in
                      force.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Waiver of Judicial Trial{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      By opting for arbitration, both the User’s (Service
                      Providers and Service Seekers) and Eaidex Services Pvt Ltd
                      voluntarily waive their right to pursue disputes through
                      traditional judicial trials, emphasising the commitment to
                      resolving matters through the agreed-upon arbitration
                      process unless otherwise stipulated in this agreement.
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Waiver of Class or Other Non-Individualized Relief{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      The parties expressly waive any entitlement to participate
                      in class or collective actions or seek non-individualized
                      relief in the arbitration process or any other forum. This
                      provision reinforces the focus on individualised dispute
                      resolution.
                    </Text>
                  </div>

                  <div ref={governingLawRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      GOVERNING LAW AND JURISDICTION{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      This website/application originates from Kerala, India.
                      The laws of India, without considering conflicts of law
                      principles, will govern these terms, unless stated
                      otherwise. User’s (Service Providers and Service Seekers)
                      hereby agree that any disputes arising from or related to
                      the aforementioned terms and conditions shall be
                      exclusively submitted to the jurisdiction of the courts in
                      Ernakulam. By using Eaidex’s website
                      (eaidex.com)/application (Eaidex), you consent to the
                      jurisdiction and venue of these courts in connection with
                      any legal action, suit, proceeding, or claim arising from
                      or related to these terms.
                    </Text>
                  </div>

                  <div ref={severabilityRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      SEVERABILITY{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px]"
                      size="txtRobotoSerifRegular12"
                    >
                      If any provision of these Terms and conditions is
                      determined to be unlawful, void, or unenforceable, such
                      provision shall nonetheless be enforceable to the fullest
                      extent permitted by applicable law, and the unenforceable
                      portion shall be deemed to be severed from these Terms and
                      conditions, such determination shall not affect the
                      validity and enforceability of any other remaining
                      provisions.
                    </Text>
                  </div>
                  <div ref={terminationOfServicesRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      TERMINATION OF SERVICES{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif  tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex Services Pvt Ltd. reserves the right to terminate
                      or suspend services at any time without prior notice. In
                      such cases, subscription fees for the remaining period
                      will not be refunded.
                    </Text>
                  </div>
                  <div ref={contactingUsRef}>
                    <Text
                      className=" text-[13px] text-black underline uppercase  font-robotoserif font-bold mb-2"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      CONTACTING US{" "}
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black font-robotoserif text-justify text-xs tracking-[0.12px]"
                      size="txtRobotoSerifRegular12"
                    >
                      If you would like to contact us to understand more about
                      terms or wish to contact us concerning any matter, you may
                      do so via the contact us or email us at info@eaidex.com
                      This document was last updated on January 1st, 2024.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menubar
        sections={sections}
        visible={sidebarVisible}
        onClose={handleToggleSidebar}
        close={handleClose}
      />
    </>
  );
}

export default TermsConditions;
