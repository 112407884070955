import { useGeneralSettings } from "../API/api";

export const useCompleteImageUrl = () => {
  const { data: generalData } = useGeneralSettings();
  const getCompleteImageUrl = (imageUrl: string) => {
    if (!generalData || !generalData.configurations) return imageUrl;
    const baseUrl = generalData.configurations.RESOURCES_BASE_URL;
    const sasToken = generalData.configurations.SAS_TOKEN_READONLY;
    return `${baseUrl}${imageUrl}${sasToken}`;
  };
  return getCompleteImageUrl;
};

export const useReferralLink = () => {
  const { data: generalData } = useGeneralSettings();
  const getReferralLink = (code: string) => {
    if (!generalData || !generalData.configurations) return "";
    const referralLinkFormat =
      generalData.configurations.REFERRAL_INVITATION_LINK;
    return referralLinkFormat.replace("{code}", code);
  };
  return getReferralLink;
};
