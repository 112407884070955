import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "@material-tailwind/react";
import {
  businessRegisterSchema,
  daysOfWeek,
  generateTimeOptions,
  WorkingDay,
} from "../../schema/businessRegisterSchema";
import { toast } from "react-toastify";

export type BusinessDataDto = {
  businessName: string;
  gstin: string;
  workingDays: WorkingDay[];
  openingTime: string;
  closingTime: string;
};

interface BusinessModalProps {
  isOpen: boolean;
  onConfirm: (details: BusinessDataDto) => void;
  onCancel: () => void;
  selectedSkills: any;
}

const timeOptions = generateTimeOptions();

const BusinessDetailsModal: React.FC<BusinessModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  selectedSkills,
}) => {
  const {
    register,
    handleSubmit: businessFormSubmission,

    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm<BusinessDataDto>({
    resolver: yupResolver(businessRegisterSchema),
    mode: "onBlur",
    defaultValues: {
      workingDays: [],
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBusinessRegister = async (data: BusinessDataDto) => {
    setIsSubmitting(true);
    try {
      onConfirm(data);
      toast.success("Service added successfully.");
    } catch (error) {
      toast.error("An error occurred during submission");
    } finally {
      setIsSubmitting(false);
    }
  };

  // TOGGLE ALL DAYS LOGIC
  const toggleAllDays = () => {
    const currentDays = getValues("workingDays");
    const allDaysSelected = currentDays?.length === daysOfWeek?.length;
    const newDays = allDaysSelected ? [] : daysOfWeek;
    setValue("workingDays", newDays, { shouldValidate: true });
    trigger("workingDays");
  };

  // HANDLE INDIVIDUAL DAY SELECTION
  const handleDaySelection = (day: WorkingDay, isChecked: boolean) => {
    const currentDays = getValues("workingDays");
    const updatedDays = isChecked
      ? [...currentDays, day]
      : currentDays.filter((d) => d !== day);
    setValue("workingDays", updatedDays, { shouldValidate: true });
    trigger("workingDays");
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <Dialog open={isOpen} handler={onCancel}>
      <div className="p-6 bg-white rounded-lg shadow-xl max-w-xl mx-auto mt-10">
        <h2 className="text-2xl font-bold mb-6">
          Business Registration
          {selectedSkills.length > 0 && (
            <> ({selectedSkills.map((skill: any) => skill?.name).join(", ")})</>
          )}
        </h2>

        <form onSubmit={businessFormSubmission(handleBusinessRegister)}>
          {/* Business Name */}
          <div className="mb-4">
            <label
              htmlFor="businessName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Business Name
            </label>
            <input
              id="businessName"
              {...register("businessName")}
              type="text"
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
            {errors.businessName && (
              <p className="text-red-600 text-sm">
                {errors.businessName.message}
              </p>
            )}
          </div>

          {/* GSTIN */}
          <div className="mb-4">
            <label
              htmlFor="gstin"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              GSTIN
            </label>
            <input
              id="gstin"
              {...register("gstin")}
              type="text"
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
            {errors.gstin && (
              <p className="text-red-600 text-sm">{errors.gstin.message}</p>
            )}
          </div>

          {/* Working Days */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Working Days
            </label>
            <div className="flex flex-wrap gap-4 mb-4">
              {daysOfWeek.map((day) => (
                <label key={day} className="inline-flex items-center">
                  <Controller
                    name="workingDays"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        checked={field.value.includes(day)}
                        onChange={(e) =>
                          handleDaySelection(day, e.target.checked)
                        }
                        className="form-check h-5 w-5 text-blue-600"
                      />
                    )}
                  />
                  <span className="ml-2 text-gray-700">{day}</span>
                </label>
              ))}
            </div>

            <label className="inline-flex items-center cursor-pointer">
              <input
                checked={
                  getValues("workingDays")?.length === daysOfWeek?.length
                }
                onChange={toggleAllDays}
                type="checkbox"
                value=""
                className="sr-only peer"
              />
              <div className="relative w-9 h-5 bg-gray-400  rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">
                All Day
              </span>
            </label>

            {errors.workingDays && (
              <p className="text-red-600 text-sm">
                {errors.workingDays.message}
              </p>
            )}
          </div>

          {/* Opening and Closing Time */}
          <div className="flex mb-4 mt-3">
            <div className="w-1/2 mr-2">
              <label
                htmlFor="openingTime"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Opening Time
              </label>
              <Controller
                name="openingTime"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  >
                    <option value="">Select time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                )}
              />
              {errors.openingTime && (
                <p className="text-red-600 text-sm">
                  {errors.openingTime.message}
                </p>
              )}
            </div>

            <div className="w-1/2 ml-2">
              <label
                htmlFor="closingTime"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Closing Time
              </label>
              <Controller
                name="closingTime"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  >
                    <option value="">Select time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                )}
              />
              {errors.closingTime && (
                <p className="text-red-600 text-sm">
                  {errors.closingTime.message}
                </p>
              )}
            </div>
          </div>

          {/* Modal Actions */}
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none mr-2"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default BusinessDetailsModal;
