import { useRef, useState } from "react";
import Text from "../component/Text/Text";
import Menubar from "../component/Menubar";

function AcceptablePolicy() {
  const scrolldivRef: any = useRef();
  const ProhibitedRef = useRef(null);
  const introductionRef = useRef(null);
  const contentRef = useRef(null);
  const agreeRef = useRef(null);
  const contributionRef = useRef(null);
  const terminationRef = useRef(null);
  const minorsRef = useRef(null);
  const contributionNotRef = useRef(null);
  const sections = [
    { label: "Introduction", ref: introductionRef },
    { label: "Prohibited Uses", ref: ProhibitedRef },
    { label: "You also agree", ref: agreeRef },
    { label: "Content standards", ref: contentRef },
    { label: "Contributions Must:", ref: contributionRef },
    { label: "Contributions Must Not:", ref: contributionNotRef },
    { label: "Minors", ref: minorsRef },
    { label: "Suspension & Termination", ref: terminationRef }
  ];

  const fixedHeaderHeight = 140;

  const handleScrollToScope = (ref: any) => {
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;

      if (scrolldivRef) {
        scrolldivRef?.current.scroll({
          top: elementTop,
          behavior: "smooth"
        });
      }
    }
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggleSidebar = (ref: React.RefObject<any>) => {
    setSidebarVisible(!sidebarVisible);
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;
      scrolldivRef?.current.scroll({
        top: elementTop,
        behavior: "smooth"
      });
    }
  };
  const handleClose = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <>
      <header className="bg-gray-50   border-gray-200 border-b-2   top-0">
        <div className="  text-black md:text-2xl sm:text-base font-bold ml-[20%] font-Montserrat leading-10">
          Acceptable Use Policy Document
        </div>
      </header>
      <div className="bg-white-600 flex   ">
        <div className="section1-main border-gray-200 border-r-2 h-screen flex-shrink-0 md:w-[20%] hidden sm:block pt-1 pr-1">
          <div className="section-sub bg-gray-100 h-screen">
            <ul className="list-disc pl-4 md:pl-14 pt-4 md:pt-16">
              {sections?.map((section, index) => (
                <li
                  key={index}
                  className=" cursor-pointer text-black hover:text-black text-xs font-normal font-robotoserif leading-none mb-3 "
                  onClick={() => handleScrollToScope(section.ref)}
                >
                  {section.label}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="section2-Main md:w-[80%] w-[100%]  pl-1 pt-1 mt-0">
          <div
            className="section2-sub bg-gray-100 p-3  overflow-scroll no-scrollbar scroll-smooth  h-screen "
            ref={scrolldivRef}
          >
            <div className="  innerArea bg-white p-4 md:p-10 md:w-10/12 w-full flex-col items-center justify-center">
              <button
                className=" text-white absolute left-0 top-24 md:hidden lg:hidden 2xl:hidden xl:hidden sm:block"
                onClick={() => handleToggleSidebar(scrolldivRef)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 17h18v-2H3v2zM3 11h18V9H3v2zm0-6v2h18V5H3z" />
                </svg>
              </button>
              <div className="font-robotoserif text-black font-normal text-xs ml-4 ">
                <div>
                  <div className=" " ref={introductionRef}>
                    <Text
                      className="text-[14px] mb-3 text-black underline text-center  uppercase"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      EAIDEX SERVICES PVT LTD ACCEPTABLE USE POLICY DOCUMENT
                    </Text>
                    <Text
                      className="text-[14px] mb-4 text-black underline uppercase text-center"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      DATED: 01/01/2024
                    </Text>
                  </div>

                  <div>
                    <Text
                      className=" mb-2 text-black  text-justify leading-6  "
                      size="txtRobotoSerifRegular12"
                    >
                      This acceptable use policy sets out the terms between you
                      (referred as Service provider and Service Seeker) and
                      Eaidex Services Pvt Ltd (hereinafter referred as Eaidex)
                      under which you may access Eaidex’s website/application
                      Eaidex.com/Eaidex. This acceptable use policy applies to
                      all users of, and visitors to, Eaidex’s platform.
                    </Text>
                    <Text
                      className=" mb-2 text-black text-justify text-xs leading-6  "
                      size="txtRobotoSerifRegular12"
                    >
                      Your use of Eaidex’s website/application means that you
                      accept and agree to abide by all the policies in this
                      acceptable use policy, which supplement Eaidex’s Terms and
                      Conditions.
                    </Text>
                    <Text
                      className=" mb-2 text-black text-justify text-xs leading-6 "
                      size="txtRobotoSerifRegular12"
                    >
                      The website eaidex.com is operated by Eaidex, a Private
                      Limited company registered in the State of Kerala, India.
                    </Text>
                  </div>
                </div>
                <div>
                  <Text
                    className=" mb-2 text-black font-Roboto-Serif text-justify text-xs "
                    size="txtRobotoSerifRegular12"
                  >
                    Every effort is made to keep the website up and running
                    smoothly. However, Eaidex takes no responsibility for, and
                    will not be liable for, the website being temporarily
                    unavailable due to technical issues beyond our control.
                  </Text>

                  <div ref={ProhibitedRef}>
                    <Text
                      className="text-[13px]  mt-4 text-black underline uppercase"
                      size="txtRobotoSerifNormalRomanBold13"
                    >
                      Prohibited Uses
                    </Text>
                    <div>
                      <Text
                        className="leading-[25.00px] mb-1 mt-1 text-black  text-justify  tracking-[0.12px]"
                        size="txtRobotoSerifRegular12"
                      >
                        You may use Eaidex’s website/application only for lawful
                        purposes. You may not use Eaidex’s website/application:
                      </Text>
                      <div>
                        <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                          <li className="text-black   ">
                            In any way that breaches any applicable local,
                            national, or international law or regulation.
                          </li>
                          <li className="  ">
                            In any way that is unlawful or fraudulent or has any
                            unlawful or fraudulent purpose or effect.
                          </li>
                          <li className="  ">
                            For the purpose of harming or attempting to harm
                            minors in any way.
                          </li>
                          <li className="  ">
                            To send, knowingly receive, upload, download, use or
                            re-use any material which does not comply with
                            Eaidex’s content standards below.
                          </li>
                          <li className="  ">
                            To transmit or procure the sending of any
                            unsolicited or unauthorised advertising or
                            promotional material or any other form of similar
                            solicitation (spam).
                          </li>
                          <li className="   ">
                            To knowingly transmit any data, send or upload any
                            material that contains viruses, Trojan horses,
                            worms, time-bombs, keystroke loggers, spyware,
                            adware or any other harmful programs or similar
                            computer code designed to affect the operation of
                            any computer software or hardware adversely.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div ref={agreeRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase underline"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    You also agree
                  </Text>
                  <div>
                    <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                      <li className="text-black font-robotoserif  ">
                        Not to reproduce, duplicate, copy or re-sell any part of
                        Eaidex’s website/application in contravention of the
                        provisions of Eaidex’s terms and Conditions.
                      </li>
                      <li className="text-black font-robotoserif  ">
                        Not to access without authority, interfere with, damage,
                        or disrupt:
                      </li>

                      <ul className="ml-8 list-decimal">
                        <li className="text-black font-robotoserif  ">
                          Any part of Eaidex’s website/application.
                        </li>
                        <li className="text-black font-robotoserif  ">
                          Any equipment or network on which Eaidex’s
                          website/application is stored.
                        </li>
                        <li className="text-black font-robotoserif  ">
                          Any software used in the provision of Eaidex’s
                          website/application.{" "}
                        </li>
                        <li className="text-black font-robotoserif  ">
                          Any equipment, network, or software owned or used by
                          any third party.
                        </li>
                        <li className="text-black font-robotoserif  ">
                          the platforms interactive services and moderation,
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] l"
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex may, from time to time, provide interactive
                      services on its website/application, including, without
                      limitation, chat rooms, forums, and bulletin/message
                      boards (“interactive services”). Wherever Eaidex provides
                      any interactive service, it will provide clear information
                      about the kind of service offered, if it is moderated, and
                      what form of moderation is used (including whether it is
                      human or technical).
                    </Text>
                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex will do its best to assess any possible risks for
                      users from third parties when they use any interactive
                      service provided on its website/application and shall
                      decide in each case whether it is appropriate to use
                      moderation of the relevant service (including what kind of
                      moderation to use) in the light of those risks. However,
                      Eaidex is under no obligation to oversee, monitor or
                      moderate any interactive service provided on the its
                      website/application, and Eaidex expressly exclude its
                      liability for any loss or damage arising from the use of
                      any interactive service by a user in contravention of
                      Eaidex’s content standards, whether the service is
                      moderated or not.
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Further, unless otherwise expressly specified, all the
                      interactive services provided on the Eaidex
                      website/application are not moderated. Eaidex will not be
                      responsible as author, editor or publisher of any
                      contributions (as defined below) submitted to any of its
                      interactive services. Eaidex expressly exclude its
                      liability for any loss or damage arising from the
                      interactive services by any person in contravention of
                      this policy. Eaidex reserve the right to promptly remove
                      or disable access to any contributions which it deems to
                      be potentially defamatory of any person, unlawful or in
                      violation of any third- party rights.
                    </Text>
                  </div>
                </div>
                <div ref={contentRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase underline"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Content standards{" "}
                  </Text>
                  <div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black   text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      These content standards apply to all material that you
                      contribute to Eaidex’s website/applications and to any
                      interactive services associated with it.
                    </Text>

                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      You must comply with the spirit and the letter of the
                      following standards. The standards apply to each part of
                      any contribution as well as to its whole.
                    </Text>
                  </div>
                </div>
                <div ref={contributionRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase underline "
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Contributions must:{" "}
                  </Text>
                  <div>
                    <ul className="ml-8 list-disc leading-6 tracking-tight">
                      <li className="text-black font-robotoserif  ">
                        Be accurate (where they state facts).
                      </li>
                      <li className="text-black font-robotoserif  ">
                        Be genuinely held (where they state opinions).
                      </li>
                      <li className="text-black font-robotoserif  ">
                        Comply with applicable law in India and in any country
                        from which they are posted.
                      </li>
                    </ul>
                  </div>
                </div>

                <div ref={contributionNotRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black underline "
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Contributions must not:{" "}
                  </Text>
                  <div>
                    {/* <ul className="ml-8 list-disc"> */}
                    <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                      <li className=" ">
                        Contain any material which is defamatory to any person.{" "}
                      </li>
                      <li className=" ">
                        Contain any material which is obscene, offensive,
                        hateful, or inflammatory.{" "}
                      </li>
                      <li className="  ">
                        Promote sexually explicit material.
                      </li>
                      <li className=" ">Promote violence.</li>
                      <li className="  ">
                        Promote discrimination based on race, sex, religion,
                        nationality, disability, sexual orientation, or age.{" "}
                      </li>
                      <li className="  ">
                        Infringe any copyright, database right or trademark of
                        any other person.{" "}
                      </li>
                      <li className=" ">Be likely to deceive any person. </li>
                      <li className="  ">
                        Be made in breach of any legal duty owed to a third
                        party, such as a contractual duty or a duty of
                        confidence.{" "}
                      </li>
                      <li className="   ">Promote any illegal activity. </li>
                      <li className=" ">
                        Be threatening, abuse or invade another’s privacy, or
                        cause annoyance, inconvenience, or needless anxiety.{" "}
                      </li>
                      <li className="  ">
                        Be likely to harass, upset, embarrass, alarm, or annoy
                        any other person.{" "}
                      </li>
                      <li className="  ">
                        Be used to impersonate any person or to misrepresent
                        your identity or affiliation with any person.{" "}
                      </li>

                      <li className=" ">
                        Give the impression that they emanate from us if this is
                        not the case.{" "}
                      </li>
                      <li className=" ">
                        Advocate, promote or assist any unlawful act such as (by
                        way of example only) copyright infringement or computer
                        misuse.{" "}
                      </li>
                    </ul>
                  </div>
                </div>

                <div ref={minorsRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase font-bold underline"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Minors{" "}
                  </Text>
                  <div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex’s website/application site is strictly for people
                      over the age of 18, and any person below that age is NOT
                      permitted to use or visit its website/application.
                    </Text>
                  </div>
                </div>

                <div ref={terminationRef}>
                  <Text
                    className="mt-[5px] text-[13px] text-black uppercase font-bold underline"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    SUSPENSION AND TERMINATION
                  </Text>
                  <div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex will determine, at its discretion, whether there
                      has been a breach of this acceptable use policy through
                      your use of Eaidex’s website/application. When a breach of
                      this policy has occurred, Eaidex may take such action as
                      it deems to be appropriate.
                    </Text>
                    <div>
                      <Text
                        className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                        size="txtRobotoSerifRegular12"
                      >
                        Failure to comply with this acceptable use policy
                        constitutes a material breach of Eaidex’s Terms and
                        Conditions upon which you are permitted to use its
                        website/application and may result in Eaidex taking all
                        or any of the following actions:
                      </Text>

                      <div>
                        <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                          <li className=" ">
                            Immediate, temporary or permanent withdrawal of your
                            right to use Eaidex’s website/application{" "}
                          </li>
                          <li className="">
                            Immediate, temporary or permanent removal of any
                            posting or material uploaded by you to Eaidex’s
                            website/application.
                          </li>
                          <li className="">Issue of a warning to you.</li>
                          <li className=" ">
                            Legal proceedings against you for reimbursement of
                            all costs on an indemnity basis (including, but not
                            limited to, reasonable administrative and legal
                            costs) resulting from the breach.{" "}
                          </li>
                          <li className="  ">
                            Disclosure of such information to law enforcement
                            authorities as Eaidex reasonably feel is necessary.
                          </li>
                          <li className=" ">Promote violence.</li>
                        </ul>
                      </div>
                    </div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      Eaidex exclude liability for actions taken in response to
                      breaches of this acceptable use policy. The responses
                      described in this policy are not limited, and Eaidex may
                      take any other action it reasonably deems to be
                      appropriate.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menubar
        sections={sections}
        visible={sidebarVisible}
        onClose={handleToggleSidebar}
        close={handleClose}
      />
    </>
  );
}

export default AcceptablePolicy;
