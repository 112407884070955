type SizeClasses = {
    'txtMontserrat-Regular18': 'font-montserrat text-18 font-normal';
    'txtMontserrat-Regular16': 'font-montserrat text-16 font-normal';
    'txtRobotoSerifNormalRomanBold13': 'font-robotoserif text-13 font-bold';
    'txtMontserratMedium16': 'font-montserrat text-16 font-medium';
    'txtMontserratMedium15': 'font-montserrat text-15 font-medium';
    'txtMontserratBold40': 'font-montserrat text-40 font-bold';
    'txtRobotoSerifRegular12': 'font-robotoserif text-12 font-normal';
    'txtMontserratSemiBold23': 'font-montserrat text-23 font-semibold';
  };
  
  type TextProps = {
    children: React.ReactNode;
    className?: string;
    size?: keyof SizeClasses;
    as?: React.ElementType;
  };
  
  const sizeClasses: SizeClasses = {
    'txtMontserrat-Regular18': 'font-montserrat text-18 font-normal',
    'txtMontserrat-Regular16': 'font-montserrat text-16 font-normal',
    'txtRobotoSerifNormalRomanBold13': 'font-robotoserif text-13 font-bold',
    'txtMontserratMedium16': 'font-montserrat text-16 font-medium',
    'txtMontserratMedium15': 'font-montserrat text-15 font-medium',
    'txtMontserratBold40': 'font-montserrat text-40 font-bold',
    'txtRobotoSerifRegular12': 'font-robotoserif text-12 font-normal',
    'txtMontserratSemiBold23': 'font-montserrat text-23 font-semibold',
  };
  
  const Text: React.FC<TextProps> = ({ children, className = '', size = 'txtMontserrat-Regular16', as: Component = 'p', ...restProps }) => (
    <Component className={`text-left ${className} ${sizeClasses[size]}`} {...restProps}>
      {children}
    </Component>
  );
  
  export default Text;
  