import React, { useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm">
      <div
        ref={modalRef}
        className="bg-white rounded-lg p-8 w-[95%] max-w-[700px] relative z-[1001] shadow-2xl"
      >
        <button className="absolute right-4 top-4 text-black" onClick={onClose}>
          <XMarkIcon className="w-6 h-6" aria-hidden="true" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
