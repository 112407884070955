import React from "react";
import { useTranslation } from "react-i18next";
import { Edit, StarOutline } from "@mui/icons-material";
import { BiTrash } from "react-icons/bi";
import { EditDataIcon, RemoveIcon } from "../../assets/images/AllSvg";

interface EmployerRating {
  rating: number;
  reviewId: number;
  skillId: number;
}

interface Review {
  reviewId: number;
  review: string;
  isAnonymous: string;
  employerRatings: EmployerRating[];
  labourerId: number;
  title: string;
  jobAwardStatus: string;
}

interface Labourer {
  id: number;
  name: string;
  profileUrl: string;
}

interface ReviewListProps {
  reviewData: Review[];
  labourers: Labourer[];
  onEdit: (review: Review) => void;
  onDelete: (reviewId: number) => void;
}

const ReviewList: React.FC<ReviewListProps> = ({
  reviewData,
  labourers,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto">
      <div className="bg-white shadow- rounded-lg ">
        <div className="bg-orange-100 border-b border-orange-200 px-6 py-4">
          <h2 className="text-2xl font-semibold text-orange-600">
            {t("Reviews")}
          </h2>
        </div>
        <div className="overflow-y-auto  max-h-[800px]">
          {reviewData?.map((review) => {
            const matchedLabourer = labourers?.find(
              (labourer) => labourer?.id === review?.labourerId
            );
            if (matchedLabourer) {
              return (
                <div
                  key={review.reviewId}
                  className="p-4 sm:p-6 border-b border-gray-200 relative"
                >
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <div className="flex items-start space-x-4">
                      <img
                        className="h-12 w-12 sm:h-16 sm:w-16 rounded-full object-cover border-2 border-indigo-500"
                        src={
                          matchedLabourer?.profileUrl ||
                          require("../../assets/images/user_default.png")
                        }
                        alt=""
                      />
                      <div>
                        <h2 className="text-lg font-semibold text-gray-800">
                          {t(matchedLabourer?.name)}
                        </h2>
                        <div className="flex items-center mt-1">
                          {[...Array(5)].map((_, i) => (
                            <StarOutline
                              key={i}
                              className={
                                i < 4 ? "text-yellow-400" : "text-gray-300"
                              }
                              fontSize="small"
                            />
                          ))}
                        </div>
                        <div className="mt-1">{t(review?.title)}</div>
                      </div>
                    </div>
                    <div className="absolute top-2 right-2 sm:static flex space-x-1">
                      <button
                        onClick={() => onEdit(review)}
                        aria-label={t("Edit review") as string}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {/* <EditDataIcon /> */}
                      </button>
                      <button
                        onClick={() => onDelete(review?.reviewId)}
                        aria-label={t("Delete review") as string}
                        className="text-red-500 hover:text-red-700"
                      >
                        {/* <RemoveIcon /> */}
                      </button>
                    </div>
                  </div>
                  <p className="mt-4 text-gray-600 font-semibold">
                    {review?.review}
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default ReviewList;
