import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChatRoomDto, useGetChatList, useUserMeQuery } from "../API/api";
import ChatList from "../component/Chats/ChatList";
import ChatMessage from "../component/Chats/ChatMessage";
import { useTranslation } from "react-i18next";

const MessageCenter = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();

  const chatRoomId = location.state?.chatRoomId;

  const { data: usersData } = useUserMeQuery();
  const {
    data: chatList,
    isLoading,
    refetch: refetchChatList,
  } = useGetChatList();

  const [chatRoomInfo, setChatRoomInfo] = useState<ChatRoomDto | null>(null);

  useEffect(() => {
    const chatRoom = chatList?.find((it) => it.chatRoomId == chatRoomId);
    if (chatRoom) {
      setChatRoomInfo(chatRoom);
    }
  }, [chatList]);

  const handleRoomSelection = (item: ChatRoomDto) => {
    setChatRoomInfo(item);
  };
  const updateChatList = async (
    updatedChatRoomId: number,
    newTimestamp: string
  ) => {
    //REFETCH CHAT LIST
    await refetchChatList();

    // UPDATE THE STATE
    setChatRoomInfo((prevInfo) => {
      if (prevInfo && prevInfo.chatRoomId === updatedChatRoomId) {
        return { ...prevInfo, timeStamp: newTimestamp };
      }
      return prevInfo;
    });
  };
  return (
    <div className="bg-white mt-5 rounded-[8px] overflow-y-auto">
      <div className="py-2.5 px-4">
        <span className="text-secondarylight block text-[12px] leading-[16px]">
          {t("Chats")} /
        </span>
        <span className="text-secondary font-medium block text-[22px] leading-[28px]">
          {usersData?.user?.name}
        </span>
      </div>
      <div className="flex border-t-[1px] border-[#E9E8E3] relative">
        <div
          className={`${
            menu ? "left-0" : "left-[-100%] md:left-0"
          } lg:w-[280px] md:relative absolute h-full bg-white md:w-[220px] w-[280px]`}
        >
          <ChatList
            isLoading={isLoading}
            menu={menu}
            setMenu={setMenu}
            chatList={chatList}
            onChatRoomSelected={handleRoomSelection}
            selectedChatRoomId={chatRoomInfo?.chatRoomId ?? 0}
            setChatRoomInfo={setChatRoomInfo}
          />
        </div>
        <ChatMessage
          chatRoomInfo={chatRoomInfo}
          userId={usersData?.user.id ?? 0}
          menu={menu}
          setMenu={setMenu}
          onMessageSent={updateChatList}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default MessageCenter;
