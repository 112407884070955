import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  ChatRoomDto,
  IMessage,
  MessageType,
  jobAwardActionPost,
  useChatMessageQuery,
  useUserMeQuery,
} from "../../API/api";
import { TbMenu2 } from "react-icons/tb";
import { useMutation } from "@tanstack/react-query";
import AwardThisJob from "../Models/AwardThisJob";
import { MessageDto, useWebSockets } from "../../API/useWebSockets";
import CircularProgressBar from "../CircularProgressBar";
import GenericDialog from "../GenericDialog";
import { useTranslation } from "react-i18next";
import { isLabourer } from "../../utils/utils";
import BecomeLabourerModal from "../Models/BecomeLabourerModal";

const ChatMessage = ({
  userId,
  menu,
  setMenu,
  chatRoomInfo,
  onMessageSent,
  isLoading,
}: {
  chatRoomInfo: ChatRoomDto | null;
  userId: number;
  menu: boolean;
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
  onMessageSent: (chatRoomId: number, timestamp: string) => void;
  isLoading: boolean;
}) => {
  const chatRoomId = chatRoomInfo?.chatRoomId ?? 0;
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<string>("");
  const [chatMessageData, setChatMessageData] = useState<IMessage[]>([]);
  const [openAwardMenu, setOpenAwardMenu] = useState(false);
  const [showAwardError, setShowAwardError] = useState(false);
  const { data: chatData } = useChatMessageQuery(chatRoomId);
  const { data: userData } = useUserMeQuery();
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (chatData && chatData.length > 0) {
      setChatMessageData(chatData);
    } else {
      setChatMessageData([]);
    }
  }, [chatData]);

  const { send, isConnected } = useWebSockets({
    chatRoomId: chatRoomId,
    enabled: true,
    onMessage(message) {
      setChatMessageData((prevData): any => [...prevData, message]);
      onMessageSent(chatRoomId, message.messageTimestamp);
    },
  });

  const sendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim() === "") {
      return;
    }
    const sentMessage: MessageDto = {
      room: chatRoomId,
      message: message,
    };
    const currentTimestamp = new Date().toISOString();

    const chatMessage: IMessage = {
      id: 0,
      roomId: chatRoomId,
      userId: userId,
      message: message,
      messageTimestamp: currentTimestamp,
      type: MessageType.TEXT,
      mediaUrl: null,
      locationCoordinate: null,
    };

    setChatMessageData((prevData): any => [...prevData, chatMessage]);
    send(sentMessage);
    setMessage("");
    onMessageSent(chatRoomId, currentTimestamp);
  };

  const jobAwardMutation = useMutation(jobAwardActionPost, {
    onSuccess(data, variables, context) {
      //console.log('data' + JSON.stringify(data))
      setOpenAwardMenu(true);
    },
    onError(error, variables, context) {
      //console.log('error' + error)
      setShowAwardError(true);
    },
  });

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [chatMessageData]);

  const handleAwardClick = () => {
    if (isLabourer(userData?.user)) {
      setShowConfirm(true);
    } else {
      handleJobClick();
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    handleJobClick();
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleJobClick = () => {
    if (chatRoomInfo?.jobId && chatRoomInfo?.laborId) {
      jobAwardMutation.mutate({
        jobId: chatRoomInfo?.jobId,
        labourerId: chatRoomInfo?.laborId,
      });
    }
  };

  const renderedMessages = chatMessageData?.map(
    (item: IMessage, index: number) => {
      const isUserMessage = item.userId === userId;

      const messageDate = moment(item?.messageTimestamp)
        .utc()
        .utcOffset("+05:30");
      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "days").startOf("day");

      let messageTime = "";
      if (messageDate.isSame(today, "day")) {
        messageTime = "Today";
      } else if (messageDate.isSame(yesterday, "day")) {
        messageTime = "Yesterday";
      } else {
        messageTime = messageDate.format("MMMM DD, YYYY");
      }

      const showDateLabel =
        index === 0 ||
        !messageDate.isSame(
          moment(chatMessageData[index - 1]?.messageTimestamp)
            .utc()
            .utcOffset("+05:30"),
          "day"
        );

      return (
        <>
          {showDateLabel && (
            <div className="text-center mb-2">
              <div className="inline-block px-2 py-1 text-sm font-medium text-gray-700 bg-white rounded-lg shadow">
                {messageTime}
              </div>
            </div>
          )}

          <div
            key={index}
            className={`${
              isUserMessage ? "ml-auto items-end" : "items-start"
            } w-fit flex flex-col justify-center gap-1 mt-auto`}
          >
            <div
              className={`${
                isUserMessage ? "justify-end" : "justify-start"
              } flex items-center`}
            >
              <div className="rounded-[4px]">
                <img
                  src={require(`../../assets/images/user_default.png`)}
                  className={`${
                    isUserMessage
                      ? "h-[20px] rounded w-[20px] ml-auto"
                      : "h-[20px] rounded w-[20px]"
                  }`}
                  alt="avatar1"
                />
              </div>
              <h1
                className={`${
                  isUserMessage
                    ? "mt-[5px] w-fit text-[9px] px-[8px] pb-[3px] rounded-[4px]"
                    : "mt-[5px] w-fit text-[9px] px-[8px] pb-[3px] rounded-[4px]"
                }`}
              >
                {messageDate.format("hh:mm A")}
              </h1>
            </div>
            <p
              className={`${
                isUserMessage
                  ? "w-auto max-w-sm text-[14px] p-[8px]  whitespace-pre-wrap break-words bg-green-200 rounded-lg border-[1px] border-[#E9E8E3]"
                  : "w-auto max-w-sm text-[14px] p-[8px] whitespace-pre-wrap break-words bg-white rounded-lg border-[1px] border-[#E9E8E3]"
              }`}
            >
              {item?.message}
            </p>
          </div>
        </>
      );
    }
  );
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey && isConnected) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  return (
    <div className="lg:w-[calc(100%-280px)] md:w-[calc(100%-220px)] w-full border-l-[1px] border-[#E9E8E3] ">
      <div className="px-[16px] py-[10px] flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <div
            className="cursor-pointer md:hidden block "
            onClick={() => setMenu(!menu)}
          >
            <TbMenu2 className="text-[20px]" />
          </div>
          <div>
            <h4 className="md:text-[22px] text-[18px] text-[#272D29] font-medium">
              {chatRoomInfo?.userName}
            </h4>
            <p className="md:text-[13px] text-[11px] text-[#272D29] font-medium">
              {/** labourerData?.search?.name */}
            </p>
          </div>
        </div>
        <div className="mr-[20px] cursor-pointer flex">
          {/* <BsThreeDotsVertical className="text-[22px]" /> */}
          {userId == chatRoomInfo?.userId ? (
            <button
              onClick={() => handleAwardClick()}
              className="bg-[#FBE9C7] border border-[#999485] rounded-lg h-[39px] px-4 flex 
             justify-center items-center gap-1 text-[14px] font-medium text-[#272D29]"
            >
              {jobAwardMutation.isLoading ? (
                <span>Awarding...</span>
              ) : (
                t("Award This Job")
              )}
            </button>
          ) : null}
        </div>
      </div>
      <AwardThisJob
        setOpenAwardMenu={setOpenAwardMenu}
        openAwardMenu={openAwardMenu}
      />
      {showConfirm && (
        <BecomeLabourerModal
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <GenericDialog
        open={showAwardError}
        setOpen={setShowAwardError}
        title={t("Award Job")}
        message={t("Something went wrong")}
      />

      <div className="bg-[#E9E8E3] px-[16px] py-[24px] h-[calc(100vh-205px)] flex items-end relative overflow-hidden">
        <div
          ref={chatWindowRef}
          className="flex flex-col gap-5 overflow-y-auto w-full scroll-remove max-h-full min-h-0 relative pb-16 chat-container"
        >
          {isLoading ? (
            <div className="h-screen flex items-center justify-center">
              <CircularProgressBar />
            </div>
          ) : (
            renderedMessages
          )}
        </div>
        <div className="flex gap-2 absolute bottom-2 w-full px-2 left-0 mx-auto">
          <textarea
            id="message-input"
            className="w-full block mx-auto p-2 min-h-[50x] max-h-[150px] rounded-lg border border-gray-300 resize-none overflow-auto no-scrollbar"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={"Type a message..."}
            rows={1}
          />
          <button
            disabled={!isConnected}
            onClick={sendMessage}
            className="bg-[#F0A61F] text-white font-bold px-4 py-2 rounded-lg whitespace-nowrap h-[50px]"
          >
            {isConnected ? t("Send") : t("Connecting")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
