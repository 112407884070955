import firebase from "../config/firebase";

class InMemoryStorage implements Storage {
  private data: Map<string, string> = new Map();
  length: number = this.data.size;

  clear(): void {
    this.data.clear();
  }

  getItem(key: string): string | null {
    return this.data.get(key) ?? null;
  }

  key(index: number): string | null {
    const keys = Array.from(this.data.keys());
    return index >= 0 && index < keys.length ? keys[index] : null;
  }

  removeItem(key: string): void {
    if (this.data.has(key)) {
      this.data.delete(key);
    }
  }

  setItem(key: string, value: string): void {
    this.data.set(key, value);
  }
}

class SmartStorage {
  private storage: Storage;

  constructor() {
    if (this.isLocalStorageAvailable()) {
      this.storage = window.localStorage;
    } else {
      this.storage = new InMemoryStorage();
    }
  }

  private isLocalStorageAvailable(): boolean {
    try {
      const testKey = "__test__";
      window.localStorage.setItem(testKey, testKey);
      window.localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  deleteFCMToken(): void {
    this.removeItem("fcmToken");
    firebase.messaging().deleteToken();
  }

  handleTokenRefresh(newToken: string): void {
    const oldToken = this.getItem("fcmToken");
    if (oldToken && oldToken !== newToken) {
      this.deleteFCMToken();
    }
    this.setItem("fcmToken", newToken);
  }

  handleLogout(): void {
    this.deleteFCMToken();
    this.removeItem("deviceId");
  }
}

export const smartStorage = new SmartStorage();
