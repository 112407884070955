import { useRef, useState } from "react";
import Text from "../component/Text/Text";
import Menubar from "../component/Menubar";
function DisclaimerPolicy() {
  const scrolldivRef: any = useRef();

  const limitionRef = useRef(null);

  const introductionRef = useRef(null);
  const consentRef = useRef(null);

  const sections = [
    { label: "Introduction", ref: introductionRef },
    { label: "Limitation of Warranties", ref: limitionRef },
    { label: "Consent", ref: consentRef }
  ];

  const fixedHeaderHeight = 140;

  const handleScrollToScope = (ref: any) => {
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;

      if (scrolldivRef) {
        scrolldivRef?.current.scroll({
          top: elementTop,
          behavior: "smooth"
        });
      }
    }
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggleSidebar = (ref: React.RefObject<any>) => {
    setSidebarVisible(!sidebarVisible);
    if (ref.current) {
      const elementTop = ref.current.offsetTop - fixedHeaderHeight;
      scrolldivRef?.current.scroll({
        top: elementTop,
        behavior: "smooth"
      });
    }
  };
  const handleClose = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <>
      <header className="bg-gray-50   border-gray-200 border-b-2   top-0">
        <div className="  text-black md:text-2xl sm:text-base font-bold ml-[20%] font-Montserrat leading-10">
          Disclaimer Policy Document
        </div>
      </header>
      <div className="bg-white-600 flex   ">
        <div className="section1-main border-gray-200 border-r-2 h-screen flex-shrink-0 md:w-[20%] hidden sm:block pt-1 pr-1">
          <div className="section-sub bg-gray-100 h-screen">
            <ul className="list-disc pl-4 md:pl-14 pt-4 md:pt-16">
              {sections?.map((section, index) => (
                <li
                  key={index}
                  className=" cursor-pointer text-black hover:text-black text-xs font-normal font-robotoserif leading-none mb-3 "
                  onClick={() => handleScrollToScope(section.ref)}
                >
                  {section.label}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="section2-Main md:w-[80%] w-[100%]  pl-1 pt-1 mt-0">
          <div
            className="section2-sub bg-gray-100 p-3  overflow-scroll no-scrollbar scroll-smooth  h-screen "
            ref={scrolldivRef}
          >
            <div className="  innerArea bg-white p-4 md:p-10 md:w-10/12 w-full flex-col items-center justify-center">
              <button
                className=" text-white absolute left-0 top-24 md:hidden lg:hidden 2xl:hidden xl:hidden sm:block"
                onClick={() => handleToggleSidebar(scrolldivRef)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 17h18v-2H3v2zM3 11h18V9H3v2zm0-6v2h18V5H3z" />
                </svg>
              </button>
              <div className="font-robotoserif text-black font-normal text-xs   ">
                <div className=" " ref={introductionRef}>
                  <Text
                    className="text-[14px] mb-3 text-black underline text-center  uppercase"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Disclaimer Policy Document{" "}
                  </Text>
                  <Text
                    className="text-[14px] mb-6 text-black underline uppercase text-center"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    DATED: 01/01/2024{" "}
                  </Text>
                </div>

                <div>
                  <Text
                    className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                    size="txtRobotoSerifRegular12"
                  >
                    If you require any more information or have any questions
                    about our site’s disclaimer, please feel free to contact us.
                  </Text>

                  <Text
                    className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px]"
                    size="txtRobotoSerifRegular12"
                  >
                    Eaidex Services Pvt Ltd (hereinafter referred as Eaidex)
                    makes no guarantees, representations, or warranties of any
                    kind as regards the website and application. Any purportedly
                    applicable warranties, terms, and conditions are excluded to
                    the fullest extent permitted by law. Your use of Eaidex’s
                    website/application is at your sole risk. The access to the
                    website/application is provided on an “AS IS” and “AS
                    AVAILABLE” basis and the same is provided without warranties
                    of any kind.
                  </Text>
                  <div>
                    <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                      <li className="  ">
                        You agree that from time to time, we may remove the
                        access to Eaidex’s website/application for indefinite
                        periods of time or cancel the Service at any time
                        without notice to you. You expressly agree that your use
                        of, or inability to use, the Service is at your sole
                        risk.
                      </li>
                      <li className=" ">
                        The information contained on this website is for general
                        information purposes only. The information is provided
                        by Eaidex, and while we endeavor to keep the information
                        up to date and correct, we make no representations or
                        warranties of any kind, express or implied, about the
                        completeness, accuracy, reliability, suitability, or
                        availability with respect to the website or the
                        information, products, services, or related graphics
                        contained on the website for any purpose. Any reliance
                        you place on such information is, therefore strictly at
                        your own risk.
                      </li>
                      <li className="  ">
                        In no event Eaidex shall be liable for any loss or
                        damage, including, without limitation, indirect or
                        consequential loss or damage, or any loss or damage
                        whatsoever arising from loss of data or profits arising
                        out of, or in connection with, the use of this website.
                      </li>
                      <li className=" ">
                        Through Eaidex’s website/application, you can link to
                        other websites which are not under the control of
                        Eaidex. Eaidex has no control over the nature, content,
                        and availability of those sites. The inclusion of any
                        links does not necessarily imply a recommendation or
                        endorse the views expressed within them.
                      </li>
                    </ul>
                  </div>
                  <Text
                    className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                    size="txtRobotoSerifRegular12"
                  >
                    Every effort is made to keep the website up and running
                    smoothly. However, Eaidex takes no responsibility for, and
                    will not be liable for, the website being temporarily
                    unavailable due to technical issues beyond our control.
                  </Text>
                </div>

                <div ref={limitionRef}>
                  <Text
                    className="mt-4 text-[13px] text-black uppercase font-bold mb-1 "
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Limitation of Warranties
                  </Text>
                  <div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black  text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      The information on this website is provided “as is”
                      without any representations or warranties, express or
                      implied. Eaidex makes no representations or warranties.
                    </Text>
                    <div>
                      <ul className="text-black ml-8 list-disc font-robotoserif text-xs font-normal  leading-6 tracking-tight ">
                        <li className="  ">
                          Without prejudice to the generality of the foregoing
                          paragraph, Eaidex does not warrant that:
                        </li>
                        <li className=" ">
                          The information regarding services on this
                          website/application will be constantly available or
                          available at all.
                        </li>
                        <li className="  ">
                          The information on this website is complete regarding
                          different services, true, accurate, up-to-date, or
                          non-misleading.
                        </li>
                        <li className=" ">
                          Eaidex is not involved in user transactions and does
                          not guarantee any transaction's completion. The
                          company is not liable for services provided by third
                          parties beyond its platform. Users are advised to
                          independently assess and handle transactions with
                          third- party service providers at their own risk.
                        </li>
                        <li className=" ">
                          Eaidex limits its liability in the Terms and
                          Condition. Eaidex does not guarantee satisfactory
                          responses to listings, and users should independently
                          verify the services provided by the users.
                        </li>
                        <li className=" ">
                          The website/application is a venue only, and Eaidex
                          does not screen users or control their behaviour.
                          Users (Service providers and Service Seekers) assume
                          risks in dealing with others on the
                          website/application.
                        </li>
                        <li className=" ">
                          Eaidex disclaims responsibility for expert advice, and
                          users should independently assess the information
                          displayed. Eaidex is an intermediary not involved in
                          transactions between sellers and buyers. Eaidex does
                          not guarantee performance and is not liable for
                          disputes between the users (Service Providers and
                          Service Seekers).
                        </li>
                        <li className="">
                          Eaidex is not responsible for third-party services,
                          and users use them at their own risk. Eaidex disclaims
                          any warranty and is not liable for consequences or
                          claims arising from third-party services. Users
                          acknowledge and waive rights regarding third-party
                          services.
                        </li>
                        <li className="  ">
                          Eaidex does not endorse opportunities on the site or
                          provide any benefits. Users are urged to independently
                          verify information before making any decisions.
                        </li>
                        <li className="  ">
                          Eaidex urges the users to be aware that when you leave
                          our website/applications, other websites may have
                          different privacy policies and terms that are beyond
                          our control. Please be sure to check the Privacy
                          Policies of those websites as well as their “Terms and
                          conditions” before engaging in any business or
                          uploading any information.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div ref={consentRef}>
                  <Text
                    className="mt-4 mb-1 text-[13px] text-black uppercase font-bold font-robotoserif"
                    size="txtRobotoSerifNormalRomanBold13"
                  >
                    Consent{" "}
                  </Text>
                  <div>
                    <Text
                      className="leading-[25.00px] mb-1 text-black text-justify text-xs tracking-[0.12px] "
                      size="txtRobotoSerifRegular12"
                    >
                      By using Eaidex’s website/application, you hereby consent
                      to our disclaimer and agree to its terms.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menubar
        sections={sections}
        visible={sidebarVisible}
        onClose={handleToggleSidebar}
        close={handleClose}
      />
    </>
  );
}

export default DisclaimerPolicy;
