import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationHI from "./locales/hi/translation.json";
import translationML from "./locales/ml/translation.json";
import translationTA from "./locales/ta/translation.json";
import translationBG from "./locales/bg/translation.json";
import translationAS from "./locales/as/translation.json";

const resources = {
  en: {
    translation: translationEN
  },
  hi: {
    translation: translationHI
  },
  ml: {
    translation: translationML
  },
  ta: {
    translation: translationTA
  },
  bg: {
    translation: translationBG
  },
  as: {
    translation: translationAS
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
