import React from "react";
import { BsStarFill, BsStar } from "react-icons/bs";

interface StarRatingProps {
  rating: number;
  onRate: (newRating: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, onRate }) => {
  // Round the rating to the nearest integer
  const roundedRating = Math.round(rating);

  // Create an array of stars based on the rating
  const stars: JSX.Element[] = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span key={i} onClick={() => onRate(i)}>
        {i <= roundedRating ? (
          <BsStarFill className="text-[#F0A61F] cursor-pointer" />
        ) : (
          <BsStar className="cursor-pointer text-[#d3d3d3]" />
        )}
      </span>
    );
  }

  return <div className="flex items-center gap-2">{stars}</div>;
};

export default StarRating;
