import React, { createContext, useContext, useReducer, ReactNode } from "react";

export interface LocationData {
  lat: number;
  lng: number;
  state?: string;
  city?: string;
  country?: string;
}

interface LocationState {
  selectedLocationData: LocationData | null;
}

type LocationAction =
  | { type: "SET_LOCATION"; payload: LocationData }
  | { type: "RESET" };

const initialState: LocationState = {
  selectedLocationData: null,
};

const locationReducer = (
  state: LocationState,
  action: LocationAction
): LocationState => {
  switch (action.type) {
    case "SET_LOCATION":
      return { ...state, selectedLocationData: action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const LocationContext = createContext<{
  state: LocationState;
  dispatch: React.Dispatch<LocationAction>;
}>({ state: initialState, dispatch: () => null });

export const LocationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(locationReducer, initialState);

  return (
    <LocationContext.Provider value={{ state, dispatch }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);

export default LocationContext;
