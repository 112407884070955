import { EyeIcon, StarIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MessageIcon } from "../../assets/images/AllSvg";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useMutation } from "@tanstack/react-query";
import {
  JobDto,
  createJob,
  jobAwardActionPost,
  jobChatRoom,
  useEmployerAvailability,
  useLanguagesQuery,
  useGetFavourites,
  useUserMeQuery,
} from "../../API/api";
import { toast } from "react-toastify";
import AwardThisJob from "../Models/AwardThisJob";
import { SearchResult, Service, SkillDto } from "../../API/types";
import { isAuthenticated } from "../../utils/jwtUtils";
import Pagination from "../Pagination";
import { useAppContext } from "../Context/AppContext";
import { FaEnvelope, FaSpinner } from "react-icons/fa";
import {
  useAddFavouriteMutation,
  useRemoveFavouriteMutation,
} from "../services/Mutation";
import { RemoveFavoriteModal } from "./RemoveFavoriteModal";
import {
  handleFavorites,
  handleFavoriteClick,
  confirmRemoveFavorite,
} from "../../utils/favoritesUtils";
import { useCompleteImageUrl } from "../../utils/GeneralUtils";
import { getSkillName, isLabourer } from "../../utils/utils";
import BecomeLabourerModal from "../Models/BecomeLabourerModal";
import { ServiceProviderType } from "../Details/ReviewDiv";

const ProfileSearchServices = ({
  searchData,
  setOpen,
  totalItems,
  searchName,
  setFilteredData,
  filteredData,
  selectedStatus,
  services,
  onServiceProviderClicked,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  offset,
}: {
  searchData: SearchResult[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  totalItems: number;
  searchName: any;
  filteredData: SearchResult[];
  setFilteredData: any;
  selectedStatus: string;
  services: Service[] | undefined;
  onServiceProviderClicked: (item: SearchResult) => void;
  currentPage: any;
  setCurrentPage: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  offset: any;
}) => {
  useEffect(() => {
    setFilteredData(searchData);
  }, []);
  interface FavoriteLoadingState {
    [key: number]: {
      add: boolean;
      remove: boolean;
    };
  }
  const { data: favouritesData } = useGetFavourites();
  const { data: languages } = useLanguagesQuery();
  const { data: userData } = useUserMeQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openAwardMenu, setOpenAwardMenu] = useState(false);
  const [show, setShow] = useState({ id: 0, status: false });
  const indexOfLastRow = currentPage * rowsPerPage;
  const totalRows = totalItems;
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, totalRows);
  const indexOfFirstRow = 0;
  const currentRows = searchData?.slice(indexOfFirstRow, indexOfLastRow);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SearchResult | null>(null);
  const [loadingStates, setLoadingStates] = useState<{
    [key: number]: { chat: boolean; award: boolean };
  }>({});

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const { data: employerstatus, refetch } =
    useEmployerAvailability(filteredData);

  useEffect(() => {
    if (selectedStatus) {
      refetch();
    }
  }, [selectedStatus]);

  const { appState, setAppState } = useAppContext();

  const updateJobId = (jobId: number | null) => {
    setAppState({
      ...appState,
      jobId: jobId,
    });
  };

  const handleDetails = (id: number) => {
    const data1 = searchData?.find((item: any) => item.userId === id);
    if (data1) {
      onServiceProviderClicked(data1);
    }
  };

  const handleClick = (id: number) => {
    setShow((prevState) => {
      if (prevState.id === id) {
        return { id: 0, status: !prevState.status };
      }
      return { id, status: true };
    });
  };

  const createJobMutation = useMutation(createJob);

  const JobRoomMutation = useMutation(jobChatRoom, {
    onSuccess: (data: any) => {
      if (data?.error) {
        toast.error(data?.error?.message);
      } else {
        navigate("/messageCenter", { state: { chatRoomId: data.chatRoomId } });
      }
    },
    onError: (error: { message: string }) => {
      toast.error(error.message);
    },
  });

  const mapSkillIds = (skills: SkillDto[]) => {
    const skillIds: number[] = [];
    for (const value of skills) {
      if (value.skillId !== undefined) {
        skillIds.push(value.skillId);
      }
    }
    return skillIds;
  };

  const jobAwardMutation = useMutation(jobAwardActionPost, {
    onSuccess: () => {
      updateJobId(null);
      setOpenAwardMenu(true);
    },
  });

  const handleChatClick = (item: SearchResult) => {
    handleJobAction(item, "chat");
  };

  // const handleAwardClick = (item: SearchResult) => {
  //   handleJobAction(item, "award");
  // };

  const handleAwardClick = (item: SearchResult) => {
    if (isLabourer(userData?.user)) {
      setShowConfirm(true);
      setSelectedItem(item);
    } else {
      handleJobAction(item, "award");
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    if (selectedItem) {
      handleJobAction(selectedItem, "award");
    }
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleJobAction = async (
    item: SearchResult,
    actionType: "chat" | "award"
  ) => {
    if (!isAuthenticated()) {
      setOpen(true);
      // setLogin(true);
      // setItemData(searchData);
      return;
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [item.userId]: { ...prevState[item.userId], [actionType]: true },
    }));
    const savedJobId = appState.jobId;
    if (savedJobId) {
      // Job already created, proceed with action
      const jobId = savedJobId as number;
      if (actionType === "chat") {
        JobRoomMutation.mutate({
          jobId: jobId,
          labourerId: item.userId,
        });
      } else if (actionType === "award") {
        jobAwardMutation.mutate({
          jobId: jobId,
          labourerId: item.userId,
        });
      }
    } else {
      // Create a new job and then proceed with action
      try {
        const createJobRequest = {
          location: item.location,
          latitude: item.lat,
          longitude: item.lng,
          skillIds: mapSkillIds(item.skills),

          labourerId: item.userId,
        };
        const job = await createJobMutation.mutateAsync(createJobRequest);
        const jobId = job.id as number;
        updateJobId(jobId);

        if (actionType === "chat") {
          JobRoomMutation.mutate({
            jobId: jobId,
            labourerId: item.userId,
          });
        } else if (actionType === "award") {
          jobAwardMutation.mutate({
            jobId: jobId,
            labourerId: item.userId,
          });
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const starRatingValues = [0, 1, 2, 3, 4];

  const RowItem = (item: SearchResult) => {
    const status = Array.isArray(employerstatus)
      ? employerstatus.find((statusItem) => statusItem.userId === item.userId)
      : null;
    const loadingState = loadingStates[item.userId] || {
      chat: false,
      award: false,
    };

    const [favoriteLoadingStates, setFavoriteLoadingStates] =
      useState<FavoriteLoadingState>({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isLoading =
      favoriteLoadingStates[item.id]?.add ||
      favoriteLoadingStates[item.id]?.remove;

    // FAVOURITE ADD AND REMOVE MUTATION
    const favouritesMutation = useAddFavouriteMutation();
    const removeFavouriteMutation = useRemoveFavouriteMutation();

    // CHECK FAVOURITE OR NOT
    const isFavorite =
      favouritesData?.some((fav) => fav.labourerId === item.id) ?? false;

    //IMAGE UTILS FUNCTIONS
    const getCompleteImageUrl = useCompleteImageUrl();

    //FAVOURITE UTILS FUNCTIONS
    const handleFavoritesClick = (labourerId: number) =>
      handleFavorites(
        labourerId,
        favouritesData,
        setFavoriteLoadingStates,
        removeFavouriteMutation,
        favouritesMutation,
        setOpen,
        isAuthenticated
      );
    const onFavoriteClick = () =>
      handleFavoriteClick(
        isFavorite,
        handleFavoritesClick,
        item.id,
        setIsModalOpen
      );
    const onConfirmRemoveFavorite = () =>
      confirmRemoveFavorite(handleFavoritesClick, item.id, setIsModalOpen);

    const getSkillName = (skillId: number) => {
      const skill = services?.find((skill: SkillDto) => skill.id === skillId);
      return skill ? skill.name : "";
    };
    const renderSkills = () =>
      item.skills.map((skill: SkillDto) => (
        <span
          key={skill.id}
          className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]"
        >
          {getSkillName(skill?.skillId ?? 0)} ({skill?.experience} years)
        </span>
      ));

    const isHybridOrEmployer =
      userData?.user?.roles.includes("employer") ||
      (userData?.user?.roles.includes("employer") &&
        userData?.user?.roles.includes("labourer"));
    const providerDetails = {
      serviceProviderType: 1,
      serviceName: "Miya Textiles",
      gstId: "GST7485HGF",
      openTime: "9:30 AM",
      closingTime: "8:30 PM",
    };

    return (
      <>
        <div className="w-full border border-[#bbb] rounded-[10px] p-2 mb-6  ">
          <div className="relative max-w-md mx-auto overflow-hidden rounded-xl md:max-w-full">
            <div className="lg:flex ">
              <div className="md:shrink-0 lg:mr-3 ">
                <img
                  src={
                    item?.profileUrl
                      ? getCompleteImageUrl(item?.profileUrl)
                      : require(`../../assets/images/userIcon.jpg`)
                  }
                  onClick={() => handleDetails(item.userId)}
                  alt=""
                  className="object-cover w-full h-48 cursor-pointer lg:h-full md:h-48 md:w-full lg:w-48 "
                />
              </div>
              <div className="w-full p-4 md:p-2 bg-g ">
                <div className="flex flex-col items-center md:justify-between md:flex-row md:items-center ">
                  <div className="flex flex-col w-full item-center lg:flex-row lg:justify-between">
                    <div className="flex items-center gap-2 mb-2 md:mb-4 lg:mb-0">
                      <div className="text-lg font-semibold tracking-wide text-indigo-900 uppercase ">
                        {t(item.name as string)}
                      </div>
                      <div
                        className={`bg-[${
                          status?.status === 1 ? "#00C54F" : "#eb300a"
                        }] 4xl:w-[20px] 4xl:h-[20px] w-[17px] h-[17px]  rounded-full   top-[210px] right-2 md:top-1 md:left-[194px]`}
                      />
                    </div>
                    {/* corner chat serch message */}

                    <div className="flex items-center gap-5 lg:flex-nowrap md:flex-wrap lg:gap-2 md:gap:1 lg:justify-end">
                      <button
                        onClick={() => handleClick(item.id)}
                        className="bg-white border border-[#999485] text-[#999485] rounded-[34px] md:h-[39px]  h-[34px] w-[130px] md:w-[125px] flex justify-center items-center text-[8px] md:text-[11px] font-semibold "
                      >
                        {show.status && show.id === item.id ? (
                          <p className="text-sm">{item.phoneNumber}</p>
                        ) : (
                          <div className="flex justify-center items-center gap-1 text-[#999485]">
                            <EyeIcon color="#999485" /> {t("Show Phone")}
                          </div>
                        )}
                      </button>
                      {providerDetails?.serviceProviderType ===
                      ServiceProviderType.Business ? (
                        <button
                          onClick={() => handleChatClick(item)}
                          className="border-[#999485] border-[1px] rounded-[34px] w-[34px] h-[34px] md:w-[44px] md:h-[44px] flex justify-center items-center"
                        >
                          <FaEnvelope className="w-5 h-5" />
                          {/* <span>Enquiry</span> */}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleChatClick(item)}
                          className="border-[#999485] border-[1px] rounded-[34px] w-[44px] h-[44px] flex justify-center items-center mr-[14px]"
                          disabled={loadingState.chat}
                        >
                          {loadingState.chat ? (
                            <FaSpinner className="inline-block  animate-spin text-[#F0A61F] w-5 h-5" />
                          ) : (
                            <MessageIcon />
                          )}
                        </button>
                      )}

                      {isHybridOrEmployer && (
                        <button
                          onClick={onFavoriteClick}
                          className="border-[#999485] border rounded-full w-[34px] h-[34px] md:w-[44px] md:h-[44px] flex justify-center items-center hover:bg-[#FBE9C7] transition-colors duration-300"
                        >
                          {isLoading ? (
                            <div className="w-5 h-5 border-2 border-t-[#F0A61F] border-r-[#F0A61F] border-b-[#F0A61F] border-l-transparent rounded-full animate-spin" />
                          ) : isFavorite ? (
                            <AiFillHeart className="w-6 h-6 text-red-400 transition-colors duration-300" />
                          ) : (
                            <AiOutlineHeart className="w-6 h-6 text-[#999485] transition-colors duration-300" />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 mt-2 mb-3 md:flex-wrap ">
                  <div className="flex ">
                    {starRatingValues?.map((rating) => (
                      <StarIcon
                        key={rating}
                        className={`${
                          item?.averageStarRating > rating
                            ? "text-[#F0A61F]"
                            : "text-[#d3d3d3]"
                        } h-5 w-5 flex-shrink-0`}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <div>
                    <p className="text-[#7C786D] text-sm font-roboto">
                      {item?.averageStarRating} {t("reviews")}
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => handleDetails(item.userId)}
                  className="border-b-[#E9E8E3] border-b-[1px] pb-[8px] cursor-pointer "
                >
                  <h2 className=" text-[#272D29] md:text-[15px] text-sm font-semibold mt-2 md:mt-0">
                    {t("Description")}
                  </h2>
                  <p className="text-[#272D29] font-roboto text-[16px] tracking-[0.16px]">
                    {item.description}{" "}
                    <span
                      onClick={() => handleDetails(item.userId)}
                      className="text-[#F0A61F] cursor-pointer"
                    >
                      {t("more")}
                    </span>
                  </p>
                </div>

                <div className="border-b-[#E9E8E3] border-b-[1px] ">
                  <h2 className="md:mt-1 md:mb-1 mt-2 text-[#272D29] md:text-[15px] text-sm font-semibold">
                    {t("Location")}
                  </h2>
                  <div className="flex gap-2 mt-1 md:mt-1 md:mb-1">
                    <p className="text-[#272D29] font-roboto text-[16px] tracking-[0.16px]">
                      {item.location}
                      <span className="text-[#7C786D] text-[14px] ml-[10px] font-semibold">
                        {item.distance.toFixed(2)} {t("Km from you")}
                      </span>
                    </p>
                  </div>
                </div>

                {/* bottom section */}
                <div className="items-center mt-4 md:flex md:gap-4 lg:gap-1 md:flex-wrap lg:justify-between lg:mt-0 lg:flex-nowrap">
                  <div className="flex flex-col md:flex-wrap md:flex-row ">
                    <div className="flex flex-col pr-10 md:gap-6 md:flex-row ">
                      <>
                        {providerDetails?.serviceProviderType ===
                          ServiceProviderType.Individual && (
                          <div className="flex flex-col md:flex-row md:gap-8 md:items-center ">
                            {item?.languages && item?.languages?.length > 0 && (
                              <div className="border-b-[#E9E8E3] border-b-[1px] pb-[8px]">
                                <h2 className="mt-2 text-[#272D29] md:text-[15px] text-sm font-semibold mb-1">
                                  {t("Languages")}
                                </h2>
                                <div className="flex gap-1 md:gap-2">
                                  {item?.languages
                                    .slice(0, 3)
                                    .map((service: any) => {
                                      const matchingLanguage = languages?.find(
                                        (item: any) =>
                                          service?.languageId === item?.id
                                      );
                                      return (
                                        <button className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]">
                                          {matchingLanguage?.name}
                                        </button>
                                      );
                                    })}
                                  {item?.languages.length > 3 && (
                                    <button
                                      type="button"
                                      className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]"
                                      onClick={() => handleDetails(item.userId)}
                                    >
                                      +{item.languages.length - 3}
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                    <div className="border-b-[#E9E8E3] border-b-[1px] pb-[8px]">
                      <h2 className="mt-2 text-[#272D29] md:text-[15px] text-sm font-semibold mb-1">
                        {/* {t("SkillsWithExperience")} */}
                        {providerDetails?.serviceProviderType ===
                        ServiceProviderType.Business
                          ? t("Services")
                          : t("SkillsWithExperience")}
                      </h2>
                      <div className="flex gap-2">{renderSkills()} </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      onClick={() => handleAwardClick(item)}
                      className="bg-[#FBE9C7] border border-[#999485] rounded-lg h-[39px] px-4  items-center text-[14px] font-medium text-[#272D29]"
                      disabled={loadingState.award}
                    >
                      {loadingState.award ? (
                        <>
                          <span>awarding</span>
                          <FaSpinner className="inline-block text-[#F0A61F] animate-spin ml-2 w-5 h-5" />
                        </>
                      ) : // t("Award This Job")
                      providerDetails?.serviceProviderType ===
                        ServiceProviderType.Business ? (
                        "Business"
                      ) : (
                        t("Award This Job")
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RemoveFavoriteModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={onConfirmRemoveFavorite}
          itemName={item?.name}
        />
      </>
    );
  };

  return (
    <div>
      <div>
        <p className="text-black text-[21px] font-medium lg:mt-6 mt-10 lg:mb-6 mb-10 lg:ml-3">
          {totalItems} {t("resultFor")}{" "}
          <span
            className="cursor-pointer"
            onClick={() => window.location.reload()}
          >
            “{searchName}”
          </span>
        </p>
        {currentRows?.map((item: SearchResult) => {
          return RowItem(item);
        })}

        <div className="flex gap-[14px] items-center justify-end mb-[71px] flex-wrap mt-[27px]">
          {filteredData?.length != undefined && filteredData?.length != 0 ? (
            <Pagination
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              startRow={startRow}
              endRow={endRow}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              indexOfLastRow={indexOfLastRow}
              totalNumber={totalRows ?? 0}
            />
          ) : null}
        </div>
      </div>
      {showConfirm && (
        <BecomeLabourerModal
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <AwardThisJob
        setOpenAwardMenu={setOpenAwardMenu}
        openAwardMenu={openAwardMenu}
      />
    </div>
  );
};

export default ProfileSearchServices;
