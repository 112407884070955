import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { FeedbackDetailsDto, useFeedbackMaster } from "../API/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FeedbackSchema } from "../schema/feedbackSchema";
import { isAuthenticated } from "../utils/jwtUtils";
import { UserType } from "../API/types";
import { useSendFeedback } from "../component/services/Mutation";
import { Settings } from "react-slick";
import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { FiPhone, FiMail, FiMapPin, FiDownload } from "react-icons/fi";
interface ContactUsProps {
  onRegisterClicked: (UserType?: UserType) => void;
}
interface FeedbackFormDto {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactUs: React.FC<ContactUsProps> = ({ onRegisterClicked }) => {
  const { t } = useTranslation();
  const placeholderName = t("Name") || "";
  const placeholderEmail = t("Email Id") || "";
  const placeholderPhone = t("Phone Number") || "";
  const placeholderMessage = t("Message") || "";

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data } = useFeedbackMaster();
  const sendFeedbackMutation = useSendFeedback();
  const {
    register: contactForm,
    handleSubmit: contactFormSubmission,
    formState: { errors: contactFormErrors },
    reset,
  } = useForm({
    resolver: yupResolver(FeedbackSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: FeedbackFormDto) => {
    try {
      const feedbackDetails: FeedbackDetailsDto = {
        name: data.name,
        message: data.message,
        emailId: data.email,
        status: "NEW",
        phoneNumber: data.phone,
      };
      await sendFeedbackMutation.mutateAsync(feedbackDetails);
      reset();
    } catch (error) {
      console.error("An error occurred during mutation:", error);
    }
  };
  return (
    <>
      <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] mt-[45px]  2xl:max-w-[1440px] mx-auto ">
        <div className="p-4 sm:p-6 lg:p-8">
          <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-center lg:text-left">
            Contact Us
          </h1>
          <div className="bg-[#FFFCF6] rounded-[20px] shadow-box overflow-visible relative min-h-[320px] ">
            <div className="flex flex-col lg:flex-row items-center lg:items-start relative">
              <div className="lg:w-3/5 px-4 sm:px-8 lg:px-10 py-4 lg:py-6">
                <h2 className="text-responsive-2xl text-black font-bold text-center lg:text-left">
                  {t("Feedback")}
                </h2>
                <p className="text-responsive-base text-black max-w-[800px] font-medium mt-3 text-center lg:text-left">
                  {t("contactPara1")}
                </p>
                <p className="text-responsive-lg text-black max-w-[800px] font-medium mt-4 text-center lg:text-left">
                  {t("contactPara2")}
                </p>
                <div className="flex justify-center lg:justify-start">
                  {!isAuthenticated() && (
                    <button
                      onClick={() => {
                        onRegisterClicked();
                      }}
                      className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded mt-4"
                    >
                      {t("Sign up")}
                    </button>
                  )}
                </div>
              </div>
              <div className="lg:w-2/5 relative mt-6 lg:mt-0 lg:pl-4">
                <div className="h-full relative overflow-visible flex justify-center lg:justify-end">
                  <img
                    src={require("../assets/images/feedback.png")}
                    alt="Feedback illustration"
                    className="max-w-full h-[350px] object-cover lg:absolute lg:top-[-50px] lg:right-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-[119px]">
          <h1 className="lg:text-[53px] text-[8vw] md:text-start text-center font-semibold">
            {t("ClientTestimonials")}
          </h1>
          <div className="lg:mt-8 detailslider  ">
            <Slider {...settings}>
              <div className="lg:p-6 p-2 shadow-testimonial max-w-[364px]   rounded-xl my-10 ">
                <div className="flex flex-col gap-3 ">
                  <p className="text-black text-[18px]">{t("contactPara3")}</p>
                  <p className="text-black text-[18px]">{t("contactPara4")}</p>
                </div>
                <div className="mt-10 flex items-center gap-4">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:p-6 p-2 shadow-testimonial max-w-[364px]  rounded-xl my-10 ">
                <div className="flex flex-col gap-3">
                  <p className="text-black text-[18px]">{t("contactPara3")}</p>
                  <p className="text-black text-[18px]">{t("contactPara4")}</p>
                </div>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:p-6 p-2 shadow-testimonial max-w-[364px]  rounded-xl my-10 ">
                <div className="flex flex-col gap-3">
                  <p className="text-black text-[18px]">{t("contactPara3")}</p>
                  <p className="text-black text-[18px]">{t("contactPara4")}</p>
                </div>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:p-10 p-2 shadow-testimonial max-w-[364px]  rounded-xl my-10">
                <p className="text-black text-[22px]">{t("HasBeen")}</p>
                <div className="mt-10 flex items-center gap-4 ">
                  <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={require("../assets/images/user1.jpeg")}
                      alt="user"
                      className="object-cover h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-[#0A2640] lg:text-[19px] font-bold">
                      {t("AlbusDumbledore")}
                    </h4>
                    <p className="text-[#0A2640] text-base">
                      {t("ManagerHowarts")}
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="my-[119px]">
          <h1 className="lg:text-[53px] text-[8vw] text-center md:text-start font-semibold">
            {t("CompanyCallCentrers")}
          </h1>
          <div className="mt-7 grid md:grid-cols-2 grid-cols-1 gap-8 p-3 ">
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add1")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd1")} </p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call1")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add2")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd2")}</p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call2")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add1")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd1")} </p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call1")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add2")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd2")}</p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call2")}</p>
            </div>
          </div>
        </div>

        {/* contct start */}
        <div>
          <div className="container mx-auto px-4 ">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className=" border rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-2">
                  <div className="flex items-center text-xl font-semibold mb-2">
                    <FiMail className="mr-2" />
                    <span>Email Us</span>
                  </div>
                </div>
                <div className="px-6 ">
                  <p className="text-lg">
                    <a
                      href="mailto:support@eaidex.com"
                      className="text-blue-600 hover:underline"
                    >
                      support@eaidex.com
                    </a>
                  </p>
                  <p className="text-sm text-gray-600 mt-2">
                    We'll respond within 24 hours
                  </p>
                </div>
              </div>

              <div className="border rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-2">
                  <div className="flex items-center text-xl font-semibold mb-2">
                    <FiPhone className="mr-2" />
                    <span>Call Us</span>
                  </div>
                </div>
                <div className="px-6 ">
                  <p className="text-lg">
                    Toll-free:{" "}
                    <a
                      href="tel:1-800-123-4567"
                      className="text-blue-600 hover:underline"
                    >
                      1-800-123-4567
                    </a>
                  </p>
                  <p className="text-sm text-gray-600 mt-2">
                    Available 24/7 for your inquiries
                  </p>
                </div>
              </div>

              <div className=" border rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-2">
                  <div className="flex items-center text-xl font-semibold mb-2">
                    <FiDownload className="mr-2" />
                    <span>Download Our App</span>
                  </div>
                </div>
                <div className="px-6 ">
                  <img
                    src={require("../assets/images/playstore.png")}
                    alt="playstore"
                    className="h-16"
                  />
                  <p className="text-sm text-gray-600 mt-2">
                    Get our app for easy access on the go
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contct end */}
        <div className="mt-[119px] mb-[85px] p-4 ">
          <p className="text-[#272D29] text-[30px] font-semibold">
            {t("QueriesText")}
          </p>
          <p className="mt-5 text-xl font-medium">{t("HelpText")}</p>
          <p className="mt-5 text-lg font-medium border-b-2 border-[#F0A61F] w-fit">
            {t("Feedback")}
          </p>
          <form onSubmit={contactFormSubmission(onSubmit)}>
            <div className="mt-6 flex flex-col gap-5">
              <div>
                <input
                  {...contactForm("name")}
                  name="name"
                  id="name"
                  type="text"
                  placeholder={placeholderName}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.name?.message}
                </p>
              </div>
              <div>
                <input
                  {...contactForm("email")}
                  type="email"
                  id="email"
                  name="email"
                  placeholder={placeholderEmail}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.email?.message}
                </p>
              </div>
              <div>
                <input
                  {...contactForm("phone")}
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder={placeholderPhone}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.phone?.message}
                </p>
              </div>

              <div>
                <textarea
                  {...contactForm("message")}
                  name="message"
                  id="message"
                  placeholder={placeholderMessage}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[148px] p-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.message?.message}
                </p>
              </div>

              <div className=" flex gap-1 items-center">
                <input className="m-0 h-4 w-[20px] mr-1" type="checkbox" />
                <div className=" text-xl tracking-[0.16px] leading-[24px] font-medium font-montserrat  text-left inline-block  shrink-0 mq450:text-base mq450:leading-[19px]">
                  {t("I’m not a robot")}
                </div>{" "}
                <img
                  className="w-[50px] h-[50px] relative z-[1] ml-3"
                  loading="eager"
                  alt=""
                  src={require("../assets/images/captchaIcon.png")}
                />
              </div>
              <button
                type="submit"
                className="min-w-[127px] py-3 px-16 rounded mt-16 mx-auto flex justify-center items-center text-white text-lg font-medium bg-[#484e4a] hover:bg-[#272D29] disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors duration-300"
                disabled={sendFeedbackMutation?.isLoading}
              >
                {sendFeedbackMutation?.isLoading ? (
                  <>
                    <span className="animate-spin mr-2">&#8987;</span>
                    Submitting...
                  </>
                ) : (
                  t("SendFeedback")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
