import React, { createContext, useContext, useState } from "react";

export interface AppState {
  jobId: number | null;
}

// Define the context
interface AppContextType {
  appState: AppState;
  setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

// Create a context
const AppContext = createContext<AppContextType | undefined>(undefined);

// Context provider component
export const AppContextProvider: React.FC<any> = ({ children }) => {
  const [appState, setAppState] = useState<AppState>({
    jobId: null,
  });

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to access the global state
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};
