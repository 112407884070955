import React from "react";
import { TickIcon } from "../../assets/images/AllSvg";
import { usePaymentCardData } from "../../API/api";
import CircularProgressBar from "../CircularProgressBar";

const PlanDisplay = () => {
  const { data: paymentCardData, isError, isLoading } = usePaymentCardData();

  const defaultBenefits = [
    { name: "Unlimited Projects" },
    { name: "24/7 Support" },
    { name: "Cloud Storage" },
  ];

  return (
    <div className="space-y-6 md:space-y-8">
      {isLoading ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <CircularProgressBar />
        </div>
      ) : isError ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            Error loading data
          </div>
        </div>
      ) : !paymentCardData || paymentCardData.length === 0 ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            No data found
          </div>
        </div>
      ) : (
        paymentCardData.map((plan: any, index: number) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg overflow-hidden shadow-lg"
          >
            <div className="flex flex-col md:flex-row">
              {/* Plan Info Section */}
              <div className="w-full md:w-2/3 bg-gradient-to-tr from-purple-500 via-pink-500 to-red-500 text-white p-6 md:p-8">
                <h2 className="text-xl md:text-2xl font-bold mb-2">
                  {plan.name}
                </h2>
                <p className="text-sm mb-4">{plan.description}</p>
                <div className="mt-4">
                  <span className="text-2xl md:text-3xl font-extrabold">
                    &#8377;{plan.price}
                  </span>
                  <span className="text-xs ml-2">
                    / {plan.validityDays} days validity
                  </span>
                </div>
              </div>

              {/* Benefits Section */}
              <div className="w-full md:w-1/3 bg-white p-6">
                <h3 className="text-lg font-semibold mb-2">{plan.name}</h3>
                <p className="text-sm text-gray-600 mb-4">{plan.description}</p>
                <div className="mb-4">
                  <span className="text-lg font-bold">&#8377;{plan.price}</span>
                  <span className="text-xs text-gray-500 ml-1">
                    / {plan.validityDays} days
                  </span>
                </div>
                <ul className="space-y-2">
                  {defaultBenefits.map((benefit, benefitIndex) => (
                    <li
                      key={benefitIndex}
                      className="flex items-center text-sm"
                    >
                      <TickIcon />
                      <span className="ml-2">{benefit.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PlanDisplay;
