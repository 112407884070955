import React from "react";
import { Dialog } from "@material-tailwind/react";
import { StarIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { classNames } from "../../utils/utils";
import { RiLoader2Fill } from "react-icons/ri";

interface EditReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  review: any;
  editedRating: number;
  editedReviewText: string;
  matchedLabourer: any;
  setEditedRating: (rating: number) => void;
  setEditedReviewText: (text: string) => void;
  isLoading: boolean;
}

const EditReviewModal: React.FC<EditReviewModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  review,
  editedRating,
  editedReviewText,
  matchedLabourer,
  setEditedRating,
  setEditedReviewText,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      handler={onClose}
      className="p-[20px] min-w-[100%] sm:min-w-[70%] lg:min-w-[996px] lg:max-h-[996px] mx-auto"
    >
      {review && (
        <div className="mt-5">
          <h1 className="text-[24px] text-black font-bold text-center mt-4 mb-6">
            {t("Edit Review")}
          </h1>
          <div className="flex flex-col mb-10 gap-y-5">
            <div className="px-4">
              <div className="flex items-center justify-between pb-1 text-lg font-medium text-black border-b-2">
                <div> {matchedLabourer.name}</div>
                <div className="flex">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <StarIcon
                      key={`edit_${rating}`}
                      className={classNames(
                        editedRating >= rating
                          ? "text-[#F0A61F]"
                          : "text-gray-200",
                        "h-5 w-5 flex-shrink-0 cursor-pointer"
                      )}
                      aria-hidden="true"
                      onClick={() => setEditedRating(rating)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <h1 className="pl-4 text-lg font-medium text-black">
                {t("Edit Your Review")}
              </h1>
              <div className="px-4">
                <textarea
                  value={editedReviewText}
                  onChange={(e) => setEditedReviewText(e.target.value)}
                  className="h-[210px] w-full rounded-xl bg-[#F8F8F8] mt-5 text-black font-normal text-lg p-2"
                  aria-label={t("Edit review text") as string}
                />
              </div>
            </div>
            <button
              onClick={onSubmit}
              disabled={isLoading}
              className={`w-full py-2 bg-[#272B2A] text-white font-semibold rounded-lg text-lg flex items-center justify-center ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <>
                  <RiLoader2Fill className="mr-2 h-6 w-6 animate-spin" />
                  {t("Updating...")}
                </>
              ) : (
                t("Update Review")
              )}
            </button>
          </div>
          <button
            className="absolute right-[20px] text-black top-[20px]"
            onClick={onClose}
            aria-label={t("Close") as string}
          >
            <XMarkIcon className="w-8 h-8" aria-hidden="true" />
          </button>
        </div>
      )}
    </Dialog>
  );
};

export default EditReviewModal;
