import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import CircularProgressBar from "../CircularProgressBar";
import { BankDetailsDto } from "../../API/api";
import { useTranslation } from "react-i18next";

interface BankDetailsTableProps {
  acountData: BankDetailsDto[] | undefined;
  handleEdit: (data: BankDetailsDto) => void;
  handleDeleteClick: (data: BankDetailsDto) => void;
  isLoading: boolean;
}

const BankDetailsTable: React.FC<BankDetailsTableProps> = ({
  acountData,
  handleEdit,
  handleDeleteClick,
  isLoading,
}) => {
  const { t } = useTranslation();

  const sortedData = acountData
    ? [...acountData].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    : [];
  const activeData = sortedData.filter((data) => data.isDefault);
  const nonActiveData = sortedData.filter((data) => !data.isDefault);

  return (
    <div className="overflow-x-auto relative">
      {activeData?.length === 0 && (
        <div className="bg-[#fbe0b1] md:p-4  p-2 mb-1 w-full">
          <span>{t("NoDefaultBankAcc")}</span>
        </div>
      )}

      {isLoading ? (
        <div className="mt-8 flex justify-center items-center">
          <CircularProgressBar />
        </div>
      ) : (
        <table className="w-full text-sm text-left mb-5 text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6 font-semibold">
                {t("Bank")}
              </th>
              <th scope="col" className="py-3 px-6 font-semibold">
                {t("IFSC Code")}
              </th>
              <th scope="col" className="py-3 px-6 font-semibold">
                {t("A/c No")}
              </th>
              <th scope="col" className="py-3 px-6 font-semibold">
                {t("Actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.length === 0 ? (
              <tr>
                <td colSpan={4} className="py-4 px-6 text-center text-gray-500">
                  {t("NoBankDetailsFound")}
                </td>
              </tr>
            ) : (
              <>
                {activeData?.map((data: BankDetailsDto, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="py-4 px-6">{t(data?.bankName)}</td>
                    <td className="py-4 px-6">{data?.ifsc}</td>
                    <td className="py-4 px-6">{data?.accountNumber}</td>
                    <td className="py-4 px-6 flex gap-5 justify-start items-center">
                      <AiFillEdit
                        className="text-blue-500 hover:text-blue-700 cursor-pointer text-xl"
                        onClick={() => handleEdit(data)}
                      />
                      <AiFillDelete
                        className="text-red-500 hover:text-red-700 cursor-pointer ml-4 text-xl"
                        onClick={() => handleDeleteClick(data)}
                      />
                      {data?.isDefault && (
                        <div className="ml-4">
                          <span className="inline-flex rounded-full bg-green-500 px-[14px] py-[3px] text-[13px] font-semibold leading-5 text-white">
                            {t("active")}
                          </span>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}

                {nonActiveData?.map((data: BankDetailsDto, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="py-4 px-6">{t(data?.bankName)}</td>
                    <td className="py-4 px-6">{data?.ifsc}</td>
                    <td className="py-4 px-6">{data?.accountNumber}</td>
                    <td className="py-4 px-6 flex gap-5 justify-start items-center">
                      <AiFillEdit
                        className="text-blue-500 hover:text-blue-700 cursor-pointer text-xl"
                        onClick={() => handleEdit(data)}
                      />
                      <AiFillDelete
                        className="text-red-500 hover:text-red-700 cursor-pointer ml-4 text-xl"
                        onClick={() => handleDeleteClick(data)}
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BankDetailsTable;
