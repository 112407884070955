import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useJobStatisticsData } from "../../API/api";
import { EmptyData } from "../chart/EmptyData";
import { PieChartIcon } from "../../assets/images/AllSvg";
import { t } from "i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const JobPieChartEmployer = () => {
  const {
    data: jobStatistics,
    isError,
    isLoading,
  } = useJobStatisticsData(false);
  const [selectedMonth, setSelectedMonth] = useState<string>("January");

  const jobStats = jobStatistics
    ? [
        jobStatistics.awarded,
        jobStatistics.accepted,
        jobStatistics.declined,
        jobStatistics.aborted,
      ]
    : [0, 0, 0, 0];

  const allValuesZero = jobStats.every((value) => value === 0);

  const statusColors = ["#B6FF59", "#FFCB45", "#36A2EB", "#FF6384"];
  const statusLabels = ["Awarded", "Accepted", "Declined", "Aborted"];

  const totalJobs = jobStats.reduce((sum, value) => sum + value, 0);

  // Filter out the zero values and their corresponding labels and colors
  const filteredJobStats = jobStats.filter((value) => value > 0);
  const filteredStatusLabels = statusLabels.filter(
    (_, index) => jobStats[index] > 0
  );
  const filteredStatusColors = statusColors.filter(
    (_, index) => jobStats[index] > 0
  );

  const data = {
    labels: filteredStatusLabels,
    datasets: [
      {
        data: filteredJobStats,
        backgroundColor: filteredStatusColors,
        borderColor: filteredStatusColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.label || "";
            const value = filteredJobStats[context.dataIndex];
            const percentage = ((value / totalJobs) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
      datalabels: {
        color: "white",
        font: {
          weight: "bold" as const,
          size: 14,
        },
        formatter: (_: any, ctx: any) => {
          const value = filteredJobStats[ctx.dataIndex];
          return value > 0 ? value.toString() : "";
        },
      },
    },
  };

  return (
    <>
      <div className="mb-4 flex justify-between p-6 items-center w-full ">
        <h2 className="text-xl font-bold mb-6 text-gray-800">
          {t("Job Statistics")}
        </h2>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : isError ? (
        <div className="text-center text-[#F0A61F] text-lg mt-3 h-full">
          {t("Error loading data")}
        </div>
      ) : allValuesZero ? (
        <EmptyData Icon={PieChartIcon} header={"job statistics"} />
      ) : (
        <div className="flex flex-col items-center w-full">
          <div className="mb-4 w-[200px] h-[200px] 2xl:w-[250px] 2xl:h-[250px]">
            <Pie data={data} options={options} />
          </div>

          <div className="flex items-center">
            {filteredStatusLabels.map((label, index) => {
              const value = filteredJobStats[index];
              const percentage = Math.round((value / totalJobs) * 100);
              return (
                <div key={index}>
                  <div key={index} className="flex gap-1  items-center ">
                    <div
                      className="inline-block w-3 h-3 rounded-full"
                      style={{ backgroundColor: filteredStatusColors[index] }}
                    ></div>
                    <div className="lg:mr-5 2xl:mr-5 xl:mr-5 mr-2 text-[8px] font-semibold md:text-xs lg:text-xs xl:text-xs 2xl:text-xs text-[#F0A61F]">
                      {t(label)}
                    </div>
                  </div>
                  <div className="text-[#2B3674] flex ml-7">
                    <span className="font-roboto font-bold 2xl:text-xl text-md leading-[30px] tracking-[2%]">
                      {percentage}%
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default JobPieChartEmployer;
