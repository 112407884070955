import { useTranslation } from "react-i18next";
import { JobAwardStatus } from "../component/JobStatistics/Employer";

interface NotificationPayload {
  title: string;
  body: string;
  icon?: string;
  data?: NotificationBaseData;
}

interface NotificationBaseData {
  type: string;
}

interface JobStatusPayload extends NotificationBaseData {
  jobStatus: string;
  jobId: string;
  jobAwardId: string;
}

export enum EventType {
  CHAT_MESSAGE = 1,
  SUBSCRIPTION_EXPIRY = 2,
  SUBSCRIPTION_PAYMENT = 3,
  JOB_STATUS_UPDATE = 4,
}

class FcmMessageHandler {
  static handlePayload(payload: any, translateFn: any) {
    if (payload.notification) {
      this.handleNotification(payload.notification);
    }
    if (payload.data) {
      return this.handleData(payload.data, translateFn);
    }
    return {
      title: translateFn("notification.chat.title"),
      body: translateFn("notification.chat.body"),
    };
  }

  private static handleNotification(notification: NotificationPayload): void {
    // Handle notification
  }

  private static handleData(data: NotificationBaseData, translateFn: any) {
    if (data.type == `${EventType.CHAT_MESSAGE}`) {
      return {
        title: translateFn("notification.chat.title"),
        body: translateFn("notification.chat.body"),
      };
    } else if (data.type == `${EventType.SUBSCRIPTION_PAYMENT}`) {
      return {
        title: translateFn("notification.subscriptionpayment.title"),
        body: translateFn("notification.subscriptionpayment.body"),
      };
    } else if (data.type == `${EventType.JOB_STATUS_UPDATE}`) {
      const payload = data as JobStatusPayload;

      if (payload.jobStatus == `${JobAwardStatus.REQUESTED}`) {
        return {
          title: translateFn("notification.jobstatus.title"),
          body: translateFn("notification.jobstatus.body"),
        };
      }

      return {
        title: "",
        body: "",
      };
    } else {
      //Unknown event received
      return {
        title: "",
        body: "",
      };
    }
  }
}

export default FcmMessageHandler;
