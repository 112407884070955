import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  usePreviousSearchDetails,
  useUserMeQuery,
  useServiceCategoryQuery,
} from "../../API/api";
import moment from "moment";
import { BiSearch, BiMapPin, BiCalendar, BiGroup } from "react-icons/bi";
import { EmptyData } from "../chart/EmptyData";
import { PieChartIcon } from "../../assets/images/AllSvg";

interface SearchData {
  location: string;
  createdAt: string;
  resultCount: number;
  skills: string;
}

interface SkillItems {
  id: number;
  name: string;
}

const SearchHistory: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useUserMeQuery();
  const userId = userData?.user.id;
  const { data: skillData } = useServiceCategoryQuery();
  const {
    data: searchHistoryData,
    isLoading,
    isError,
  } = usePreviousSearchDetails(userId);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [searchHistoryData]);

  if (isError) {
    return (
      <div className="flex items-center justify-center h-full md:pb-20 pb-10">
        <div className="text-center text-[#F0A61F] text-lg font-semibold">
          {t("Error loading data")}
        </div>
      </div>
    );
  }

  if (searchHistoryData?.length === 0) {
    return <EmptyData Icon={PieChartIcon} header={"search history"} />;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mx-auto w-full">
      <h2 className="text-xl font-bold mb-6 text-gray-800">
        {t("Search History")}
      </h2>
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div
          ref={scrollRef}
          className="overflow-y-auto max-h-[40vh] pr-4 space-y-4"
          style={{ scrollbarWidth: "thin", scrollbarColor: "#F0A61F #E5E7EB" }}
        >
          {searchHistoryData?.map((data: SearchData, index: number) => {
            const skillIds = JSON.parse(data?.skills)[0];
            const skill = skillData?.find((s: SkillItems) => s.id === skillIds);
            return (
              <div
                key={index}
                className="bg-gray-50 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300"
              >
                <div className="flex items-center gap-2 mb-2">
                  <BiSearch className="text-[#F0A61F]" size={20} />
                  <span className="font-semibold text-gray-800">
                    {skill ? (
                      <span className="bg-orange-200 text-[13px] py-1 px-3 rounded-full text-secondary">
                        {t(skill.name)}
                      </span>
                    ) : (
                      t("Unknown Service")
                    )}
                  </span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 text-sm text-gray-600">
                  <div className="flex items-center gap-2">
                    <BiMapPin size={16} className="text-gray-400" />
                    <span>{t(data?.location || "Kochi")}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <BiCalendar size={16} className="text-gray-400" />
                    <span>{moment(data?.createdAt).format("DD/MM/YYYY")}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <BiGroup size={16} className="text-gray-400" />
                    <span>
                      {data?.resultCount} {t("results")}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchHistory;
