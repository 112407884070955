import { useCallback, useEffect, useRef, useState } from "react";
import io, { Socket } from "socket.io-client";
import { IMessage, MessageType } from "./api";
import { _token } from "../utils/jwtUtils";

type Props = {
  chatRoomId: number;
  enabled: boolean;
  onMessage: (message: IMessage) => void;
};

enum Event {
  connect = "connect",
  error = "error",
  connect_error = "connect_error",
  disconnect = "disconnect",
  ChatMessage = "ChatMessage",
  JoinRoom = "JoinRoom",
  reconnect = "reconnect",
}

/**
 *  this dto is used for backend call.
 */
export interface MessageDto {
  room: number;
  message: string;
  mediaUrl?: string;
  location?: string;
  type?: MessageType;
}

export const useWebSockets = ({ chatRoomId, onMessage }: Props) => {
  const { REACT_APP_API_URL } = process.env;

  //console.log('chatRoomId ' + chatRoomId)

  const socketRef = useRef<Socket>();
  //const [messages, setMessages] = useState<IMessage[]>([]);
  const [isConnected, setConnected] = useState(false);

  const send = (msg: MessageDto) => {
    socketRef.current!.emit(Event.ChatMessage, msg);
  };

  const _onConnect = () => {
    console.log("_onConnect");
    setConnected(true);
  };

  const onConnectError = (err : any) => {
    console.log("onConnectError " + err.message);
    setConnected(false);
  };

  const onError = () => {
    console.log("onError");
    setConnected(false);
  };

  const onDisconnect = () => {
    console.log("onDisconnect");
    setConnected(false);
  };

  const onReconnect = () => {
    console.log("onReconnect");
    setConnected(true);
    socketRef.current?.emit(Event.JoinRoom, { room: chatRoomId });
  };

  useEffect(() => {
    if (chatRoomId == undefined) return;
    const socket = io(`${REACT_APP_API_URL}/chats`, {
      query: {
        chatRoomId,
        Authorization: `Bearer ${_token()}`,
      },
      transports: ["websocket"],
    });
    socket.on(Event.connect, _onConnect);
    socket.on(Event.connect_error, onConnectError);
    socket.on(Event.error, onError);
    socket.on(Event.disconnect, onDisconnect);
    socket.on(Event.ChatMessage, handleChatMessage);
    socket.emit(Event.JoinRoom, { room: chatRoomId });
    socket.on(Event.reconnect, onReconnect);

    socketRef.current = socket;

    return () => {
      //console.log("on hook unmounted");
      socket.off(Event.connect, _onConnect);
      socket.off(Event.connect_error, onConnectError);
      socket.off(Event.error, onError);
      socket.off(Event.disconnect, onDisconnect);
      socket.off(Event.ChatMessage, handleChatMessage);
      socket.off(Event.reconnect, onReconnect);
      socket.disconnect();
    };
  }, [chatRoomId]);

  const handleChatMessage = useCallback((msg: any) => {
    onMessage(msg);
  }, []);

  return {
    send,
    isConnected,
  };
};
