import React, { useContext, useEffect, useRef, useState } from "react";
import { Megnify, Filter } from "../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurServices from "../component/SearchServices/OurServices";
import PreviousSearch from "../component/SearchServices/PreviousSearch";
import Filtermodel from "../component/Models/Filtermodel";
import {
  useLanguagesQuery,
  useSearchLabourer,
  usePreviousSearchDetails,
} from "../API/api";
import MapComponent from "../component/GoogleMapComponent";
import useGeolocation, {
  AddressDetails,
  LocationData,
} from "../utils/useGeolocation";
import { SearchResult, Service } from "../API/types";
import CircularProgressBar from "../component/CircularProgressBar";
import {
  NavigationType,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { ItemDataContext } from "../component/Context/contextData";
import SearchResultComponent from "../component/SearchServices/ProfileSearchServices";
import LocationSelector from "./Location/LocationSelector";
import { useLocationContext } from "../component/Context/LocationContext";

export enum DisplayMode {
  Default = 0,
  SearchResult = 1,
  RecentSearches = 2,
  ServiceList = 3,
}

const defaultLocation = {
  city: "Ahmedabad",
  state: "Gujarat",
  country: "India",
};

const defaultSearchId = {
  id: null,
  name: "",
};

export interface SearchQuery {
  searchInput: string;
  location: LocationData | null;
  address: AddressDetails | null;
  searchId: { id: number | null; name: string };
}

interface ServiceProviderResultProps {
  serviceData: Array<Service> | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setMethod: React.Dispatch<React.SetStateAction<string>>;
  selectedStatus: string;
  _displayMode: DisplayMode;
  handleSearchQuerySave: (searchQuery: SearchQuery) => void;
  savedSearchQuery: SearchQuery | null;
}

const ServiceProviderResult = ({
  serviceData,
  setOpen,
  selectedStatus,
  _displayMode = DisplayMode.Default,
  handleSearchQuerySave,
  savedSearchQuery,
}: ServiceProviderResultProps) => {
  const { t } = useTranslation();

  const navigationType = useNavigationType();
  const { itemData, setItemData } = useContext(ItemDataContext);
  const { state } = useLocationContext();

  const isPagePoped = navigationType === NavigationType.Pop && itemData;

  console.log("navigationType " + navigationType + " " + itemData?.length);
  console.log("savedSearchQuery " + savedSearchQuery);

  useEffect(() => {
    if (isPagePoped) {
      console.log("navigationType " + navigationType + " " + itemData.length);
      setSearchQResult(itemData);
    } else {
    }
  }, [navigationType]);

  const [searchInput, setSearchInput] = useState<string>(
    isPagePoped ? savedSearchQuery?.searchInput ?? "" : ""
  );
  const [locationData, setLocationData] = useState<LocationData | null>(
    isPagePoped ? savedSearchQuery?.location ?? null : null
  );
  const [selectedLocation, setSelectedLocation] =
    useState<null | AddressDetails>(
      isPagePoped
        ? savedSearchQuery?.address ?? defaultLocation
        : defaultLocation
    );
  const [searchId, setSearchId] = useState<{ id: number | null; name: string }>(
    isPagePoped
      ? savedSearchQuery?.searchId ?? defaultSearchId
      : defaultSearchId
  );
  const [resData, setResData] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState<SearchResult[]>();
  const placeholderText = t("Searchforservices") || "";
  const [showPopover, setShowPopover] = useState(false);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(_displayMode);
  const { location, selectedName, error, detectLocation } = useGeolocation();
  const { data: languages } = useLanguagesQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<number[]>([]);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [showFilterButton, setShowFilterButton] = useState<boolean>(false);
  const {
    isLoading,
    data: searchResult,
    isFetched,
    fetchStatus,
  } = useSearchLabourer(
    state?.selectedLocationData?.lat,
    state?.selectedLocationData?.lng,
    searchId?.id,
    selectedLanguages,
    selectedSkill,
    currentPage,
    limit,
    selectedSortOption
  );

  const totalItems = searchResult?.total ?? 0;
  const offset = searchResult?.offset ?? 0;
  const [searchQResult, setSearchQResult] = useState<SearchResult[]>();

  useEffect(() => {
    if (searchResult) {
      setItemData(searchResult?.data ?? []);
    }
    setSearchQResult(searchResult?.data ?? []);
  }, [searchResult]);

  useEffect(() => {
    setLocationData(location);
    setSelectedLocation(selectedName);
    if (error) {
      console.log(error);
    }
  }, [location, selectedName, error]);

  useEffect(() => {
    setSelectedLocation({
      city: t("Ahmedabad"),
      state: t("Gujarat"),
      country: t("India"),
    });
  }, []);

  const handleTogglePopover = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowPopover(!showPopover);
  };

  const handlePlaceSelect = (place: any) => {
    setLocationData({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    });
    setSelectedLocation({
      city: JSON.parse(JSON.stringify(place?.address_components[0]?.long_name)),
      state: JSON.parse(
        JSON.stringify(
          place?.address_components[2]?.long_name
            ? place.address_components[1]?.long_name
            : place?.address_components[2]?.long_name
        )
      ),
      country: JSON.parse(
        JSON.stringify(
          place?.address_components[3]?.long_name +
            " , " +
            place?.address_components[4]?.long_name
        )
      ),
    });
  };
  const onServiceSelected = (service: Service) => {
    setSearchId({
      id: service.id,
      name: service.name,
    });
    setDisplayMode(DisplayMode.SearchResult);
    setShowFilterButton(true);
    // refetchSearchHistory();
  };

  const handleDetectLocation = () => {
    detectLocation();
  };

  const handleFilteredData = (filteredData: any) => {
    setResData(filteredData);
    setFilteredData(filteredData);
  };
  const navigate = useNavigate();
  const onServiceProviderClicked = (item: SearchResult) => {
    handleSearchQuerySave({
      searchInput: searchInput!,
      location: locationData,
      address: selectedLocation,
      searchId: searchId,
    });
    navigate("/details", {
      state: { details: item, alldata: searchQResult, favLabourerId: item?.id },
    });
  };

  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        popoverRef.current &&
        !(popoverRef?.current as any)?.contains(event.target)
      ) {
        setShowPopover(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [showPopover]);

  return (
    <>
      <div className="pt-10 ">
        <h1 className="text-[#162634] lg:text-3xl text-[7vw] font-bold pb-5">
          {t("whatServiceDoYouNeed")}
        </h1>
        <div className="flex gap-[14px] lg:flex-nowrap flex-wrap ">
          <div className="relative lg:mt-0 mt-4 h-full max-w-[767px] w-full">
            <div className="absolute flex items-center justify-center top-0 bottom-0  w-[60px] h-full">
              <Megnify className={"#272D29"} />
            </div>
            <input
              className="border border-solid outline-none focus:outline-none pl-[60px] py-[18px] rounded-[14px]  w-full placeholder:text-[#999485] text-black  border-[#B9B9B9] text-[21px] leading-[24px] font-medium placeholder:4xl:text-[1vw]"
              type="text"
              placeholder={placeholderText}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  //searchLabour(searchInput);
                }
              }}
            />
          </div>
          <div className="flex gap-3 justify-between w-full">
            <LocationSelector />

            <div>
              {showFilterButton && (
                <button
                  onClick={() => setOpenFilter(true)}
                  className="border-[#B9B9B9] border-[1px] rounded-[14px] sm:p-[23px] p-[20px]"
                >
                  <Filter />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 flex-wrap pt-4 md:pt-8 justify-end">
          <button
            className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw] whitespace-nowrap"
            onClick={() => setDisplayMode(DisplayMode.ServiceList)}
          >
            {t("OurServices")}
          </button>
          <button
            className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw] whitespace-nowrap"
            onClick={() => setDisplayMode(DisplayMode.RecentSearches)}
          >
            {t("PreviousSearches")}
          </button>
        </div>
      </div>
      {searchQResult && searchQResult?.length > 0 ? (
        <>
          {displayMode === DisplayMode.ServiceList ? (
            <OurServices
              services={serviceData}
              searchInput={searchInput}
              onServiceCategorySelected={onServiceSelected}
            />
          ) : displayMode === DisplayMode.RecentSearches ? (
            <PreviousSearch searchInput={searchInput} />
          ) : (
            <SearchResultComponent
              searchData={searchQResult}
              setOpen={setOpen}
              totalItems={totalItems}
              searchName={searchId.name}
              filteredData={filteredData ?? []}
              setFilteredData={setFilteredData}
              selectedStatus={selectedStatus}
              services={serviceData}
              onServiceProviderClicked={onServiceProviderClicked}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              offset={offset}
            />
          )}
        </>
      ) : (
        <>
          {displayMode === DisplayMode.ServiceList ||
          displayMode === DisplayMode.Default ? (
            <OurServices
              services={serviceData}
              searchInput={searchInput}
              onServiceCategorySelected={onServiceSelected}
            />
          ) : null}
          {displayMode === DisplayMode.RecentSearches ||
          displayMode === DisplayMode.Default ? (
            <PreviousSearch searchInput={searchInput} />
          ) : null}
          {isLoading && displayMode === DisplayMode.SearchResult ? (
            <div className="flex items-center justify-center mt-8">
              <CircularProgressBar />
            </div>
          ) : (
            !isLoading && (
              <p className="mt-8 text-center">{t("No results found.")}</p>
            )
          )}
        </>
      )}
      <Filtermodel
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        serviceData={serviceData}
        languages={languages}
        searchData={resData || []}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
      />
    </>
  );
};

export default ServiceProviderResult;
