import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";

if ("serviceWorker" in navigator) {
  const firebaseConfig = {
    apiKey: "AIzaSyD8rCw8IGc1JgTsyWc1bgOol4pX_m7bti0",
    authDomain: "eaidexfirenote.firebaseapp.com",
    projectId: "eaidexfirenote",
    storageBucket: "eaidexfirenote.appspot.com",
    messagingSenderId: "704256427947",
    appId: "1:704256427947:web:462595381c9f20ff1b74ee",
    measurementId: "G-YBPZ9GWB3V",
  };

  firebase?.initializeApp(firebaseConfig);
}
export default firebase;
