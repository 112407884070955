import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useUserMeQuery } from "../../API/api";
import { useTranslation } from "react-i18next";
import { FaAward, FaCheckCircle } from "react-icons/fa";

const AwardThisJob = ({
  openAwardMenu,
  setOpenAwardMenu,
}: {
  setOpenAwardMenu: React.Dispatch<React.SetStateAction<boolean>>;
  openAwardMenu: boolean;
}) => {
  const navigate = useNavigate();
  const { data: userData, refetch } = useUserMeQuery();
  const { t } = useTranslation();

  const handleConfirmClick = async () => {
    setOpenAwardMenu(false);
    const userRoles = userData?.user?.roles || [];
    if (userRoles.length === 1 && userRoles.includes("labourer")) {
      await refetch();
    }
    navigate("/jobstatistics?tab=employer", { replace: true });
  };

  return (
    <Transition.Root show={openAwardMenu} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setOpenAwardMenu(false)}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:align-middle">
              <div className="bg-gradient-to-r from-green-400 to-green-600 px-6 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-t-lg">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <FaAward
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-4">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-white"
                    >
                      {t("Job Awarded")}
                    </Dialog.Title>
                  </div>
                </div>
              </div>

              <div className="bg-white px-8 py-6 sm:p-6">
                <p
                  id="award-description"
                  className="text-lg text-gray-800 leading-relaxed"
                >
                  {t(
                    "You have successfully awarded this job. Please wait for the confirmation from the labourer."
                  )}
                </p>
              </div>

              <div className="bg-gray-50 px-6 py-4 sm:flex sm:flex-row-reverse rounded-b-lg">
                <button
                  type="button"
                  className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700  sm:ml-3 sm:w-auto"
                  onClick={handleConfirmClick}
                >
                  <FaCheckCircle className="mr-2 h-5 w-5" aria-hidden="true" />
                  {t("Okay")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AwardThisJob;
