import Slider, { Settings } from "react-slick";
import { Usericonbig } from "../assets/images/AllSvg";
import { AiFillHeart } from "react-icons/ai";
import { StarIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useFavouritesBy, useUserMeQuery, useGetFavourites } from "../API/api";
import { useNavigate } from "react-router-dom";
import { classNames, hasRole } from "../utils/utils";
import { UserRole } from "../API/types";
import CircularProgressBar from "../component/CircularProgressBar";
import { useCompleteImageUrl } from "../utils/GeneralUtils";

var settings: Settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Favourites = ({ serviceData }: { serviceData: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: userData } = useUserMeQuery();
  const hasLabourerRole = hasRole(userData?.user, UserRole.LABOURER);
  const { data: favourites, isLoading: favouriteLoading } = useGetFavourites();
  const getSkillName = (skillId: number) => {
    const skill = serviceData?.find((skill: any) => skill.id === skillId);
    return skill ? skill.name : "";
  };
  const { data: favouritesBy, isLoading: favouritesByLoading } =
    useFavouritesBy(hasLabourerRole);
  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();
  const labourerDiv = () => {
    return (
      hasRole(userData?.user, UserRole.LABOURER) && (
        <>
          <div className="flex flex-col gap-2 mt-2">
            <h1 className="text-[#272D29] text-[24px] font-semibold">
              {t("Favourites")}
            </h1>
            <p className="font-medium text-lg text-[#272D29]">
              {t("FavouritesLiistPara1")}
            </p>
            <p className="font-medium text-lg text-[#272D29]">
              {t("FavouritesLiistPara2")}
            </p>
          </div>
          <div className="flex items-center gap-4 mt-10">
            <p className="font-semibold md:text-[22px] text-[18px] text-left text-[#272D29]">
              {t("No of employers added you as their favourite")}:
            </p>
            <div className="w-[104px] h-[48px] bg-[#F0A61F] text-white border border-[#D1D0CA] font-semibold text-[22px] rounded flex justify-center items-center">
              {favouritesBy?.length}
            </div>
          </div>
          {favouritesBy?.length !== 0 ? (
            <div className="mt-9 bg-[#FFFCF6] py-10 pt-[102px] px-6">
              <div className="detailslider">
                <Slider {...settings}>
                  {favouritesBy?.map((_item: any) => {
                    return Item(_item);
                  })}
                </Slider>
              </div>
            </div>
          ) : (
            emptyState()
          )}
        </>
      )
    );
  };

  const emptyState = () => {
    return (
      <div className="mt-9 bg-[#FFFCF6] h-[166px] px-6 flex items-center justify-center font-semibold text-lg">
        {t("noFavoritesYet")}
      </div>
    );
  };
  const ItemCard = (item: {
    id: number;
    labourerId: number;
    name: string;
    rating: number;
    reviewsCount: number;
    labourerimage: any;
    skills: any[];
    location: string;
  }) => {
    return (
      <div
        key={item?.id}
        className="border-[1px] border-[#BBBBBB] rounded-[10px] w-full m-auto"
        onClick={() =>
          navigate("/details", {
            state: {
              details: item,
              favLabourerId: item?.labourerId,
              isFav: true,
            },
          })
        }
      >
        <div className="relative">
          <div className="bg-[#00C54F] border-[#FFFFFF] border-[1px] w-[12px] h-[12px] rounded-full absolute top-[-4px] left-[-4px]" />
          <img
            src={
              item?.labourerimage
                ? getCompleteImageUrl(item?.labourerimage)
                : require(`../assets/images/user_default.png`)
            }
            alt="profile"
            className="rounded-t-[10px] 2xl:h-[290px] h-[220px] object-cover w-full"
          />

          <button className="bg-[#FBE9C7]  rounded-[34px] w-[44px] h-[44px] flex justify-center items-center absolute top-[15px] right-[20px]">
            <AiFillHeart className="w-6 h-6 text-red-600 transition-colors duration-300" />
          </button>
        </div>
        <div className="sm:p-[16px_20px_32px_25px] p-[16px_16px_22px_16px]">
          <h1 className="text-[#272D29] text-[22px] font-semibold">
            {item?.name}
          </h1>
          <div className="flex gap-[6px] mt-[6px]">
            <div className="flex items-center">
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  className={classNames(
                    item?.rating > rating ? "text-[#F0A61F]" : "text-[#d3d3d3]",
                    "h-5 w-5 flex-shrink-0"
                  )}
                  aria-hidden="true"
                />
              ))}
            </div>
            <p className="text-[#7C786D] font-roboto">
              {item?.reviewsCount} {t("Reviews")}
            </p>
          </div>
          <div className="flex gap-2 mt-[7px]">
            {item?.skills?.map((service: any) => (
              <>
                <button className="bg-[#E9E8E3] rounded-[24px] tracking-[1px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]">
                  {getSkillName(service?.skillId)}
                </button>
              </>
            ))}
          </div>
          <p className="text-[17px] mt-[17px] tracking-[1px] text-[#7C786D]">
            {t(item?.location)}
          </p>
        </div>
      </div>
    );
  };

  const Item = (item: { id: number; name: string }) => {
    return (
      <div
        className="h-[295px] border border-[#BBBBBB] bg-[#FFFFFF] rounded-[10px] relative mt-[10px]"
        key={item?.id}
      >
        <div className="relative h-[223px] overflow-hidden rounded-t-[8px] bg-[#E7E8E9] flex items-center justify-center">
          <Usericonbig />
        </div>
        <div className="h-[12px] w-[12px] rounded-full bg-[#00C54F] absolute -top-1 -left-1 border border-white"></div>
        <h1 className="text-center text-[#272D29] text-[22px] font-semibold mt-5">
          {t(item?.name)}
        </h1>
      </div>
    );
  };

  return (
    <div className="mt-8 bg-[#F5F5F5]">
      <div>
        {favouriteLoading && (
          <div className="flex items-center justify-center h-screen">
            <CircularProgressBar />
          </div>
        )}

        {labourerDiv()}
      </div>

      <>
        {hasRole(userData?.user, UserRole.EMPLOYER) && (
          <div className="mt-10 mb-[43px]">
            <h1 className="text-[#272D29] text-[24px] font-semibold">
              {t("Your Favourites List")}({favourites?.length})
            </h1>
            <p className="font-medium text-lg text-[#272D29] mt-8">
              {t("FavouritesLiistPara")}
            </p>

            {favourites?.length !== 0 ? (
              <div className="grid lg:grid-cols-3 mt-[34px] xl:gap-[30px] gap-[14px]">
                {favourites?.map((item: any) => {
                  return ItemCard(item);
                })}
              </div>
            ) : (
              <div className="mt-9 bg-[#FFFCF6] h-[166px] px-6 flex items-center justify-center font-semibold text-lg">
                {t("noFavoritesYet")}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default Favourites;
