import { useTranslation } from "react-i18next";
import { useUserMeQuery } from "../API/api";
import Labourer from "../component/JobStatistics/Labourer";
import Employer from "../component/JobStatistics/Employer";
import { useEffect, useState } from "react";
import CircularProgressBar from "../component/CircularProgressBar";
import { useLocation, useNavigate } from "react-router-dom";

const JobStatistics = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading } = useUserMeQuery();
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    if (userData?.user?.roles) {
      const userRoles = userData.user.roles;

      if (tabParam && userRoles.includes(tabParam)) {
        setActiveTab(tabParam);
      } else {
        setActiveTab(userRoles[0]);
      }
    }
  }, [userData, location]);

  const handleTabClick = (role: string) => {
    setActiveTab(role);
    navigate(`/jobstatistics?tab=${role}`, { replace: true });
  };

  return (
    <div>
      <div className="text-2xl text-[#272D29] font-semibold my-8">
        {t("Job Statistics")}
      </div>
      {isLoading ? (
        <div className="mt-8 flex justify-center items-center">
          <CircularProgressBar />
        </div>
      ) : (
        <div>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {userData?.user?.roles?.map((role: any) => (
              <a
                key={role}
                className={`text-black hover:text-gray-700 whitespace-nowrap border-b-2 px-10 text-[19px] font-medium cursor-pointer ${
                  activeTab === role ? "border-b-2 border-[#F0A61F]" : ""
                }`}
                onClick={() => handleTabClick(role)}
              >
                {role === "labourer" && <span>{t("Labourer")}</span>}
                {role === "employer" && <span>{t("Employer")}</span>}
              </a>
            ))}
          </nav>
        </div>
      )}
      {activeTab === "labourer" && <Labourer />}
      {activeTab === "employer" && <Employer />}
    </div>
  );
};

export default JobStatistics;
