import React, { useRef, useEffect } from "react";
interface Section {
  label: string;
  ref: React.RefObject<any>;
}

interface SidebarProps {
  sections: Section[];
  visible: boolean;
  close: () => void;
  onClose: (ref: React.RefObject<any>) => void;
}

function Menubar({ sections, visible, onClose, close }: SidebarProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        close();
      }
    }
    if (visible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible, close]);

  return (
    <div
      ref={menuRef}
      className={`fixed top-36 left-0 z-40 w-72 h-screen p-2 pb-36 overflow-y-auto transition-transform ${
        visible ? "" : "-translate-x-full"
      } bg-[#f3f4f6] transition-all duration-500`}
      aria-labelledby="drawer-navigation-label"
    >
      <button
        type="button"
        onClick={() => close()}
        className="text-gray-500 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-1 right-1 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="py-4">
        <ul className="space-y-3 font-medium">
          {sections?.map((section, index) => (
            <li key={index} className="relative">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose(section.ref);
                }}
                className="width-full text-gray-500 hover:text-gray-900 text-sm duration-200 ease-in-out text-left"
              >
                {section.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Menubar;
