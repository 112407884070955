import React from "react";
import { FaUserTie, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface BecomeLabourerModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}
const BecomeLabourerModal: React.FC<BecomeLabourerModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
      <div className="bg-gradient-to-br from-white to-blue-50 p-4 sm:p-6 rounded-xl shadow-2xl w-full max-w-xs sm:max-w-2xl m-4 transition-all duration-300 ease-in-out transform">
        <div className="flex flex-col sm:flex-row items-center sm:space-x-6">
          <div className="flex-shrink-0 mb-4 sm:mb-0">
            <div className="bg-blue-100 p-3 sm:p-4 rounded-full">
              <FaUserTie className="text-blue-600 text-3xl sm:text-4xl" />
            </div>
          </div>
          <div className="flex-grow text-center sm:text-left">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">
              {t("Become an Employer")}
            </h2>
            <p className="text-sm sm:text-base">
              {t("You wish to become an employer (Service Seeker) too?")}
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4 mt-6">
          <button
            onClick={onCancel}
            className="px-4 sm:px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-300 text-sm font-medium flex items-center justify-center"
          >
            <FaTimesCircle className="mr-2" /> {t("No, Thanks")}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 sm:px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300 text-sm font-medium flex items-center justify-center"
          >
            <FaCheckCircle className="mr-2" /> {t("Yes, I'm In!")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BecomeLabourerModal;
