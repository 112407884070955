import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RegisterSchema } from "../../schema/RegisterSchema";
import { useMutation } from "@tanstack/react-query";
import { signupUser } from "../../API/api";
import { clearVerifiedToken, setToken } from "../../utils/jwtUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type EmployerRegistrationProps = {
  phoneNumber: string;
  onLoginLinkClicked: () => void;
  onSignupComplete: () => void;
};

const EmployerRegistration = (props: EmployerRegistrationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register: registerForm,
    handleSubmit: registerFormSubmission,
    formState: {
      errors: registerFormErrors,
      isValid: registerFormIsValid,
      isDirty: registerFormIsDirty,
    },
    control: registerFormControl,
    getValues: registerFormGetValues,
    reset: registerFormReset,
  } = useForm({
    resolver: yupResolver(RegisterSchema),
    mode: "onBlur",
  });

  const signUpMutation = useMutation(signupUser, {
    onSuccess: (data) => {
      if (data.accessToken) {
        registerFormReset();
        clearVerifiedToken()
        setToken(data.accessToken);
        //toast.success('');
        //console.log('location?.pathname ' + location?.pathname)
        props.onSignupComplete();
      }
    },
    onError(error: any, variables, context) {
      toast.error("Please enter the valid details");
    },
  });

  const handleSubmit = (formData: any) => {
    const updatedformData = {
      ...formData,
      userType: "employer",
    };
    signUpMutation.mutate(updatedformData);
    registerFormReset();
  };

  return (
    <form onSubmit={registerFormSubmission(handleSubmit)}>
      <div className="max-w-[480px] h-[80vh] flex flex-col justify-center">
        <div className="items-center justify-between sm:flex">
          <img
            src={require("../../assets/images/login-vector.png")}
            className="sm:hidden"
          />
          <div className="mt-6 sm:mt-0">
            <h1 className="text-[#272D29] md:text-[36px] text-[30px] font-bold">
              {t("Signup")}
            </h1>
          </div>
          <img
            src={require("../../assets/images/login-vector.png")}
            className="sm:mt-[-50px] sm:block hidden"
          />
        </div>
        <div>
          <input
            value={props.phoneNumber}
            type="text"
            placeholder={t("MobileNumber") || ""}
            // name="phone"
            className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
            // onChange={(e) => {
            //   handleLoginOnChange(e);
            //   handleSignUpOnChange(e);
            // }}
            {...registerForm("phone")}
          />
          <p className="text-[16px] text-red-600">
            {registerFormErrors.phone?.message}
          </p>
          <input
            type="text"
            placeholder={t("Name") || ""}
            // name="name"
            // onChange={handleSignUpOnChange}
            {...registerForm("name")}
            className="border-[#D1D0CA] border-[1px] rounded mt-[16px] w-full h-[48px] pl-3  focus:outline-none"
          />
          <p className="text-[16px] text-red-600">
            {registerFormErrors.name?.message}
          </p>
        </div>
        <button
          // onClick={handleSubmit}
          className="bg-[#F0A61F] rounded text-white mt-[32px] w-full h-[44px]"
          type="submit"
        >
          {t("Continue")}
        </button>
        <div className="flex justify-between items-center mt-[24px]">
          <p className="text-[#272D29]">
            {t("AlreadyHaveanAccount")}{" "}
            <a
              className="underline text-[#F0A61F]"
              onClick={() => {
                props.onLoginLinkClicked();
              }}
            >
              {t("Login")}
            </a>
          </p>
          <a
            className="underline text-[#F0A61F]"
            onClick={() => {
              navigate("/faqs");
            }}
          >
            {t("FAQ")}
          </a>
        </div>
      </div>
    </form>
  );
};

export default EmployerRegistration;
