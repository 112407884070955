import React from "react";
import { Bar } from "react-chartjs-2";
import {
  GetMonthlyProfileViews,
  GetTotalProfileViews,
  ProfileViewMonthDto,
  useUserMeQuery,
} from "../../API/api";
import CircularProgressBar from "../CircularProgressBar";
import { ChartOptions } from "chart.js";
import { EmptyData } from "../chart/EmptyData";
import { BarChartIcon } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";

const createGradient = (ctx: any, chartArea: any) => {
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.top,
    0,
    chartArea.bottom
  );
  gradient.addColorStop(0, "#50E3C1");
  gradient.addColorStop(1, "#0E8D70");
  return gradient;
};

const ProfileViewsChart = () => {
  const { t } = useTranslation();

  const { data: userData } = useUserMeQuery();
  const labourerId = userData?.labourerDetails?.id;
  const {
    data: profileViewsData,
    isLoading,
    isError,
  } = GetMonthlyProfileViews(labourerId);

  const sortedProfileViewsData = (profileViewsData ?? [])
    .sort((a, b) => a.monthIndex - b.monthIndex)
    .slice(0, 6);

  const labels = sortedProfileViewsData.map((dataPoint) => dataPoint.month);
  const profileViewsValues = (sortedProfileViewsData ?? []).map(
    (dataPoint: ProfileViewMonthDto) => dataPoint.profileViews
  );
  const { data: totalViewData } = GetTotalProfileViews(labourerId);
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Profile Visitors",
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return createGradient(ctx, chartArea);
        },

        borderColor: "#0E8D70",
        borderWidth: 1,
        hoverBackgroundColor: "#0E8D70",
        minBarLength: 4,
        maxBarThickness: 25,

        data: profileViewsValues,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        color: "black",

        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        stacked: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
        min: 0,
        suggestedMax: Math.max(...profileViewsValues) * 1.2,
      },
    },
  };

  const totalVisitors = totalViewData?.length
    ? totalViewData[0]?.profileViews ?? 0
    : 0;

  return (
    <>
      <div className="mb-4 flex justify-start p-2 items-center w-full ">
        <div>
          <div className="flex flex-col gap-3 ">
            <div className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto ">
              {t("Profile Views")}
            </div>
            <div className="flex gap-2">
              <div className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg ">
                {totalVisitors}
              </div>
              <div className="block font-base font-roboto 2xl:text-base md:text-base text-[14px] lg:text-base text-[#D0B9A3]">
                {t("Visitors")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-full md:pb-20  pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            <CircularProgressBar />
          </div>
        </div>
      ) : isError ? (
        <div className="flex items-center justify-center h-full md:pb-20  pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            {t("Error loading data")}
          </div>
        </div>
      ) : totalVisitors === 0 ? (
        <EmptyData Icon={BarChartIcon} header={"profile views"} />
      ) : (
        <div className="flex flex-col items-center w-full  pb-8">
          <Bar data={data} options={options} />
        </div>
      )}
    </>
  );
};

export default ProfileViewsChart;
