import * as yup from "yup";
import {
  GSTIN_REQUIRED,
  BUSINESS_NAME_REQUIRED,
  OPENING_TIME_REQUIRED,
  CLOSING_TIME_REQUIRED,
  WORKING_DAY_REQUIRED,
  WORKINGDAY_REQUIRED,
  GSTIN_LENGTH_REQUIRED,
} from "../constants/ValidationMessage";

export type WorkingDay = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";
export const daysOfWeek: WorkingDay[] = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

// TIME OPTION GENERATOR FUNCTION
export const generateTimeOptions = (): string[] => {
  const options: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of ["00", "30"]) {
      const formattedHour = hour % 12 || 12;
      const period = hour < 12 ? "AM" : "PM";
      options.push(`${formattedHour}:${minute}${period}`);
    }
  }
  return options;
};

const timeOptions = generateTimeOptions();

export const businessRegisterSchema = yup.object().shape({
  businessName: yup.string().required(BUSINESS_NAME_REQUIRED),
  gstin: yup
    .string()
    .required(GSTIN_REQUIRED)
    .length(15, GSTIN_LENGTH_REQUIRED),
  workingDays: yup
    .array()
    .of(yup.mixed<WorkingDay>().oneOf(daysOfWeek).required())
    .min(1, WORKINGDAY_REQUIRED)
    .required(WORKING_DAY_REQUIRED),
  openingTime: yup.string().required(OPENING_TIME_REQUIRED),
  closingTime: yup
    .string()
    .required(CLOSING_TIME_REQUIRED)
    .test(
      "is-after-opening",
      "Closing Time must be after Opening Time",
      function (closingTime) {
        const { openingTime } = this.parent;
        if (!openingTime || !closingTime) return true;
        return (
          timeOptions.indexOf(closingTime) > timeOptions.indexOf(openingTime)
        );
      }
    ),
});
