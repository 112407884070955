import { useTranslation } from "react-i18next";
import { useUserMeQuery } from "../API/api";
import { useState } from "react";
import PayInHistory from "../component/PaymentHistory/PayInHistory";
import PayOutHistory from "../component/PaymentHistory/PayOutHistory";

const PaymentHistory = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading } = useUserMeQuery();
  const [isPayInActive, setIsPayInActive] = useState(true);

  const handleTabClick = (isPayInActive: boolean) => {
    setIsPayInActive(isPayInActive);
  };  
  return (
    <div>
      <div className="text-2xl text-[#272D29] font-semibold my-8">
        {t("Payment History")}
      </div>
      
        <div>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">            
              <a              
                className={`text-black hover:text-gray-700 whitespace-nowrap border-b-2 px-10 text-[19px] font-medium cursor-pointer 
                ${isPayInActive ? "border-b-2 border-[#F0A61F]" : ""}`}
                onClick={() => handleTabClick(true)}
              >
                <span>{t("Pay In")}</span> 
              </a>            
              <a
                className={`text-black hover:text-gray-700 whitespace-nowrap border-b-2 px-10 text-[19px] font-medium cursor-pointer 
                ${!isPayInActive ? "border-b-2 border-[#F0A61F]" : ""}`}
                onClick={() => handleTabClick(false)}
              >
                <span>{t("Pay Out")}</span>
              </a>            
          </nav>
        </div>      
      {isPayInActive ? <PayInHistory /> : <PayOutHistory />}
    </div>
  );
};

export default PaymentHistory;
