import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { InitCheckoutResponse } from "../API/subscriptionApi";
import appConfig from "../config/AppConfig";
import { _token } from "../utils/jwtUtils";
import CircularProgressBar from "../component/CircularProgressBar";

type InitCheckoutDto = {
  privatekey: string;
  mercid: string;
  orderid: string;
  currency: string;
  isocurrency: string;
  chmod: string;
  buyerEmail: string;
  buyerPhone: string;
  buyerFirstName: string;
  buyerLastName: string;
  buyerAddress: string;
  buyerCity: string;
  buyerState: string;
  buyerCountry: string;
  buyerPinCode: string;
  amount: string;
  UID: string;
  checksum: string;
  customvar: string;
};

const mapPaymentInit = (
  initCheckout: InitCheckoutResponse
): InitCheckoutDto | null => {
  if(!initCheckout) return null
  //console.log(initCheckout)
  return {
    privatekey: initCheckout.privateKey,
    mercid: initCheckout.mercid,
    orderid: `${initCheckout.paymentId}`,
    currency: initCheckout.currency,
    isocurrency: initCheckout.isocurrency,
    chmod: initCheckout.chmod,
    buyerEmail: initCheckout.buyerEmail,
    buyerPhone: initCheckout.buyerPhone,
    buyerFirstName: initCheckout.buyerFirstName,
    buyerLastName: initCheckout.buyerLastName,
    buyerAddress: initCheckout.buyerAddress,
    buyerCity: initCheckout.buyerCity,
    buyerState: initCheckout.buyerState,
    buyerCountry: initCheckout.buyerCountry,
    buyerPinCode: initCheckout.buyerPinCode,
    amount: `${initCheckout.amountToBePaid}`,
    UID: initCheckout.UID,
    checksum: initCheckout.checksum,
    customvar: initCheckout.paymentUUID,
  };
};

export const SendToPaymentGateway: React.FC = () => {
  const location = useLocation();
  const state = location.state as InitCheckoutResponse;

  useEffect(() => {
    //console.log(state);
    if (state) {
      // Find the form element by its ID and submit it
      const form = document.getElementById("paymentForm") as HTMLFormElement;
      form.submit();
    }
  }, [location.state]);


  return (
    <>
    <div className="flex flex-col items-center justify-center h-screen">
      <form id="paymentForm" action={appConfig.paymentGatewayUrl} method="POST">
        {/* Your form fields */}
        {Object.entries(mapPaymentInit(state) ?? {}).map(([name, value]) => (
          <input hidden key={name} type="text" name={name} value={value} />
        ))}

        {/* You can hide the submit button if you want */}
        <button type="submit" style={{ display: "none" }}></button>
      </form>
        <div className="flex items-center justify-center mt-4">
            <CircularProgressBar />
            <p className="mt-2 text-gray-600">Processing...</p>
          </div>
      </div>
    </>
  );
};
