import { Fragment, useEffect, useState } from "react";
import PieChartReferral from "../chart/PieChart";
import {
  Aborted,
  Accepted,
  ActionIcon,
  Awarded,
  ViewIcon,
} from "../../assets/images/AllSvg";
import Pagination from "../Pagination";
import { useTranslation } from "react-i18next";
import {
  jobAwardAction,
  useJobAwardedList,
  useJobStatisticsData,
} from "../../API/api";
import moment from "moment";
import { StarIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  Dialog,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { classNames } from "../../utils/utils";
import {
  AddRatingReview,
  EmployerRating,
  JobAward,
  SkillMaster,
} from "../../API/types";
import { RatingReviewDialog } from "./RatingReviewDialog";
import {
  addReview,
  updateEmployerReview,
  useReviewDetails,
} from "../../API/reviewsApi";
import { useUpdateJobCompleted } from "../services/Mutation";
import CircularProgressBar from "../CircularProgressBar";
import { FaSpinner } from "react-icons/fa";

const initialPayload = {
  jobAwardId: 0,
  review: "",
  isAnonymous: true,
  employerRatings: [
    {
      id: undefined,
      reviewId: 0,
      skillId: 0,
      rating: 0,
    },
  ],
};

export enum JobAwardStatus {
  REQUESTED = 1,
  REJECTED_BY_LABOURER = 2,
  REJECTED_BY_EMPLOYER = 3,
  ACCEPTED = 4,
  COMPLETED = 6,
  CLOSED = 5,
}

const Employer = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [actionData, setActionData] = useState({
    id: 0,
    status: 0,
  });
  const [openEmployer, setOpenEmployer] = useState(false);
  const [payloadData, setPayloadData] =
    useState<AddRatingReview>(initialPayload);
  const [reviewId, setReviewId] = useState<number | null>(null);
  const [skills, setSkills] = useState<SkillMaster[]>([]);
  const offset = (currentPage - 1) * itemsPerPage;
  const {
    data: jobAwardData,
    isLoading: jobLoading,
    refetch: jobData,
  } = useJobAwardedList(offset, itemsPerPage, true);

  const { data: jobAward, refetch: jobAwardRefetch } =
    useJobStatisticsData(false);
  const totalRows: number = jobAwardData?.total || 0;
  const startIndex: number = 0;
  const endIndex: number = Math.min(offset + itemsPerPage, totalRows);
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const setRowsPerPage = (rows: number) => {
    setItemsPerPage(rows);
    setCurrentPage(1);
  };

  const searchData: JobAward[] | undefined = jobAwardData?.jobAwards;

  const viewsData: JobAward[] | undefined = searchData?.slice(
    startIndex,
    endIndex
  );

  const handleOpenReview = (item: JobAward) => {
    const selectedJobAward = jobAwardData?.jobAwards?.find(
      (award: JobAward) => award.id === item?.id
    );

    if (selectedJobAward) {
      const skillMasterIds = selectedJobAward?.job?.jobSkillsRequireds.map(
        (skill) => skill.skillMasterId
      );
      const skills = jobAwardData?.skill?.filter((skill) =>
        skillMasterIds.includes(skill.id)
      );

      if (skills) {
        setSkills(skills);
      } else {
        setSkills([]);
      }

      if (selectedJobAward.reviewId) {
        //Wait for the review rating detail response
        setReviewId(selectedJobAward.reviewId);
      } else {
        setReviewId(null);
        const employerRatings = selectedJobAward!.job.jobSkillsRequireds!.map(
          (it) => {
            return {
              reviewId: selectedJobAward.reviewId ?? 0,
              skillId: it.skillMasterId,
              rating: 0,
            };
          }
        );
        //Update payload
        setPayloadData({
          jobAwardId: selectedJobAward.id,
          review: "",
          isAnonymous: true,
          employerRatings: employerRatings,
        });
      }
      setReviewOpen(true);
    } else {
      toast.error(t("Selected job award not found for item:"));
    }
  };

  const onReviewDetailReceived = () => {
    // Update the payload once the review details received
    var employerRatings: EmployerRating[];
    if (reviewDetail?.employerRatings) {
      employerRatings = reviewDetail.employerRatings;
      console.log(reviewDetail?.id);

      setPayloadData((prevState) => ({
        ...prevState,
        review: reviewDetail?.review ?? "",
        employerRatings: employerRatings,
      }));
    }
  };

  const { data: reviewDetail, isLoading, refetch } = useReviewDetails(reviewId);

  useEffect(() => {
    if (reviewId) {
      refetch();
    }
  }, [reviewId]);

  useEffect(() => {
    if (reviewDetail && reviewId === reviewDetail.id) {
      onReviewDetailReceived();
    }
  }, [isLoading]);

  const handleRatingChange = (item: SkillMaster, ratingStar: number) => {
    const ratings = payloadData.employerRatings.map((obj) => {
      if (obj.skillId == item.id) {
        return { ...obj, rating: ratingStar };
      } else {
        return obj;
      }
    });

    setPayloadData((prevState) => ({
      ...prevState,
      employerRatings: ratings,
    }));
  };

  const findRating = (skillId: number) => {
    if (!payloadData?.employerRatings) return 0;
    return (
      payloadData.employerRatings.find((it) => it.skillId == skillId)?.rating ??
      0
    );
  };

  const reviewAddMutation = useMutation(addReview, {
    onSuccess: (data) => {
      if (data?.id) {
        setReviewOpen(false);
        jobData();
        toast.success(t("succesfully reviewed"));
      } else {
        setReviewOpen(false);
        toast.error(data?.error?.message);
      }
    },
  });

  const updateReview = useMutation(updateEmployerReview, {
    onSuccess: (data) => {
      setReviewOpen(false);
      toast.success(t("Review Updated successfully"));
      jobData();
    },
    onError: (error) => {
      setReviewOpen(false);
      jobData();
    },
  });

  const handleSubmit = async () => {
    const selectedReviewId = payloadData?.employerRatings[0].reviewId;
    const existingReview = jobAwardData?.jobAwards?.find(
      (award: JobAward) => award?.reviewId === selectedReviewId
    );
    if (existingReview) {
      try {
        const { review } = payloadData;
        const updatedEmployerRatings = skills.map((item: any) => {
          const existingRating = payloadData.employerRatings.find(
            (ratingObj: EmployerRating) => ratingObj.skillId === item.id
          );
          return {
            reviewId: existingRating?.reviewId ?? 0,
            skillId: existingRating?.skillId ?? 0,
            rating: existingRating?.rating ?? 0,
          };
        });

        const updatedReviewData: AddRatingReview = {
          review: review,
          isAnonymous: false,
          employerRatings: updatedEmployerRatings,
        };
        updateReview.mutate({
          reviewId: existingReview.reviewId ?? 0,
          reviewData: updatedReviewData,
        });
      } catch (error) {
        setReviewOpen(false);
        jobData();
      }
    } else {
      reviewAddMutation.mutate(payloadData);
    }
  };

  const handleActionJobMutation = useMutation(jobAwardAction, {
    onSuccess: (data) => {
      jobAwardRefetch();
      jobData();
      toast.success(t("jobAbortRequestSent"));
      setOpenEmployer(false);
    },
    onError: (error) => {
      setOpenEmployer(false);
      toast.error(t("jobAbortRequestFailed"));
    },
  });

  const handleJobAwardAction = () => {
    handleActionJobMutation.mutate({
      status: actionData.status,
      jobAwardId: actionData.id,
    });
  };

  const useJobCompletedMutation = useUpdateJobCompleted();

  const handleCompleteJob = async (item: JobAward) => {
    const newJobData = {
      status: JobAwardStatus.COMPLETED,
      jobAwardId: item?.id,
    };
    try {
      await useJobCompletedMutation.mutateAsync(newJobData);
      await jobData();
      handleOpenReview(item);
    } catch (error) {
      toast.error(t("Failed to complete job:"));
    }
  };

  return (
    <>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 mt-[52px]">
        <div></div>
        <PieChartReferral jobAward={jobAward} />
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mb-8 mt-16 gap-[33px]">
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#B6FF59] flex justify-center items-center mx-auto">
              <Awarded />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.awarded}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Awarded")}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#FF4848] flex justify-center items-center mx-auto">
              <Aborted />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.aborted}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Aborted")}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#FFCB45] flex justify-center items-center mx-auto">
              <Accepted />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.accepted}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Accepted")}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#8348FF] flex justify-center items-center mx-auto">
              <Aborted />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.declined}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Declined")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-scroll pb-5">
        <table className="min-w-[968px] w-full divide-y divide-graylighter border border-[#C9C9C9]">
          <thead className="bg-[#EDEDED]">
            <tr>
              <th className=" px-[23px] py-[19px]  text-left text-sm font-semibold text-black w-[190px]">
                {t("Labourer")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Skills Required")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Awarded On")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Rate Given")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Status")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Action")}
              </th>
            </tr>
          </thead>
          {jobLoading ? (
            <tr>
              <td colSpan={6}>
                <div className="flex justify-center items-center py-8">
                  <CircularProgressBar />
                </div>
              </td>
            </tr>
          ) : (
            <tbody>
              {viewsData?.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div className="text-center items-center text-[#F0A61F] text-lg mt-3 p-10">
                      {t("No Results Found")}
                    </div>
                  </td>
                </tr>
              ) : (
                viewsData?.map((item: JobAward, index: number) => {
                  const matchedLabourer = jobAwardData?.labourers?.find(
                    (labourer: any) => labourer.id === item.labourerId
                  );
                  const matchedSkill = jobAwardData?.skill?.find(
                    (skill: any) =>
                      skill?.id ===
                      item?.job?.jobSkillsRequireds[0]?.skillMasterId
                  );

                  return (
                    <tr className="border-b-2" key={index}>
                      <td className="py-[26px] px-[23px] w-[190px]">
                        {t(matchedLabourer?.name as string)}
                      </td>
                      <td>
                        <div className="flex gap-[8px] justify-center">
                          <span className="bg-[#F0A61F] text-[13px] leading-[16px] py-[8px] px-[13px] rounded-[24px] text-secondary">
                            {t(matchedSkill?.name as string)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                          {moment(item?.awardedOn).format("DD-MM-YYYY hh:mm A")}
                        </span>
                      </td>
                      <td>
                        {item?.avgRating !== null ? (
                          <div
                            className="flex items-center justify-center text-center"
                            onClick={() => handleOpenReview(item)}
                          >
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  item?.avgRating! > rating
                                    ? "text-[#F0A61F]"
                                    : "text-[#d3d3d3]",
                                  "h-5 w-5 flex-shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                        ) : (
                          <span
                            onClick={() => handleOpenReview(item)}
                            className="font-medium flex text-[16px] text-center break-normal leading-[20px] justify-center gap-1"
                          >
                            <button className="bg-[#F0A61F] p-2 rounded-md">
                              {t("Rate Now")}
                            </button>
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {item?.status === JobAwardStatus.REQUESTED ? (
                          <span className="bg-[#eecf1d] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#272D29]">
                            {t("Pending")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status === JobAwardStatus.COMPLETED ? (
                          <span className="bg-green-600 rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Completed")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status ===
                        JobAwardStatus.REJECTED_BY_LABOURER ? (
                          <span className="bg-[#c23c3c] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Declined")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status ===
                        JobAwardStatus.REJECTED_BY_EMPLOYER ? (
                          <span className="bg-[#FF7545] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Aborted")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status === JobAwardStatus.ACCEPTED ? (
                          <span className="bg-[#00C44F] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#272D29]">
                            {t("Accepted")}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td hidden={item?.status !== JobAwardStatus.ACCEPTED}>
                        <div className="flex relative justify-center gap-[16px]">
                          <Menu placement="bottom-end">
                            <MenuHandler>
                              <button>
                                <ActionIcon className="w-[40px] focus:outline-none focus:ring-0 h-[40px] flex items-center cursor-pointer justify-center" />
                              </button>
                            </MenuHandler>
                            <MenuList className="py-1 px-0 min-w-[140px]">
                              <MenuItem
                                className="rounded-none text-center font-medium hover:bg-[#F0A61F1A] focus:bg-[#F0A61F1A]"
                                onClick={() => {
                                  setActionData({
                                    id: item.id,
                                    status: JobAwardStatus.REJECTED_BY_EMPLOYER,
                                  });
                                  setOpenEmployer(true);
                                }}
                              >
                                {t("Abort")}
                              </MenuItem>
                              <MenuItem
                                className="rounded-none text-center font-medium hover:bg-[#F0A61F1A] focus:bg-[#F0A61F1A]"
                                onClick={() => handleCompleteJob(item)}
                                disabled={useJobCompletedMutation.isLoading}
                              >
                                {useJobCompletedMutation.isLoading ? (
                                  <>
                                    <span>Completing</span>
                                    <FaSpinner className="inline-block text-[#F0A61F] animate-spin ml-2 w-5 h-5" />
                                  </>
                                ) : (
                                  "Completed"
                                )}
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          )}
        </table>
      </div>
      {(jobAwardData?.jobAwards.length ?? 0) > 0 && (
        <Pagination
          rowsPerPage={itemsPerPage}
          setRowsPerPage={setRowsPerPage}
          startRow={offset + 1}
          endRow={endIndex}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          indexOfLastRow={endIndex}
          totalNumber={totalRows ?? 0}
        />
      )}
      <Fragment>
        <RatingReviewDialog
          isLoading={
            (isLoading && reviewId != null) ||
            reviewAddMutation.isLoading ||
            updateReview.isLoading
          }
          reviewOpen={reviewOpen}
          setReviewOpen={setReviewOpen}
          skills={skills}
          findRating={findRating}
          handleRatingChange={handleRatingChange}
          review={payloadData.review}
          onReviewChanged={(text) => {
            setPayloadData((prevState) => ({
              ...prevState,
              review: text,
            }));
          }}
          handleSubmit={handleSubmit}
          reviewId={reviewId}
        />
      </Fragment>
      <Fragment>
        <Dialog
          open={openEmployer}
          handler={setOpenEmployer}
          className=" p-[20px] min-w-[100%] sm:min-w-[70%]  lg:min-w-[996px]   lg:max-h-[996px] mx-auto"
        >
          <>
            <div className="mt-5">
              <h1 className="text-[24px] text-black font-bold text-center mt-4 mb-6">
                {t("Reason for abort this job")}
              </h1>
              <div className="flex flex-col mb-10 gap-y-10">
                <div>
                  <h1 className="text-lg font-medium text-black">
                    {t("Write a Reason")}
                  </h1>
                  <textarea className="h-[210px] w-full rounded-xl bg-[#F8F8F8] mt-5" />
                </div>
                <button
                  onClick={handleJobAwardAction}
                  className="w-full py-2 bg-[#272B2A] text-white font-semibold rounded-lg text-lg"
                >
                  {t("Submit")}
                </button>
              </div>
              <div>
                <button
                  className="absolute right-[20px] text-black top-[20px] "
                  onClick={(e) => {
                    setOpenEmployer(false);
                  }}
                >
                  <XMarkIcon className="w-8 h-8 " aria-hidden="true" />
                </button>
              </div>
            </div>
          </>
        </Dialog>
      </Fragment>
    </>
  );
};

export default Employer;
