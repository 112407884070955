import { useEmployerReviews } from "../../API/reviewsApi";
import { useTranslation } from "react-i18next";

const RecentReviewChart = () => {
  const { t } = useTranslation();

  const {
    data: employerReviewMade,
    refetch,
    isLoading,
    isError,
  } = useEmployerReviews(true);
  const getEmployerName = (userId: string) => {
    return employerReviewMade?.labourers?.find(
      (labourers: any) => labourers?.id === userId
    )?.name;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  if (isError)
    return (
      <div className="flex justify-center items-center h-64 text-red-500">
        {t("Error loading reviews")}
      </div>
    );

  const sortedReviews = employerReviewMade?.employerReviews
    ?.sort(
      (a: any, b: any) =>
        new Date(b.reviewUpdatedOn).getTime() -
        new Date(a.reviewUpdatedOn).getTime()
    )
    .slice(0, 4);
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden pb-6">
      <div className="bg-gray-50 px-4 py-5 border-b border-gray-200 sm:px-6">
        <h2 className="text-xl font-bold mb-6 text-gray-800">
          {t("Recent Ratings & Reviews")}
        </h2>
      </div>

      {sortedReviews?.length === 0 ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            {t("Currently, there are no reviews.")}
          </div>
        </div>
      ) : isLoading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {sortedReviews?.map((item: any, index: number) => {
            const employerName = getEmployerName(item?.labourerId);
            return (
              <li
                key={index}
                className="px-4 py-4 sm:px-6 hover:bg-gray-50 transition duration-150 ease-in-out"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-12 w-12 rounded-full object-cover"
                      src={
                        item.profileUrl ||
                        require("../../assets/images/user_default.png")
                      }
                      alt={`${employerName}'s profile`}
                    />
                    <div className="ml-4">
                      <div className="text-sm font-medium text-indigo-600">
                        {employerName}
                      </div>
                      <div className="text-sm text-gray-500">{item?.title}</div>
                      <div className="text-xs text-gray-400">
                        {formatDate(item?.reviewUpdatedOn)}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    {/* <div className="flex">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          item?.employerRatings[0].rating > rating
                            ? "text-yellow-400"
                            : "text-gray-300",
                          "h-5 w-5 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div> */}
                    <div className="ml-2 text-sm text-gray-500">
                      {/* {item?.employerRatings[0].rating.toFixed(1)} */}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default RecentReviewChart;
