import { Dialog } from "@material-tailwind/react";
import { SkillMaster } from "../../API/types";
import StarRating from "../StarRating";
import { XMarkIcon } from "@heroicons/react/20/solid";
import CircularProgressBar from "../CircularProgressBar";

export const RatingReviewDialog = ({
  reviewOpen,
  setReviewOpen,
  skills,
  findRating,
  handleRatingChange,
  review,
  onReviewChanged,
  handleSubmit,
  reviewId,
  isLoading,
}: {
  reviewOpen: boolean;
  setReviewOpen: (status: boolean) => void;
  skills: SkillMaster[];
  findRating: (skillId: number) => number;
  handleRatingChange: (skill: SkillMaster, rating: number) => void;
  review: string;
  onReviewChanged: (text: string) => void;
  handleSubmit: any;
  reviewId: number | null;
  isLoading: boolean;
}) => {
  return (
    <Dialog
      open={reviewOpen}
      handler={() => setReviewOpen(true)}
      className="p-[20px] min-w-[100%] sm:min-w-[70%] lg:min-w-[996px] lg:max-h-[996px] mx-auto"
    >
      <>
        {isLoading && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <CircularProgressBar />
          </div>
        )}
        <div className="mt-5">
          <h1 className="text-[24px] text-black font-bold text-center mt-4 mb-6">
            Leave a Review
          </h1>
          <div className="flex flex-col mb-10 gap-y-5">
            {skills.map((item: SkillMaster) => {
              return (
                <div key={item.id} className="px-4">
                  <div className="flex items-center justify-between pb-1 text-lg font-medium text-black border-b-2">
                    {item.name}
                    <StarRating
                      rating={findRating(item.id)}
                      onRate={(newRating: number) => {
                        handleRatingChange(item, newRating);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div>
              <h1 className="pl-4 text-lg font-medium text-black">
                Write a Review
              </h1>
              <div className="px-4">
                <textarea
                  value={review}
                  onChange={(e) => onReviewChanged(e.target.value)}
                  className="h-[210px] w-full rounded-xl bg-[#F8F8F8] mt-5 text-black font-normal text-lg"
                />
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="w-full py-2 bg-[#272B2A] text-white font-semibold rounded-lg text-lg"
            >
              Submit
            </button>
          </div>

          <div>
            <button
              className="absolute right-[20px] text-black top-[20px] "
              onClick={(e) => {
                setReviewOpen(false);
              }}
            >
              <XMarkIcon className="w-8 h-8 " aria-hidden="true" />
            </button>
          </div>
        </div>
      </>
    </Dialog>
  );
};
