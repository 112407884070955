import React, { useState } from "react";
import SideBar from "../Sidebar";

type props = {
  children: any;
};

const Layout = ({ children }: props) => {
  const [open, Setopen] = useState<Boolean>();

  return (
    <div className="flex">
      <SideBar />
        {children}
    </div>
  );
};

export default Layout;