import React from "react";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { classNames, getSkillName } from "../../utils/utils";
import { Skill } from "../../API/types";
import LocationSelector from "../../pages/Location/LocationSelector";
import CustomModal from "./CustomModal";
import { useLocationContext } from "../Context/LocationContext";

interface SkillSelectionPopupProps {
  openPopup: boolean;
  setOpenPopup: (open: boolean) => void;
  details: any;
  serviceData: any;
  selectedSkill: any;
  handleSkillCheckboxClick: (skill: any) => void;
  handleModalContinue: () => void;
  isFav: boolean;
}

const SkillSelectionPopup: React.FC<SkillSelectionPopupProps> = ({
  openPopup,
  setOpenPopup,
  details,
  serviceData,
  selectedSkill,
  handleSkillCheckboxClick,
  handleModalContinue,
  isFav,
}) => {
  const { t } = useTranslation();
  const { state } = useLocationContext();
  const { selectedLocationData } = state;

  return (
    <CustomModal
      isOpen={openPopup && isFav === true}
      onClose={() => setOpenPopup(false)}
    >
      <div className="mt-5">
        <h1 className="text-[24px] text-black font-bold text-center mt-4 mb-6">
          {t("Skills")}
        </h1>
        <div className="flex flex-col flex-wrap items-center gap-2 px-3">
          <div className="flex gap-[14px] md:flex-nowrap flex-wrap w-full rounded items-center px-3 border-[#B9B9B9] border-[1px]"></div>
          <LocationSelector />

          <h2 className="w-full text-base font-semibold text-left text-black">
            {t("Select Skills")}
          </h2>
          <div className="flex flex-col border border-[#B9B9B9] rounded w-full [&>*:last-child]:border-b-0">
            {details?.skills.map((skill: Skill, index: number) => (
              <div
                key={index}
                className="flex items-center gap-1 border-b border-[#B9B9B9] w-full py-[10px] px-[15px]"
              >
                <Checkbox
                  checked={selectedSkill?.skillId === skill?.skillId}
                  onChange={() => handleSkillCheckboxClick(skill)}
                  sx={{
                    color: "#D4D4D4",
                    "&.Mui-checked": {
                      color: "#F0A61F",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 25 },
                  }}
                  size="small"
                />
                <p className="text-black text-[16px]">
                  {t(getSkillName(skill?.skillId, serviceData))}
                </p>
              </div>
            ))}
          </div>

          <button
            className={classNames(
              "bg-[#272D29] text-white font-bold rounded-[4px] p-2 mt-4 w-full",
              !selectedSkill ||
                !selectedLocationData?.lat ||
                !selectedLocationData?.lng
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100 cursor-pointer"
            )}
            disabled={
              !selectedSkill ||
              !selectedLocationData?.lat ||
              !selectedLocationData?.lng
            }
            onClick={handleModalContinue}
          >
            {t("Continue")}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SkillSelectionPopup;
