import { useTranslation } from "react-i18next";
import { UserType } from "../../API/types";

interface ChooseUserTypeProps {
  defaultUserType: UserType;
  onUserSelected: (userType: UserType) => void;
}

const ChooseUserType = (props: ChooseUserTypeProps) => {
  const { defaultUserType, onUserSelected } = props;
  const { t } = useTranslation();
  return (
    <div className="grid lg:grid-cols-1 grid-cols-1 gap-4 mt-6 place-content-center h-[81vh]">
      <div
        onClick={() => {
          onUserSelected(UserType.LABOURER);
        }}
        className={`${
          defaultUserType == UserType.LABOURER
            ? "bg-[#1d7ade] rounded-[8px] relative "
            : ""
        } p-[1px] max-h-[160px] `}
      >
        <div
          className={`${
            defaultUserType == UserType.LABOURER
              ? "bg-[#faf9fd] rounded-[8px] "
              : ""
          } h-full`}
        >
          <div
            className={`${
              defaultUserType == UserType.LABOURER
                ? " bg-[#1c7ade0f] border-[#1d7ade]"
                : ""
            } z-[2] pb-[18px] pt-[16px] px-[28px] sm:px-[15px] cursor-pointer flex flex-col border-[1.8px] rounded-[8px] border-black200 h-[100%]`}
          >
            <div className="flex items-center justify-between gap-4 text-lg font-semibold">
              {t("As labourer")}
              <div className="w-32">
                <img
                  src={require("../../assets/images/needjob.png")}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          onUserSelected(UserType.EMPLOYER);
        }}
        className={`${
          defaultUserType == UserType.EMPLOYER
            ? "bg-[#1d7ade] rounded-[8px] relative "
            : ""
        } p-[1px] max-h-[160px]`}
      >
        <div
          className={`${
            defaultUserType == UserType.EMPLOYER
              ? "bg-[#faf9fd] rounded-[8px] "
              : ""
          } h-full`}
        >
          <div
            className={`${
              defaultUserType == UserType.EMPLOYER
                ? " bg-[#1c7ade0f] border-[#1d7ade]"
                : ""
            }z-[2] pb-[18px] pt-[16px] px-[28px] sm:px-[15px] cursor-pointer flex flex-col border-[1.8px] rounded-[8px] border-black200 h-[100%]`}
          >
            <div className="flex items-center justify-between gap-4 text-lg font-semibold ">
              {t("As employer")}
              <div className="w-32">
                <img
                  src={require("../../assets/images/businesswoman.png")}
                  className="w-full "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUserType;
