import { useMutation } from "@tanstack/react-query";
import {
  NoContentResponse,
  REACT_APP_API_URL,
  apiEndpoint,
  queryClient,
} from "./api";
import { ApiError } from "./types";

export interface AddFavoriteRequest {
  labourerId: number;
}

export interface AddFavoriteResponse {
  id: number;
  labourerId: number;
  createdAt: Date;
  employerId: number;
}
type UseAddFavouriteProps = {
  onSuccessCb: (data: AddFavoriteResponse) => void;
  onErrorCb: (error: ApiError) => void;
};
//const { REACT_APP_API_URL } = process.env;

const queryKey = "favourites";

export const addFavouritesEndpoint = async (labourerId: number) => {
  const requestBody: AddFavoriteRequest = { labourerId: labourerId };
  return apiEndpoint<AddFavoriteResponse>(
    `${REACT_APP_API_URL}/favourites`,
    requestBody,
    "POST"
  );
};

export const useAddFavourite = ({
  onSuccessCb,
  onErrorCb,
}: UseAddFavouriteProps) => {
  return useMutation(addFavouritesEndpoint, {
    onSuccess: async (data: AddFavoriteResponse) => {
      await queryClient.invalidateQueries([queryKey]);
      onSuccessCb(data);
    },
    onError: async (error: ApiError) => {
      onErrorCb(error);
    },
  });
};

export const removeFavourite = async (id: number) => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/favourites/${id}`,
    {},
    "DELETE"
  );
};
