import * as yup from "yup";
import {
  COUNTRY_REQUIRED,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  PHONE_REQUIRED,
  EMAIL_REQUIRED,
  ADDRESS_REQUIRED,
  PINCODE_REQUIRED,
  STATE_REQUIRED,
  CITY_REQUIRED
} from "../constants/ValidationMessage";

let EMAIL_REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
let PHONE_REGX = /^[0-9]{10}$/;
let PINCODE_REGX = /^\d{6}$/;

export const CheckoutFormSchema = yup
  .object({
    firstName: yup.string().required(FIRST_NAME_REQUIRED).nullable(),
    lastName: yup.string().required(LAST_NAME_REQUIRED).nullable(),
    address: yup.string().required(ADDRESS_REQUIRED).nullable(),
    appartment: yup.string().required(ADDRESS_REQUIRED).nullable(),
    city: yup.string().required(CITY_REQUIRED).nullable(),
    country: yup.string().required(COUNTRY_REQUIRED),
    state: yup.string().required(STATE_REQUIRED),

    Email: yup
      .string()
      .required(EMAIL_REQUIRED)
      .matches(EMAIL_REGX, "Invalid email address"),
    pincode: yup
      .string()
      .required(PINCODE_REQUIRED)
      .matches(PINCODE_REGX, "Invalid pin code"),
    Phone: yup
      .string()
      .required(PHONE_REQUIRED)
      .matches(PHONE_REGX, "Mobile number must be exactly 10 digits")
  })
  .required();
