import { StarIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useUserMeQuery } from "../../API/api";
import { Review1 } from "../../assets/images/AllSvg";
import { classNames } from "../../utils/utils";
import { useEmployerReviewObtained } from "../../API/reviewsApi";
import { RiLoader2Fill } from "react-icons/ri";

const ReviewObtained = ({ serviceData }: any) => {
  const { t } = useTranslation();
  const { data: userData } = useUserMeQuery();
  const {
    data: employerReview,
    isLoading,
    isError,
  } = useEmployerReviewObtained(userData?.labourerDetails?.userId!);
  const getAverageRating = (skillId: any) => {
    const skillRatings = employerReview?.employerReviews.filter(
      (review: any) => review.employerRatings[0].skillId === skillId
    );

    if (skillRatings.length === 0) {
      return 0;
    }

    const totalRating = skillRatings.reduce(
      (sum: any, review: any) => sum + review.employerRatings[0].rating,
      0
    );

    const averageRating = totalRating / skillRatings.length;
    return averageRating.toFixed(1);
  };

  const uniqueSkills = Array.from(
    new Set(
      employerReview?.employerReviews?.map(
        (review: any) => review.employerRatings[0].skillId
      )
    )
  );
  const getEmployerName = (userId: any) => {
    const employer = employerReview?.users?.find(
      (user: any) => user?.id === userId
    );
    return employer?.name || t("Employer");
  };
  const LoadingDisplay = () => (
    <div className="flex flex-col items-center justify-center w-full h-[40vh]">
      <RiLoader2Fill className="w-12 h-12 text-[#F0A61F] animate-spin mb-4" />
      <p className="text-lg font-semibold">{t("Loading...")}</p>
    </div>
  );

  if (isLoading) {
    return <LoadingDisplay />;
  }

  return employerReview?.employerReviews?.length !== 0 ? (
    <div className="mt-[52px]">
      <div className="flex flex-row gap-x-[26px] lg:flex-nowrap flex-wrap">
        <div
          className="overflow-hidden bg-white shadow divide-y-[1.5px] divide-gray/50 border-[1.5px] border-gray/50
              lg:min-w-[350px] min-w-full lg:mb-0 mb-5 h-fit rounded-[11px]"
        >
          <div className="px-4 py-[25px]">
            <div className="flex items-center justify-between">
              <h2 className="text-[#F0A61F] font-semibold">
                {t("Skill Added")}
              </h2>
              <h2 className="text-[#F0A61F] font-semibold">
                {t("Star Rating")}
              </h2>
            </div>
          </div>
          <ul role="list" className="divide-y-[1.5px] divide-gray/50">
            {uniqueSkills?.map((skillId: any) => {
              const skillName = serviceData.find(
                (service: any) => service.id === skillId
              )?.name;
              const averageRating = Number(getAverageRating(skillId)); // Convert to number
              return (
                <li className="px-4 py-[25px]">
                  <div className="flex items-center justify-between font-medium">
                    <span className="text-[17px font-medium]">{skillName}</span>
                    <div className="flex items-center gap-1">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className={classNames(
                            averageRating > rating
                              ? "text-[#F0A61F]"
                              : "text-[#d3d3d3]",
                            "h-5 w-5 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="w-full">
          <div className="pb-[19px] mx-auto flex flex-col">
            <div className="">
              <div className="border p-[15px] flex items-center justify-between border-[#D1D0CA] rounded-t-[11px]">
                <div>
                  <h5>{t("Reviews")}</h5>
                </div>
              </div>
            </div>
            {employerReview?.employerReviews?.map(
              (item: any, index: number) => {
                const employerName = getEmployerName(item?.job?.userId);
                return (
                  <>
                    <div className="border p-3 pb-4 border-[#D1D0CA]">
                      <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                          <div className="min-w-[60px]">
                            {/* <Review1 /> */}
                            <img
                              className="h-14 w-14 rounded-full"
                              src={
                                item.profileUrl
                                  ? item.profileUrl
                                  : require("../../assets/images/user_default.png")
                              }
                              alt=""
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-secondary mb-2">
                              {employerName}
                            </h3>
                            <div className="flex star">
                              {[0, 1, 2, 3, 4].map((rating) => (
                                <StarIcon
                                  key={rating}
                                  className={classNames(
                                    item?.employerRatings[0].rating > rating
                                      ? "text-[#F0A61F]"
                                      : "text-gray-200",
                                    "h-5 w-5 flex-shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              ))}
                            </div>
                            <div className="mt-2">
                              <p className="text-black text-sm leading-[20px]">
                                {item?.review}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center w-full h-[40vh] font-semibold text-lg">
      {t("No reviews found")}
    </div>
  );
};

export default ReviewObtained;
