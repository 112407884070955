import React, { useState } from "react";
import { useServiceCategoryQuery, useGetFavourites } from "../../API/api";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const FavouriteListChart = () => {
  const { t } = useTranslation();
  const { data: serviceData } = useServiceCategoryQuery();
  const {
    data: favourites,
    isError,
    isLoading: favouriteLoading,
  } = useGetFavourites();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const getSkillName = (skillId:any) => {
    const skill = serviceData?.find((skill) => skill.id === skillId);
    return skill ? skill.name : "";
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentFavourites = favourites?.slice(indexOfFirstRow, indexOfLastRow);
  const totalRows = favourites?.length || 0;
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, totalRows);

  const handlePageChange = (pageNumber:any) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden ">
      <div className="bg-gray-50 px-4 py-5 border-b border-gray-200 sm:px-6">
        <h2 className="text-xl font-bold mb-6 text-gray-800">
          {t("My Favourite Labourerss")}({totalRows})
        </h2>
      </div>
      <div className="p-4">
        {favouriteLoading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : isError ? (
          <div className="text-center text-red-500">{t("Error loading data")}</div>
        ) : totalRows === 0 ? (
          <div className="text-center">{t("No favourites.")}</div>
        ) : (
          <div className="space-y-4">
            {currentFavourites?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b pb-3"
              >
                <div className="text-lg font-medium">{t(item?.name)}</div>
                <div className="flex flex-wrap gap-2">
                  {item?.skills?.map((service, idx) => (
                    <span
                      key={idx}
                      className="bg-gray-200 px-2 py-1 rounded text-sm"
                    >
                      {t(getSkillName(service?.skillId))}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {totalRows > rowsPerPage && (
          <div className="flex justify-between items-center mt-4">
            <span className="text-sm text-gray-600">
              {startRow}-{endRow} of {totalRows}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-1 border rounded disabled:opacity-50"
              >
                <BiChevronLeft className="h-5 w-5" />
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={indexOfLastRow >= totalRows}
                className="p-1 border rounded disabled:opacity-50"
              >
                <BiChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FavouriteListChart;
