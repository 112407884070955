import { useTranslation } from "react-i18next";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

interface PaginationProps {
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  startRow: number;
  endRow: number;
  totalRows: number;
  handlePageChange: (pageNumber: number) => void;
  currentPage: number;
  indexOfLastRow: number;
  filteredData?: any[];
  totalNumber: number;
}

const Pagination: React.FC<PaginationProps> = ({
  rowsPerPage,
  setRowsPerPage,
  startRow,
  endRow,
  totalRows,
  handlePageChange,
  currentPage,
  indexOfLastRow,
  totalNumber
  //t,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-[14px] items-center justify-end mb-[71px] flex-wrap mt-[27px]">
      <p className="text-[#272D29] text-[17px]">{t("rowsPerPage")}</p>
      <div className="relative text-black w-fit">
        <select
          name="number"
          className="bg-[#E9E8E3] rounded-[40px] w-[97px] h-[44px] px-[26px] outline-none"
          value={rowsPerPage}
          onChange={(e) => setRowsPerPage(Number(e.target.value))}
        >
          {[5, 10, 15, 20].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      <p className="text-[#272D29] text-[17px]">{`${startRow}-${endRow} of ${totalRows}`}</p>
      <div className="flex gap-4">
        <button
          className={`bg-${
            currentPage === 1 ? "[#E9E8E3]" : "[#FBE9C7]"
          } w-[44px] h-[44px] rounded-full flex justify-center items-center text-[24px]`}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <BiChevronLeft />
        </button>

        <button
          className={`bg-${
            indexOfLastRow >= totalNumber ? "[#E9E8E3]" : "[#FBE9C7]"
          } w-[44px] h-[44px] rounded-full flex justify-center items-center text-[24px]`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastRow >= totalNumber}
        >
          <BiChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
