import React, { useState } from "react";
import { toast } from "react-toastify";
import Copy from "../utils/clipboard";
import { useTranslation } from "react-i18next";
import { useUserMeQuery, useReferralStatus } from "../API/api";
import { useReferralLink } from "../utils/GeneralUtils";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useMediaQuery } from "@mui/material";
import {
  FaFacebookF,
  FaTelegramPlane,
  FaCopy,
  FaShareAlt,
  FaWhatsapp,
} from "react-icons/fa";

import { Credits, People, Referrals } from "../assets/images/AllSvg";

function MyReferrals() {
  const { data: userData } = useUserMeQuery();
  const { data: referralStatusData } = useReferralStatus();
  const { t } = useTranslation();
  const generateReferralLink = useReferralLink();
  const [isShareOpen, setIsShareOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const userReferralCode = userData?.user?.referralCode;
  const referralLink = userReferralCode
    ? generateReferralLink(userReferralCode)
    : "";

  const handleCopy = () => {
    Copy(referralLink);
    toast.success("Link Copied!", { autoClose: 1000 });
    setIsShareOpen(false);
  };

  const handleShare = (platform: any) => {
    let shareUrl;
    switch (platform) {
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${encodeURIComponent(referralLink)}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          referralLink
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          referralLink
        )}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, "_blank");
    setIsShareOpen(false);
  };

  const boxContents = [
    {
      number: referralStatusData?.signups || 0,
      title: "Referral SignUps",
      image: <People />,
    },
    {
      number: referralStatusData?.successfulReferrals || 0,
      title: "Successful Referrals",
      image: <Referrals />,
    },
    {
      number: referralStatusData?.creditsEarned || 0,
      title: "Credits Earned",
      image: <Credits />,
    },
  ];
  const handleToggleShare = () => {
    setIsShareOpen(!isShareOpen);
  };
  return (
    <div className="mt-5 overflow-hidden rounded-[8px] mx-auto w-full">
      <div className=" mainDiv rounded-lg  bg-white border pb-5 ">
        <div className="bg-gradient-to-r from-yellow-400  to-amber-200 p-6 text-white">
          <h1 className="text-2xl font-bold mb-2">
            {t("Refer friends & Earn credits")}
          </h1>
          <p className="text-sm opacity-90">
            {t("Sharing is Winning. Refer your friends and start earning.")}
          </p>
        </div>

        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4 text-gray-700">
            {t("Share the referral link with friends")}
          </h2>

          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <input
              value={referralLink}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              type="text"
              readOnly
            />
            <Popover
              open={isShareOpen}
              handler={setIsShareOpen}
              placement={isSmallScreen ? "bottom" : "bottom-end"}
            >
              <PopoverHandler>
                <IconButton
                  className="bg-amber-500 hover:bg-amber-300 transition-colors duration-300 w-full "
                  onClick={() => setIsShareOpen(!isShareOpen)}
                  onMouseEnter={handleToggleShare} // TRIGGER ON HOVER
                >
                  <FaShareAlt />
                </IconButton>
              </PopoverHandler>

              <PopoverContent className="p-4 flex flex-col items-start space-y-2">
                <span className="font-semibold text-gray-700">
                  {t("Share via")}
                </span>
                <div className="flex space-x-4">
                  <IconButton
                    className="bg-blue-600 text-white"
                    onClick={() => handleShare("facebook")}
                  >
                    <FaFacebookF />
                  </IconButton>
                  <IconButton
                    className="bg-green-500 text-white"
                    onClick={() => handleShare("whatsapp")}
                  >
                    <FaWhatsapp />
                  </IconButton>
                  <IconButton
                    className="bg-blue-400 text-white"
                    onClick={() => handleShare("telegram")}
                  >
                    <FaTelegramPlane />
                  </IconButton>
                  <Tooltip content="Copy link">
                    <IconButton
                      className="bg-gray-500 text-white"
                      onClick={handleCopy}
                    >
                      <FaCopy />
                    </IconButton>
                  </Tooltip>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between gap-5 w-full mt-5 mb-10   md:flex-row subDiv1">
        {boxContents?.map((box, index) => (
          <div
            key={index}
            className="flex flex-col items-center border justify-center w-full gap-8 p-4 mb-4 bg-white rounded-md shadow-sm md:w-1/3 "
          >
            <p className="text-lg font-bold text-gray-800">{box?.number}</p>
            <div className="flex items-center justify-center h-14 w-14">
              {box.image}
            </div>
            <p className="text-base text-gray-900">{t(box?.title)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyReferrals;
