import React, { useEffect, useRef } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Token } from "@mui/icons-material";
import { Megnify } from "../assets/images/AllSvg";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { LocationData, useLocationContext } from "./Context/LocationContext";
import { useGeneralSettings } from "../API/api";

interface MapComponentProps {
  showPopover: boolean;
  showSearchBar: boolean;
  popoverRef: React.RefObject<HTMLDivElement>;
  handleTogglePopover: () => void;
  onInteraction: (e: React.MouseEvent) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({
  showPopover,
  popoverRef,
  handleTogglePopover,
  showSearchBar,
  onInteraction,
}) => {
  const autocompleteRef = useRef<any>(null);
  const { dispatch } = useLocationContext();

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.geometry.location) {
      const locationData: LocationData = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        city:
          place.address_components?.find((c) => c.types.includes("locality"))
            ?.long_name || "",
        state:
          place.address_components?.find((c) =>
            c.types.includes("administrative_area_level_1")
          )?.long_name || "",
        country:
          place.address_components?.find((c) => c.types.includes("country"))
            ?.long_name || "",
      };
      dispatch({ type: "SET_LOCATION", payload: locationData });
      handleTogglePopover();
    }
  };

  const handleDetectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            { location: { lat: latitude, lng: longitude } },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
                handlePlaceSelect(results[0]);
              }
            }
          );
        },
        (error) => {
          console.error("Error detecting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (autocompleteRef.current && autocompleteRef.current.gm_accessors_) {
      const autocomplete =
        autocompleteRef.current.gm_accessors_.place.gm_accessor_.place;
      if (autocomplete) {
        google.maps.event.clearListeners(autocomplete, "place_changed");
        google.maps.event.addListener(autocomplete, "place_changed", () => {
          const place = autocomplete.getPlace();
          handlePlaceSelect(place);
        });
      }
    }
    return () => {
      //remove
      const pacContainers = document.querySelectorAll(".pac-container");
      pacContainers.forEach((container) => container.remove());
    };
  }, []);
  const { data: generalData } = useGeneralSettings();

  return (
    <div onClick={onInteraction}>
      {showPopover && (
        <div
          ref={popoverRef}
          id="popover-location"
          className={`${
            showPopover ? "block" : "hidden"
          } absolute bg-white border border-gray-300 rounded-[12px] mt-[19px] p-4 right-0 w-[360px] z-50`}
        >
          <div className="flex items-center justify-between mb-3">
            <div>
              <Token />
            </div>
            <button
              type="button"
              onClick={handleDetectLocation}
              className="text-[14px]  hover:text-indigo-700 hover:font-bold "
            >
              Current Location
            </button>
            <button
              type="button"
              onClick={handleDetectLocation}
              className="text-[14px] text-indigo-700 font-bold flex p-[3px]"
            >
              Detect Using GPS
              <div className="align-middle text-indigo-700 p-[3px]">
                <FaAngleRight />
              </div>
            </button>
          </div>

          {showSearchBar && (
            <div className="flex">
              <div className="absolute flex items-center top-[22px] bottom-0 pl-2 w-[60px] h-full">
                <Megnify className={"#272D29"} />
              </div>
              <GooglePlacesAutocomplete
                apiKey={generalData?.configurations?.GOOGLE_MAPS_API_KEY}
                ref={autocompleteRef}
                onPlaceSelected={handlePlaceSelect}
                options={{
                  types: ["(regions)"],
                  componentRestrictions: { country: "in" },
                }}
                className="outline-none border rounded-[5px] p-1 pl-8 w-full"
              />
            </div>
          )}
          <div className="absolute -z-[1]  right-[20px] top-[-7px] rotate-45 bg-white border border-gray-300 w-3 h-3"></div>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
