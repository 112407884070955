import { XMarkIcon } from "@heroicons/react/20/solid";
import { Dialog } from "@material-tailwind/react";
import { Checkbox } from "@mui/material";
import { Fragment, useState } from "react";

interface ServiceDto {
  id: number;
  name: string;
  serviceCategoryId: number;
}
interface LanguageDto {
  id: number;
  name: string;
}
const Filtermodel = (props: any) => {
  const [tempSelectedLanguages, setTempSelectedLanguages] = useState(
    props.selectedLanguages
  );
  const [tempSelectedSkill, setTempSelectedSkill] = useState(
    props.selectedSkill
  );
  const [tempSelectedSortOption, setTempSelectedSortOption] = useState(
    props.selectedSortOption
  );

  const handleLanguageToggle = (languageId: number) => {
    if (tempSelectedLanguages.includes(languageId)) {
      setTempSelectedLanguages(
        tempSelectedLanguages.filter((id: number) => id !== languageId)
      );
    } else {
      setTempSelectedLanguages([...tempSelectedLanguages, languageId]);
    }
  };

  const handleSkillToggle = (skillId: number) => {
    if (tempSelectedSkill.includes(skillId)) {
      setTempSelectedSkill(
        tempSelectedSkill.filter((id: number) => id !== skillId)
      );
    } else {
      setTempSelectedSkill([...tempSelectedSkill, skillId]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTempSelectedSortOption(event.target.value);
  };

  const handleDone = () => {
    props.setOpenFilter(false);
    props.setSelectedLanguages(tempSelectedLanguages);
    props.setSelectedSkill(tempSelectedSkill);
    props.setSelectedSortOption(tempSelectedSortOption);
    props.setOpenFilter(false);
  };
  return (
    <Fragment>
      <Dialog
        open={props?.openFilter}
        handler={() => props?.setOpenFilter(true)}
        className="md:p-4 p-4 min-w-[300px] sm:min-w-[450px] lg:min-w-[800px] max-w-full mx-auto   "
      >
        <div className="mb-1">
          <h1 className="text-2xl font-bold text-center text-[#272D29]">
            Filter
          </h1>
        </div>
        <div className="mb-1">
          <label htmlFor="sort" className="block mb-2 font-medium text-black">
            Sort by
          </label>
          <select
            id="sort"
            className="w-full py-2 px-4 mb-3 bg-white border border-gray-300 rounded focus:outline-none focus:border-yellow-500"
            value={tempSelectedSortOption}
            onChange={handleChange}
          >
            <option value="">Select...</option>
            <option value="distance ASC ">Sort By Distance ASC</option>
            <option value="distance DESC">Sort By Distance DESC</option>
            <option value="averageStarRating ASC">Sort By Reviews ASC</option>
            <option value="averageStarRating DESC">Sort By Reviews DESC</option>
          </select>
        </div>

        <div className="mb-2 p-1 bg-white rounded-md ">
          <h4 className="mb-2 text-xl font-bold text-gray-800">
            Select Languages
          </h4>
          <div className="max-h-48 overflow-y-auto rounded-md border border-gray-200">
            {props.languages?.map((lang: LanguageDto) => (
              <label
                key={lang?.id}
                className="flex  items-center justify-between pl-4 pr-4  cursor-pointer hover:bg-gray-100"
              >
                <span className="text-base font-medium text-gray-700">
                  {lang?.name}
                </span>
                <Checkbox
                  sx={{
                    color: "#D4D4D4",
                    "&.Mui-checked": {
                      color: "#F0A61F"
                    },
                    "& .MuiSvgIcon-root": { fontSize: 25 }
                  }}
                  size="small"
                  checked={tempSelectedLanguages.includes(lang?.id)}
                  onChange={() => handleLanguageToggle(lang?.id)}
                />
              </label>
            ))}
          </div>
        </div>
        <div className="mb-2  p-1 bg-white rounded-lg ">
          <h4 className=" text-xl mb-2 font-bold text-gray-800">
            Select Services
          </h4>
          <div className="max-h-48 overflow-y-auto rounded-md border border-gray-200">
            {props?.serviceData?.map((service: ServiceDto) => (
              <label
                key={service?.id}
                className="flex items-center justify-between pl-4 pr-4 cursor-pointer hover:bg-gray-100"
              >
                <span className="text-base font-medium text-gray-700">
                  {service?.name}
                </span>
                <Checkbox
                  sx={{
                    color: "#D4D4D4",
                    "&.Mui-checked": {
                      color: "#F0A61F"
                    },
                    "& .MuiSvgIcon-root": { fontSize: 25 }
                  }}
                  size="small"
                  checked={tempSelectedSkill.includes(service.id)}
                  onChange={() => handleSkillToggle(service.id)}
                />
              </label>
            ))}
          </div>
        </div>
        <button
          onClick={handleDone}
          className="w-full py-3 text-center bg-[#1E221F] border-none text-white font-bold text-base rounded-md lg:inline-block md:inline-block hover:bg-[#363c38]"
        >
          Done
        </button>
        <div>
          <button
            className="absolute right-4 top-4 text-black"
            onClick={() => {
              props.setOpenFilter(!props?.openFilter);
            }}
          >
            <XMarkIcon className="w-8 h-8" aria-hidden="true" />
          </button>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Filtermodel;
