import React, { useContext, useEffect, useRef, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Filter } from "../assets/images/AllSvg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import {
  createJob,
  jobChatRoom,
  useLanguagesQuery,
  useSearchLabourer,
  jobAwardActionPost,
  useEmployerAvailability,
  useGetFavourites,
  useServiceCategoryQuery,
  useUserMeQuery,
} from "../API/api";
import { toast } from "react-toastify";
import Filtermodel from "../component/Models/Filtermodel";
import AwardThisJob from "../component/Models/AwardThisJob";
import {
  ItemDataContext,
  ItemDataProvider,
} from "../component/Context/contextData";
import SearchResultItem from "../component/SearchServices/SearchResults";
import { SearchResult, Service, SkillDto } from "../API/types";
import useGeolocation, {
  AddressDetails,
  LocationData,
} from "../utils/useGeolocation";
import CircularProgressBar from "../component/CircularProgressBar";
import Pagination from "../component/Pagination";
import { isAuthenticated } from "../utils/jwtUtils";
import { useAppContext } from "../component/Context/AppContext";
import {
  useAddFavouriteMutation,
  useRemoveFavouriteMutation,
} from "../component/services/Mutation";
import { handleFavorites } from "../utils/favoritesUtils";
import { isLabourer } from "../utils/utils";
import BecomeLabourerModal from "../component/Models/BecomeLabourerModal";
import LocationSelector from "./Location/LocationSelector";
import { useLocationContext } from "../component/Context/LocationContext";
import AutoCompleteServices from "../component/AutoCompleteSkills/AutocompleteServices";

export interface ResultNavigationParams {
  searchInput: Service;
  location: LocationData;
}
const Result = ({
  setOpen,
  setLogin,
  _serviceData,
  selectedStatus,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setMethod: React.Dispatch<React.SetStateAction<string>>;
  _serviceData: any;
  selectedStatus: string;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: favouritesData } = useGetFavourites();
  const { data: languages } = useLanguagesQuery();
  const { data: serviceData } = useServiceCategoryQuery();

  const { state } = useLocationContext();
  const { location: _locationData, searchInput: _search } =
    (location.state as ResultNavigationParams) ?? {};
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<number[]>([]);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const navigate = useNavigate();
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openFilter, setOpenFilter] = useState(false);
  const [openAwardMenu, setOpenAwardMenu] = useState(false);
  const [selectedLocation, setSelectedLocation] =
    useState<null | AddressDetails>({
      city: "Ahmedabad",
      state: "Gujarat",
      country: "India",
    });

  const [locationData, setLocationData] = useState<LocationData | null>(
    _locationData
  );

  const { appState, setAppState } = useAppContext();

  const updateJobId = (jobId: number | null) => {
    setAppState({
      ...appState,
      jobId: jobId,
    });
  };

  const [filteredData, setFilteredData] = useState<SearchResult[] | null>();
  const { setItemData } = useContext(ItemDataContext);
  const [service, setService] = useState<Service[]>([_search]);

  const getServiceId = () => {
    return service && service.length > 0 ? service[0]?.id : null;
  };

  const getServiceName = () => {
    return service && service.length > 0 ? service[0]?.name : "";
  };

  const {
    status,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    data: _searchData,
    fetchStatus,
  } = useSearchLabourer(
    state?.selectedLocationData?.lat,
    state?.selectedLocationData?.lng,
    getServiceId(),
    selectedLanguages,
    selectedSkill,
    currentPage,
    rowsPerPage,
    selectedSortOption
  );

  const searchData = _searchData?.data;
  const totalItems = _searchData?.total;

  useEffect(() => {
    updateJobId(null);
  }, [fetchStatus]);

  useEffect(() => {
    if (serviceData && (!service || service[0] == undefined)) {
      setService([serviceData![0]]);
    }
  }, [serviceData]);

  useEffect(() => {
    updateJobId(null);
    console.info(status, fetchStatus);
  }, [status, fetchStatus]);

  const [showPopover, setShowPopover] = useState(false);
  const handleTogglePopover = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowPopover(!showPopover);
  };

  useEffect(() => {
    setFilteredData(_searchData?.data ?? null);
  }, [_searchData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    location: geoLocation,
    selectedName,
    error,
    detectLocation,
  } = useGeolocation();

  useEffect(() => {
    setLocationData(geoLocation);
    setSelectedLocation(selectedName);
    if (error) {
      console.log(error);
    }
  }, [geoLocation, selectedName, error]);

  const handleShowNumberClick = (id: number) => {
    setSelectedRowId(id);
  };

  const handleDetails = (id: number) => {
    const data = searchData?.find((item: any) => item.userId === id);
    if (data) {
      navigate("/details", {
        state: {
          details: data,
          favLabourerId: data?.id,
          alldata: searchData,
          skill: _search,
        },
      });
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = 0;
  const currentRows = searchData?.slice(indexOfFirstRow, indexOfLastRow);
  const totalRows = totalItems ?? 0;
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, totalRows);
  const [loadingStates, setLoadingStates] = useState<{
    [key: number]: { chat: boolean; award: boolean };
  }>({});

  interface FavoriteLoadingState {
    [key: number]: {
      add: boolean;
      remove: boolean;
    };
  }
  const [favoriteLoadingStates, setFavoriteLoadingStates] =
    useState<FavoriteLoadingState>({});

  // FAVOURITE ADD AND REMOVE MUTATION
  const favouritesMutation = useAddFavouriteMutation();
  const removeFavouriteMutation = useRemoveFavouriteMutation();

  //FAVOURITE UTIL FUNCTIONS
  const handleFavoritesClick = (labourerId: number) =>
    handleFavorites(
      labourerId,
      favouritesData,
      setFavoriteLoadingStates,
      removeFavouriteMutation,
      favouritesMutation,
      setOpen,
      isAuthenticated
    );

  const jobAwardMutation = useMutation(jobAwardActionPost, {
    onSuccess: () => {
      updateJobId(null);
      setOpenAwardMenu(true);
    },
  });

  const { refetch } = useEmployerAvailability(searchData as any[]);
  useEffect(() => {
    if (selectedStatus) {
      refetch();
    }
  }, [selectedStatus]);

  const createJobMutation = useMutation(createJob);

  const JobRoomMutation = useMutation(jobChatRoom, {
    onSuccess: (data: any) => {
      if (data?.error) {
        toast.error(data?.error?.message);
      } else {
        navigate("/messageCenter", { state: { chatRoomId: data.chatRoomId } });
      }
    },
    onError: (error: { message: string }) => {
      toast.error(error.message);
    },
  });

  const mapSkillIds = (skills: SkillDto[]) => {
    const skillIds: number[] = [];
    for (const value of skills) {
      if (value.skillId !== undefined) {
        skillIds.push(value.skillId);
      }
    }
    return skillIds;
  };
  const { data: userData } = useUserMeQuery();
  const handleJobAction = async (
    item: SearchResult,
    actionType: "chat" | "award"
  ) => {
    if (!isAuthenticated()) {
      setOpen(true);
      setLogin(true);
      setItemData(searchData);
      return;
    }

    // Set loading state for the current action
    setLoadingStates((prevState) => ({
      ...prevState,
      [item.userId]: { ...prevState[item.userId], [actionType]: true },
    }));

    const jobId = appState.jobId;

    try {
      if (jobId) {
        // Job already exists, proceed with the action
        await performJobAction(jobId, item.userId, actionType);
      } else {
        // Create a new job and then proceed with the action
        const newJobId = await createAndReturnJobId(item);
        updateJobId(newJobId);
        await performJobAction(newJobId, item.userId, actionType);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      // Reset loading state regardless of success or failure
      setLoadingStates((prevState) => ({
        ...prevState,
        [item.userId]: { ...prevState[item.userId], [actionType]: false },
      }));
    }
  };

  // Function to perform chat or award action
  const performJobAction = async (
    jobId: number,
    userId: number,
    actionType: "chat" | "award"
  ) => {
    if (actionType === "chat") {
      return JobRoomMutation.mutateAsync({
        jobId,
        labourerId: userId,
      });
    } else if (actionType === "award") {
      return jobAwardMutation.mutateAsync({
        jobId,
        labourerId: userId,
      });
    }
  };

  // Function to create a new job and return its ID
  const createAndReturnJobId = async (item: SearchResult) => {
    const createJobRequest = {
      location: item.location,
      latitude: item.lat,
      longitude: item.lng,
      skillIds: mapSkillIds(item.skills),
      labourerId: item.userId,
    };

    const job = await createJobMutation.mutateAsync(createJobRequest);
    return job.id as number;
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SearchResult | null>(null);

  const handleChatClick = (item: SearchResult) => {
    handleJobAction(item, "chat");
  };

  const handleAwardClick = (item: SearchResult) => {
    if (isLabourer(userData?.user)) {
      setShowConfirm(true);
      setSelectedItem(item);
    } else {
      handleJobAction(item, "award");
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    if (selectedItem) {
      handleJobAction(selectedItem, "award");
    }
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handlePlaceSelect = (place: any) => {
    setLocationData({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    });
    setSelectedLocation({
      city: JSON.parse(JSON.stringify(place?.address_components[0]?.long_name)),
      state: JSON.parse(
        JSON.stringify(
          place?.address_components[2]?.long_name
            ? place?.address_components[1].long_name
            : place?.address_components[2]?.long_name
        )
      ),
      country: JSON.parse(
        JSON.stringify(
          place?.address_components[3]?.long_name +
            " , " +
            place?.address_components[4]?.long_name
        )
      ),
    });
  };

  const handleDetectLocation = () => {
    detectLocation();
  };

  const getSkillName = (skillId: number) => {
    const skill = serviceData?.find((skill: SkillDto) => skill.id === skillId);
    return skill ? skill.name : "";
  };

  const handleSelectedService = (data: any) => {
    setService(data);
  };

  const popoverRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        popoverRef.current &&
        !(popoverRef?.current as any)?.contains(event.target)
      ) {
        setShowPopover(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [showPopover]);

  if (!service) {
    return <></>;
  }

  return (
    <ItemDataProvider>
      <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] mt-[45px] px-5 2xl:max-w-[1440px] mx-auto ">
        <button
          onClick={() => navigate("/home")}
          className="flex items-center gap-2 mb-[10px] text-[18px]"
        >
          <BiLeftArrowAlt className="text-[#0A2640] text-[24px]" /> {t("Back")}
        </button>
        <div className="justify-between lg:flex ">
          <div className="mt-6 lg:mt-24">
            <h3 className="font-bold lg:text-[55px] sm:text-[10px] md:text-[32px] text-[#162634] mb-[34px] lg:text-start text-center">
              {t("Your Search Results")}
            </h3>

            <div className="flex flex-wrap justify-center gap-5 md:flex-nowrap lg:gap-3 md:gap-3 lg:justify-start ">
              <div className="relative 2xl:min-w-[447px] min-w-[300px] md:min-w-[220px] border-[#B9B9B9] px-2">
                 <AutoCompleteServices
                  serviceData={serviceData ?? []}
                  name={getServiceName()}
                  selectedService={handleSelectedService}
                /> 
              </div>
              <div className="flex justify-between gap-3">
                <LocationSelector />

                <div>
                  <button
                    onClick={() => setOpenFilter(true)}
                    className="border-[#B9B9B9] border-[1px] rounded-[14px] sm:p-[23px] p-[20px]"
                  >
                    <Filter />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <img
              src={require("../assets/images/Resulthero.png")}
              alt="Resulthero"
              className="hidden w-full max-w-xs mx-auto mt-10 lg:mt-0 lg:max-w-none lg:block"
            />
          </div>
        </div>
        <div>
          <p className="text-black text-[21px] font-medium lg:mt-2 mt-10 mb-6">
            {isSearchLoading || isSearchFetching ? null : (
              <>
                {totalItems ?? 0} {t("resultFor")}{" "}
                <span className="cursor-pointer">“{getServiceName()}”</span>
              </>
            )}
          </p>
          {status == "loading" ? (
            <div className="flex items-center justify-center mt-8">
              <CircularProgressBar />
            </div>
          ) : null}
          {currentRows?.map((item: any) => {
            return (
              <>
                <SearchResultItem
                  item={item}
                  handleDetails={handleDetails}
                  handleShowNumberClick={handleShowNumberClick}
                  handleFavorites={handleFavoritesClick}
                  handleJobAwardClick={handleAwardClick}
                  handleChatClick={handleChatClick}
                  showPhoneNumber={selectedRowId == item.id}
                  favouritesData={favouritesData}
                  languages={languages ?? []}
                  getSkillName={getSkillName}
                  loadingStates={loadingStates}
                  favoriteLoadingStates={favoriteLoadingStates}
                  userData={userData}
                />
              </>
            );
          })}

          {showConfirm && (
            <BecomeLabourerModal
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}

          {filteredData?.length != undefined && filteredData?.length != 0 ? (
            <Pagination
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              startRow={startRow}
              endRow={endRow}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              indexOfLastRow={indexOfLastRow}
              totalNumber={totalRows ?? 0}
            />
          ) : null}
        </div>
      </div>
      <Filtermodel
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        serviceData={serviceData}
        languages={languages}
        searchData={searchData || []}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
      />
      <AwardThisJob
        setOpenAwardMenu={setOpenAwardMenu}
        openAwardMenu={openAwardMenu}
      />
    </ItemDataProvider>
  );
};

export default Result;
